import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { AccountDetailsComponent } from './account-details/account-details.component';
import { CartProductComponent } from './cart-product/cart-product.component';
import { CartComponent } from './cart/cart.component';
import { CategoriesListComponent } from './categories-list/categories-list.component';
import { CurbsideWarningComponent } from './curbside-warning/curbside-warning.component';
import { CustomiseProductComponent } from './customise-product/customise-product.component';
import { HeaderComponent } from './header/header.component';
import { LoginModalComponent } from './login/login-modal/login-modal.component';
import { LoginIndexComponent } from './login/login-index/login-index.component';
import { MenuComponent } from './menu/menu.component';
import { NotificationSettingsComponent } from './notification-settings/notification-settings.component';
import { OrderReceiptComponent } from './order-receipt/order-receipt.component';
import { ProductsListComponent } from './products-list/products-list.component';
import { StoreListComponent } from './store-list/store-list.component';
import { StorePickerComponent } from './store-picker/store-picker.component';
import { UnavailableProductsComponent } from './unavailable-products/unavailable-products.component';
import { StoreTimePickerComponent } from './store-time-picker/store-time-picker.component';
import { BrMaskerModule } from 'br-mask';
import { CartComboComponent } from './cart-combo/cart-combo.component';
import { StoreTimePickerModalComponent } from './store-time-picker-modal/store-time-picker-modal.component';
import { StoreInfoComponent } from './store-info/store-info.component';
import { VoucherComponent } from './voucher/voucher.component';
import { CustomiseModifiersComponent } from './customise-modifiers/customise-modifiers.component';
import { ErrorModalComponent } from './error-modal/error-modal.component';
import { StoreInfoModalComponent } from './store-info-modal/store-info-modal.component';
import { MyCardsComponent } from './my-cards/my-cards.component';
import { ConfirmModalComponent } from './confirm-modal/confirm-modal.component';
import { LogviewComponent } from './logview/logview.component';
import { RewardModalComponent } from './reward-modal/reward-modal.component';
import { FooterComponent } from './footer/footer.component';
import { PopoverMessageComponent } from './popover-message/popover-message.component';
import { ContactStoreMessageComponent } from './popover-message/contact-store/contact-store.component';
import { PointsEarnedComponent } from './points-earned/points-earned.component';
import { StoreTimeDisplayComponent } from './store-time-display/store-time-display.component';
import { ItemPickerModalComponent } from './item-picker-modal/item-picker-modal.component';
import { ItemPickerComponent } from './item-picker/item-picker.component';
import { ProductCardComponent } from './product-card/product-card.component';
import { BannerComponent } from './banner/banner.component';
import { CateringThresholdMessageComponent } from './popover-message/catering-threshold-message/catering-threshold-message.component';

@NgModule({
  imports: [
    BrowserModule,
    RouterModule,
    IonicModule,
    FormsModule,
    BrMaskerModule,
  ],
  declarations: [
    HeaderComponent,
    MenuComponent,
    StoreListComponent,
    StorePickerComponent,
    StoreTimeDisplayComponent,
    StoreTimePickerComponent,
    StoreTimePickerModalComponent,
    StoreInfoComponent,
    StoreInfoModalComponent,
    CurbsideWarningComponent,
    CategoriesListComponent,
    LoginIndexComponent,
    ProductsListComponent,
    CartComponent,
    CustomiseProductComponent,
    CustomiseModifiersComponent,
    CartProductComponent,
    CartComboComponent,
    UnavailableProductsComponent,
    AccountDetailsComponent,
    NotificationSettingsComponent,
    MyCardsComponent,
    OrderReceiptComponent,
    LoginModalComponent,
    VoucherComponent,
    ErrorModalComponent,
    ConfirmModalComponent,
    LogviewComponent,
    RewardModalComponent,
    FooterComponent,
    PopoverMessageComponent,
    ContactStoreMessageComponent,
    PointsEarnedComponent,
    ItemPickerModalComponent,
    ItemPickerComponent,
    ProductCardComponent,
    BannerComponent,
    CateringThresholdMessageComponent,
  ],
  exports: [
    HeaderComponent,
    MenuComponent,
    StoreListComponent,
    StorePickerComponent,
    StoreTimeDisplayComponent,
    StoreTimePickerComponent,
    StoreTimePickerModalComponent,
    StoreInfoComponent,
    StoreInfoModalComponent,
    CurbsideWarningComponent,
    CategoriesListComponent,
    LoginIndexComponent,
    ProductsListComponent,
    CartComponent,
    CustomiseProductComponent,
    CustomiseModifiersComponent,
    CartProductComponent,
    CartComboComponent,
    UnavailableProductsComponent,
    AccountDetailsComponent,
    NotificationSettingsComponent,
    MyCardsComponent,
    OrderReceiptComponent,
    LoginModalComponent,
    VoucherComponent,
    ErrorModalComponent,
    ConfirmModalComponent,
    LogviewComponent,
    RewardModalComponent,
    FooterComponent,
    PopoverMessageComponent,
    ContactStoreMessageComponent,
    PointsEarnedComponent,
    ItemPickerModalComponent,
    ItemPickerComponent,
    ProductCardComponent,
    BannerComponent,
    CateringThresholdMessageComponent,
  ],
})
export class ComponentsModule {}
