import { Injectable } from '@angular/core';
import { Store } from '../models/domain/store';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EnvironmentVariables } from '../models/environment';
import { StoreDTO } from '../models/dto/store.dto';
import { map } from 'rxjs/operators';
import { OnlineConfiguration } from '../models/domain/online-configuration';
import { StoreStock } from '../models/domain/store-stock';
import { OrderPeriodDTO } from '../models/dto/order-period.dto';
import { StoreOrderPeriods } from '../models/domain/order/order';
import { OnlineConfigurationDto } from '../models/dto/online-configuration.dto';

export abstract class IStoreClient {
  abstract get(): Observable<Array<Store>>;
  abstract getStoreStock(store: Store): Observable<StoreStock>;
  abstract getOnlineConfiguration(): Observable<OnlineConfiguration>;
  abstract getStoreOrderPeriods(store: Store): Observable<StoreOrderPeriods>;
}

@Injectable({
  providedIn: 'root',
})
export class StoreClient implements IStoreClient {
  constructor(
    private client: HttpClient,
    public variables: EnvironmentVariables
  ) {}

  getOnlineConfiguration(): Observable<OnlineConfiguration> {
    return this.client
      .get<OnlineConfigurationDto>(
        this.variables.baseApiUrl + '/Stores/GetConfiguration'
      )
      .pipe(
        map((dto) => {
          const allergenConfig: OnlineConfiguration['AllergenConfig'] =
            !!dto.AllergenText && {
              allergenText: dto.AllergenText,
              allergenLink: dto.AllergenLink,
            };
          return {
            ...dto,
            AllergenConfig: allergenConfig,
          } satisfies OnlineConfiguration;
        })
      );
  }

  get(): Observable<Array<Store>> {
    return this.client
      .get<Array<StoreDTO>>(
        this.variables.baseApiUrl + '/Stores/GetAllOperating'
      )
      .pipe(
        map((ss) =>
          ss.map((s) => {
            return StoreDTO.ToDomain(s);
          })
        )
      );
  }

  getStoreStock(store: Store): Observable<StoreStock> {
    return this.client.get<StoreStock>(
      `${this.variables.baseApiUrl}/Stores/GetStock/${store.Id}`
    );
  }

  /**
   * Gets information about the current orders in the system for a `store` on a
   * particular `date`.
   *
   * @param store The store to get info for.
   */
  getStoreOrderPeriods(store: Store): Observable<StoreOrderPeriods> {
    return this.client
      .get<Record<string, Array<OrderPeriodDTO>>>(
        `${this.variables.baseApiUrl}/Stores/${store.Id}/OrderPeriods`
      )
      .pipe(
        map((orderPeriodDtos) => {
          const periodMap = new Map(
            Object.entries(orderPeriodDtos).map(([dateStr, dtos]) => {
              const date = new Date(dateStr);
              return [
                `${date.getFullYear()}/${
                  date.getMonth() + 1
                }/${date.getDate()}`,
                dtos.map(OrderPeriodDTO.ToDomain),
              ];
            })
          );
          return periodMap;
        })
      );
  }
}
