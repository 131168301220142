import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { IMemberClient } from '../clients/member-client';
import { AppEvents } from '../models/domain/events/app-events';
import { MemberEvents } from '../models/domain/events/member-events';
import { RegisterInfo } from '../models/domain/login/register-info';
import { CurbsideDetails, Member, SavedCard } from '../models/domain/member';
import { LoginModel, LoginViewMode } from '../models/view-models/login-model';
import { SendVerificationCodeEvent } from '../models/domain/events/send-verification-code-event';
import { MemberResult } from '../models/domain/response/member-result';
import { Reward } from '../models/domain/reward';
import { DistinctBehaviorSubject } from '../helpers/rxJsHelpers';

export abstract class IMemberService {
  abstract currentMember: Member;
  abstract initialised: BehaviorSubject<boolean>;
  abstract loginModel: LoginModel;
  abstract getMemberFromAppToken(appToken: string): void;
}

@Injectable({
  providedIn: 'root',
})
export class MemberService implements IMemberService, OnDestroy {
  public referrer: string = null;
  //Initialise as anonymous
  public currentMember: Member = null;
  public initialised = new DistinctBehaviorSubject<boolean>(false);
  public loginModel = new LoginModel({
    ViewMode: LoginViewMode.PhoneNumber,
    PhoneNumber: '',
    AccessToken: '',
  });

  // TODO: Fix sparse array
  // eslint-disable-next-line no-sparse-arrays
  subscriptions: Array<Subscription> = [
    MemberEvents.SendIdentificationCode.subscribe((ev) => {
      this.sendIdentificationCode(ev);
    }),
    MemberEvents.GetMemberFromAccessToken.subscribe((l) => {
      this.getMemberFromAccessToken(l.phoneNumber, l.accessToken);
    }),
    MemberEvents.GetLoggedInMember.subscribe(() => {
      this.getLoggedInMember();
    }),
    MemberEvents.GetMemberFromAppToken.subscribe((l) => {
      this.getMemberFromAppToken(l);
    }),
    MemberEvents.Register.subscribe((r) => {
      this.register(r);
    }),
    MemberEvents.Referrer.subscribe((referrer) => (this.referrer = referrer)),
    MemberEvents.RefreshMember.subscribe(() => {
      this.getLoggedInMember();
    }),
    MemberEvents.LogOut.subscribe(() => {
      this.logOut();
    }),
    MemberEvents.UpdateMember.subscribe((m) => {
      this.updateMember(m);
    }),
    MemberEvents.SaveCard.subscribe((c) => {
      this.saveCard(c);
    }),
    MemberEvents.RemoveCard.subscribe((c) => {
      this.removeCard(c);
    }),
    AppEvents.RewardPurchased.subscribe((ev) => {
      this.rewardPurchased(ev);
    }),
  ];

  constructor(private client: IMemberClient) {}

  private rewardPurchased(ev: { price: number; reward: Reward }): void {
    if (!this.currentMember) {
      return;
    }
    if (
      !this.currentMember.Assets.some(
        (currentReward) => currentReward.Key === ev.reward.Key
      )
    ) {
      this.currentMember.Assets.unshift(ev.reward);
    }

    this.currentMember.PointsBalance.Balance.Monetary -= ev.price;
    this.currentMember.PointsBalance.Balance.NonMonetary -= ev.price * 100;
  }

  private sendIdentificationCode(ev: SendVerificationCodeEvent): void {
    if (!(ev.phoneNumber || '').match('^[0-9]{9,11}')) {
      this.loginModel.Error = 'Please enter your phone number';
      return;
    }
    this.loginModel.Error = null;
    this.loginModel.Loading = true;

    let sendCodeEvent: Observable<MemberResult>;
    if (ev.method === 'EMAIL') {
      sendCodeEvent = this.client.sendIdentificationCodeEmail(ev.phoneNumber);
    } else {
      sendCodeEvent = this.client.sendIdentificationCodeSms(ev.phoneNumber);
    }
    sendCodeEvent.subscribe((r) => {
      if (r.Success) {
        this.loginModel.ViewMode = LoginViewMode.AccessToken;
      } else if (r.Reason == 'register') {
        this.loginModel.ViewMode = LoginViewMode.Register;
      } else {
        this.loginModel.Error = r.Reason;
      }

      this.loginModel.Loading = false;
    });
  }

  private getMemberFromAccessToken(phoneNumber: string, accessToken: string) {
    if (accessToken == null || accessToken.length < 4) {
      this.loginModel.Error = 'Please enter your verification code';
      return;
    }

    this.loginModel.Error = null;
    this.loginModel.Loading = true;

    // let phoneNumber = this.loginModel.PhoneNumber;

    // phoneNumber = "02" + (this.loginModel.PhoneNumber || "").replace(/ /g, "");

    //phone number service not currently working
    this.client
      .getMemberFromAccessToken(phoneNumber, accessToken)
      .subscribe((r) => {
        if (r.Success) {
          this.setCurrentMember(r.Member);
        } else {
          this.loginModel.Error = r.Reason;
        }
        //Allow events to trigger when AccessToken is confirmed
        this.initialised.next(true);
        this.loginModel.Loading = false;
      });
  }

  public getMemberFromAppToken(appToken: string): void {
    this.client.getMemberFromAppToken(appToken).subscribe((r) => {
      MemberEvents.InApp.next(true);
      MemberEvents.ExternalAuthToken.next(appToken);
      if (
        r.Success &&
        r.Member.FirstName &&
        r.Member.FirstName.trim() &&
        r.Member.LastName &&
        r.Member.LastName.trim()
      ) {
        this.setCurrentMember(r.Member);
      } else {
        AppEvents.LogEvent.emit(
          'Could not get member from App Token: ' + r.Reason
        );
        this.loginModel.Error = r.Reason;
      }
      //Allow events to trigger when AppToken is confirmed
      this.initialised.next(true);
    });
  }

  private getLoggedInMember() {
    const isPreviouslyLoggedIn = this.client.isPreviouslyLoggedIn();
    if (!isPreviouslyLoggedIn) {
      this.initialised.next(true);
      return;
    }
    MemberEvents.LoggingIn.next(true);
    this.client
      .getLoggedInMember()
      .pipe(
        catchError((error) => {
          return of({
            Success: false,
            Member: null,
            Reason: error?.Message,
          });
        })
      )
      .subscribe((r) => {
        if (r) {
          if (r.Success) {
            this.setCurrentMember(r.Member);
          } else {
            this.loginModel.Error = r.Reason;
          }
        }
        //Allow events to trigger when login status is confirmed
        MemberEvents.LoggingIn.next(false);
        this.initialised.next(true);
      });
  }

  private register(registerInfo: RegisterInfo) {
    const memberError = this.validateMemberRegisterInfo(registerInfo);
    if (memberError) {
      this.loginModel.Error = memberError;
      return;
    }

    if (this.referrer) {
      registerInfo.Referrer = this.referrer;
    }

    //Default to enabling email if customer enabled loyalty program when signing up
    if (registerInfo.LoyaltyProgram) {
      registerInfo.AllowEmail = true;
    }

    if (!registerInfo.TermsAndConditions) {
      this.loginModel.Error = 'Please accept terms and conditions.';
      return;
    }
    this.loginModel.Error = null;
    this.loginModel.Loading = true;
    this.client.register(registerInfo).subscribe((r) => {
      if (r.Success) {
        this.loginModel.ViewMode = LoginViewMode.AccessToken;
        MemberEvents.Referrer.next(null);
        MemberEvents.RegistrationSuccessful.next({
          email: registerInfo.EmailAddress,
        });
      } else {
        this.loginModel.Error = r.Reason;
      }
      this.loginModel.Loading = false;
    });
  }

  private setCurrentMember(member: Member) {
    MemberEvents.Referrer.next(null);
    if (member.PointsBalance?.Balance?.Monetary) {
      //Align the points balance with points costs
      //Points balance is delivered as if it were in cents
      //So 47 points comes through as 4700
      //The point descriptions on assets in the points shop comes through
      // unmultiplied and does not need adjusting
      member.PointsBalance.Balance.Monetary /= 100;
    }

    // Remove all rewards that are not yet bought - i.e. they are a point shop
    // item. These rewards can be purchased separately.
    const comoPointShopPrefix = 'ps_';
    member.Assets = member.Assets?.filter(
      (reward) => !reward.Key.startsWith(comoPointShopPrefix)
    );

    this.currentMember = member;
    MemberEvents.CurrentMember.next(this.currentMember);
    //Emit the current member, even if null, to indicate that no member has been logged in
    MemberEvents.LoggedIn.emit(this.currentMember);

    member.CurbsideDetails ??= new CurbsideDetails();
    member.NotificationSettings ??= {};
    member.PaymentMethods ??= [];

    this.loginModel = new LoginModel({
      ViewMode: LoginViewMode.PhoneNumber,
      Error: null,
    });
  }

  private saveCard(card: SavedCard) {
    this.client.saveCard(this.currentMember, card).subscribe(() => {
      this.getLoggedInMember();
    });
  }

  private removeCard(card: SavedCard) {
    if (this.currentMember.PaymentMethods.includes(card)) {
      this.currentMember.PaymentMethods.splice(
        this.currentMember.PaymentMethods.indexOf(card),
        1
      );
      MemberEvents.MemberUpdated.emit(this.currentMember);
      this.client.removeCard(this.currentMember, card).subscribe(() => {});
    }
  }

  private updateMember(member: Member) {
    const validation = this.validateMember(member);
    if (validation) {
      MemberEvents.UpdateMemberFailed.emit(validation);
      return;
    }

    this.loginModel.Loading = true;
    this.client.updateMember(member).subscribe((m) => {
      this.loginModel.Loading = false;

      if (m.Success == false) {
        MemberEvents.UpdateMemberFailed.emit(m.Reason);
        return;
      }
      this.currentMember = member;
      MemberEvents.CurrentMember.next(member);
      MemberEvents.MemberUpdated.emit(member);
    });
  }

  //Validate Member Register Info
  //Returns null if the register info is valid
  private validateMemberRegisterInfo(registerInfo: RegisterInfo): string {
    const phoneNumber = (registerInfo.PhoneNumber || '').replace(/ /g, '');
    const confirmPhoneNumber = (
      this.loginModel.ConfirmPhoneNumber || ''
    ).replace(/ /g, '');

    if (phoneNumber) {
      const first2numbers = phoneNumber.slice(0, 2);
      if (first2numbers !== '02') {
        return 'Phone number must start with 02';
      }
    }

    if (!confirmPhoneNumber) {
      return 'Please confirm phone number';
    }
    if (phoneNumber !== confirmPhoneNumber) {
      return 'Confirm phone number does not match phone number';
    }

    //Validating other register info
    const validateRegisterInfo = this.validateMember(registerInfo);
    return validateRegisterInfo;
  }
  private validateMember(member: RegisterInfo | Member): string {
    //First Name and Last name Validation
    if (member.FirstName?.match(/\S/g) == null) {
      return 'Please enter your first name.';
    }

    if (member.LastName?.match(/\S/g) == null) {
      return 'Please enter your last name.';
    }
    //validNameRegex below will allow a-z and also macrons alphabets
    //e.g. Māui-pōtiki or Kevin ßáçøñ or Sinéad O’Connor
    const validNameRegex = "^[a-zA-Z’. \\u00C0-\\u00FF\\u0100-\\u017F'`\\-]+$";

    if (!(member.FirstName || '').match(validNameRegex)) {
      return 'First name does not allow special characters or numbers';
    }

    if (member.FirstName.length > 98) {
      return 'First name must be less than 99 characters';
    }

    if (!(member.LastName || '').match(validNameRegex)) {
      return 'Last name does not allow special characters or numbers';
    }

    if (member.LastName.length > 98) {
      return 'Last name must be less than 99 characters';
    }

    //Email Address Validation
    if (
      !(member.EmailAddress || '').match(
        '^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$'
      )
    ) {
      return 'Please enter your email address.';
    }

    //Birthday Validation
    if (member.Birthday) {
      //check if valid date
      if (
        !member.Birthday.match(
          '^(0?[1-9]|[12][0-9]|3[01])/(0?[1-9]|1[012])/[0-9]{4}$'
        )
      ) {
        return 'Please check your birthday is correct.';
      }

      const birthday = new Date(this.adjustBirthdayFormat(member.Birthday));

      if (
        birthday.toString() === 'Invalid Date' ||
        birthday.getFullYear() < 1900
      ) {
        return 'Please check your birthday is correct.';
      } else if (
        new Date().getTime() - birthday.getTime() <
        1000 * 60 * 60 * 24 * 365.25 * 16
      ) {
        return 'Please note that you must be at least 16 years old to sign up for online ordering';
      }
    }

    return null;
  }

  private adjustBirthdayFormat(birthday: string): string {
    if (!birthday) {
      return birthday;
    } else {
      return birthday.split('/').reverse().join('/');
    }
  }

  private logOut(): void {
    this.currentMember = null;
    this.client.logOut();
    MemberEvents.LoggedOut.emit();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }
}
