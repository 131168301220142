import { Injectable, OnDestroy } from '@angular/core';
import { AppEvents } from '../models/domain/events/app-events';
import { NavigationEvents } from '../models/domain/events/navigation-events';
import { Order } from '../models/domain/order/order';
import { OrderCombo } from '../models/domain/order/order-combo';
import { OrderProduct } from '../models/domain/order/order-product';
import { MemberEvents } from '../models/domain/events/member-events';
import { Member } from '../models/domain/member';
import { distinct, merge, zip } from 'rxjs';
import { filter, first, map } from 'rxjs/operators';
import { sha256 } from '../helpers/hashHelpers';

@Injectable({
  providedIn: 'root',
})
export class GTMService implements OnDestroy {
  private subscriptions = [
    AppEvents.AddProduct.subscribe((p) => this.addProduct(p)),
    AppEvents.AddCombo.subscribe((c) => this.addCombo(c)),
    AppEvents.ChangeQuantity.subscribe((p) =>
      this.changeQuantity(p.OrderProduct, p.Change)
    ),
    AppEvents.ChangeComboQuantity.subscribe((c) =>
      this.changeComboQuantity(c.OrderCombo, c.Change)
    ),
    NavigationEvents.NavigateToPayment.subscribe((o) => {
      this.navigateToPayment(o.Order);
    }),
    AppEvents.OrderSubmitted.subscribe((o) => {
      this.orderSubmitted(o);
    }),
    MemberEvents.LoggedIn.subscribe((member) => {
      this.loggedIn(member);
    }),
    MemberEvents.LoggedOut.subscribe(() => {
      this.loggedOut();
    }),
    zip(
      MemberEvents.RegistrationSuccessful.pipe(first()),
      MemberEvents.Referrer.pipe(
        filter((r) => !!r),
        first()
      )
    ).subscribe(([registerInfo, referrer]) => {
      this.registeredWithReferrer(registerInfo.email, referrer);
    }),
    merge(AppEvents.OrderLoaded, AppEvents.OrderIdSet)
      .pipe(
        map((order) => order?.IdSignature),
        distinct()
      )
      .subscribe((orderIdSignature) => {
        this.setOrderId(orderIdSignature);
      }),
  ];

  private addProduct(product: OrderProduct) {
    const dataToTrackGtm = {
      category: 'order',
      event: 'addToCart',
      ecommerce: {
        currencyCode: 'NZD',
        add: {
          products: [
            {
              name: product.Name,
              id: product.PLU,
              price: product.ItemPrice,
              category: product.Category,
              quantity: product.Quantity,
            },
          ],
        },
      },
    };
    this.pushToDataLayer(dataToTrackGtm);
  }

  private changeQuantity(product: OrderProduct, change) {
    if (change > 0) {
      this.addProduct(product);
    } else {
      const dataToTrackGtm = {
        category: 'order',
        event: 'removeFromCart',
        ecommerce: {
          currencyCode: 'NZD',
          add: {
            products: [
              {
                name: product.Name,
                id: product.PLU,
                price: product.ItemPrice,
                category: product.Category,
                quantity: product.Quantity,
              },
            ],
          },
        },
      };
      this.pushToDataLayer(dataToTrackGtm);
    }
  }

  private addCombo(combo: OrderCombo) {
    const dataToTrackGtm = {
      category: 'order',
      event: 'addToCart',
      ecommerce: {
        currencyCode: 'NZD',
        add: {
          products: [
            {
              name: combo.Name,
              id: combo.PLU,
              price: combo.ItemPrice,
              category: combo.Category,
              quantity: combo.Quantity,
            },
          ],
        },
      },
    };
    this.pushToDataLayer(dataToTrackGtm);
  }

  private changeComboQuantity(combo: OrderCombo, change) {
    if (change > 0) {
      this.addCombo(combo);
    } else {
      const dataToTrackGtm = {
        category: 'order',
        event: 'removeFromCart',
        ecommerce: {
          currencyCode: 'NZD',
          add: {
            products: [
              {
                name: combo.Name,
                id: combo.PLU,
                price: combo.ItemPrice,
                category: combo.Category,
                quantity: combo.Quantity,
              },
            ],
          },
        },
      };
      this.pushToDataLayer(dataToTrackGtm);
    }
  }

  private navigateToPayment(order: Order) {
    const dataLayerProducts = [];
    for (const combo of order.Combos) {
      dataLayerProducts.push({
        id: combo.PLU,
        name: combo.Name,
        price: combo.ItemPrice,
        quantity: combo.Quantity,
        category: combo.Category,
      });
    }
    for (const product of order.Products) {
      dataLayerProducts.push({
        id: product.PLU,
        name: product.Name,
        price: product.ItemPrice,
        quantity: product.Quantity,
        category: product.Category,
      });
    }

    this.pushToDataLayer({
      event: 'checkout',
      ecommerce: {
        checkout: {
          actionField: { step: 1 },
          currencyCode: 'NZD',
          products: dataLayerProducts,
        },
      },
    });
  }

  private orderSubmitted(order: Order) {
    const dataLayerProducts = [];
    order.Combos.forEach((combo) => {
      dataLayerProducts.push({
        id: combo.PLU,
        name: combo.Name,
        price: combo.ItemPrice,
        quantity: combo.Quantity,
        category: combo.Category ?? 'NULL CATEGORY', //TODO: Include Category in products and delete that product dictionary
      });
    });
    order.Products.forEach((product) => {
      dataLayerProducts.push({
        id: product.PLU,
        name: product.Name,
        price: product.ItemPrice,
        quantity: product.Quantity,
        category: product.Category ?? 'NULL CATEGORY', //TODO: Include Category in products and delete that product dictionary
      });
    });

    let couponCode = '';
    if (order.Coupons.length > 0) {
      couponCode = order.Coupons[0].Code;
    } else if (order.ComoRewards.length > 0) {
      couponCode = order.ComoRewards[0].code ?? order.ComoRewards[0].key;
    }

    this.pushToDataLayer({
      event: 'purchase',
      ecommerce: {
        purchase: {
          actionField: {
            id: order.IdSignature,
            revenue: order.Total,
            coupon: couponCode,
          },
          currencyCode: 'NZD',
          products: dataLayerProducts,
        },
      },
    });
  }

  private registeredWithReferrer(email: string, referrer: string) {
    this.pushToDataLayer({
      event: 'new_user_referred',
      customer: {
        new_registration: true,
        user_email: email,
        user_email_hashed: this.hashEmail(email),
        referrer: referrer,
      },
    });
  }

  private loggedIn(member: Member) {
    this.setCustomerEmail(member.EmailAddress);
  }

  private loggedOut() {
    this.setCustomerEmail(null);
  }

  private setCustomerEmail(email: string | null) {
    this.pushToDataLayer({
      customer: {
        user_email: email,
        user_email_hashed: this.hashEmail(email),
      },
    });
  }

  private hashEmail(email: string): string {
    return email && sha256(email.toLowerCase().trim());
  }

  private setOrderId(orderIdSignature: string) {
    this.pushToDataLayer({
      order_id: orderIdSignature,
    });
  }

  private pushToDataLayer(obj: any) {
    (window as any).dataLayer.push(obj);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }
}
