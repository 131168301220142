import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { Combo } from 'src/app/models/domain/combo';
import { Product } from 'src/app/models/domain/product';
import { CouponRequirements } from 'src/app/models/view-models/coupon-model';
import { MenuService } from 'src/app/services/menu.service';
import { OrderService } from 'src/app/services/order.service';
import { ItemPickerModalDismissEvent } from '../item-picker-modal/item-picker-modal-dismiss-event';
import { OrderCombo } from 'src/app/models/domain/order/order-combo';
import { OrderProduct } from 'src/app/models/domain/order/order-product';
import { CustomiseModifiersComponent } from '../customise-modifiers/customise-modifiers.component';
import { AppEvents } from 'src/app/models/domain/events/app-events';
import { EnvironmentVariables } from 'src/app/models/environment';

type ItemCombo = {
  itemType: 'combo';
  item: Combo;
  orderItem: OrderCombo;
};
type ItemProduct = {
  itemType: 'product';
  item: Product;
  orderItem: OrderProduct;
};

type PickedItem = ItemCombo | ItemProduct;

type ItemPickerPage = {
  isDiscountedPage: boolean;
  // *-TODO:Combos-*
  // requiredCombos: ItemCombo[];
  requiredProducts: ItemProduct[];
};

/**
 * Names of indexes 1 through to 10. (NOT ZERO)
 */
const indexString = {
  1: 'first',
  2: 'second',
  3: 'third',
  4: 'fourth',
  5: 'fifth',
  6: 'sixth',
  7: 'seventh',
  8: 'eighth',
  9: 'ninth',
  10: 'tenth',
};

@Component({
  selector: 'app-item-picker',
  templateUrl: './item-picker.component.html',
  styleUrls: ['./item-picker.component.scss'],
})
export class ItemPickerComponent implements OnInit, OnDestroy {
  @Input()
  couponRequirements: CouponRequirements;

  @Input()
  redeemableType: 'coupon' | 'reward';

  /**
   * `false` when we don't know which item will be discounted. When this is
   * `true` we should show the discounted set as $0, otherwise we display the
   * original price.
   *
   * i.e. when a subset of products are included in both required and discounted
   * lists, so the cheaper one will be discounted, but we don't know which is
   * cheaper until both are selected.
   */
  overlappingItemStatus: 'none' | 'discount' | 'order';

  pages: ItemPickerPage[];
  selectedPageIndex: number;
  selectedPage: ItemPickerPage;
  pageTitle: string;

  selectedItem: PickedItem;
  selectedItemValid = false;
  private selectedItems: PickedItem[] = [];

  public subscriptions: Subscription[] = [];

  constructor(
    public orderService: OrderService,
    public menuService: MenuService,
    public environmentVariables: EnvironmentVariables,
    public modalController: ModalController
  ) {}

  ngOnInit(): void {
    this.initPages();
    this.overlappingItemStatus = this.getOverlappingItemStatus();

    this.selectPage(0);
  }

  /**
   * Converts the category options of each set into individual items, so each
   * list consists of products only.
   */
  private initPages(): void {
    const pages: ItemPickerPage[] = [];
    const missingItems = [
      ...this.couponRequirements.missingRequired,
      ...this.couponRequirements.missingDiscounted,
    ].filter((req) => req.length);
    for (const requirementSet of missingItems) {
      // *-TODO:Combos-*
      // const requiredCombos: { [plu: string]: Combo } = {};
      const requiredProducts: { [plu: number]: Product } = {};

      for (const requirement of requirementSet) {
        for (const category of this.menuService.currentMenu.Categories) {
          if (requirement.itemType === 'Category') {
            if (category.Name === requirement.name) {
              // *-TODO:Combos-*
              // for (const combo of category.Combos) {
              //   requiredCombos[combo.PLU] = combo;
              // }
              for (const product of category.Products) {
                requiredProducts[product.PLU] = product;
              }
            }
          } else {
            // The requirement is a single product/combo
            for (const product of category.Products) {
              if (product.PLU === requirement.plu) {
                requiredProducts[product.PLU] = product;
              }
            }
            // *-TODO:Combos-*
            // for (const combo of category.Combos) {
            //   if (combo.PLU === requirement.plu.toString()) {
            //     requiredProducts[combo.PLU] = combo;
            //   }
            // }
          }
        }
      }

      const isDiscountedPage =
        this.couponRequirements.missingDiscounted.includes(requirementSet);

      // *-TODO:Combos-*
      // const requiredOrderCombos: ItemPickerPage['requiredCombos'] =
      //   Object.values(requiredCombos).map((combo) => {
      //     return {
      //       itemType: 'combo',
      //       item: combo,
      //       orderItem: OrderCombo.FromCombo(combo),
      //     };
      //   });
      const requiredOrderProducts: ItemPickerPage['requiredProducts'] =
        Object.values(requiredProducts).map((product) => {
          return {
            itemType: 'product',
            item: product,
            orderItem: OrderProduct.FromProduct(product),
          };
        });

      pages.push({
        isDiscountedPage,
        // *-TODO:Combos-*
        // requiredCombos: requiredOrderCombos,
        requiredProducts: requiredOrderProducts,
      });
    }

    this.pages = pages;
  }

  /**
   * Returns:
   *
   * * 'discount' when there is at least one PLU that exists in both a required
   * and a discounted page
   *
   * * 'order' when there is an item in the order that qualifies as a
   * required/discounted item
   *
   * * 'none' when there are no items on the order + required + discounted pages
   * that could be the same.
   */
  private getOverlappingItemStatus(): this['overlappingItemStatus'] {
    const allRequiredPLUs = [];
    const allDiscountedPLUs = [];
    for (const page of this.pages.filter((p) => !p.isDiscountedPage)) {
      // *-TODO:Combos-*
      // for (const combo of page.requiredCombos.map((item) => item.item)) {
      //   allRequiredPLUs.push(combo.PLU);
      // }
      for (const product of page.requiredProducts.map((item) => item.item)) {
        allRequiredPLUs.push(product.PLU);
      }
    }

    // Check the discounted products against the required products
    for (const page of this.pages.filter((p) => p.isDiscountedPage)) {
      // for (const combo of page.requiredCombos.map((item) => item.item)) {
      //   if (allRequiredPLUs.includes(combo.PLU)) {
      //     return 'discount';
      //   }
      //   allDiscountedPLUs.push(combo.PLU);
      // }
      for (const product of page.requiredProducts.map((item) => item.item)) {
        if (allRequiredPLUs.includes(product.PLU)) {
          return 'discount';
        }
        allDiscountedPLUs.push(product.PLU);
      }
    }

    // Check the items already in the order against each group
    // for (const combo of this.orderService.order.Combos) {
    //   if (
    //     allRequiredPLUs.includes(combo.PLU) ||
    //     allDiscountedPLUs.includes(combo.PLU)
    //   ) {
    //     return 'order';
    //   }
    // }
    for (const product of this.orderService.order.Products) {
      if (
        allRequiredPLUs.includes(product.PLU) ||
        allDiscountedPLUs.includes(product.PLU)
      ) {
        return 'order';
      }
    }

    return 'none';
  }

  /**
   * Navigates to the previous page if there is one, and selects the item the
   * user had previously chosen on that page.
   */
  previousPage(): void {
    if (!this.selectedPageIndex) {
      return;
    }
    this.selectItem(this.selectedItems.pop());
    this.selectPage(this.selectedPageIndex - 1);
  }

  /**
   * Navigates to the next page if there is one, and adds the selected item to
   * the list of selected items. If the last page was already being displayed,
   * then calls `finish()` instead of going to the next one.
   */
  nextPage(): void {
    if (!this.selectedItem) {
      return;
    }

    this.selectedItems.push(this.selectedItem);
    this.selectItem(null);

    if (this.selectedPageIndex + 1 >= this.pages.length) {
      this.finish();
      return;
    }
    this.selectPage(this.selectedPageIndex + 1);
  }

  /**
   * Navigates to page `pageNumber`, sets the new title, and, if there is only
   * one option on the page, selects it.
   */
  private selectPage(pageNumber: number) {
    if (pageNumber < 0 || pageNumber >= this.pages.length) {
      return;
    }

    this.selectedPageIndex = pageNumber;
    this.selectedPage = this.pages[pageNumber];
    switch (this.overlappingItemStatus) {
      case 'none': {
        if (
          this.redeemableType === 'coupon' ||
          !this.selectedPage.isDiscountedPage
        ) {
          const itemStr =
            this.pages.filter((page) => page.isDiscountedPage).length > 1
              ? 'items'
              : 'item';

          this.pageTitle = `Select your ${
            this.selectedPage.isDiscountedPage ? 'discounted' : 'required'
          } ${itemStr}`;
        } else {
          this.pageTitle = `Select your ${this.environmentVariables.loyaltyConfig.rewardsName}`;
        }

        break;
      }
      case 'discount': {
        this.pageTitle = `Select your ${indexString[pageNumber + 1]} item`;
        break;
      }
      case 'order': {
        this.pageTitle = 'Select an item';
        break;
      }
    }
  }

  /**
   * Called when the user chooses a product/combo in the modal. Depending on
   * if the item is a product or combo, calls either `chooseCombo()` or
   * `chooseProduct()`.
   */
  chooseItem(event: Event): void {
    const selection = (event as CustomEvent<{ value: ItemCombo | ItemProduct }>)
      .detail?.value;

    if (!selection) {
      return;
    }

    if (selection.itemType === 'combo') {
      // *-TODO:Combos-*
      // this.chooseCombo(selection);
    } else {
      this.chooseProduct(selection);
    }
  }

  /**
   * Sets the `product` as the selected item.
   */
  private chooseProduct(product: ItemProduct): void {
    if (
      !this.selectedPage.requiredProducts
        .map((requiredProduct) => requiredProduct.item.PLU)
        .includes(product.item.PLU)
    ) {
      return;
    }

    const requiresModifierSelection = product.orderItem.ModifierGroups.some(
      (group) => {
        return (
          group.Selection < group.SelectionRequirement ||
          (group.SelectionLimit && group.Selection > group.SelectionLimit)
        );
      }
    );

    if (requiresModifierSelection) {
      this.showModifierSelection(product);
    } else {
      this.selectItem(product);
    }
  }

  /**
   * Sets the `combo` as the selected item.
   */
  // *-TODO:Combos-*
  // TODO: Fully implement like `chooseProduct()`, and allow modifier selection
  // in `showModifierSelection()`
  // private chooseCombo(combo: ItemCombo): void {
  //   if (
  //     !this.selectedPage.requiredCombos
  //       .map((requiredCombo) => requiredCombo.item.PLU)
  //       .includes(combo.item.PLU)
  //   ) {
  //     return;
  //   }

  //   this.selectItem(combo);
  // }

  /**
   * Selects an item and checks it is valid.
   *
   * @param item The item being selected, or `null` to remove selection.
   */
  private selectItem(item: PickedItem | null): void {
    if (!item) {
      this.selectedItemValid = false;
      this.selectedItem = null;

      return;
    }

    let isValid = null;
    // *-TODO:Combos-*
    // if (item.itemType === 'combo') {
    //   for (const comboItem of item.orderItem.Products) {
    //     const isComboItemValid = OrderComboItem.Validate(comboItem);
    //     if (isValid === null) {
    //       isValid = isComboItemValid;
    //     } else if (!isComboItemValid) {
    //       isValid = false;
    //     }
    //   }
    // }

    if (item.itemType === 'product') {
      const isProductValid = OrderProduct.Validate(item.orderItem);
      if (isValid === null) {
        isValid = isProductValid;
      } else if (!isProductValid) {
        isValid = false;
      }

      if (isProductValid) {
        AppEvents.CalculatePrice.emit(item.orderItem);
      }
    }

    this.selectedItemValid = isValid ?? false;
    this.selectedItem = item;
  }

  async showModifierSelection(product: ItemProduct): Promise<void> {
    if (!product.item.ModifierGroups?.length) {
      return;
    }

    const customiseModifiersModal = await this.modalController.create({
      component: CustomiseModifiersComponent,
      componentProps: {
        orderProduct: product.orderItem,
      },
      cssClass: 'show-header-modal',
    });
    customiseModifiersModal.onDidDismiss().then(() => {
      this.selectItem(product);
    });
    await customiseModifiersModal.present();
  }

  /**
   * Dismisses the modal and returns the selected items to the caller.
   */
  finish(): void {
    const res: ItemPickerModalDismissEvent = {
      code: 'SELECTED',
      // *-TODO:Combos-*
      // selectedCombos: this.selectedItems
      //   .filter((item) => item.itemType === 'combo')
      //   .map((item) => item.orderItem as OrderCombo),
      selectedProducts: this.selectedItems
        .filter((item) => item.itemType === 'product')
        .map((item) => item.orderItem as OrderProduct),
    };
    this.modalController.dismiss(res);
  }

  /**
   * Dismisses the modal with no selected items.
   */
  dismiss(): void {
    this.modalController.dismiss({
      code: 'DISMISSED',
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }
}
