import { OrderModifierGroup } from '../../domain/order/order-modifier-group';
import { OrderProduct } from '../../domain/order/order-product';
import { DietaryRequirementDto } from '../dietary-requirement.dto';
import { OrderModifierDTO } from './order-modifier.dto';

export class OrderProductDTO {
  public Id: string;
  public Quantity: number;
  public TotalPrice: number;
  public DisplayName: string;
  public SelectedModifiers: Array<OrderModifierDTO>;
  public ImageUrl: string;
  public BasePrice: number;
  public Price: number;
  public Name: string;
  public Description: string;
  public Category: string;
  public PLU: number;
  public DietaryRequirements: DietaryRequirementDto[];

  //Required by Como, so we can identify which cart item the Como api applied the discount to
  public LineId: number;
  //Required by como which uses it as departmentCode
  public CategoryId: string;

  constructor(o: Partial<OrderProductDTO>) {
    Object.assign(this, o);
  }

  public static FromDomain(product: OrderProduct): OrderProductDTO {
    const dto: OrderProductDTO = {
      Id: product.ProductId,
      Quantity: product.Quantity,
      BasePrice: product.BasePrice,
      Price: product.ItemPrice,
      TotalPrice: product.TotalPrice,
      DisplayName: product.DisplayName,
      ImageUrl: product.ImageUrl,
      SelectedModifiers: product.ModifierGroups.map((mg) => mg.Modifiers)
        .reduce((m1, m2) => {
          return m1.concat(m2);
        }, [])
        .filter((m) => m.Included != m.Selected)
        .map((m) => OrderModifierDTO.FromDomain(m)),

      Category: product.Category,
      Name: product.Name,
      Description: product.Description,
      PLU: product.PLU,
      DietaryRequirements: product.DietaryRequirements,
      CategoryId: product.CategoryId,
      LineId: product.LineId,
    };
    return dto;
  }

  public static ToDomain(dto: OrderProductDTO): OrderProduct {
    const product = new OrderProduct({
      ProductId: dto.Id,
      Quantity: dto.Quantity,
      BasePrice: dto.BasePrice,
      TotalPrice: dto.TotalPrice,
      DisplayName: dto.Name,
      ImageUrl: dto.ImageUrl,
      ModifierGroups: [
        new OrderModifierGroup({
          Modifiers: dto.SelectedModifiers
            ? dto.SelectedModifiers.map((m) => OrderModifierDTO.ToDomain(m))
            : [],
        }),
      ],
      Description: dto.Description,
      Category: dto.Category,
      Name: dto.Name,
      PLU: dto.PLU,
      DietaryRequirements: (dto.DietaryRequirements || []).map(
        DietaryRequirementDto.toDomain
      ),
      CategoryId: dto.CategoryId,
      LineId: dto.LineId,
      //Only relevant for products that are in combos
      Selected: dto.Quantity > 0,
    });
    return product;
  }
}
