/**
 * Reasons why the `OrderTime` is disabled.
 */
export enum OrderTimeDisabledReason {
  /** The time is disabled due to other orders filling the time slot. */
  DISABLED_KITCHEN_LIMITS = 'DISABLED_KITCHEN_LIMITS',

  /**
   * The time is disabled due to the size of the order being too large as set
   * by catering thresholds.
   */
  DISABLED_CATERING_THRESHOLD = 'DISABLED_CATERING_THRESHOLD',

  /** The time is disabled because the order can't be fit. */
  DISABLED_ORDER_SIZE = 'DISABLED_ORDER_SIZE',

  /** The time is disabled because of the store's wait time. */
  DISABLED_WAIT_TIME = 'DISABLED_WAIT_TIME',
}
