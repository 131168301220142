import { OrderDay } from '../models/domain/order/order-day';
import { OrderTime } from '../models/domain/order/order-time';
import { addTime } from './dateHelpers';

/**
 * Returns a JS `Date` object from the `OrderDay` and `OrderTime`.
 *
 * @param date
 * @param time
 */
export const getDateTime = (date: OrderDay, time: OrderTime): Date => {
  return addTime(date.Date, {
    hours: time.Time.getHours(),
    minutes: time.Time.getMinutes(),
    seconds: time.Time.getSeconds(),
    milliseconds: time.Time.getMilliseconds(),
  });
};
