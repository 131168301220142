/**
 * Information about the utilisation of the kitchen across a 5 minute period to
 * help ensure that a kitchen is not overwhelmed by more orders that can be
 * processed.
 */
export class OrderPeriod {
  /** ID of the store. */
  public StoreId: string;

  /**
   * Time in minutes from midnight to `StartTime`.
   */
  public Offset: number;

  /**
   * Start time of the period (inclusive). This should be a multiple of 5
   * minutes.
   */
  public StartTime: Date;

  /**
   * End time of the period (exclusive). This should be a multiple of 5
   * minutes and should be exactly 5 minutes after `StartTime`.
   */
  public EndTime: Date;

  /**
   * Total of the orders that are being prepared by the kitchen between
   * `StartTime` and `EndTime`.
   */
  public Total: number;

  constructor(data: Partial<OrderPeriod>) {
    Object.assign(this, data);
  }
}
