import { Component, Input } from '@angular/core';
import { Menu } from 'src/app/models/domain/menu';
import { Order } from 'src/app/models/domain/order/order';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss'],
})
export class CartComponent {
  @Input()
  order: Order;

  @Input()
  menu: Menu;

  /**
   * Renders the cart in read only view. Items will not be able to be modified.
   */
  @Input()
  readonly?: boolean;
}
