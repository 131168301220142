import { OrderTime } from './order-time';

export class OrderDay {
  public Label: string;
  public Date: Date;
  public DayOfWeek: number;

  public AvailableOrderTimes: Array<OrderTime>;

  constructor(o: Partial<OrderDay>) {
    Object.assign(this, o);
  }
}
