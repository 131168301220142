import { DietaryRequirement } from '../dietary-requirement';
import { Product, SaleTimeFrame } from '../product';
import { OrderModifier } from './order-modifier';
import { OrderModifierGroup } from './order-modifier-group';

/**
 * A product that has been added to an order, rather than just the config for a
 * product that could be added.
 *
 * @see Product
 */
export class OrderProduct {
  public ProductId: string;
  public Name: string;
  public DisplayName: string;
  public ShortName: string;
  public Description: string;
  public ImageUrl: string;

  public Quantity: number;
  public ModifierGroups: Array<OrderModifierGroup>;
  //Base price without any modifiers
  public BasePrice: number;
  public BasePriceInCombo: number;
  //Price with modifiers applied
  public ItemPrice: number;
  /** Total price before discounts, i.e., Price * Quantity. */
  public SubTotal: number;
  /** Price removed from the products. */
  public Discount: number;
  /** Total price after discounts, i.e., Price * Quantity - Discount. */
  public TotalPrice: number;
  public Category: string;
  public SaleTimeFrame: SaleTimeFrame;

  public PLU: number;
  public DietaryRequirements: DietaryRequirement[];

  //Used only by combos checkbox for display purposes
  //Means quantity is > 0
  public Selected: boolean;

  //Calculated by front end, not needed on server side
  public TimeAvailable: boolean;
  public OutOfStock: boolean;
  public IsHiddenOutOfStock: boolean;

  //What is returned from the API when retrieving a submitted order
  public Price: number;

  /**
   * The 'line' of the receipt the combo is on. Used to differentiate it from
   * other products/combos that may be the same product/combo but with some
   * differences, e.g. modifiers.
   */
  // Required by Como, so we can identify which cart item the Como api applied the discount to
  public LineId: number;

  /**
   * The PLU of the category of the product.
   */
  // Required by Como
  public CategoryId: string;

  /**
   * The modifications that have been added or removed from the product.
   *
   * This is used client side only.
   */
  public ModifierErrorText: string;

  /**
   * The modifications that have been added or removed from the product.
   *
   * This is used client side only.
   */
  public validatedModifications: OrderModifier[];

  constructor(o: Partial<OrderProduct>) {
    Object.assign(this, o);
  }

  public static FromProduct(product: Product): OrderProduct {
    return new OrderProduct({
      ProductId: product.Id,
      DisplayName: product.DisplayName,
      Name: product.Name,
      ShortName: product.ShortName,
      Description: product.Description,
      ImageUrl: product.ImageUrl,
      Quantity: 1,
      BasePrice: product.Price,
      BasePriceInCombo: product.PriceInCombo,
      ItemPrice: product.Price,
      Discount: 0,
      TotalPrice: product.Price,
      ModifierGroups: product.ModifierGroups.map((mg) => {
        return new OrderModifierGroup({
          Name: mg.Name,
          Modifiers: mg.Modifiers.map(OrderModifier.fromModifier),
          SelectionLimit: mg.SelectionLimit,
          SelectionRequirement: mg.SelectionRequirement,
          Selection: mg.Modifiers.filter((m) => m.Included).length,
        });
      }),
      Category: product.Category,
      PLU: product.PLU,
      DietaryRequirements: [],
      SaleTimeFrame: product.SaleTimeFrame
        ? {
            OpenTime: product.SaleTimeFrame.OpenTime,
            CloseTime: product.SaleTimeFrame.CloseTime,
          }
        : null,
      IsHiddenOutOfStock: product.IsHiddenOutOfStock,
      OutOfStock: product.OutOfStock,
      TimeAvailable: product.TimeAvailable,
    });
  }

  public static ApplyToOrderProduct(
    from: OrderProduct,
    to: OrderProduct
  ): OrderProduct {
    to.Quantity = from.Quantity;
    to.Selected = to.Quantity > 0;
    to.ModifierGroups.forEach((mg) => {
      mg.Modifiers.forEach((m) => {
        //If a modifier is found in the from products "SelectedModifiers"
        //Then it must have been selected or deselected
        //So apply that change to the "to" product
        //"From" should only have one modifier group, that contains all the selections from
        //Each of the "to" modifiers
        const relevantModifiers = from.ModifierGroups.map((mg2) =>
          mg2.Modifiers.filter((dm) => dm.ModifierId == m.ModifierId)
        ).reduce((m1, m2) => {
          return m1.concat(m2);
        }, []);
        if (relevantModifiers.length > 0) {
          m.Selected = relevantModifiers[0].Selected;
          m.Included = relevantModifiers[0].Included;
        }
      });
      to.DietaryRequirements = from.DietaryRequirements;
    });
    return to;
  }

  /**
   * Validates the product, setting the `Product.validatedModifications` and
   * `Product.ModifierErrorText` fields and returns the `true` if the validation
   * is successful.
   */
  public static Validate(product: OrderProduct): boolean {
    let valid = true;
    const invalidGroups: string[] = [];
    const selections: OrderModifier[] = [];
    product.ModifierGroups.forEach((mg) => {
      if (
        mg.Selection < mg.SelectionRequirement ||
        (mg.SelectionLimit > 0 && mg.Selection > mg.SelectionLimit)
      ) {
        valid = false;
        invalidGroups.push(mg.Name);
      } else {
        selections.push(
          ...mg.Modifiers.filter((m) => m.Included != m.Selected)
        );
      }
    });

    product.validatedModifications = selections.sort((a, b) => {
      if (a.Selected === b.Selected) return 0;
      return a.Selected ? 1 : -1;
    });

    if (invalidGroups.length > 0) {
      product.ModifierErrorText = 'Please select ' + invalidGroups.join(', ');
    } else {
      product.ModifierErrorText = '';
    }

    return valid;
  }
}
