import { EventEmitter } from '@angular/core';
import { Category } from '../category';
import { Combo } from '../combo';
import { NavigationPage, OrderPage } from '../navigation-page';
import { Order } from '../order/order';
import { Product } from '../product';
import { SaleTypePageNavigationEvent } from './sale-type-page-navigation-event';

export class NavigationEvents {
  public static NavigateToPage = new EventEmitter<NavigationPage>();
  //Allows table ordering intercept
  public static NavigateToOrderPage = new EventEmitter<{
    Page: OrderPage;
    Order: Order;
  }>();
  public static NavigateToSaleTypePage =
    new EventEmitter<SaleTypePageNavigationEvent>();
  public static NavigateToProduct = new EventEmitter<Product>();
  public static NavigateToCombo = new EventEmitter<Combo>();
  public static NavigateToCategory = new EventEmitter<{
    Category: Category;
    Order: Order;
  }>();

  public static NavigateToPayment = new EventEmitter<{
    Order: Order;
    PaymentOption: string;
  }>();
  public static NavigateToPaymentResult = new EventEmitter<{
    idSignature: string;
    status?: string;
    sessionId?: string;
  }>();
  public static NavigateToConfirm = new EventEmitter<string>();
  /** A navigation menu was opened, e.g. the side drawer. */
  public static MenuOpening = new EventEmitter();
}
