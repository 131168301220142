import { Type } from '@angular/core';
import { IMemberClient } from '../clients/member-client';
import { IMenuClient } from '../clients/menu-client';
import { IOrderClient } from '../clients/order-client';
import { IStoreClient } from '../clients/store-client';
import { Category } from './domain/category';
import { IRewardClient } from '../clients/reward-client';
import { IConfigClient } from '../clients/config-client';

export class Environment {
  production: boolean;
  menuClient: Type<IMenuClient>;
  configClient: Type<IConfigClient>;
  storeClient: Type<IStoreClient>;
  memberClient: Type<IMemberClient>;
  rewardClient: Type<IRewardClient>;
  orderClient: Type<IOrderClient>;
  variables: EnvironmentVariables;
}
export class EnvironmentVariables {
  brandTradeName: string;
  brandPath: string;
  comoLocationId: string;
  appInsightsInstrumentationKey: string;
  pageTitle: string;
  favicon: string;
  baseApiUrl: string;
  paymentUrl: string;
  futureOrderDays: number;
  defaultMenu: number;
  logoLocation: string;
  categoryPLUs: { [category: string]: number };
  displayCombosSeparately: boolean;
  /** Timers and intervals cause hanging in e2e, disable for e2e testing */
  disableTimers: boolean;
  gtmCode: string;
  googleMapsApiKey: string;
  fbPixelCode: string;
  facebookLink: string;
  instagramLink: string;
  tiktokLink: string;
  youtubeLink: string;
  termsLink: string;
  privacyLink: string;
  categories: Array<Category>;
  currencyFormat: string;
  cateringConfig: CateringConfig;
  loyaltyConfig: LoyaltyConfig;
  gstPercentage: number;
  /** Speed in milliseconds for the slider to auto transition */
  sliderSpeed: 8000;
  mapConfig: MapConfig;
  notificationsConfig: NotificationsConfig;
}

export type CateringConfig = {
  cateringEnabled: boolean;
};
export type LoyaltyConfig = {
  loyaltyLink: string;
  loyaltyLabel: string;
  loyaltyEnabled: boolean;
  /** Header on the reward modal */
  rewardsModalTitle: string;
  rewardShopSubtitle: string;
  /** The verb for earning points, 'earn' or 'clock' */
  earnVerb: string;
  /** What the metric is called, points or 'mpb' */
  pointsName: string;
  /** What each asset or deal is called, perk or reward */
  rewardsName: string;
  /** Number of points earned in loyalty per dollar spent */
  pointRatio: number;
  /** Number of decimal places to display */
  pointDecimals: number;
  /** Whether the purchasable rewards exist in loyalty and should be fetched from the server. */
  purchaseRewardsEnabled: boolean;
  /** Main label for the loyalty toggle on Notification model under updating account */
  loyaltyToggleLabel: string;
  /** Description for the loyalty toggle on Notification model under updating account  (Small text under the main label) */
  loyaltyToggleLabelDescription: string;
  /** Main label for the email toggle on Notification model under updating account */
  emailToggleLabel: string;
  /** Description for the email toggle on Notification model under updating account (Small text under the main label) */
  emailToggleLabelDescription: string;
  /** User-friendly time, e.g., "15 minutes", "30 minutes" or "2 hours" */
  usedRewardLockTime: string;
  /** Loyalty logo to display on sign up, currently only for referred customers */
  loyaltyLogo: string;
  /** Loyalty 'Call to Action' Text to display on sign up, currently only for referred customers */
  loyaltyReferredSignUpText: string;
};

export type MapConfig = {
  features: {
    featureType: 'all' | 'road.highway';
    stylers: Partial<{
      /**
       * Sets the colour of the feature to exactly this value, overriding any
       * hue/saturation/lightness values.
       */
      color: string;
      /**
       * The direction on the colour wheel for the feature to take.
       *
       * Saturation/Lightness is ignored. Google maps just uses this for the
       * orientation on the colour wheel. Therefore for consistency, use a fully
       * saturated colour wheel value, i.e. the value at the edge of the wheel.
       *
       * If this value is set, the feature will still retain its default
       * saturation/lightness value from google maps, allowing the features in
       * the map to differentiate themselves even with the same hue (unlike
       * setting the value in `color`).
       */
      hue: string;
      saturation: number;
    }>;
  }[];
  mapMarkers: {
    /** CSS colour of the label, i.e. 'green', '#00FF00', etc */
    labelColour: string;
    iconWidth: number;
    iconHeight: number;
  };
};

export type NotificationsConfig = {
  /** Enable Notifications */
  notificationsEnabled: boolean;
  /** Show loyalty section under notifications panel updating account*/
  showLoyaltySection: boolean;
};
