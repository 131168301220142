import { OrderModifier } from '../../domain/order/order-modifier';
import { OrderModifierGroup } from '../../domain/order/order-modifier-group';
import { OrderProduct } from '../../domain/order/order-product';
import { SaleType, Store, WeekOpenTime } from '../../domain/store';
import { OrderModifierDTO } from './order-modifier.dto';

export class OrderStoreDTO {
  public Id: string;
  public Address: string;
  public City: string;
  public CurbsideMapImageLocation: string;
  public DisplayName: string;
  public Latitude: number;
  public Longitude: number;
  public Name: string;
  public PhoneNumber: string;
  public Suburb: string;

  public WeekOpenTimes: Array<WeekOpenTime>;

  constructor(o: Partial<OrderStoreDTO>) {
    Object.assign(this, o);
  }

  public static FromStore(store: Store): OrderStoreDTO {
    if (!store) {
      return null;
    }
    const dto: OrderStoreDTO = {
      Id: store.Id,
      Address: store.Address,
      City: store.City,
      CurbsideMapImageLocation: store.CurbsideMapImageLocation,
      DisplayName: store.DisplayName,
      Latitude: store.Latitude,
      Longitude: store.Longitude,
      Name: store.Name,
      PhoneNumber: store.PhoneNumber,
      Suburb: store.Suburb,
      WeekOpenTimes: store.WeekOpenTimes,
    };
    return dto;
  }

  public static ToStore(dto: OrderStoreDTO): Store {
    if (!dto) {
      return null;
    }
    const store = new Store({
      Id: dto.Id,
      Address: dto.Address,
      City: dto.City,
      CurbsideMapImageLocation: dto.CurbsideMapImageLocation,
      DisplayName: dto.DisplayName,
      Latitude: dto.Latitude,
      Longitude: dto.Longitude,
      Name: dto.Name,
      PhoneNumber: dto.PhoneNumber,
      Suburb: dto.Suburb,
      WeekOpenTimes: dto.WeekOpenTimes,
    });
    return store;
  }
}
