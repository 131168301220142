import { Component, Input } from '@angular/core';

/**
 * A simple component that asks the user to contact a store by their phone
 * number.
 */
@Component({
  selector: 'app-contact-store-message',
  templateUrl: './contact-store.component.html',
  styleUrls: ['./contact-store.component.scss'],
})
export class ContactStoreMessageComponent {
  /**
   * Contact phone number of the store.
   */
  @Input()
  phoneNumber: string;
}
