import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { LogviewService } from 'src/app/services/logview.service';

@Component({
  selector: 'app-logview',
  templateUrl: './logview.component.html',
  styleUrls: ['./logview.component.scss'],
})
export class LogviewComponent implements OnInit {
  constructor(
    public modalController: ModalController,
    public logviewService: LogviewService
  ) {}
  logstring: string;
  ngOnInit() {
    this.logstring = this.logviewService.logs.join('\n');
  }

  close() {
    this.modalController.dismiss();
  }
}
