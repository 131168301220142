import { OrderCombo } from '../../domain/order/order-combo';
import { OrderModifierDTO } from './order-modifier.dto';
import { OrderProductDTO } from './order-product.dto';

export class OrderComboDTO {
  public Id: string;
  public Name: string;
  public ImageUrl: string;

  public Quantity: number;
  public Items: Array<OrderComboItemDTO>;

  public SelectedProducts: Array<OrderProductDTO>;

  public Active: boolean;
  public PLU: string;

  //What is returned from the API when retrieving a submitted order
  public Price: number;

  public ItemPrice: number;
  public TotalPrice: number;
  public Description: string;

  public HideProductsAndModifiers: boolean;

  //Required by Como, so we can identify which cart item the Como api applied the discount to
  public LineId: number;
  //Required by como which uses it as departmentCode
  public CategoryId: string;

  public Category: string;
  public static FromDomain(combo: OrderCombo): OrderComboDTO {
    const dto: OrderComboDTO = {
      Id: combo.ComboId,
      Quantity: combo.Quantity,
      Price: combo.Price,
      ItemPrice: combo.ItemPrice,
      TotalPrice: combo.TotalPrice,
      Name: combo.DisplayName,
      ImageUrl: combo.ImageUrl,
      Active: true,
      Category: combo.Category,
      Items: combo.Products.map((p) => {
        return {
          Name: p.Name,
          IsModifiers: p.IsModifiers,
          Products: p.Products
            ? p.Products.map((p2) => OrderProductDTO.FromDomain(p2))
            : [],
          Modifiers: p.Modifiers
            ? p.Modifiers.map((m) => OrderModifierDTO.FromDomain(m))
            : [],
          MaxItems: p.MaxItems,
          MinItems: p.MinItems,
          SelectionCount: p.SelectionCount,
        };
      }),
      SelectedProducts: combo.Products.map((comboItem) =>
        //Any product with a quantity greater than 0 is selected
        comboItem.Products.filter((p) => p.Quantity > 0).map((p) =>
          OrderProductDTO.FromDomain(p)
        )
      ).reduce((a, b) => a.concat(b), []),
      Description: combo.Description,
      HideProductsAndModifiers: combo.HideProductsAndModifiers,
      PLU: combo.PLU,
      CategoryId: combo.CategoryId,
      LineId: combo.LineId,
    };
    return dto;
  }

  public static ToDomain(dto: OrderComboDTO): OrderCombo {
    const combo = new OrderCombo({
      ComboId: dto.Id,
      Quantity: dto.Quantity,
      Price: dto.Price,
      TotalPrice: dto.TotalPrice,
      DisplayName: dto.Name,
      ImageUrl: dto.ImageUrl,
      Products: (dto.Items || []).map((i) => {
        return {
          Name: i.Name,
          IsModifiers: i.IsModifiers,
          Products: i.Products
            ? i.Products.map((p) => OrderProductDTO.ToDomain(p))
            : [],
          Modifiers: i.Modifiers
            ? i.Modifiers.map((m) => OrderModifierDTO.ToDomain(m))
            : [],
          MaxItems: i.MaxItems,
          MinItems: i.MinItems,
          SelectionCount: i.Products.map((p) => p.Quantity).reduce(
            (a, b) => a + b,
            0
          ),
        };
      }),
      Category: 'Combos',
      Description: dto.Description,
      Name: dto.Name,
      PLU: dto.PLU,
      CategoryId: dto.CategoryId,
      HideProductsAndModifiers: dto.HideProductsAndModifiers,
      LineId: dto.LineId,
    });
    return combo;
  }
}

export class OrderComboItemDTO {
  public Name: string;
  public IsModifiers: boolean;
  public Products: Array<OrderProductDTO>;
  public Modifiers: Array<OrderModifierDTO>;
  public MaxItems: number;
  public MinItems: number;
  public SelectionCount: number;
}
