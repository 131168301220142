import { Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { ViewDidEnter } from '@ionic/angular';
import { timer } from 'rxjs';
import { filter, first } from 'rxjs/operators';
import { NavigationEvents } from 'src/app/models/domain/events/navigation-events';
import { AccountPage, OrderPage } from 'src/app/models/domain/navigation-page';
import { EnvironmentVariables } from 'src/app/models/environment';
import { IMemberService } from 'src/app/services/member.service';
import { OrderService } from 'src/app/services/order.service';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss'],
})
export class AccountComponent implements OnDestroy, ViewDidEnter {
  @ViewChild('scroll', { read: ElementRef }) scroll: ElementRef<HTMLElement>;

  tabRoutes = ['details', 'notification-settings', 'my-cards'];
  activeRoute = '';

  AccountPage = AccountPage;

  subscriptions = [
    this.router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        const url = (e as NavigationEnd).url;
        this.activeRoute = url.substring(url.lastIndexOf('/') + 1);
        if (this.activeRoute == '' || this.activeRoute == 'account') {
          this.activeRoute = 'details';
        }
        this.scrollToActiveRoute();
      }
    }),
  ];

  constructor(
    public memberService: IMemberService,
    public orderService: OrderService,
    public router: Router,
    public variables: EnvironmentVariables
  ) {
    if (!variables.loyaltyConfig.loyaltyEnabled) {
      this.tabRoutes.splice(this.tabRoutes.indexOf('notification-settings'), 1);
    }
    this.memberService.initialised
      .pipe(
        filter((i) => i),
        // Initialised should only be set to `true` one time, this is a safeguard
        first()
      )
      .subscribe(() => {
        if (!this.memberService.currentMember) {
          NavigationEvents.NavigateToOrderPage.emit({
            Page: OrderPage.MainMenu,
            Order: this.orderService.order,
          });
        }
      });
  }

  ionViewDidEnter(): void {
    timer(100).subscribe(() => this.scrollToActiveRoute());
  }

  //routerLink links break after one navigation
  navigate(e: Event, page: AccountPage): void {
    e.preventDefault();
    e.stopPropagation();
    this.router.navigateByUrl('account/' + page);
  }

  scrollToActiveRoute(): void {
    if (!this.activeRoute) {
      return;
    }
    const tabIndex = this.tabRoutes.indexOf(this.activeRoute);

    if (tabIndex == -1) {
      return;
    }
    const navEl = this.scroll.nativeElement.children[tabIndex] as HTMLElement;
    this.scroll.nativeElement.scrollTo({
      left: navEl.offsetLeft - 100,
      top: 0,
      behavior: 'smooth',
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }
}
