import {
  AfterViewInit,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { ModalController, PopoverController } from '@ionic/angular';
import { AppEvents } from 'src/app/models/domain/events/app-events';
import { Member } from 'src/app/models/domain/member';
import { Order } from 'src/app/models/domain/order/order';
import { OrderTime } from 'src/app/models/domain/order/order-time';
import { SaleType, Store } from 'src/app/models/domain/store';
import { StoreChangeEvent } from 'src/app/models/domain/events/store-change-event';
import { StorePickerComponent } from '../store-picker/store-picker.component';
import { EnvironmentVariables } from 'src/app/models/environment';
import { timer } from 'rxjs';
import { OrderTimeChangedReason } from 'src/app/models/domain/events/order-time-changed-reason';
import { OrderTimeChangeEvent } from 'src/app/models/domain/events/order-time-changed-event';
import { OrderDay } from 'src/app/models/domain/order/order-day';
import { filter } from 'rxjs/operators';
import { OrderDayChangeEvent } from 'src/app/models/domain/events/order-day-changed-event';
import { CateringThresholdMessageComponent } from '../popover-message/catering-threshold-message/catering-threshold-message.component';

@Component({
  selector: 'app-store-list',
  templateUrl: './store-list.component.html',
  styleUrls: ['./store-list.component.scss'],
})
export class StoreListComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input()
  stores: Array<Store>;

  @Input()
  order: Order;

  @Input()
  member: Member;

  @Input()
  mode: string; //dropdown(default)|link

  @Input()
  readonly?: boolean;

  public activeSaleTypes = new Array<SaleType>();

  public systemChangedDay = false;
  public systemChangedTime = false;

  //Binding in HTML
  public SaleType = SaleType;

  public subscriptions = [
    AppEvents.StoreChanged.subscribe(() => {
      this.onStoreChanged();
    }),
    AppEvents.OrderDayChanged.subscribe((ev) => {
      this.onOrderDayChangedSuccessfully(ev);
    }),
    AppEvents.OrderTimeLastChanged.subscribe((ev) => {
      this.onOrderTimeSuccessfullyChanged(ev);
    }),
  ];

  constructor(
    public modalController: ModalController,
    public variables: EnvironmentVariables,
    public popoverController: PopoverController
  ) {}

  ngOnInit(): void {
    //If component is loaded before order, trigger here instead
    this.subscriptions.push(
      AppEvents.OrderLoaded.pipe(filter((o) => o != null)).subscribe(() => {
        this.getStoreSaleTypes();
      })
    );
  }

  ngAfterViewInit(): void {
    timer(100).subscribe(() => {
      this.getStoreSaleTypes();
    });
  }

  /**
   * Sets `systemChangedTime` depending on `OrderTimeChangeEvent.Reason`.
   */
  private onOrderTimeSuccessfullyChanged(ev: OrderTimeChangeEvent) {
    this.systemChangedTime = this.didSystemChangeTime(ev.Reason);
  }

  /**
   * Sets `systemChangedTime` depending on `OrderTimeChangeEvent.Reason`.
   */
  private onOrderDayChangedSuccessfully(ev: OrderDayChangeEvent) {
    this.systemChangedDay = this.didSystemChangeTime(ev.Reason);
  }

  private didSystemChangeTime(reason: OrderTimeChangedReason) {
    return (
      reason === OrderTimeChangedReason.ORDER_DAY_NOT_AVAILABLE ||
      reason === OrderTimeChangedReason.ORDER_SIZE_KITCHEN_LIMITS ||
      reason === OrderTimeChangedReason.ORDER_SIZE_CATERING_THRESHOLD
    );
  }

  async openStorePicker(): Promise<void> {
    if (this.readonly) {
      return;
    }
    const storePickerModal = await this.modalController.create({
      component: StorePickerComponent,
      componentProps: {
        stores: this.stores,
        currentStore: this.order.Store,
      },
      cssClass: 'show-header-modal',
      id: 'StorePicker',
    });
    storePickerModal.onDidDismiss().then((d) => {
      const data = d.data as Store;
      if (data) {
        this.onChangeStore(data);
      }
    });
    await storePickerModal.present();
  }

  private onChangeStore(store: Store): void {
    if (this.readonly) {
      return;
    }
    if (store !== this.order.Store) {
      AppEvents.ChangeStore.emit(new StoreChangeEvent(store, this.order));
    }
  }

  //Store changed by store service
  private onStoreChanged() {
    this.getStoreSaleTypes();
  }

  /**
   * Returns `true` when `a` and `b` have the same `Offset`. This is needed
   * because the object references might be different even when they have the
   * same offset.
   */
  public areTimesEqual(a: OrderTime, b: OrderTime): boolean {
    if (a === b) return true;
    return a?.Offset === b?.Offset;
  }

  /**
   * Sets `activeSaleTypes` to contains the store's Sale Types, other than
   * catering because that will be automatically selected when navigating to the
   * catering URL.
   */
  private getStoreSaleTypes(): void {
    if (this.order?.Store?.SaleTypes) {
      this.activeSaleTypes = this.order.Store.SaleTypes.filter(
        (saleType) => saleType.IsActive
      );
      // If the order is not catering then also remove catering from the
      // available sale types.
      if (this.order.SaleType?.Code !== SaleType.CateringCode) {
        this.activeSaleTypes = this.order.Store.SaleTypes.filter(
          (saleType) => saleType.Code !== SaleType.CateringCode
        );
      }
    }
  }

  //Sale type changed by UI
  onSaleTypeChanged(event: Event): void {
    if (this.readonly) {
      return;
    }
    //Use Event and cast to CustomEvent to satisfy AOT compiler
    const saleType = (event as CustomEvent<{ value: SaleType }>).detail.value;
    if (saleType != this.order.SaleType) {
      AppEvents.ChangeSaleType.emit(saleType);
    }
  }

  onOrderDayChanged(event: Event): void {
    //Use Event and cast to CustomEvent to satisfy AOT compiler
    const orderDay = (event as CustomEvent<{ value: OrderDay }>).detail.value;
    if (orderDay != this.order.OrderDay) {
      AppEvents.ChangeOrderDay.emit({
        OrderDay: orderDay,
        Reason: OrderTimeChangedReason.USER,
      });
      this.systemChangedTime = false;
    }
  }

  onOrderTimeChanged(event: Event): void {
    //Use Event and cast to CustomEvent to satisfy AOT compiler
    const orderTime = (event as CustomEvent<{ value: OrderTime }>).detail.value;
    if (orderTime != this.order.OrderTime) {
      AppEvents.ChangeOrderTime.emit({
        OrderTime: orderTime,
        Reason: OrderTimeChangedReason.USER,
      });
      this.systemChangedTime = false;
    }
  }

  /**
   * Returns `true` when the order day has any times that are enabled.
   */
  hasEnabledStoreTimes(): boolean {
    return this.order?.OrderDay?.AvailableOrderTimes?.some(
      (time) => time.Enabled
    );
  }

  /**
   * Returns `true` when the order day has some times, and none of them are
   * enabled.
   */
  hasNoAvailableTimes(): boolean {
    return (
      this.order?.OrderDay?.AvailableOrderTimes?.length &&
      this.order.OrderDay.AvailableOrderTimes.every((time) => !time.Enabled)
    );
  }

  /**
   * Returns `true` when the order day has no times, whether enabled or not.
   */
  hasNoStoreTimes(): boolean {
    return this.order?.OrderDay?.AvailableOrderTimes?.length === 0;
  }

  /**
   * Shows a popup with details about the catering thresholds.
   *
   * @param ev The click event that triggered this. Used so the popover anchors
   * to the element.
   */
  async showCateringHelpText(ev: Event): Promise<void> {
    const popover = await this.popoverController.create({
      component: CateringThresholdMessageComponent,
      componentProps: {
        cateringThresholds: this.order.Store.CateringThresholds,
      },
      event: ev,
      cssClass: 'popover-info',
    });

    return popover.present();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }
}
