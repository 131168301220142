import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalOptions } from '@ionic/core';
import { first } from 'rxjs/operators';
import { Combo } from 'src/app/models/domain/combo';
import { AppEvents } from 'src/app/models/domain/events/app-events';
import { OrderCombo } from 'src/app/models/domain/order/order-combo';
import { OrderProduct } from 'src/app/models/domain/order/order-product';
import { Product } from 'src/app/models/domain/product';
import { EnvironmentVariables } from 'src/app/models/environment';
import {
  CustomiseProductComponent,
  CustomiseProductDismissEvent,
} from '../customise-product/customise-product.component';
import { IModalService } from '../../services/modal.service';

@Component({
  selector: 'app-products-list',
  templateUrl: './products-list.component.html',
  styleUrls: ['./products-list.component.scss'],
})
export class ProductsListComponent implements OnInit {
  @Input()
  products: Array<Product>;

  @Input()
  combos: Array<Combo>;

  constructor(
    public route: ActivatedRoute,
    public modalService: IModalService,
    public variables: EnvironmentVariables
  ) {}

  ngOnInit(): void {
    this.route.queryParams.pipe(first()).subscribe((params) => {
      if (params.productId) {
        const product = this.products.find((p) => p.Id == params.productId);
        if (product) {
          // Open the product customisation modal without a combo selected
          this.customiseProduct(product, false, null);
        }
      } else if (params.comboId) {
        const combo = this.combos.find((c) => c.Id == params.comboId);
        if (combo) {
          this.customiseCombo(combo, null);
        }
      }
    });
  }

  /**
   * Opens a modal for the user to customise and order a product.
   *
   * @param product base product to be customised.
   * @param isCombo `true` if the user triggered customisation through a "combo"
   * button.
   * @param event the event that triggered this function call, or `null` if it
   * was not triggered by a `MouseEvent`.
   */
  async customiseProduct(
    product: Product,
    isCombo: boolean,
    event: MouseEvent
  ): Promise<void> {
    if (event) {
      event.stopPropagation();
    }
    if (!product.OutOfStock) {
      let componentProps: ModalOptions['componentProps'];

      // If the product is part of a combo then find the combo and add the product
      // as a selection to it
      if (isCombo) {
        const combo = product.AvailableCombos[0];
        const orderCombo = OrderCombo.FromCombo(combo);
        orderCombo.Products.forEach((comboAdditions) => {
          comboAdditions.Products.forEach((comboProduct) => {
            if (comboProduct.ProductId == product.Id) {
              comboProduct.Quantity = 1;
              return;
            }
          });
        });
        AppEvents.ViewCombo.emit(orderCombo);
        componentProps = {
          orderCombo: orderCombo,
          originalProduct: product,
        };
      } else {
        // If the product is not part of a combo then simply add the product to
        // the order
        const orderProduct = OrderProduct.FromProduct(product);
        componentProps = {
          orderProduct: orderProduct,
          originalProduct: product,
        };
        AppEvents.ViewProduct.emit(orderProduct);
      }

      this.modalService
        .presentModal<CustomiseProductDismissEvent>({
          component: CustomiseProductComponent,
          componentProps: componentProps,
          cssClass: 'show-header-modal',
        })
        .subscribe((data) => {
          if (data) {
            if (data.Combo) {
              AppEvents.AddCombo.emit(data.Combo);
            } else if (data.Product) {
              AppEvents.AddProduct.emit(data.Product);
            }
          }
        });
    }
  }

  /**
   * Adds a product directly to the cart. If there are required modifications
   * then the customise product modal will appear instead.
   */
  addProduct(product: Product, event: MouseEvent): void {
    if (!product.OutOfStock) {
      event.stopPropagation();

      const hasRequiredModifications = product.ModifierGroups.some((group) => {
        const numModifiersIncluded = group.Modifiers.filter(
          (mod) => mod.Included
        ).length;

        return (
          group.SelectionRequirement &&
          group.SelectionRequirement > numModifiersIncluded
        );
      });
      const orderProduct = OrderProduct.FromProduct(product);
      AppEvents.ViewProduct.emit(orderProduct);
      if (hasRequiredModifications) {
        this.customiseProduct(product, false, event);
      } else {
        AppEvents.AddProduct.emit(orderProduct);
      }
    }
  }

  /**
   * Opens the customise combo modal.
   */
  async customiseCombo(combo: Combo, event: MouseEvent): Promise<void> {
    if (event) {
      event.stopPropagation();
    }
    if (!combo.OutOfStock) {
      const orderCombo = OrderCombo.FromCombo(combo);
      AppEvents.ViewCombo.emit(orderCombo);
      this.modalService
        .presentModal<CustomiseProductDismissEvent>({
          component: CustomiseProductComponent,
          componentProps: {
            orderCombo: orderCombo,
            originalCombo: combo,
          },
          cssClass: 'show-header-modal',
        })
        .subscribe((data) => {
          if (data?.Combo) {
            AppEvents.AddCombo.emit(data.Combo);
          }
        });
    }
  }
}
