import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Menu } from '../models/domain/menu';
import { EnvironmentVariables } from '../models/environment';
import { MenuDTO } from '../models/dto/menu.dto';
import { map } from 'rxjs/operators';
import { Product } from '../models/domain/product';
import { ComboItem } from '../models/domain/combo';

export abstract class IMenuClient {
  abstract get(): Observable<Array<Menu>>;
}

@Injectable({
  providedIn: 'root',
})
export class MenuClient implements IMenuClient {
  constructor(
    private client: HttpClient,
    public variables: EnvironmentVariables
  ) {}

  get(): Observable<Array<Menu>> {
    return this.client
      .get<Array<MenuDTO>>(this.variables.baseApiUrl + '/Menus/GetAll')
      .pipe(
        map((md) => {
          md.forEach((m) =>
            m.CombosDetails.forEach((c) => {
              //Put the burgers first in the items array
              c.Items.sort((a, b) => {
                const aHasBurger = a.Products.some(
                  (p) => p.Category === 'Burger'
                );
                const bHasBurger = b.Products.some(
                  (p) => p.Category === 'Burger'
                );
                if (aHasBurger == bHasBurger) {
                  return 0;
                }

                return bHasBurger ? 1 : -1;
              });
              //Api returns duplicate products in array
              c.Items.forEach((item) => {
                for (let i = item.Products.length - 1; i >= 0; i--) {
                  if (
                    item.Products.findIndex(
                      (p) => p.Id === item.Products[i].Id
                    ) < i
                  ) {
                    item.Products.splice(i, 1);
                  }
                }
              });
            })
          );
          return md;
        }),
        map((md) => md.map((m) => MenuDTO.ToDomain(m))),
        map((md) => {
          md.forEach((m) => {
            // Set display order for each category
            m.Categories.forEach((c) => {
              const envCat = this.variables.categories.find(
                (ec) => ec.Name === c.Name
              );
              if (envCat) {
                c.DisplayName = envCat.DisplayName;
                c.DisplayOrder = envCat.DisplayOrder;
              } else {
                c.DisplayOrder = 0;
              }
            });
            // Order the categories by display order
            m.Categories.sort((a, b) => a.DisplayOrder - b.DisplayOrder);
            // Order the products in each category by display order
            m.Categories.map((c) => {
              c.Combos.forEach((combo) => {
                combo.Items.forEach((comboItem) => {
                  comboItem.Modifiers.sort(
                    (a, b) => a.DisplayOrder - b.DisplayOrder
                  );
                  comboItem.Products.sort((a, b) =>
                    this.compareProducts(a, b, comboItem)
                  );
                });
              });
            });
          });
          return md;
        })
      );
  }

  /**
   * Compares 2 `Product`s to determine order.
   *
   * * Default combo item first if it exists.
   * * Cheaper items first.
   * * For same price items, use `Product.DisplayOrder`, with higher numbers
   * first.
   *
   * @returns A negative number if `a` should be earlier, or a positive number
   * if `b` should be earlier.
   */
  private compareProducts(
    a: Product,
    b: Product,
    comboItem: ComboItem
  ): number {
    if (comboItem.DefaultProductId == a.Id) {
      return -1;
    }
    if (comboItem.DefaultProductId == b.Id) {
      return 1;
    }

    if (a.PriceInCombo != b.PriceInCombo) {
      return a.PriceInCombo - b.PriceInCombo;
    }

    return b.DisplayOrder - a.DisplayOrder;
  }
}
