import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { EnvironmentVariables } from '../models/environment';
import { Coupon } from '../models/domain/coupon';
import { catchError, map } from 'rxjs/operators';
import { Order } from '../models/domain/order/order';
import { OrderDTO } from '../models/dto/order/order.dto';
import { BenefitsResponse } from '../models/domain/benefits-response';

export abstract class ICouponClient {
  abstract getCoupon(code: string): Observable<Coupon>;
  abstract getBenefits(order: Order): Observable<BenefitsResponse>;
}

@Injectable({
  providedIn: 'root',
})
export class CouponClient implements ICouponClient {
  constructor(
    private client: HttpClient,
    public variables: EnvironmentVariables
  ) {}

  getCoupon(code: string): Observable<Coupon> {
    return this.client
      .get<Coupon>(this.variables.baseApiUrl + '/Coupons/GetByCode/' + code)
      .pipe(
        catchError(this.handleError),
        map((c) => {
          c.Code = code;
          return c;
        })
      );
  }

  getBenefits(order: Order): Observable<BenefitsResponse> {
    return this.client
      .post<BenefitsResponse>(
        this.variables.baseApiUrl + '/Coupons/GetBenefits',
        OrderDTO.FromDomain(order)
      )
      .pipe(
        catchError(this.handleError),
        map((c) => {
          return c;
        })
      );
  }

  private handleError(exception: HttpErrorResponse): Observable<never> {
    return throwError(exception.error);
  }
}
