import { Component } from '@angular/core';
import { filter, map } from 'rxjs/operators';
import { AppEvents } from 'src/app/models/domain/events/app-events';
import { SaleType, Store } from 'src/app/models/domain/store';
import { OrderService } from 'src/app/services/order.service';
import { NavigationEvents } from '../../models/domain/events/navigation-events';
import { OrderPage } from '../../models/domain/navigation-page';
import { ViewDidLeave, ViewWillEnter } from '@ionic/angular';
import { MenuService } from '../../services/menu.service';
import { combineLatest, Subscription } from 'rxjs';

@Component({
  selector: 'app-catering',
  templateUrl: './catering.component.html',
  styleUrls: ['./catering.component.scss'],
})
export class CateringComponent implements ViewWillEnter, ViewDidLeave {
  public stores: Array<Store>;
  subscriptions: Subscription[] = [];

  public banner$ = AppEvents.OnlineConfiguration.pipe(
    map((config) => config?.CateringBanner)
  );

  constructor(
    public orderService: OrderService,
    public menuService: MenuService
  ) {}

  ionViewWillEnter(): void {
    this.subscriptions = [
      this.orderService.initialised.subscribe(() => {
        this.checkStoreSupportsCatering();
      }),
      AppEvents.OrderPreLoaded.pipe(filter((e) => e != null)).subscribe(() => {
        this.checkOrderHasCorrectSaleType();
      }),
      combineLatest([
        AppEvents.OrderLoaded.pipe(filter((o) => o != null)),
        AppEvents.Stores,
      ]).subscribe(([order, stores]) => {
        if (stores) {
          this.checkStoreSupportsCatering();
          this.storeCheck(stores);
        }
      }),
    ];
    if (this.orderService.order) {
      this.checkOrderHasCorrectSaleType();
    }
  }

  storeCheck(stores: Store[]): void {
    if (
      !stores?.find(
        (store) => store.Catering?.IsActive && store.Catering?.MenuId
      )
    ) {
      NavigationEvents.NavigateToOrderPage.emit({
        Page: OrderPage.MainMenu,
        Order: this.orderService.order,
      });
    }
  }

  checkOrderHasCorrectSaleType(): void {
    if (this.orderService.order.SaleType?.Code != SaleType.CateringCode) {
      AppEvents.ChangeSaleType.emit(SaleType.CateringObj);
    }
  }

  checkStoreSupportsCatering(): void {
    if (!this.orderService.order?.Store) {
      return;
    }

    // If the store does not support catering then remove the store from the order
    if (
      !this.orderService.order.Store.Catering ||
      !this.orderService.order.Store.Catering?.MenuId ||
      !this.orderService.order.Store.Catering.IsActive
    ) {
      this.orderService.order.Store = null;
    }
  }

  ionViewDidLeave(): void {
    this.subscriptions.forEach((s) => {
      s.unsubscribe();
    });
  }
}
