import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Store, StoreHoliday, WeekOpenTime } from 'src/app/models/domain/store';

@Component({
  selector: 'app-store-info',
  templateUrl: './store-info.component.html',
  styleUrls: ['./store-info.component.scss'],
})
export class StoreInfoComponent implements OnInit, OnChanges {
  @Input() store: Store;
  @Input() saleType: string | undefined;
  @Input() weekOpenTimes: ReadonlyArray<WeekOpenTime> | undefined;

  @Output()
  storeSelected = new EventEmitter<Store>();

  monthNames = {
    1: 'January',
    2: 'February',
    3: 'March',
    4: 'April',
    5: 'May',
    6: 'June',
    7: 'July',
    8: 'August',
    9: 'September',
    10: 'October',
    11: 'November',
    12: 'December',
  } as const;

  /**
   * Day open/closed of the next 7 days.
   * Monday = 0 -> Sunday = 6
   * Note that e.g. Today could be Wednesday, that means index 0 and 1 are next
   * Monday and Tuesday and indexes 2 through 6 are this week Wednesday through
   * Sunday.
   */
  daysOfWeek: {
    holiday: boolean;
  }[] = [];

  storeHolidays: StoreHoliday[] = [];

  ngOnInit(): void {
    this.setStoreHolidays(this.store);
  }

  ngOnChanges(changes: SimpleChanges): void {
    const newStore = changes.store.currentValue as Store;
    this.setStoreHolidays(newStore);
  }

  private setStoreHolidays(store: Store) {
    this.storeHolidays =
      store.Holidays?.filter((holiday) => !holiday.Open).sort((a, b) => {
        if (a.Month !== b.Month) {
          return a.Month - b.Month;
        }
        return a.Day - b.Day;
      }) ?? [];

    this.setNextWeek(this.storeHolidays);
  }

  private setNextWeek(storeHolidays: StoreHoliday[]) {
    const daysOfWeek: this['daysOfWeek'] = [];
    const today = new Date();

    for (let dayOffset = 0; dayOffset < 7; dayOffset++) {
      const dayOfWeek = new Date(today);
      dayOfWeek.setDate(dayOfWeek.getDate() + dayOffset);

      // Make Monday index 0 and Sunday 6
      let dayOfWeekIndex = dayOfWeek.getDay() - 1;
      if (dayOfWeekIndex === -1) dayOfWeekIndex = 6;

      const date = dayOfWeek.getDate();
      const month = dayOfWeek.getMonth() + 1;

      daysOfWeek[dayOfWeekIndex] = {
        holiday: storeHolidays.some(
          (holiday) => holiday.Day === date && holiday.Month === month
        ),
      };
    }

    this.daysOfWeek = daysOfWeek;
  }

  selectStore(): void {
    this.storeSelected.emit(this.store);
  }
}
