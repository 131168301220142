import { OrderModifier } from './order-modifier';

export class OrderModifierGroup {
  public Name: string;
  public Modifiers: Array<OrderModifier>;
  public SelectionRequirement: number;
  public SelectionLimit: number;
  public Selection: number;
  public SelectionText: string;

  constructor(o: Partial<OrderModifierGroup>) {
    Object.assign(this, o);
  }
}
