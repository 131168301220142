import { Coupon } from '../coupon';
import { OrderPeriod } from '../order-period';
import { SaleType, Store } from '../store';
import { Reward } from './reward';
import { OrderCombo } from './order-combo';
import { OrderDay } from './order-day';
import { OrderProduct } from './order-product';
import { OrderStatus } from './order-status';
import { OrderTime } from './order-time';

export class Order {
  constructor(o: Partial<Order>) {
    Object.assign(this, o);
  }

  public Id: string;
  public IdSignature: string;
  public UserId = 0;
  public ComoIdSignature: string;

  public Status: OrderStatus;
  public PixelPointTransactionId: number;

  public EmailAddress: string;
  public FirstName: string;
  public LastName: string;
  public PhoneNumber: string;

  public CarColour: string;
  public CarModel: string;
  public NumberPlate: string;

  public Combos: Array<OrderCombo> = new Array<OrderCombo>();
  public Products: Array<OrderProduct> = new Array<OrderProduct>();

  public SubTotal = 0;
  public Discount = 0;
  public Total = 0;
  public PointsEarned = 0;

  public Store: Store = null;
  public SaleType: SaleType = null;
  /**
   * The day the order is on.
   *
   * This (with `OrderTime`) is used instead of `ExpectedTime` so that time can
   * be preserved when the user selects a different date, as there are not
   * separate types to represent Date only and Time only in Javascript.
   */
  public OrderDay: OrderDay = null;
  /**
   * The time of the `OrderDay` that the order is for.
   *
   * This (with `OrderDay`) is used instead of `ExpectedTime` so that date can
   * be preserved when the user selects a different time, as there are not
   * separate types to represent Date only and Time only in Javascript.
   */
  public OrderTime: OrderTime = null;
  public Coupons: Array<Coupon> = new Array<Coupon>();
  public ComoRewards: Array<Reward> = new Array<Reward>();
  public ComoDeals: Array<Reward> = new Array<Reward>();

  /** Required when using Como rewards. */
  public TransactionOpenTime: Date;

  /** Used for sorting "My Orders" by most recent first */
  public ExpectedTime: Date;

  public TableNumber: number;

  /** Recover from refresh on payment page. */
  public NavigatedToPayment: boolean;

  /**
   * Last time the order was viewed, if its longer than a certain time frame,
   * the customer should be prompted to clear their cart.
   */
  public LastLoaded: Date;

  //All below are applied dynamically, do not persist

  public IsTableOrder = false;

  public ProductCount = 0;

  public IsGuestOrder = false;
}

/** Map of date (yyyy/MM/dd format) to the order periods for that date */
export type StoreOrderPeriods = Map<string, Array<OrderPeriod>>;
