import {
  Component,
  EventEmitter,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { ModalController, PopoverController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { AppEvents } from 'src/app/models/domain/events/app-events';
import { SaleType, Store } from 'src/app/models/domain/store';
import { StoreTimePickerModel } from 'src/app/models/view-models/store-time-picker-model';
import { StorePickerComponent } from '../store-picker/store-picker.component';
import { OrderTime } from 'src/app/models/domain/order/order-time';
import { CateringThresholdMessageComponent } from '../popover-message/catering-threshold-message/catering-threshold-message.component';

@Component({
  selector: 'app-store-time-picker',
  templateUrl: './store-time-picker.component.html',
  styleUrls: ['./store-time-picker.component.scss'],
})
export class StoreTimePickerComponent implements OnInit, OnDestroy {
  @Input()
  stores: Array<Store>;

  @Input()
  model: StoreTimePickerModel;

  @Output()
  onDismiss = new EventEmitter<StoreTimePickerModel>();

  public storePickerModal: HTMLIonModalElement;

  initialisedWithStore = false;
  validSelection = false;

  public activeSaleTypes = new Array<SaleType>();

  subscriptions: Array<Subscription> = [
    AppEvents.StoreAvailableOrderTimesInitialised.subscribe(() => {
      this.updateOrderDay();
    }),
    AppEvents.OrderTimesStatusChanged.subscribe(() => {
      // Update to the next available time when the status of the loaded times
      // may have changed
      if (this.model.OrderDay) {
        this.onChangeOrderDay();
      }
    }),
  ];

  //Binding in HTML
  public SaleType = SaleType;

  constructor(
    public modalController: ModalController,
    public ngZone: NgZone,
    public popoverController: PopoverController
  ) {
    // this.ngZone.runOutsideAngular(() => {
    //   this.subscriptions = [
    //     //Check that current time is still enabled
    //     // interval(60 * 1000).subscribe(i => this.checkCurrentOrderDay())
    //   ];
    // });
  }
  ngOnInit(): void {
    this.initialisedWithStore = this.model.Store != null;
    if (this.model.Store) {
      this.activeSaleTypes = this.model.Store.SaleTypes.filter(
        (saleType) => saleType.IsActive
      );
      // If the order is not catering then also remove catering from the
      // available sale types.
      if (this.model.SaleType?.Code !== SaleType.CateringCode) {
        this.activeSaleTypes = this.activeSaleTypes.filter(
          (saleType) => saleType.Code !== SaleType.CateringCode
        );
      }
    }
    this.validateSelection();
  }

  /**
   * Returns `true` when `a` and `b` have the same `Offset`. This is needed
   * because the object references might be different even when they have the
   * same offset.
   */
  public areTimesEqual(a: OrderTime, b: OrderTime): boolean {
    return a?.Offset === b?.Offset;
  }

  async openStorePicker(): Promise<void> {
    //On slow internet, the store picker is delayed showing,
    //leading to multiple presses
    if (this.storePickerModal) {
      return;
    }
    this.storePickerModal = await this.modalController.create({
      component: StorePickerComponent,
      componentProps: {
        stores: this.stores,
        currentStore: this.model.Store,
      },
      cssClass: 'show-header-modal',
      id: 'StorePicker',
    });
    this.storePickerModal.onDidDismiss().then((d) => {
      const data = d.data as Store;
      if (data) {
        if (this.model.Store != data) {
          this.model.Store = data;
          this.onChangeStore();
        }
      }
      this.storePickerModal = null;
    });
    await this.storePickerModal.present();
  }

  onChangeStore(): void {
    this.activeSaleTypes = this.model.Store.SaleTypes.filter(
      (saleType) => saleType.IsActive
    );
    // If the order is not catering then also remove catering from the
    // available sale types.
    if (this.model.SaleType?.Code !== SaleType.CateringCode) {
      this.activeSaleTypes = this.activeSaleTypes.filter(
        (saleType) => saleType.Code !== SaleType.CateringCode
      );
    }

    if (!this.model.SaleType) {
      if (this.activeSaleTypes.length == 1) {
        this.model.SaleType = this.activeSaleTypes[0];
      }
    } else if (this.model.SaleType.Code != SaleType.CateringCode) {
      //Choose the default sales type if current sales type is not available
      this.model.SaleType =
        this.activeSaleTypes.find((s) => s.Code == this.model.SaleType?.Code) ||
        this.model.Store.SaleTypes[0];
    }
    this.checkCurrentOrderDay();

    this.validateSelection();
    //TODO: Update available times and dates in modal
  }

  onChangeSaleType(): void {
    this.checkCurrentOrderDay();
    this.validateSelection();
  }

  checkCurrentOrderDay(): void {
    if (!this.model.Store) {
      return;
    }
    //Only the actual selected store on the order has its order days and times calculated
    //So when highlighting a store in the modal, but not actually choosing it
    //Ask for the Orders service to calculate which available order days and times
    // are available for this store
    AppEvents.SetStoreAvailableOrderTimes.emit({ Store: this.model.Store });
    AppEvents.LoadStoreOrderPeriods.emit({ Store: this.model.Store });
  }

  /**
   * Updates the model order day based on the model's store's available order
   * days.
   */
  private updateOrderDay(): void {
    //Order service should intercept event synchronously and return before this continues
    if (!this.model.OrderDay) {
      this.model.OrderDay = this.model.Store.AvailableOrderDays[0];
      this.onChangeOrderDay();
    } else if (
      !this.model.Store.AvailableOrderDays.includes(this.model.OrderDay)
    ) {
      //If no order day selected, choose the one with the same date as the current order
      //or today if not found
      this.model.OrderDay =
        this.model.Store.AvailableOrderDays.find(
          (d) => d.Date.getTime() == this.model.OrderDay.Date.getTime()
        ) || this.model.Store.AvailableOrderDays[0];
      this.onChangeOrderDay();
    }
  }

  /**
   * Shows a popup with details about the catering thresholds.
   *
   * @param ev The click event that triggered this. Used so the popover anchors
   * to the element.
   */
  async showCateringHelpText(ev: Event): Promise<void> {
    const popover = await this.popoverController.create({
      component: CateringThresholdMessageComponent,
      componentProps: {
        cateringThresholds: this.model.Store.CateringThresholds,
      },
      event: ev,
      cssClass: 'popover-info',
    });

    return popover.present();
  }

  onChangeOrderDay(): void {
    AppEvents.CheckAvailableOrderTimes.emit({
      Store: this.model.Store,
      OrderDay: this.model.OrderDay,
    });

    if (
      !this.model.OrderDay.AvailableOrderTimes.find(
        (t) => t.Enabled == true && t == this.model.OrderTime
      )
    ) {
      this.model.OrderTime =
        this.model.OrderDay.AvailableOrderTimes.find(
          (t) =>
            t.Time.getTime() == this.model.OrderTime?.Time.getTime() &&
            t.Enabled == true
        ) ||
        this.model.OrderDay.AvailableOrderTimes.find((t) => t.Enabled == true);
    }
    this.validateSelection();
  }

  onChangeOrderTime(): void {
    this.validateSelection();
  }

  cancel(): void {
    this.onDismiss.emit(null);
  }

  finish(): void {
    this.onDismiss.emit(this.model);
  }

  validateSelection(): void {
    //TODO: Change to forms validation
    this.validSelection =
      this.model.Store != null &&
      this.model.Store.OnlineOrderingActive == true &&
      this.model.SaleType != null &&
      this.model.OrderDay != null &&
      this.model.OrderTime != null;
  }

  /**
   * Returns `true` when the order day has any times that are enabled.
   */
  hasEnabledStoreTimes(): boolean {
    return this.model.OrderDay.AvailableOrderTimes.some((time) => time.Enabled);
  }

  /**
   * Returns `true` when the order day has some times, and none of them are
   * enabled.
   */
  hasNoAvailableTimes(): boolean {
    return (
      this.model.OrderDay.AvailableOrderTimes.length > 0 &&
      this.model.OrderDay.AvailableOrderTimes.every((time) => !time.Enabled)
    );
  }

  /**
   * Returns `true` when the order day has no times, whether enabled or not.
   */
  hasNoStoreTimes(): boolean {
    return this.model.OrderDay.AvailableOrderTimes.length === 0;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }
}
