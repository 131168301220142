import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Store } from 'src/app/models/domain/store';
import { ModalComponent } from 'src/app/models/view-models/modal-component';
import { StoreTimePickerModel } from 'src/app/models/view-models/store-time-picker-model';

@Component({
  selector: 'app-store-time-picker-modal',
  templateUrl: './store-time-picker-modal.component.html',
  styleUrls: ['./store-time-picker-modal.component.scss'],
})
export class StoreTimePickerModalComponent extends ModalComponent<StoreTimePickerModel> {
  @Input()
  stores: Array<Store>;

  @Input()
  model: StoreTimePickerModel;

  constructor(modalController: ModalController) {
    super(modalController);
  }

  storeTimePickerDismissed(result: StoreTimePickerModel): void {
    this.dismiss(result);
  }
}
