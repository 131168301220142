import { BehaviorSubject, Observable, fromEvent, merge } from 'rxjs';
import { debounceTime, map, startWith, throttle } from 'rxjs/operators';

/**
 * Debounce, but emits the first observed event at the start of each debounce
 * period instantly, rather than only emitting the last event and at the end.
 *
 * |--A--B-C------D-------EF-|
 * |--A~~~~~~C----D~~~----E~~~F|
 *
 * @param timeMs Amount of time to wait after the first emission before
 * @param source The observable to run the debounce on.
 * @returns An observable that emits at the leading and trailing edge of each
 * debounce period only.
 *
 * @see https://stackoverflow.com/a/71024333
 */
export const debounceTimeKeepLeading = <T>(
  timeMs: number,
  source: Observable<T>
): Observable<T> => {
  const debounced = source.pipe(debounceTime(timeMs));

  const effect = merge(debounced, source.pipe(throttle(() => debounced)));

  return effect;
};

/**
 * Returns an observable that emits `true` when the browser resizes to a mobile
 * width, or `false` when it resizes to desktop width.
 */
export const isMobile = (): Observable<boolean> => {
  return fromEvent(window, 'resize').pipe(
    map(() => window.innerWidth),
    startWith(window.innerWidth),
    map((width) => width < 768)
  );
};

/**
 * A `BehaviourSubject` that only emits when the next value is distinct from the
 * current value.
 */
export class DistinctBehaviorSubject<T> extends BehaviorSubject<T> {
  public next(value: T): void {
    if (value !== this.value) {
      super.next(value);
    }
  }
}
