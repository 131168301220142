import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { MemberEvents } from 'src/app/models/domain/events/member-events';
import { SavedCard } from 'src/app/models/domain/member';
import { IMemberService } from 'src/app/services/member.service';
import { ConfirmModalComponent } from '../confirm-modal/confirm-modal.component';

@Component({
  selector: 'app-my-cards',
  templateUrl: './my-cards.component.html',
  styleUrls: ['./my-cards.component.scss'],
})
export class MyCardsComponent implements OnInit {
  constructor(
    public memberService: IMemberService,
    public modalController: ModalController
  ) {}

  ngOnInit() {}

  async removeCard(card: SavedCard) {
    const confirmModal = await this.modalController.create({
      component: ConfirmModalComponent,
      componentProps: {
        title: 'Remove card',
        message: 'Do you want to remove this payment method?',
      },
      cssClass: 'confirm-modal',
    });
    confirmModal.onDidDismiss().then((d) => {
      if (d.data) {
        MemberEvents.RemoveCard.emit(card);
      }
    });
    await confirmModal.present();
  }
}
