import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IOrderClient } from '../clients/order-client';
import { Order } from '../models/domain/order/order';
import { PaginatedResults } from '../models/paginated-results';

/**
 * Manages orders in the system.
 */
export interface IOrdersService {
  /** Gets the order with ID signature `signature`. */
  getOrder(signature: string): Observable<Order>;
  /**
   * Gets a page of orders starting from `offset`, for the user with
   * `comoIdSignature`.
   */
  getOrders(
    comoIdSignature: string,
    offset: number
  ): Observable<PaginatedResults<Order>>;

  /**
   * Checks with the server that the order total has been paid correctly and returns a string response.
   */
  validateAmountPaid(signature: string): Observable<string>;
}

@Injectable({
  providedIn: 'root',
})
export class OrdersService implements IOrdersService {
  constructor(public orderClient: IOrderClient) {}

  getOrder(signature: string): Observable<Order> {
    return this.orderClient.get(signature);
  }
  getOrders(
    comoIdSignature: string,
    offset: number
  ): Observable<PaginatedResults<Order>> {
    return this.orderClient.getHistoryPage(comoIdSignature, offset);
  }
  validateAmountPaid(signature: string): Observable<string> {
    return this.orderClient.validateAmountPaid(signature);
  }
}
