/**
 * Different statuses an `Order` can take.
 */
export enum OrderStatus {
  REFUNDED = 'Refunded',

  PLACED = 'Placed',
  /** @deprecated Use `PLACED`. */
  WAITING = 'Waiting',

  RECEIVED = 'Received',
  /** @deprecated Use `RECEIVED`. */
  COOKING = 'Cooking',

  UNCONFIRMED = 'Unconfirmed',
  /** @deprecated Use `UNCONFIRMED`. */
  FAILED = 'Failed',

  COMPLETED = 'Completed',
  /** @deprecated Use `COMPLETED`. */
  COLLECTED = 'Collected',
}
