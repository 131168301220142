import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { AppEvents } from '../models/domain/events/app-events';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

/**
 * Taps into any error response from the Angular HttpClient and emits the
 * request details as an AppEvent to get logged against the order.
 */
@Injectable()
export class NetworkErrorInterceptor implements HttpInterceptor {
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap({
        error: (err) => {
          let body = request.body;
          // Don't log the body of a logs failure, as it will exponentially grow
          // in size on every subsequent failure.
          if (/\/Orders\/.*\/Logs/.test(request.url)) {
            body = null;
          }
          AppEvents.NetworkError.emit({
            route: request.urlWithParams,
            method: request.method,
            body: body,
            err,
          });
        },
      })
    );
  }
}
