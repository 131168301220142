import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DietaryRequirement } from '../models/domain/dietary-requirement';
import { IConfigClient } from '../clients/config-client';
import { shareReplay } from 'rxjs/operators';

/**
 * Manages getting global configuration settings from the server.
 */
export abstract class IConfigService {
  /** Observable for the dietary requirements. */
  dietaryRequirements$: Observable<DietaryRequirement[]>;
}

@Injectable({
  providedIn: 'root',
})
export class ConfigService implements IConfigService {
  dietaryRequirements$: Observable<DietaryRequirement[]>;

  constructor(private configClient: IConfigClient) {
    this.dietaryRequirements$ = this.configClient
      .getDietaryRequirements()
      // Use `shareReplay` to fetch only once because this should never change
      .pipe(shareReplay());
  }
}
