import { ModalController } from '@ionic/angular';

/**
 * A class that can be displayed use `ModalService.presentModal`.
 */
export abstract class ModalComponent<T = undefined> {
  constructor(private modalController: ModalController) {}

  /**
   * Closes the modal.
   *
   * @param returnValue The result of the modal.
   */
  dismiss(returnValue: T): void {
    this.modalController.dismiss(returnValue);
  }
}
