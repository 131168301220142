import { Banner } from '../domain/online-configuration';
import { PaymentIntegration } from '../domain/response/payment-response';
import {
  CateringThresholdSetting,
  SaleType,
  Store,
  StoreHoliday,
  WeekOpenTime,
} from '../domain/store';

export class StoreDTO {
  public Id: string;
  public Address: string;
  public City: string;
  public DisplayName: string;
  public Latitude: number;
  public Longitude: number;
  public Name: string;
  public PaymentIntegrationSelected: PaymentIntegration;
  public PhoneNumber: string;
  public Suburb: string;

  public WeekOpenTimes: Array<WeekOpenTime>;
  public Holidays: Array<StoreHoliday>;
  public WaitTime: number;

  public OnlineOrderingStatus: string;
  public OnlineOrderingStatusMessage: string;
  public WarningBanner: Banner;

  public MenuId: string;

  public InStore: SaleType;

  public CurbsideSale: SaleType;
  public CurbsideMapImageLocation: string;

  public TableOrder: SaleType;
  public TableOrderMaximumTableNumber: number;

  public Catering: SaleType;
  public CateringMinimumOrderPrice: number;
  public CateringMinimumOrderWaitingHours: number;
  public CateringMaximumOrderWaitingHours: number;
  public CateringThresholds: CateringThresholdSettingDTO[];

  public KitchenDollarLimit: number;
  public IsKitchenOverflowActive: boolean;

  public static ToDomain(dto: StoreDTO): Store {
    if (!dto) {
      return null;
    }
    const store = new Store({
      Id: dto.Id,
      Address: dto.Address,
      City: dto.City,
      CurbsideMapImageLocation: dto.CurbsideMapImageLocation,
      DisplayName: dto.DisplayName,
      Latitude: dto.Latitude,
      Longitude: dto.Longitude,
      Name: dto.Name,
      PhoneNumber: dto.PhoneNumber,
      Suburb: dto.Suburb,
      WeekOpenTimes: dto.WeekOpenTimes,
      Holidays: dto.Holidays,
      WaitTime: dto.WaitTime,
      MenuId: dto.MenuId,
      OnlineOrderingActive: dto.OnlineOrderingStatus == 'Active',
      OnlineOrderingStatusMessage: dto.OnlineOrderingStatusMessage,
      WarningBanner: dto.WarningBanner,
      CateringMinimumOrderPrice: dto.CateringMinimumOrderPrice,
      CateringMinimumOrderWaitingHours: dto.CateringMinimumOrderWaitingHours,
      CateringMaximumOrderWaitingHours: dto.CateringMaximumOrderWaitingHours,
      TableOrderMaximumTableNumber: dto.TableOrderMaximumTableNumber,
      PaymentIntegrationSelected: dto.PaymentIntegrationSelected,
      InStore: dto.InStore,
      Curbside: dto.CurbsideSale,
      TableOrder: dto.TableOrder,
      Catering: dto.Catering,
      CateringThresholds: [
        new CateringThresholdSetting({
          AdvanceHours: dto.CateringMinimumOrderWaitingHours,
          MinimumSpend: dto.CateringMinimumOrderPrice,
        }),
        ...(dto.CateringThresholds
          ? dto.CateringThresholds.map(CateringThresholdSettingDTO.ToDomain)
          : []),
      ].sort((a, b) => a.AdvanceHours - b.AdvanceHours),
      SaleTypes: [],
      KitchenDollarLimit: dto.KitchenDollarLimit,
      IsKitchenOverflowActive: dto.IsKitchenOverflowActive,
    });

    if (store.InStore?.IsActive) {
      store.SaleTypes.push(store.InStore);
    }
    if (store.Curbside?.IsActive) {
      store.SaleTypes.push(store.Curbside);
    }
    if (store.Catering?.IsActive) {
      store.SaleTypes.push(store.Catering);
    }
    if (store.TableOrder?.IsActive) {
      store.SaleTypes.push(store.TableOrder);
    }
    if (store.InStore) {
      store.InStore.Name = SaleType.InStore;
      store.InStore.Code = SaleType.InStoreCode;
    }
    if (store.Curbside) {
      store.Curbside.Name = SaleType.Curbside;
      store.Curbside.Code = SaleType.CurbsideCode;
    }
    if (store.TableOrder) {
      store.TableOrder.Name = SaleType.TableOrder;
      store.TableOrder.Code = SaleType.TableOrderCode;
    }
    if (store.Catering) {
      store.Catering.Name = SaleType.Catering;
      store.Catering.Code = SaleType.CateringCode;
    }

    return store;
  }
}

export class CateringThresholdSettingDTO {
  AdvanceHours: number;
  MinimumSpend: number;

  static ToDomain(dto: CateringThresholdSettingDTO): CateringThresholdSetting {
    return new CateringThresholdSetting({
      AdvanceHours: dto.AdvanceHours,
      MinimumSpend: dto.MinimumSpend,
    });
  }

  static ToDto(domain: CateringThresholdSetting): CateringThresholdSettingDTO {
    const dto = new CateringThresholdSettingDTO();
    dto.AdvanceHours = domain.AdvanceHours;
    dto.MinimumSpend = domain.MinimumSpend;

    return dto;
  }
}
