import { Component, OnDestroy, OnInit } from '@angular/core';
import { MenuController, ModalController } from '@ionic/angular';
import { first } from 'rxjs/operators';
import { AppEvents } from 'src/app/models/domain/events/app-events';
import { MemberEvents } from 'src/app/models/domain/events/member-events';
import { NavigationEvents } from 'src/app/models/domain/events/navigation-events';
import { OrderPage } from 'src/app/models/domain/navigation-page';
import {
  MenuItem,
  OnlineConfiguration,
} from 'src/app/models/domain/online-configuration';
import { EnvironmentVariables } from 'src/app/models/environment';
import { IMemberService } from 'src/app/services/member.service';
import { OrderService } from 'src/app/services/order.service';
import { ConfirmModalComponent } from '../confirm-modal/confirm-modal.component';
import { LoginModalComponent } from '../login/login-modal/login-modal.component';
import { LogviewComponent } from '../logview/logview.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit, OnDestroy {
  onlineConfiguration: OnlineConfiguration;
  expandedMenuItem: MenuItem;
  fullYear = new Date().getFullYear();
  inApp = false;
  inAppLocations: boolean =
    window.location.toString().includes('locations') && this.inApp;
  catering = this.variables.cateringConfig?.cateringEnabled;
  subscriptions = [
    AppEvents.OnlineConfiguration.subscribe((o) => {
      this.onlineConfiguration = o;
    }),
    MemberEvents.InApp.subscribe((i) => {
      this.inApp = i;
      this.inAppLocations =
        window.location.toString().includes('locations') && this.inApp;
    }),
  ];

  constructor(
    public menuController: MenuController,
    public orderService: OrderService,
    public memberService: IMemberService,
    public variables: EnvironmentVariables,
    public modalController: ModalController
  ) {}

  ngOnInit(): void {
    this.menuController.swipeGesture(false);
  }

  toggleExpandedMenuItem(menuItem: MenuItem, event: MouseEvent): void {
    event.stopPropagation();
    if (this.expandedMenuItem == menuItem) {
      this.expandedMenuItem = null;
    } else {
      this.expandedMenuItem = menuItem;
    }
  }

  async logOut(): Promise<void> {
    const confirmModal = await this.modalController.create({
      component: ConfirmModalComponent,
      componentProps: {
        title: 'Sign out',
        message: 'Are you sure you want to sign out?',
        yes: 'Sign out',
        no: 'Cancel',
      },
      cssClass: 'confirm-modal',
    });
    confirmModal.onDidDismiss().then((d) => {
      if (d.data) {
        MemberEvents.LoggedOut.pipe(first()).subscribe(() => {
          NavigationEvents.NavigateToOrderPage.emit({
            Page: OrderPage.MainMenu,
            Order: this.orderService.order,
          });
        });
        MemberEvents.LogOut.emit();
      }
    });
    await confirmModal.present();
  }

  async showLoginModal(): Promise<void> {
    const loginModal = await this.modalController.create({
      component: LoginModalComponent,
      componentProps: {
        loginModel: this.memberService.loginModel,
      },
      cssClass: 'show-header-modal login-modal',
    });
    await loginModal.present();
  }

  async showLogView(): Promise<void> {
    if (!environment.production) {
      const logViewModal = await this.modalController.create({
        component: LogviewComponent,
      });
      await logViewModal.present();
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }
}
