import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ViewDidEnter } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';
import { ConfirmModalComponent } from 'src/app/components/confirm-modal/confirm-modal.component';
import { ErrorModalComponent } from 'src/app/components/error-modal/error-modal.component';
import { AppEvents } from 'src/app/models/domain/events/app-events';
import { MemberEvents } from 'src/app/models/domain/events/member-events';
import { NavigationEvents } from 'src/app/models/domain/events/navigation-events';
import { OrderPage } from 'src/app/models/domain/navigation-page';
import { Order } from 'src/app/models/domain/order/order';
import { PaymentIntegration } from 'src/app/models/domain/response/payment-response';
import { PaymentResultDTO } from 'src/app/models/dto/payment-result.dto';
import { ModalService } from 'src/app/services/modal.service';
import { OrderService } from 'src/app/services/order.service';
import { OrderClient } from 'src/app/clients/order-client';

@Component({
  selector: 'app-payment-result',
  templateUrl: './payment-result.component.html',
  styleUrls: ['./payment-result.component.scss'],
})
export class PaymentResultComponent implements ViewDidEnter, OnDestroy {
  subscriptions: Array<Subscription> = [];

  constructor(
    public route: ActivatedRoute,
    public orderService: OrderService,
    public orderClient: OrderClient,
    public modalService: ModalService
  ) {}

  ionViewDidEnter(): void {
    this.route.queryParams
      .pipe(
        map((params) => params.orderSignature as string | undefined),
        distinctUntilChanged()
      )
      .subscribe((orderIdSignature) => {
        //Get order from memory so we don't have to wait for menu to load
        //Need stored order to redirect to correct url in case of table or catering order
        let order = this.orderService.getStoredOrder();
        if (!order?.IdSignature) {
          this.paymentFailed('Order not found', order);
          return;
        }
        if (order.IdSignature !== orderIdSignature) {
          this.orderService.getOrder(orderIdSignature).subscribe((dbOrder) => {
            if (!dbOrder) {
              this.paymentFailed('Order not found', order);
              return;
            }
            order = dbOrder;
          });
        }
        this.orderService
          .checkTransactionStatus(orderIdSignature)
          .subscribe((paymentResult) => {
            if (paymentResult.Message == 'Success') {
              //Order will be submitted automatically by checkTransactionStatus
              this.paymentSuccess(paymentResult, order);
            } else {
              const paymentFailedTitle = 'Sorry';
              const paymentFailedReason =
                paymentResult.PaymentFailedReason ||
                'Payment was not successful. Please check your card details and try again';

              this.paymentFailed(
                '',
                order,
                paymentFailedTitle,
                paymentFailedReason
              );
            }
          });
      });
  }

  private async paymentFailed(
    error: string,
    order: Order,
    errorTitle: string = null,
    errorMessageHeader: string = null
  ): Promise<void> {
    //Navigating to payment result does not fully load the order until we know if payment succeeded or not
    //If payment failed, we need to try load the order again
    this.orderService.checkForStoredOrder();

    NavigationEvents.NavigateToOrderPage.emit({
      Page: OrderPage.Summary,
      Order: order,
    });

    AppEvents.ShowErrorModal.emit({
      title: errorTitle,
      header: errorMessageHeader,
      errorMessage: error,
    });

    AppEvents.OrderPaymentFailed.emit();
  }

  private paymentSuccess(paymentResult: PaymentResultDTO, order: Order): void {
    //Only Windcave returns a card in the result
    const isWindcave = paymentResult.Card && !paymentResult.SavedMethod;
    if (isWindcave) {
      paymentResult.Card.IntegrationMethod = PaymentIntegration.Windcave;

      //Must wait until member is loaded so we can check if the card already exists
      this.subscriptions.push(
        MemberEvents.CurrentMember.pipe(filter((m) => m != null)).subscribe(
          async () => {
            const existingCard =
              MemberEvents.CurrentMember.value.PaymentMethods.find(
                (c) =>
                  (c.CardNumber == paymentResult.Card.CardNumber ||
                    c.CardNumber == (paymentResult.Card as any).cardNumber) &&
                  c.IntegrationMethod == paymentResult.Card.IntegrationMethod
              );

            if (!existingCard) {
              this.modalService
                .presentModal({
                  component: ConfirmModalComponent,
                  componentProps: {
                    title: 'Save card',
                    message: 'Do you want to save this card for next time?',
                  },
                  cssClass: 'confirm-modal',
                })
                .subscribe((saveCard) => {
                  if (saveCard) {
                    MemberEvents.SaveCard.emit(paymentResult.Card);
                  }
                });
            }
          }
        )
      );
    } else {
      //Refresh in case card was saved by Paymark
      MemberEvents.RefreshMember.emit();
    }
    NavigationEvents.NavigateToConfirm.emit(order.IdSignature);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }
}
