import { Component } from '@angular/core';
import { combineLatest, Subscription } from 'rxjs';
import { ConfirmModalComponent } from 'src/app/components/confirm-modal/confirm-modal.component';
import { UnavailableProductsComponent } from 'src/app/components/unavailable-products/unavailable-products.component';
import { AppEvents } from 'src/app/models/domain/events/app-events';
import { SaleType, Store } from 'src/app/models/domain/store';
import { UnavailableProductsModel } from 'src/app/models/view-models/unavailable-products-model';
import { MenuService } from 'src/app/services/menu.service';
import { OrderService } from 'src/app/services/order.service';
import { filter } from 'rxjs/operators';
import { CurbsideWarningComponent } from 'src/app/components/curbside-warning/curbside-warning.component';
import { IMemberService } from 'src/app/services/member.service';
import { OnlineConfiguration } from 'src/app/models/domain/online-configuration';
import { IModalService } from 'src/app/services/modal.service';
import { ViewWillEnter, ViewWillLeave } from '@ionic/angular';
import { MemberEvents } from '../../models/domain/events/member-events';

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.scss'],
})
export class OrderComponent implements ViewWillEnter, ViewWillLeave {
  public stores: Array<Store>;
  subscriptions: Subscription[] = [];
  unavailableProducts: { [reason: string]: UnavailableProductsModel } = null;

  private curbsideWarned = false;
  /** Whether the user should be prompted to clear a stale cart. */
  private shouldNotPromptToClearCart: boolean;

  public onlineConfiguration: OnlineConfiguration;

  constructor(
    public orderService: OrderService,
    public menuService: MenuService,
    private modalService: IModalService,
    private memberService: IMemberService
  ) {}

  ionViewWillEnter(): void {
    this.subscriptions = [
      AppEvents.ProductsUnavailable.subscribe((u) => {
        this.displayUnavailableProducts(u);
      }),
      AppEvents.OrderPreLoaded.pipe(filter((e) => e != null)).subscribe(() => {
        this.checkOrderHasCorrectSaleType();
        if (this.orderService.initialStoreParam) {
          this.orderService.order.Store = new Store({
            Name: this.orderService.initialStoreParam,
          });
        }
      }),
      combineLatest([
        AppEvents.OrderLoaded.pipe(filter((o) => o != null)),
        AppEvents.Stores.pipe(filter((s) => s != null)),
      ]).subscribe((ev) => {
        this.applyToOrder();

        this.stores = ev[1];
      }),
      AppEvents.SaleTypeChanged.subscribe((s) => {
        if (
          s.SaleType?.Code == SaleType.CurbsideCode &&
          this.curbsideWarned == false
        ) {
          this.showCurbsideWarning();
        }
      }),
      AppEvents.OnlineConfiguration.subscribe((o) => {
        this.onlineConfiguration = o;
      }),
      MemberEvents.Referrer.subscribe(
        (referrer) => (this.shouldNotPromptToClearCart = !!referrer)
      ),
    ];

    if (this.orderService.order) {
      this.checkOrderHasCorrectSaleType();
    }
  }

  applyToOrder(): void {
    const lastLoadCutoff = new Date();
    lastLoadCutoff.setHours(lastLoadCutoff.getHours() - 8);
    const hardCutoffDate = new Date();
    hardCutoffDate.setHours(hardCutoffDate.getHours() - 48);
    if (this.orderService.order.LastLoaded < hardCutoffDate) {
      this.orderService.setToNewOrder({ store: true, saleType: true });
    } else if (
      this.orderService.order.LastLoaded < lastLoadCutoff &&
      (this.orderService.order.Products.length > 0 ||
        this.orderService.order.Combos.length > 0) &&
      !this.shouldNotPromptToClearCart
    ) {
      this.modalService
        .presentModal<boolean>({
          component: ConfirmModalComponent,
          componentProps: {
            title: 'Items in cart',
            message:
              'You have items currently in your cart, do you want to continue where you left off?',
            yes: 'Continue',
            no: 'Clear cart',
          },
          cssClass: 'confirm-modal cart-continue-modal',
        })
        .subscribe((keepCart) => {
          if (!keepCart) {
            this.orderService.setToNewOrder({ store: true, saleType: true });
          }
        });
    }
    this.orderService.order.IsTableOrder = false;
    this.orderService.order.IsGuestOrder = false;
    this.orderService.order.LastLoaded = new Date();
  }

  checkOrderHasCorrectSaleType(): void {
    if (
      this.orderService.order.SaleType?.Code === SaleType.CateringCode ||
      this.orderService.order.SaleType?.Code === SaleType.TableOrderCode
    ) {
      AppEvents.ChangeSaleType.emit(null);
    }
  }

  displayUnavailableProducts(unavailability: UnavailableProductsModel): void {
    //If unavailableProducts is not currently null,
    //  then we are currently displaying the dialog,
    //  most likely with unavailability for another reason
    //  so we add the new unavailability to the current model
    //  for the dialog
    //If unavailableProducts is null, then display the dialog for the first time
    if (this.unavailableProducts) {
      if (!this.unavailableProducts[unavailability.Reason]) {
        this.unavailableProducts[unavailability.Reason] = unavailability;
      } else {
        this.unavailableProducts[unavailability.Reason].Combos.push(
          ...unavailability.Combos
        );
        this.unavailableProducts[unavailability.Reason].Products.push(
          ...unavailability.Products
        );
      }
    } else {
      this.unavailableProducts = {};
      this.unavailableProducts[unavailability.Reason] = unavailability;

      this.modalService
        .presentModal<undefined>({
          component: UnavailableProductsComponent,
          componentProps: {
            unavailableProducts: this.unavailableProducts,
          },
          cssClass: 'auto-size-modal unavailable-products-modal',
        })
        .subscribe(() => {
          this.unavailableProducts = null;
        });
    }
  }

  showCurbsideWarning(): void {
    this.modalService
      .presentModal({
        component: CurbsideWarningComponent,
        componentProps: {
          member: this.memberService.currentMember,
        },
        cssClass: 'curbside-warning-modal',
        id: 'curbside-warning-modal',
      })
      .subscribe(() => {
        if (this.memberService.currentMember?.CurbsideDetails?.NumberPlate) {
          this.curbsideWarned = true;
        }
      });
  }

  ionViewWillLeave(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }
}
