/**
 * Dietary requirements to show on products as modifiers.
 */
export class DietaryRequirement {
  /** Display name of the requirement. */
  Name: string;
  /** PLU of the requirement for printing on the receipt. */
  Plu: number;
  /** Hardcoded requirement type. */
  Type: DietaryRequirementType;

  constructor(obj: Partial<DietaryRequirement>) {
    this.Name = obj.Name;
    this.Plu = obj.Plu;
    this.Type = obj.Type;
  }
}

/**
 * Dietary requirement values that exactly match the hard-coded values on the
 * server for matching PLUs and Names.
 */
export enum DietaryRequirementType {
  DairyAllergy = 'DairyAllergy',
  EggAllergy = 'EggAllergy',
  GlutenAllergy = 'GlutenAllergy',
  Halal = 'Halal',
  PeanutAllergy = 'PeanutAllergy',
  TomatoAllergy = 'TomatoAllergy',
}
