import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, merge, Observable, of, throwError } from 'rxjs';
import { catchError, filter, map } from 'rxjs/operators';
import { Order } from '../models/domain/order/order';
import { PaymentResponse } from '../models/domain/response/payment-response';
import { OrderDTO } from '../models/dto/order/order.dto';
import { PaymentResultDTO } from '../models/dto/payment-result.dto';
import { SaveOrderResultDTO } from '../models/dto/response/save-order-result.dto';
import { EnvironmentVariables } from '../models/environment';
import { OrderValidationResult } from '../models/domain/order/order-validation-result';
import { OrderValidationResponse } from '../models/domain/response/order-validation-response';
import { OrderValidationFailedReason } from '../models/domain/order/order-validation-failed-reason';
import { PaginatedResults } from '../models/paginated-results';
import { StoreDTO } from '../models/dto/store.dto';
import { SaveOrderResult } from '../models/domain/response/save-order-result';
import { NavigationEvents } from '../models/domain/events/navigation-events';
import { MemberEvents } from '../models/domain/events/member-events';
import { OrderLog } from '../models/domain/order/order-log';
import { getDateTime } from '../helpers/orderDateTimeHelpers';
import { OrderUpdateTimeResponseDto } from '../models/dto/response/order-update-time-response.dto';
import { OrderUpdateTimeResult } from '../models/domain/response/order-update-time-result';
import { Member } from '../models/domain/member';
import { toUtcWithOffset } from '../helpers/dateHelpers';

export abstract class IOrderClient {
  /** Gets the current order from local browser storage. */
  abstract getStored(): Order;

  /** Gets the order with the ID signature from the system. */
  abstract get(signature: string): Observable<Order>;

  /** Checks with the server that the order is valid. */
  abstract validateOrder(order: Order): Observable<OrderValidationResult>;

  /** Checks with the server that the order total has been paid correctly. */
  abstract validateAmountPaid(signature: string): Observable<string>;

  /** Checks with the server for duplicate orders. */
  abstract getAllDuplicateOrders(signature: string): Observable<Array<Order>>;

  /**
   * Gets the page of data starting after `offset` results.
   *
   * @param comoIdSignature User's encrypted Como ID.
   * @param offset The number of results to skip before returning the next page.
   */
  abstract getHistoryPage(
    comoIdSignature: string,
    offset: number
  ): Observable<PaginatedResults<Order>>;

  /** Gets a URL for paying the order. */
  abstract submitOrder(
    order: Order,
    paymentOption: string
  ): Observable<PaymentResponse>;

  /**
   * Checks the status of the order to see if payment is successful or failed.
   */
  abstract getTransactionStatus(order: Order): Observable<PaymentResultDTO>;

  /** Saves the order to local browser storage. */
  abstract saveLocal(order: Order): void;

  /** Saves the order on the system. */
  abstract save(order: Order): Observable<SaveOrderResult | null>;

  /** Saves the order time. */
  abstract updateTime(order: Order): Observable<OrderUpdateTimeResult | null>;

  /** Sends a receipt for the order with `idSignature` to the `emailAddress`. */
  abstract resendReceipt(
    idSignature: string,
    emailAddress: string
  ): Observable<any>;

  /** Sends the order logs to the server. */
  abstract sendLogs(idSignature: string, logs: OrderLog[]): Observable<void>;

  /** Gets the logs from local storage. */
  abstract getStoredLogs(): OrderLog[];

  /** Saves the logs to local storage. */
  abstract saveLogsLocal(orderLogs: OrderLog[]): void;
}
@Injectable({
  providedIn: 'root',
})
export class OrderClient implements IOrderClient {
  /**
   * Local Storage Key for the order logs.
   */
  private orderLogsKey: Readonly<string> = 'currentOrderLogs';

  /**
   * Authentication token used for authenticating requests within the
   * application.
   */
  private authToken: string | null = null;

  /**
   * Subscription used for setting authToken based on the logged-in user.
   */
  private authenticationEvents = merge(
    MemberEvents.LoggedIn,
    MemberEvents.LoggedOut.pipe(map(() => null as Member))
  ).subscribe((member) => {
    this.authToken = member?.ComoIdSignature ?? null;
  });

  constructor(
    private client: HttpClient,
    public variables: EnvironmentVariables
  ) {}

  public sendLogs(idSignature: string, logs: OrderLog[]): Observable<void> {
    return this.client
      .put(`${this.variables.baseApiUrl}/Orders/${idSignature}/Logs`, { logs })
      .pipe(
        map(() => {
          // Return nothing to convert the observable return type to `Observable<void>`
        })
      );
  }

  public get(signature: string): Observable<Order> {
    return this.client
      .get<OrderDTO>(
        `${this.variables.baseApiUrl}/Orders/GetByOrderSignatureId/${signature}`
      )
      .pipe(
        map((r) => {
          return OrderDTO.ToDomain(r);
        })
      );
  }

  public validateAmountPaid(signature: string): Observable<string> {
    return this.client
      .get(
        `${this.variables.baseApiUrl}/Orders/signature/${signature}/CheckAmountPaid`,
        { responseType: 'text' }
      )
      .pipe(
        catchError(this.saveError),
        //res is null on cors preflight
        filter((res) => !!res),
        map((res) => {
          if (typeof res === 'string') {
            return res;
          }
        })
      );
  }

  public getStored(): Order {
    const dto: OrderDTO = JSON.parse(localStorage.getItem('currentOrder'));
    const order = OrderDTO.ToDomain(dto);

    if (!order) {
      return null;
    }

    return order;
  }

  public getStoredLogs(): OrderLog[] {
    return JSON.parse(localStorage.getItem(this.orderLogsKey) ?? '[]');
  }

  public saveLogsLocal(orderLogs: OrderLog[]): void {
    if (orderLogs?.length) {
      localStorage.setItem(this.orderLogsKey, JSON.stringify(orderLogs));
    }
  }

  public getHistoryPage(
    comoIdSignature: string,
    offset: number
  ): Observable<PaginatedResults<Order>> {
    return this.client
      .get<{
        Orders: PaginatedResults<OrderDTO>;
        Stores: Array<StoreDTO>;
      }>(
        `${this.variables.baseApiUrl}/Orders/History/Users/${comoIdSignature}`,
        {
          params: {
            offset: offset.toString(),
          },
        }
      )
      .pipe(
        map((r) => {
          return {
            ...r.Orders,
            Results: r.Orders.Results.map((o) => {
              const order = OrderDTO.ToDomain(o);
              const storeDto = r.Stores.find(
                (store) => o.StoreName === store.Name
              );
              if (storeDto) {
                order.Store = StoreDTO.ToDomain(storeDto);
              }
              return order;
            }),
          };
        })
      );
  }

  public getAllDuplicateOrders(signature: string): Observable<Array<Order>> {
    return this.client
      .get<Array<OrderDTO>>(
        `${this.variables.baseApiUrl}/Orders/GetAllDuplicateOrders/${signature}`
      )
      .pipe(
        map((rr) =>
          rr.map((r) => {
            return OrderDTO.ToDomain(r);
          })
        )
      );
  }

  /**
   * Sends the order to the server for validation.
   */
  validateOrder(order: Order): Observable<OrderValidationResult> {
    return this.client
      .post<OrderValidationResponse>(
        `${this.variables.baseApiUrl}/Orders/Validate`,
        OrderDTO.FromDomain(order)
      )
      .pipe(
        catchError((error) => {
          const errorResponse = (error as HttpErrorResponse)
            .error as OrderValidationResponse;
          if (errorResponse.ErrorCode) {
            return of(errorResponse);
          }

          return of({ Success: false } as OrderValidationResponse);
        }),
        map((result) => {
          if (result.Success) {
            return { Success: result.Success };
          }

          return {
            Success: false,
            Reason: Object.values(OrderValidationFailedReason).includes(
              result.ErrorCode as any
            )
              ? (result.ErrorCode as OrderValidationFailedReason)
              : OrderValidationFailedReason.UNKNOWN,
          };
        })
      );
  }

  public submitOrder(
    order: Order,
    paymentOption: string
  ): Observable<PaymentResponse> {
    const data = {
      ComoIdSignature: order.ComoIdSignature,
      OrderIdSignature: order.IdSignature,
      CardId: paymentOption == 'new' ? null : paymentOption,
      OriginUrl: '',
      AccessToken: MemberEvents.ExternalAuthToken.getValue(),
    };
    return this.client
      .post<PaymentResponse>(
        `${this.variables.baseApiUrl}/Orders/${order.IdSignature}/Submit`,
        data
      )
      .pipe(
        catchError((error) => {
          return this.paymentError(error, order);
        }),
        map((result: PaymentResponse) => {
          if (!result.Link?.startsWith('http') && !result.Success) {
            //Unsuccessful payment for SAVED CARD goes here...
            throw result;
          }
          return result;
        })
      );
  }

  public getTransactionStatus(order: Order): Observable<PaymentResultDTO> {
    return this.client
      .post<PaymentResultDTO>(
        `${this.variables.baseApiUrl}/Orders/GetTransactionStatus/${order.IdSignature}`,
        null
      )
      .pipe(
        catchError((exception: any) => {
          if (exception.error == null) {
            return throwError('Something went wrong checking your payment');
          } else {
            return throwError(exception.error);
          }
        })
      );
  }

  private paymentError(exception: any, order: Order) {
    if (exception.error == null) {
      return throwError('Could not load payment page');
    } else {
      if (
        exception.error ==
        'This order has already been paid, please check your email or order history for confirmation'
      ) {
        NavigationEvents.NavigateToConfirm.emit(order.IdSignature);
        return EMPTY;
      }
      return throwError(exception.error);
    }
  }

  public saveLocal(order: Order): void {
    localStorage.setItem(
      'currentOrder',
      JSON.stringify(OrderDTO.FromDomain(order))
    );
  }

  public save(order: Order): Observable<SaveOrderResult> {
    return this.client
      .post<SaveOrderResultDTO>(
        `${this.variables.baseApiUrl}/Orders/SaveOrderProgress`,
        {
          signatures: {
            orderIdSignature: order.IdSignature,
            comoIdSignature: order.ComoIdSignature,
          },
          order: OrderDTO.FromDomain(order),
        }
      )
      .pipe(
        catchError(this.saveError),
        //res is null on cors preflight
        filter((res) => !!res),
        map<SaveOrderResultDTO, SaveOrderResult>((res) => {
          // The order saved successfully and returned a string saying so.
          // Return null so nothing happens in the order service
          if (typeof res === 'string') {
            return {
              Success: true,
            };
          }

          //Sometimes ASP.net returns a serialization of the task wrapping the result instead of the result
          if ((res as any).IsCompletedSuccessfully) {
            res = (res as any).Result as SaveOrderResultDTO;
          }

          return {
            Success: true,
            Updates: {
              IdSignature: res.orderIdSignature,
              TransactionOpenTime: res.transactionOpenTime
                ? new Date(res.transactionOpenTime)
                : null,
            },
          };
        })
      );
  }

  public updateTime(order: Order): Observable<OrderUpdateTimeResult> {
    const newTime = getDateTime(order.OrderDay, order.OrderTime);
    return this.client
      .post<OrderUpdateTimeResponseDto>(
        `${this.variables.baseApiUrl}/Orders/${order.IdSignature}/expectedTime`,
        {
          newTime: toUtcWithOffset(newTime),
        },
        {
          headers: { Authorization: this.authToken },
        }
      )
      .pipe(
        catchError(this.saveError),
        //res is null on cors preflight
        filter((res) => !!res),
        map<OrderUpdateTimeResponseDto, OrderUpdateTimeResult>((res) => {
          return { ...res };
        })
      );
  }

  private saveError(exception: any) {
    if (exception.error == null) {
      return throwError('Could not save order');
    } else {
      return throwError(exception.error);
    }
  }

  public resendReceipt(
    idSignature: string,
    emailAddress: string
  ): Observable<any> {
    return this.client.post(
      `${this.variables.baseApiUrl}/Orders/ResendReceipt/${idSignature}/${emailAddress}`,
      {}
    );
  }
}
