import { Component, Input } from '@angular/core';
import { EnvironmentVariables } from 'src/app/models/environment';
import { Order } from 'src/app/models/domain/order/order';
import { getLoyaltyNumberFormat } from '../../helpers/angularHelper';

@Component({
  selector: 'app-points-earned',
  templateUrl: './points-earned.component.html',
  styleUrls: ['./points-earned.component.scss'],
})
export class PointsEarnedComponent {
  @Input()
  public order: Order;

  public loyaltyPointFormat = getLoyaltyNumberFormat(this.variables);

  constructor(public variables: EnvironmentVariables) {}
}
