import { Component, Input, OnInit } from '@angular/core';
import { PopoverController, ToastController } from '@ionic/angular';
import { first } from 'rxjs/operators';
import { AppEvents } from 'src/app/models/domain/events/app-events';
import { Order } from 'src/app/models/domain/order/order';
import { OrderStatus } from 'src/app/models/domain/order/order-status';
import { PopoverMessageComponent } from '../popover-message/popover-message.component';
import { EnvironmentVariables } from 'src/app/models/environment';
import { ContactStoreMessageComponent } from '../popover-message/contact-store/contact-store.component';

@Component({
  selector: 'app-order-receipt',
  templateUrl: './order-receipt.component.html',
  styleUrls: ['./order-receipt.component.scss'],
})
export class OrderReceiptComponent implements OnInit {
  @Input()
  public order: Order;

  /**
   *  Calculate GST from price inclusive of GST with `gstCalculator * priceInclusive`
   */
  public gstFraction = this.env.gstPercentage / (1 + this.env.gstPercentage);

  public status: {
    Label: string;
    Helptext?: string;
  };

  public constructor(
    public toastController: ToastController,
    public popoverController: PopoverController,
    public env: EnvironmentVariables
  ) {}

  public ngOnInit(): void {
    this.initStatus();
  }

  /**
   * Shows extra information about the order that the user does not usually need
   * to see.
   */
  async showExtraOrderInfo(ev: Event): Promise<void> {
    // If the order has not been sent to the POS yet it will not have a transaction ID
    if (
      !this.order.Status ||
      this.order.Status === OrderStatus.PLACED ||
      this.order.Status === OrderStatus.WAITING
    ) {
      return;
    }

    const popover = await this.popoverController.create({
      component: PopoverMessageComponent,
      componentProps: {
        message: `Transaction ID: #${this.order.PixelPointTransactionId}`,
      },
      event: ev,
    });

    return popover.present();
  }

  /**
   * Shows extra info about the status to the user.
   */
  async showStatusHelpText(ev: Event): Promise<void> {
    const popover = await this.popoverController.create({
      component: ContactStoreMessageComponent,
      componentProps: {
        phoneNumber: this.order.Store.PhoneNumber,
      },
      event: ev,
    });

    return popover.present();
  }

  /**
   * Initialises the display-friendly status and help text.
   */
  private initStatus() {
    switch (this.order.Status) {
      case OrderStatus.PLACED:
      case OrderStatus.WAITING:
        this.status = { Label: 'Order placed' };
        break;
      case OrderStatus.RECEIVED:
      case OrderStatus.COOKING:
        this.status = { Label: 'Order received' };
        break;
      case OrderStatus.REFUNDED:
        this.status = { Label: 'Order refunded' };
        break;
      case OrderStatus.COMPLETED:
      case OrderStatus.COLLECTED:
        this.status = { Label: 'Order complete' };
        break;
      case OrderStatus.UNCONFIRMED:
      case OrderStatus.FAILED:
      default:
        this.status = {
          Label: 'Order unconfirmed',
          Helptext: 'Contact the store to confirm',
        };
    }
  }

  public resendReceipt(event: MouseEvent): void {
    event.stopPropagation();
    AppEvents.ReceiptResent.pipe(first()).subscribe(async () => {
      (
        await this.toastController.create({ message: 'Resent', duration: 500 })
      ).present();
    });
    AppEvents.ResendReceipt.emit(this.order.IdSignature);
  }

  public reorder(): void {
    AppEvents.Reorder.emit(this.order);
  }
}
