import { Injectable } from '@angular/core';
import {
  ModalController,
  ModalOptions as IonicModalOptions,
} from '@ionic/angular';
import { Observable, Subject, defer } from 'rxjs';
import { shareReplay, takeUntil } from 'rxjs/operators';

/**
 * Service that manages modal lifecycles.
 */
export abstract class IModalService {
  abstract presentModal<TDismiss>(
    opts: IonicModalOptions
  ): Observable<TDismiss>;
}

@Injectable({
  providedIn: 'root',
})
export class ModalService implements IModalService {
  /**
   * A lookup by ID of the modals that are currently open, with the dismiss
   * observable, and the previous subscriber cancellation token.
   */
  private currentModals: Record<
    string,
    { observable$: Observable<any>; cancel$: Subject<void> }
  > = {};

  constructor(private modalController: ModalController) {}

  /**
   * Returns an observable that emits when the modal is dismissed, with the data
   * from the dismiss event.
   *
   * @param opts The data required to construct the modal (TODO: abstract away from IONIC modal)
   */
  presentModal<TDismiss>(opts: IonicModalOptions): Observable<TDismiss> {
    let existingObservable$: Observable<TDismiss>;

    /*
     * Find if a modal is already displaying with the same ID. If so:
     * - emit to the cancellation observable so previous subscribers do not
     *   receive data from the dismiss event
     * - remove the entry from currentModals
     *
     * // TODO: Have a parameter for the function that allows dismissing the
     * // previous modal so a new one can be created, rather than subscribing the
     * // new caller to the existing modal. This would be useful in cases where
     * // the ID of the modal is the same, but it has different arguments in
     * // options
     */
    if (opts.id && this.currentModals[opts.id]) {
      const current = this.currentModals[opts.id];
      existingObservable$ = current.observable$;
      current.cancel$.next();
      current.cancel$.complete();
      delete this.currentModals[opts.id];
    }

    let id: string;
    if (opts.id) {
      // Use the passed in ID if it exists
      id = opts.id;
    } else {
      // Keep generating a random ID until we find one that is not already
      // assigned to a modal
      do {
        id = `modal-${Math.ceil(Math.random() * 10000)}`;
      } while (this.currentModals[id]);
    }

    let observable$: Observable<TDismiss>;
    if (existingObservable$) {
      // Use the existing observable and popup if it exists
      observable$ = existingObservable$;
    } else {
      // Otherwise create a new observable and modal that emits when the modal
      // is dismissed
      observable$ = defer(async () => {
        const itemPickerModal = await this.modalController.create({
          ...opts,
          // Add the new ID if we generated one
          id,
        });
        itemPickerModal.present();
        return itemPickerModal.onDidDismiss<TDismiss>().then((ev) => {
          delete this.currentModals[id];
          return ev.data;
        });
      }).pipe(
        // shareReplay so the modal isn't recreated every time a new subscriber
        // subscribes to the observable
        shareReplay()
      );
    }

    // Create an observable that will cancel previous subscriptions to the modal
    // when emitted to
    const cancel$ = new Subject<void>();

    // When the modal emits, complete the cancellation observable so we don't
    // leave observables open
    observable$.subscribe(() => cancel$.complete());

    this.currentModals[id] = { observable$, cancel$ };

    return observable$.pipe(
      // When cancellationObservable$ is emitted to, this observable will
      // complete and previous subscriptions will be closed
      takeUntil(cancel$)
    );
  }
}
