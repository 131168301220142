import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { EMPTY, of } from 'rxjs';
import { catchError, debounceTime } from 'rxjs/operators';
import { IOrderClient } from '../clients/order-client';
import { Category } from '../models/domain/category';
import { Combo } from '../models/domain/combo';
import { AppEvents } from '../models/domain/events/app-events';
import { NavigationEvents } from '../models/domain/events/navigation-events';
import { NavigationPage, OrderPage } from '../models/domain/navigation-page';
import { Order } from '../models/domain/order/order';
import { Product } from '../models/domain/product';
import { SaleType, SaleTypeCode } from '../models/domain/store';
import { MemberEvents } from '../models/domain/events/member-events';
import {
  generateQueryString,
  QueryParameterConfig,
} from '../helpers/urlHelpers';
import { SaleTypePageNavigationEvent } from '../models/domain/events/sale-type-page-navigation-event';
import { OrderErrorReason } from '../models/domain/enums/order-error-reason';
import { PaymentResponse } from '../models/domain/response/payment-response';

@Injectable({
  providedIn: 'root',
})
export class NavigationService implements OnDestroy {
  public subscriptions = [
    NavigationEvents.NavigateToPage.subscribe((p) => {
      this.navigateToPage(p);
    }),
    NavigationEvents.NavigateToOrderPage.subscribe((e) => {
      this.navigateToOrderPage(e.Page, e.Order);
    }),
    NavigationEvents.NavigateToSaleTypePage.subscribe((e) => {
      this.navigateToSaleTypePage(e);
    }),
    NavigationEvents.NavigateToProduct.subscribe((p) => {
      this.navigateToProduct(p);
    }),
    NavigationEvents.NavigateToCombo.subscribe((c) => {
      this.navigateToCombo(c);
    }),
    NavigationEvents.NavigateToCategory.subscribe((e) => {
      this.navigateToCategory(e.Category, e.Order);
    }),
    NavigationEvents.NavigateToPayment.pipe(
      /*
       * Delay/Debounce to:
       * * allow log service to add logs
       * * catch duplicate payment events, only processing the last one after a
       *   second elapses.
       */
      debounceTime(1000)
    ).subscribe((o) => {
      this.navigateToPayment(o.Order, o.PaymentOption);
    }),
    NavigationEvents.NavigateToPaymentResult.subscribe((evParams) => {
      this.navigateToPaymentResult(evParams.idSignature, evParams.status);
    }),
    NavigationEvents.NavigateToConfirm.subscribe((signature) => {
      this.navigateToConfirm(signature);
    }),
  ];

  constructor(private router: Router, private orderClient: IOrderClient) {}

  private addExternalAuthToken() {
    if (MemberEvents.ExternalAuthToken.getValue().includes('-')) {
      return 'TempToken=' + MemberEvents.ExternalAuthToken.getValue();
    }
    return '';
  }

  private navigateToPage(page: NavigationPage): void {
    this.navigateByUrl(page, [this.addExternalAuthToken()]);
  }

  private navigateToOrderPage(page: OrderPage, order: Order): void {
    let saleTypePageNavigationEvent: Partial<SaleTypePageNavigationEvent> = {
      Page: page,
      SaleType: (order?.SaleType?.Code as SaleTypeCode) ?? SaleType.InStoreCode,
    };

    if (
      order?.SaleType?.Code == SaleType.TableOrderCode &&
      order.Store &&
      order.TableNumber
    ) {
      saleTypePageNavigationEvent = {
        Page: page,
        SaleType: SaleType.TableOrderCode,
        TableNumber: order.TableNumber,
        StoreId: order.Store.Id,
      };
    }

    this.navigateToSaleTypePage(
      saleTypePageNavigationEvent as SaleTypePageNavigationEvent
    );
  }

  private navigateToSaleTypePage(ev: SaleTypePageNavigationEvent): void {
    let baseRoute: string;
    if (
      ev.SaleType === SaleType.TableOrderCode &&
      ev.StoreId &&
      ev.TableNumber
    ) {
      baseRoute = 'table/' + ev.StoreId + '/' + ev.TableNumber;
    } else if (ev.SaleType === SaleType.CateringCode) {
      baseRoute = NavigationPage.Catering;
    } else {
      baseRoute = NavigationPage.Order;
    }

    this.navigateByUrl(`${baseRoute}/${ev.Page}`, [
      this.addExternalAuthToken(),
    ]);
  }

  private navigateToProduct(product: Product): void {
    this.navigateByUrl(`${NavigationPage.Order}/${OrderPage.MainMenu}`, [
      `productId=${product.Id}`,
    ]);
  }

  private navigateToCombo(combo: Combo): void {
    this.navigateByUrl(`${NavigationPage.Order}/${OrderPage.MainMenu}`, [
      `comboId=${combo.Id}`,
    ]);
  }

  private navigateToCategory(category: Category, order: Order): void {
    this.navigateToOrderPage(OrderPage.MainMenu, order);
    AppEvents.ChangeCategory.emit(category);
  }

  private navigateToPayment(order: Order, paymentOption: string): void {
    this.orderClient
      .save(order)
      .pipe(
        catchError((error) => {
          if (error == 'Order already submitted') {
            NavigationEvents.NavigateToPaymentResult.emit({
              idSignature: order.IdSignature,
            });
          } else if (error === OrderErrorReason.IdSignatureEncryptionFailed) {
            //Create a new order Id when encryption failed
            order.IdSignature = '';
            // Make sure order is saved locally so the user can't continue
            // modifying a previous order if they refresh
            this.orderClient.saveLocal(order);
          }
          AppEvents.OrderPaymentFailed.emit();
          return EMPTY;
        })
      )
      .subscribe((orderSaveResponse) => {
        if (!orderSaveResponse.Success) {
          return;
        }
        order.NavigatedToPayment = true;
        this.orderClient.saveLocal(order);
        this.orderClient
          .submitOrder(order, paymentOption)
          .pipe(
            catchError((error) => {
              if (error?.FailureMessages?.[0]) {
                return of(error as PaymentResponse);
              }
              return of({
                Success: false,
                FailureMessages:
                  typeof error === 'string'
                    ? [error]
                    : ['An unknown error occurred'],
              } satisfies PaymentResponse);
            })
          )
          .subscribe((paymentResponse: PaymentResponse) => {
            this.handleSubmissionResponse(paymentResponse, order);
          });
      });
  }

  private handleSubmissionResponse(
    paymentResponse: PaymentResponse,
    order: Order
  ) {
    if (!paymentResponse.Success) {
      // The payment request failed
      this.handleSubmissionFailure(paymentResponse, order);
      return;
    }
    if (paymentResponse.Link && !order.Total) {
      // There was no payment option selected but the response had a link to the
      // payment page. This means the client thinks it is a $0 order, but the API
      // thinks it should cost. Throw an error.
      this.handleSubmissionFailure(
        {
          ...paymentResponse,
          FailureMessages: [
            'An error occurred when verifying prices. Could not proceed with your order.',
            ...(paymentResponse.FailureMessages || []),
          ],
        },
        order
      );
      return;
    }
    if (paymentResponse.Link) {
      // The payment request returned a link for the user to navigate to
      AppEvents.PaymentLink.next({
        link: paymentResponse.Link,
      });
      this.navigateToOrderPage(OrderPage.Payment, order);
      return;
    }
    // The payment request succeeded
    this.navigateToConfirmationResult(order);
  }

  private handleSubmissionFailure(
    paymentResponse: PaymentResponse,
    order: Order
  ) {
    // Display error
    // Navigate back to menu
    AppEvents.ShowErrorModal.emit({
      errorMessage: paymentResponse.FailureMessages[0],
      options: {
        forceReload: true,
      },
    });

    order.NavigatedToPayment = false;
    if (!order.Total) {
      AppEvents.OrderSubmitFailed.emit();
    } else {
      AppEvents.OrderPaymentFailed.emit();
    }
    this.orderClient.saveLocal(order);
    return;
  }

  private navigateToPaymentResult(id: string, status?: string): void {
    const externalAuthToken = this.addExternalAuthToken();
    const paymentStatus: QueryParameterConfig = {
      key: 'status',
      value: status,
      includeIfNull: false,
    };
    const idSignature: QueryParameterConfig = {
      key: 'orderSignature',
      value: id,
      includeIfNull: false,
    };
    this.navigateByUrl(
      `${this.getOrderPageRouteBase()}/${OrderPage.PaymentResult}`,
      [idSignature, externalAuthToken, paymentStatus]
    );
  }

  private navigateToConfirmationResult(order: Order) {
    const idSignature: QueryParameterConfig = {
      key: 'orderSignature',
      value: order.IdSignature,
      includeIfNull: false,
    };
    this.navigateByUrl(
      `${this.getOrderPageRouteBase()}/${OrderPage.PaymentResult}`,
      [this.addExternalAuthToken(), idSignature]
    );
  }

  private navigateToConfirm(signature: string): void {
    const idSignature: QueryParameterConfig = {
      key: 'orderSignature',
      value: signature,
      includeIfNull: false,
    };
    this.navigateByUrl(
      `${this.getOrderPageRouteBase()}/${OrderPage.Confirmation}`,
      [idSignature, this.addExternalAuthToken()]
    );
  }

  /**
   * Navigates to the specified URL and adds query parameters if provided.
   *
   * @param url URL to navigate to. Can be an absolute or a relative URL.
   * @param queryParams List of query parameters to append to the URL.
   */
  private navigateByUrl(
    url: string,
    queryParams?: (QueryParameterConfig | string)[]
  ) {
    // Split query params out of url, in case they were added earlier
    const [urlNormalised, urlQuery] = url.split('?');
    if (urlQuery) {
      queryParams.push(...urlQuery.split('&'));
    }
    url = urlNormalised.replace(/\/+/, '/');
    const params = queryParams?.length ? generateQueryString(queryParams) : '';
    this.router.navigateByUrl(`${url}${params}`);
  }

  /**
   * Gets the Navigation page that the user is currently on, so that it can be
   * preserved when navigating to a new order page. If the current URL is not
   * from an order page, so the sale type cannot be ascertained, the default
   * base route of `NavigationPage.Order` will be used.
   *
   * E.g., `/catering/main-menu` -> `/catering/checkout`
   */
  private getOrderPageRouteBase(): string {
    const defaultBaseOrderPage = NavigationPage.Order;
    const orderBaseRoutes = [
      NavigationPage.Order,
      NavigationPage.Table,
      NavigationPage.Catering,
    ];
    let route = location.pathname;
    if (route.startsWith('/')) {
      route = route.substring(1);
    }
    const routeSegments = route.split('/');
    let routeBase: string =
      routeSegments[0] || (defaultBaseOrderPage as string);

    if (!orderBaseRoutes.includes(routeBase as NavigationPage)) {
      routeBase = defaultBaseOrderPage;
    }

    if (
      routeBase === (NavigationPage.Table as string) &&
      Number.parseInt(routeSegments[1], 10)
    ) {
      routeBase += `/${routeSegments[1]}`;
    }
    return routeBase;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }
}
