import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { merge, Subscription } from 'rxjs';
import { AppEvents } from 'src/app/models/domain/events/app-events';
import { Member } from 'src/app/models/domain/member';
import { Reward } from 'src/app/models/domain/reward';
import { Order } from 'src/app/models/domain/order/order';
import { EnvironmentVariables } from 'src/app/models/environment';
import { CouponService } from 'src/app/services/coupon.service';
import { PurchasableReward } from 'src/app/models/domain/purchasable-reward';
import { ModalComponent } from 'src/app/models/view-models/modal-component';
import { RewardModalDismissEvent } from './reward-modal-dismiss-event';
import { getLoyaltyNumberFormat } from '../../helpers/angularHelper';

@Component({
  selector: 'app-reward-modal',
  templateUrl: './reward-modal.component.html',
  styleUrls: ['./reward-modal.component.scss'],
})
export class RewardModalComponent
  extends ModalComponent<RewardModalDismissEvent>
  implements OnInit, OnDestroy
{
  @Input() order: Order;

  @Input() member: Member;

  public selectedReward: Reward | null = null;
  public hasRewardInCart = false;
  public rewardBeingPurchased: PurchasableReward = null;

  public confirmReward: Reward = null;

  public purchasableRewards: PurchasableReward[] = [];
  public nextPurchasableReward: PurchasableReward = undefined;

  public loyaltyPointFormat = getLoyaltyNumberFormat(this.variables);

  private subscriptions: Subscription[] = [];

  constructor(
    modalController: ModalController,
    public variables: EnvironmentVariables,
    public couponService: CouponService
  ) {
    super(modalController);
  }

  ngOnInit(): void {
    if (this.order.ComoRewards.length > 0) {
      this.hasRewardInCart = true;
      this.selectedReward = this.member.Assets.find(
        (a) =>
          (a.Key != null && a.Key == this.order.ComoRewards[0].key) ||
          (a.Name != null && a.Name == this.order.ComoRewards[0].name)
      );
    }

    this.subscriptions.push(
      this.couponService.purchasableRewards$.subscribe((purchasableRewards) => {
        this.purchasableRewards = purchasableRewards;
        this.nextPurchasableReward = purchasableRewards
          .filter(
            (reward) =>
              reward.Price > this.member.PointsBalance.Balance.Monetary
          )
          .sort((a, b) => a.Price - b.Price)[0];
      }),
      AppEvents.RewardPurchased.subscribe((ev) => {
        this.confirmReward = ev.reward;
      }),
      merge(
        AppEvents.RewardPurchased,
        AppEvents.RewardPurchaseFailed
      ).subscribe(() => {
        this.rewardBeingPurchased = null;
      })
    );
  }

  selectReward(reward: Reward): void {
    this.confirmReward = null;
    if (this.selectedReward == reward) {
      this.selectedReward = null;
    } else {
      this.selectedReward = reward;
    }
  }

  selectPurchasableReward(purchasableReward: PurchasableReward): void {
    this.rewardBeingPurchased = purchasableReward;
    AppEvents.PurchaseReward.emit({ purchasableReward });
  }

  useReward(): void {
    super.dismiss({
      code: 'SELECTED',
      selectedReward: this.selectedReward,
    });
  }

  dismiss(): void {
    super.dismiss({
      code: 'DISMISSED',
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }
}
