import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { timer } from 'rxjs';
import { AppEvents } from 'src/app/models/domain/events/app-events';
import { NavigationEvents } from 'src/app/models/domain/events/navigation-events';
import { SaleType, Store, WeekOpenTime } from 'src/app/models/domain/store';
import { StoreInfoModalComponent } from '../store-info-modal/store-info-modal.component';
import { OrderService } from '../../services/order.service';

@Component({
  selector: 'app-store-picker',
  templateUrl: './store-picker.component.html',
  styleUrls: ['./store-picker.component.scss'],
})
export class StorePickerComponent implements OnInit, OnDestroy {
  @Input()
  stores: Array<Store>;

  @Input()
  currentStore: Store;

  highlightedStore: Store | null = null;
  storeOpenTimes: ReadonlyArray<WeekOpenTime> | undefined;
  orderSaleType: string | undefined;

  currentLocation: google.maps.LatLngLiteral;

  showRegionSelector = true;

  model: {
    currentRegion: string | null;
  } = {
    currentRegion: null,
  };
  regions: Array<{ name: string; expanded: boolean }>;

  initialisedWithStore = false;

  isCateringOrder: boolean =
    this.orderService.order.SaleType?.Code === SaleType.CateringCode;

  //Auto update to closest store based on location until the customer manually selects a store
  manuallySelectedStore = false;

  subscriptions = [
    NavigationEvents.MenuOpening.subscribe(() => {
      this.menuOpening();
    }),
    AppEvents.CurrentLocation.subscribe((l) => {
      this.currentLocation = l;
      this.checkCurrentLocationStore();
    }),
  ];

  constructor(
    public orderService: OrderService,
    public modalController: ModalController
  ) {}

  ngOnInit(): void {
    //Distinct cities for stores
    this.regions = this.stores
      .map((store) => store.City)
      .filter((v, i, a) => a.indexOf(v) === i)
      .sort((a, b) => (a < b ? -1 : 1))
      .map((regionName) => ({ name: regionName, expanded: true }));

    this.showRegionSelector =
      this.regions.length > 1 && this.stores?.length > 8;

    this.isCateringOrder =
      this.orderService.order.SaleType?.Code === SaleType.CateringCode;
    this.initialisedWithStore = this.currentStore != null;
    this.manuallySelectedStore = this.initialisedWithStore;
    if (this.currentStore) {
      this.setStore(this.currentStore);
      this.model.currentRegion = this.highlightedStore?.City;
    } else {
      //TODO: Sort by closest store if we have
      if (this.isCateringOrder) {
        this.setStore(
          this.stores.find(
            (store) =>
              store.OnlineOrderingActive &&
              store.Catering?.IsActive &&
              store.Catering?.MenuId
          )
        );
      } else {
        this.setStore(this.stores[0]);
      }
      this.model.currentRegion = this.highlightedStore.City;
      this.checkCurrentLocationStore();
    }
  }

  private setStore(store: Store): void {
    this.highlightedStore = store;
    this.storeOpenTimes = store
      ? this.orderService.getStoreOpenTimesForOrder(
          store,
          this.orderService.order
        )
      : undefined;
    this.orderSaleType = this.orderService.order?.SaleType?.Name;
  }

  toggleRegionExpanded(regionName: string): void {
    const region = this.regions.find((r) => r.name === regionName);
    if (region) {
      region.expanded = !region.expanded;
    }
  }

  selectStore(store: Store, event: MouseEvent): void {
    this.modalController.dismiss(store);
    if (event) {
      event.stopPropagation();
    }
  }

  regionSelected(): void {
    const firstStoreInRegion = this.stores.find(
      (s) => s.City == this.model.currentRegion
    );
    this.highlightStore(firstStoreInRegion);
  }

  storeDetailsModal: HTMLIonModalElement;

  async showStoreDetails(store: Store): Promise<void> {
    const storeOpenTimes = this.orderService.getStoreOpenTimesForOrder(
      store,
      this.orderService.order
    );
    const orderSaleType = this.orderService.order?.SaleType?.Name;
    this.storeDetailsModal = await this.modalController.create({
      component: StoreInfoModalComponent,
      componentProps: {
        store: store,
        storeOpenTimes: storeOpenTimes,
        orderSaleType: orderSaleType,
      },
      cssClass: 'show-header-modal',
    });
    this.storeDetailsModal.onDidDismiss().then((d) => {
      const data = d.data as Store;
      if (data) {
        timer(10).subscribe(() => {
          this.modalController.dismiss(data);
        });
      }
    });
    await this.storeDetailsModal.present();
  }

  highlightStore(store: Store): void {
    this.setStore(store);
    this.manuallySelectedStore = true;
  }

  checkStoreCatering(store: Store): boolean {
    if (
      store.OnlineOrderingActive &&
      store.Catering?.IsActive &&
      store.Catering?.MenuId != '0'
    ) {
      return true;
    }
    return false;
  }

  checkCurrentLocationStore(): void {
    if (!this.stores || !this.currentLocation || this.manuallySelectedStore) {
      return;
    }
    //disabled functionality as per request from marketing team
    // const stores = [...this.stores].sort((a, b) => a.Distance < b.Distance ? -1: 1);
    // this.highlightedStore = stores[0];
    // this.model.currentRegion = this.highlightedStore.City;
  }

  close(): void {
    this.modalController.dismiss(null, null, 'StorePicker');
  }

  menuOpening(): void {
    if (this.initialisedWithStore) {
      this.modalController.dismiss(null, null, 'StorePicker');
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }
}
