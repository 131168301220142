import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { GoogleMapsModule } from '@angular/google-maps';
import { IonicModule } from '@ionic/angular';
import { ComponentsModule } from '../components/components.module';
import { AccountComponent } from './account/account.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { HomeComponent } from './home/home.component';
import { LocationsComponent } from './locations/locations.component';
import { MainMenuComponent } from './main-menu/main-menu.component';
import { MyOrdersComponent } from './my-orders/my-orders.component';
import { OrderConfirmationComponent } from './order-confirmation/order-confirmation.component';
import { OrderComponent } from './order/order.component';
import { PaymentResultComponent } from './payment-result/payment-result.component';
import { SummaryComponent } from './summary/summary.component';
import { FormsModule } from '@angular/forms';
import { TableOrderingComponent } from './table-ordering/table-ordering.component';
import { CateringComponent } from './catering/catering.component';
import { PaymentComponent } from './payment/payment.component';
import { ComponentLibModule } from '../component-lib/component-lib.module';

@NgModule({
  imports: [
    BrowserModule,
    RouterModule,
    IonicModule,
    FormsModule,
    ComponentsModule,
    GoogleMapsModule,
    ComponentLibModule,
  ],
  declarations: [
    HomeComponent,
    OrderComponent,
    CheckoutComponent,
    MainMenuComponent,
    AccountComponent,
    SummaryComponent,
    PaymentResultComponent,
    OrderConfirmationComponent,
    MyOrdersComponent,
    LocationsComponent,
    TableOrderingComponent,
    CateringComponent,
    PaymentComponent,
  ],
})
export class ContainersModule {}
