import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { NavigationEvents } from 'src/app/models/domain/events/navigation-events';
import { Member } from 'src/app/models/domain/member';
import { NavigationPage } from 'src/app/models/domain/navigation-page';
import { ModalComponent } from 'src/app/models/view-models/modal-component';

@Component({
  selector: 'app-curbside-warning',
  templateUrl: './curbside-warning.component.html',
  styleUrls: ['./curbside-warning.component.scss'],
})
export class CurbsideWarningComponent extends ModalComponent {
  @Input()
  member: Member;

  constructor(modalController: ModalController) {
    super(modalController);
  }

  goToProfile(): void {
    NavigationEvents.NavigateToPage.emit(NavigationPage.AccountDetails);
    this.dismiss(undefined);
  }

  close(): void {
    this.dismiss(undefined);
  }
}
