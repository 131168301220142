import { OrderPeriod } from '../domain/order-period';

/** @see OrderPeriod */
export class OrderPeriodDTO {
  public StoreId: string;
  public Offset: number;
  public StartTime: string;
  public EndTime: string;
  public Total: number;

  public static ToDomain(dto: OrderPeriodDTO): OrderPeriod {
    return new OrderPeriod({
      ...dto,
      StartTime: new Date(dto.StartTime),
      EndTime: new Date(dto.EndTime),
    });
  }
}
