import { DatePipe } from '@angular/common';
import { Coupon } from '../../domain/coupon';
import { Reward } from '../../domain/order/reward';
import { Order } from '../../domain/order/order';
import { OrderDay } from '../../domain/order/order-day';
import { OrderStatus } from '../../domain/order/order-status';
import { OrderTime } from '../../domain/order/order-time';
import { SaleType, SaleTypeCode, Store } from '../../domain/store';
import { OrderComboDTO } from './order-combo.dto';
import { OrderProductDTO } from './order-product.dto';
import { OrderStoreDTO } from './order-store.dto';
import { toUtcWithOffset } from '../../../helpers/dateHelpers';

export class OrderDTO {
  public Id: string;
  public IdSignature: string;

  public Status: string;
  public PixelPointTransactionId: number;

  public Store: OrderStoreDTO;
  public StoreId: string;
  public StoreName: string;
  public StoreDisplayName: string;
  public SaleType: string;
  public OrderSaleType: SaleType;

  public Products: Array<OrderProductDTO>;
  public Combos: Array<OrderComboDTO>;
  public Coupons: Array<Coupon>;

  public ExpectedDate: string;
  public ExpectedTime: string;

  public Subtotal: number;
  public Discount: number;
  public Total: number;
  public PointsEarned: number;

  public ComoIdSignature: string;

  //Set by server on submit
  public FirstName: string;
  public LastName: string;
  public EmailAddress: string;
  public PhoneNumber: string;

  public CarColour: string;
  public CarModel: string;
  public NumberPlate: string;

  public TableNumber: number;
  public IsGuestOrder: boolean;
  public Buzzer: number;

  public ComoRewards: Array<Reward>;
  public ComoDeals: Array<Reward>;
  public TransactionOpenTime: string;

  public NavigatedToPayment: boolean;

  public LastLoaded: string;

  constructor(o: Partial<OrderDTO>) {
    Object.assign(this, o);
  }

  public static ToDomain(dto: OrderDTO): Order {
    if (dto == null) {
      return null;
    }
    const datePipe: DatePipe = new DatePipe('en-US');
    const isValidSaleType = [
      SaleType.CateringCode,
      SaleType.TableOrderCode,
      SaleType.InStoreCode,
      SaleType.CurbsideCode,
    ].includes(dto.SaleType);
    const saleType =
      dto.SaleType && isValidSaleType
        ? new SaleType({ Code: dto.SaleType as SaleTypeCode })
        : null;
    const order: Order = new Order({
      Id: dto.Id,
      IdSignature: dto.IdSignature,
      Status: dto.Status as OrderStatus,
      PixelPointTransactionId: dto.PixelPointTransactionId,
      ComoIdSignature: dto.ComoIdSignature,
      NumberPlate: dto.NumberPlate,
      CarColour: dto.CarColour,
      CarModel: dto.CarModel,
      Store: dto.StoreName
        ? new Store({
            Id: dto.StoreId,
            Name: dto.StoreName,
            DisplayName: dto.StoreDisplayName,
          })
        : null,
      SaleType: saleType,
      ExpectedTime: new Date(dto.ExpectedTime),
      OrderDay:
        dto.ExpectedTime || dto.ExpectedDate
          ? new OrderDay({
              Date: new Date(dto.ExpectedTime || dto.ExpectedDate),
              Label: datePipe.transform(
                new Date(dto.ExpectedTime || dto.ExpectedDate),
                'EEE, dd MMM'
              ),
            })
          : null,
      OrderTime: dto.ExpectedTime
        ? new OrderTime({
            Time: new Date(dto.ExpectedTime),
            Label: datePipe.transform(new Date(dto.ExpectedTime), 'h:mm a'),
          })
        : null,
      Products: dto.Products
        ? dto.Products.map((p) => OrderProductDTO.ToDomain(p))
        : [],
      Combos: dto.Combos
        ? dto.Combos.map((c) => OrderComboDTO.ToDomain(c))
        : [],
      SubTotal: dto.Subtotal,
      Discount: dto.Discount,
      Total: dto.Total,
      PointsEarned: dto.PointsEarned,
      Coupons: dto.Coupons,
      EmailAddress: dto.EmailAddress,
      FirstName: dto.FirstName,
      LastName: dto.LastName,
      PhoneNumber: dto.PhoneNumber,
      TableNumber: dto.TableNumber,
      ComoRewards: dto.ComoRewards ? dto.ComoRewards : [],
      ComoDeals: dto.ComoDeals ? dto.ComoDeals : [],
      TransactionOpenTime: dto.TransactionOpenTime
        ? new Date(dto.TransactionOpenTime)
        : null,
      NavigatedToPayment: dto.NavigatedToPayment,
      LastLoaded: dto.LastLoaded ? new Date(dto.LastLoaded) : new Date(),
      IsGuestOrder: dto.IsGuestOrder,
    });
    if (order.OrderDay?.Date) {
      order.OrderDay.Date.setHours(0, 0, 0, 0);
    }
    return order;
  }

  public static FromDomain(order: Order): OrderDTO {
    if (order == null) {
      return null;
    }
    const dto = new OrderDTO({});
    dto.Id = order.Id;
    dto.IdSignature = order.IdSignature;
    dto.Status = order.Status;
    dto.PixelPointTransactionId = order.PixelPointTransactionId;
    dto.ComoIdSignature = order.ComoIdSignature;
    dto.EmailAddress = order.EmailAddress;
    dto.FirstName = order.FirstName;
    dto.LastName = order.LastName;
    dto.PhoneNumber = order.PhoneNumber;
    dto.NumberPlate = order.NumberPlate;
    dto.CarColour = order.CarColour;
    dto.CarModel = order.CarModel;
    dto.Store = OrderStoreDTO.FromStore(order.Store);
    dto.StoreId = order.Store?.Id;
    dto.StoreName = order.Store?.Name;
    dto.StoreDisplayName = order.Store?.DisplayName;
    dto.SaleType = order.SaleType?.Code;
    dto.OrderSaleType = order.SaleType;
    dto.Products = order.Products
      ? order.Products.map((p) => OrderProductDTO.FromDomain(p))
      : [];
    dto.Combos = order.Combos
      ? order.Combos.map((c) => OrderComboDTO.FromDomain(c))
      : [];
    dto.Coupons = order.Coupons;
    dto.TableNumber = order.TableNumber;
    dto.IsGuestOrder = order.IsGuestOrder;
    dto.Buzzer = order.TableNumber;
    dto.ComoRewards = order.ComoRewards;
    dto.ComoDeals = order.ComoDeals;
    dto.NavigatedToPayment = order.NavigatedToPayment;

    const datepipe: DatePipe = new DatePipe('en-US');
    if (order.TransactionOpenTime) {
      dto.TransactionOpenTime = toUtcWithOffset(order.TransactionOpenTime);
    }
    if (order.LastLoaded) {
      dto.LastLoaded = toUtcWithOffset(order.LastLoaded);
    }

    if (order.OrderDay?.Date) {
      const expectedTime = new Date(order.OrderDay?.Date.getTime());
      if (expectedTime && order.OrderDay) {
        dto.ExpectedDate = toUtcWithOffset(expectedTime);

        if (expectedTime && order.OrderTime) {
          expectedTime.setHours(order.OrderTime.Time.getHours());
          expectedTime.setMinutes(order.OrderTime.Time.getMinutes());

          //2021-09-23T13:00:00+12:00
          dto.ExpectedTime = toUtcWithOffset(expectedTime);
        }
      }
    }

    dto.Coupons = order.Coupons;
    dto.Subtotal = order.SubTotal;
    dto.Discount = order.Discount;
    dto.Total = order.Total;
    dto.PointsEarned = order.PointsEarned;
    return dto;
  }
}
