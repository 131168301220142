import { Component, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import {
  PopoverController,
  ToastController,
  ViewWillEnter,
} from '@ionic/angular';
import { MemberEvents } from 'src/app/models/domain/events/member-events';
import { Member } from 'src/app/models/domain/member';
import { IMemberService } from 'src/app/services/member.service';
import { PopoverMessageComponent } from '../popover-message/popover-message.component';

@Component({
  selector: 'app-account-details',
  templateUrl: './account-details.component.html',
  styleUrls: ['./account-details.component.scss'],
})
export class AccountDetailsComponent implements ViewWillEnter, OnDestroy {
  subscriptions = [
    MemberEvents.CurrentMember.subscribe(() => this.getCopyOfMember()),
    MemberEvents.MemberUpdated.subscribe(async () => {
      (
        await this.toastController.create({
          message: 'Profile updated',
          duration: 800,
        })
      ).present();
      this.setEditing(false);
    }),
    MemberEvents.UpdateMemberFailed.subscribe(async (e) => {
      (
        await this.toastController.create({ message: e, duration: 1500 })
      ).present();
    }),
  ];

  detailsForm: FormGroup;
  editing = false;

  constructor(
    public memberService: IMemberService,
    public toastController: ToastController,
    public popoverController: PopoverController
  ) {}

  member: Member;

  ionViewWillEnter(): void {
    this.getCopyOfMember();
  }

  getCopyOfMember(): void {
    this.member = new Member(this.memberService.currentMember);
  }

  setEditing(value: boolean): void {
    this.editing = value;
  }

  async showPhoneNumberMessage(ev: Event): Promise<void> {
    const popover = this.popoverController.create({
      component: PopoverMessageComponent,
      componentProps: {
        message: 'Please contact us directly to change your phone number',
      },
      event: ev,
    });
    await (await popover).present();
  }

  async showBirthdayMessage(ev: Event): Promise<void> {
    const popover = await this.popoverController.create({
      component: PopoverMessageComponent,
      componentProps: {
        message: 'Please contact us directly to update your D.O.B.',
      },
      event: ev,
      cssClass: 'popover-warning',
    });

    popover.present();
  }

  allowedKeys = [
    '0',
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    '/',
    'Backspace',
    'Delete',
    'Enter',
    'ArrowLeft',
    'ArrowRight',
    'Tab',
  ];

  /**
   * Prevent any character being added that is not valid for a date.
   */
  birthdayKeyDown(event: KeyboardEvent): void {
    if (!this.allowedKeys.includes(event.key) && !event.ctrlKey) {
      event.preventDefault();
    }
  }

  updateProfile(): void {
    MemberEvents.UpdateMember.emit(this.member);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  displayError = async (message: string): Promise<void> => {
    (
      await this.toastController.create({
        message: message,
        duration: 3000,
      })
    ).present();
  };
}
