import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { MemberEvents } from 'src/app/models/domain/events/member-events';
import { NavigationEvents } from 'src/app/models/domain/events/navigation-events';
import { LoginModel } from 'src/app/models/view-models/login-model';
import { ModalComponent } from '../../../models/view-models/modal-component';

@Component({
  selector: 'app-login-modal',
  templateUrl: './login-modal.component.html',
  styleUrls: ['./login-modal.component.scss'],
})
export class LoginModalComponent
  extends ModalComponent<void>
  implements OnInit, OnDestroy
{
  @Input()
  loginModel: LoginModel;

  public subscriptions: Subscription[] = [];

  constructor(modalController: ModalController) {
    super(modalController);
  }

  ngOnInit() {
    this.subscriptions = [
      MemberEvents.LoggedIn.subscribe(() => {
        this.dismiss();
      }),
      NavigationEvents.MenuOpening.subscribe((e) => {
        this.menuOpening();
      }),
    ];
  }

  /**
   * A navigation menu was opened.
   */
  menuOpening() {
    super.dismiss();
  }

  /**
   * Dismisses the login modal.
   */
  dismiss() {
    super.dismiss();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }
}
