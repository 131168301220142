import { Component } from '@angular/core';
import { ViewDidEnter, ViewDidLeave } from '@ionic/angular';
import { combineLatest, merge, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { LoginModalComponent } from 'src/app/components/login/login-modal/login-modal.component';
import { makeURLStartWithHttp } from 'src/app/helpers/urlHelpers';
import { Category } from 'src/app/models/domain/category';
import { AppEvents } from 'src/app/models/domain/events/app-events';
import { MemberEvents } from 'src/app/models/domain/events/member-events';
import { NavigationEvents } from 'src/app/models/domain/events/navigation-events';
import { OrderPage } from 'src/app/models/domain/navigation-page';
import {
  LinkImage,
  LinkType,
} from 'src/app/models/domain/online-configuration';
import { SaleType, Store } from 'src/app/models/domain/store';
import { EnvironmentVariables } from 'src/app/models/environment';
import { CouponService } from 'src/app/services/coupon.service';
import { IMemberService } from 'src/app/services/member.service';
import { MenuService } from 'src/app/services/menu.service';
import { OrderService } from 'src/app/services/order.service';
import { Order } from '../../models/domain/order/order';
import { StoreTimePickerModel } from '../../models/view-models/store-time-picker-model';
import { StoreTimePickerModalComponent } from '../../components/store-time-picker-modal/store-time-picker-modal.component';
import { StoreChangeEvent } from '../../models/domain/events/store-change-event';
import { OrderTimeChangedReason } from '../../models/domain/events/order-time-changed-reason';
import { ModalService } from '../../services/modal.service';
import { CarouselElement } from '../../component-lib/carousel/model/CarouselElement';

@Component({
  selector: 'app-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.scss'],
})
export class MainMenuComponent implements ViewDidEnter, ViewDidLeave {
  public stores: Array<Store>;
  public orderValid = false;
  public canGoToCheckout = false;

  public selectedCategory: Category | null = null;

  //Force the store list component to be reinitialised every time we navigate
  //So it gets reset correctly when a new order is created
  public showStoreList = false;

  public isReferral = false;

  public storeTimePickerShown: boolean;
  public storeTimePickerModel: StoreTimePickerModel;

  public displaySlider = false;
  public carouselImages: Array<CarouselElement & LinkImage>;

  private subscriptions: Subscription[] = [];

  constructor(
    public orderService: OrderService,
    public memberService: IMemberService,
    public menuService: MenuService,
    public couponService: CouponService,
    public variables: EnvironmentVariables,
    private modalService: ModalService
  ) {}

  ionViewDidEnter(): void {
    this.subscriptions = [
      //Mobile button active
      merge(AppEvents.CartUpdated, AppEvents.CartCleared).subscribe(() => {
        this.checkCanGoToCheckout();
      }),
      //Desktop button active
      merge(
        AppEvents.CartUpdated,
        AppEvents.CartCleared,
        AppEvents.OrderTimeChanged,
        MemberEvents.LoggedIn,
        AppEvents.SaleTypeChanged,
        MemberEvents.MemberUpdated,
        MemberEvents.LoggedOut
      ).subscribe(() => {
        this.checkOrderValid();
      }),
      MemberEvents.Referrer.subscribe((referrer) => {
        if (referrer != null) {
          this.isReferral = true;
          this.showLoginModal(true);
        }
      }),
      AppEvents.Stores.subscribe((stores) => {
        this.stores = stores;
      }),
      AppEvents.ChangeCategory.subscribe(() => {
        this.scrollToCategories();
      }),
      combineLatest([
        AppEvents.OrderLoaded.pipe(filter((o) => o != null)),
        AppEvents.Stores.pipe(filter((s) => s != null)),
      ]).subscribe(([order]) => {
        if (!order.Store) {
          this.showStoreTimePickerModal(order);
        }
      }),
      combineLatest([
        AppEvents.OrderLoaded.pipe(filter((o) => o != null)),
        AppEvents.CarouselSet.pipe(filter((o) => o != null)),
      ]).subscribe((e) => {
        const onlineConfiguration = e[1];

        const order = e[0];
        if (order.SaleType?.Code == SaleType.CateringCode) {
          this.carouselImages = onlineConfiguration.StoreCateringCarousel?.map(
            (item) => ({
              ...item,
              imageSrc: item.Image,
              link: item.Link,
            })
          );
        } else if (order.SaleType?.Code == SaleType.TableOrderCode) {
          this.carouselImages = onlineConfiguration.StoreTableCarousel?.map(
            (item) => ({
              ...item,
              imageSrc: item.Image,
              link: item.Link,
            })
          );
        } else {
          this.carouselImages = onlineConfiguration.StoreOrderCarousel?.map(
            (item) => ({
              ...item,
              imageSrc: item.Image,
              link: item.Link,
            })
          );
        }
      }),
      this.orderService.currentCategory.subscribe((category) => {
        this.selectedCategory = category;
      }),
    ];
    this.showStoreList = true;
    this.displaySlider = true;
    this.checkCanGoToCheckout();
    this.checkOrderValid();
  }

  checkCanGoToCheckout(): void {
    this.canGoToCheckout =
      this.orderService.order &&
      (this.orderService.order.Combos.length > 0 ||
        this.orderService.order.Products.length > 0);
  }

  checkOrderValid(): void {
    if (!this.orderService.order) {
      this.orderValid = false;
      return;
    }
    this.orderValid =
      (this.orderService.order.Products.length > 0 ||
        this.orderService.order.Combos.length > 0) &&
      this.orderService.order.Store != null &&
      this.orderService.order.Store.OnlineOrderingActive &&
      this.orderService.order.SaleType != null &&
      this.orderService.order.OrderDay != null &&
      this.orderService.order.OrderTime != null &&
      (this.orderService.order.SaleType.Code != SaleType.CurbsideCode ||
        !!this.memberService.currentMember?.CurbsideDetails?.NumberPlate) &&
      (this.orderService.order.SaleType.Code != SaleType.CateringCode ||
        this.orderService.order.SubTotal >=
          this.orderService.order.Store.CateringMinimumOrderPrice);
  }

  goToCheckout(): void {
    if (this.canGoToCheckout) {
      NavigationEvents.NavigateToOrderPage.emit({
        Page: OrderPage.Checkout,
        Order: this.orderService.order,
      });
    }
  }

  goToSummary(): void {
    if (
      !this.memberService.currentMember &&
      !this.orderService.order.IsTableOrder
    ) {
      this.showLoginModal();
      return;
    }
    NavigationEvents.NavigateToOrderPage.emit({
      Page: OrderPage.Summary,
      Order: this.orderService.order,
    });
  }

  async showLoginModal(stayOnPage: boolean = false): Promise<void> {
    this.modalService
      .presentModal({
        component: LoginModalComponent,
        componentProps: {
          loginModel: this.memberService.loginModel,
        },
        cssClass: 'show-header-modal login-modal',
      })
      .subscribe(() => {
        //Navigate to summary
        //Check current member again to avoid login loop
        if (this.memberService.currentMember && !stayOnPage) {
          this.goToSummary();
        }
        //Check if we need to show the store time picker
        if (stayOnPage && !this.orderService.order.Store) {
          this.showStoreTimePickerModal(this.orderService.order, true);
        }
      });
  }

  sliderImageClicked(sliderImage: LinkImage): void {
    if (sliderImage.Type === LinkType.Category) {
      const category = this.menuService.currentMenu.Categories.find(
        (c) => c.Name == sliderImage.Link
      );
      if (category) {
        NavigationEvents.NavigateToCategory.emit({
          Category: category,
          Order: this.orderService.order,
        });
      }
    } else if (sliderImage.Type === LinkType.Url) {
      if (sliderImage.Link) {
        sliderImage.Link = makeURLStartWithHttp(sliderImage.Link); //to avoid redirect issue
        window.open(sliderImage.Link, '_blank');
      }
    } else if (sliderImage.Type === LinkType.Product) {
      const link = sliderImage.Link.substring(
        sliderImage.Link.indexOf('?') + 1
      );
      const product = this.menuService.currentMenu.ProductDictionary[link];
      if (product) {
        NavigationEvents.NavigateToProduct.emit(product);
      }
    } else if (sliderImage.Type === LinkType.Combo) {
      const link = sliderImage.Link.substring(
        sliderImage.Link.indexOf('?') + 1
      );
      const combo = this.menuService.currentMenu.ComboDictionary[link];
      if (combo) {
        NavigationEvents.NavigateToCombo.emit(combo);
      }
    }
  }

  scrollToCategories(): void {
    const container = Array.from(
      document.getElementsByTagName('app-main-menu')
    )[0];
    // There are multiple pickers (mobile and desktop), so find the one that is
    // visible
    const categoryPicker = Array.from(
      document.getElementsByTagName('app-categories-list')
    ).find((el) => {
      const boundingBox = el.getBoundingClientRect();
      return boundingBox.height > 0 && boundingBox.width > 0;
    });

    if (!categoryPicker) {
      return;
    }

    // Scroll downwards only, i.e. when the picker is below the top of the container
    if (
      container &&
      categoryPicker.getBoundingClientRect().top <=
        container.getBoundingClientRect().top
    ) {
      return;
    }

    // Use setTimeout to allow the new category's contents to render before
    // trying to scroll to it. This is because if the previous category has very
    // few items the page may not be able to scroll down because it's at the
    // bottom of the page already.
    setTimeout(() => {
      categoryPicker.scrollIntoView({
        block: 'start',
        behavior: 'auto',
      });
    });
  }

  /**
   * Full screen modal, cannot be exited
   * Only shows when store list loaded without store already selected
   * On mobile appears the same as store time picker
   */
  showStoreTimePickerModal(
    order: Order,
    allowUnregisteredReferral: boolean = false
  ): void {
    /* If this is a referral and the user has not signed in then do not show the
     * store time picker, as we should show the registration page instead. But
     * if the user closes the registration model then fall back to regular flow
     * of showing the store time picker. */
    if (
      this.isReferral &&
      !this.memberService.currentMember &&
      !allowUnregisteredReferral
    ) {
      return;
    }

    this.storeTimePickerModel = new StoreTimePickerModel({
      Store: order.Store,
      SaleType: order.SaleType,
      OrderDay: order.OrderDay,
      OrderTime: order.OrderTime,
    });

    this.modalService
      .presentModal<StoreTimePickerModel>({
        component: StoreTimePickerModalComponent,
        componentProps: {
          stores: this.stores,
          model: this.storeTimePickerModel,
        },
        cssClass: 'initial-store-picker-modal',
        backdropDismiss: false,
        id: 'store-list', //Make sure we only ever show it once
      })
      .subscribe((d) => {
        this.storeTimePickerDismissed(d);
        this.storeTimePickerModel = null;
      });
  }

  /**
   * Called when the store time picker is dismissed.
   */
  storeTimePickerDismissed(result: StoreTimePickerModel): void {
    if (result) {
      if (this.orderService.order.Store != result.Store) {
        AppEvents.ChangeStore.emit(
          new StoreChangeEvent(result.Store, this.orderService.order, true)
        );
      }

      if (this.orderService.order.SaleType != result.SaleType) {
        AppEvents.ChangeSaleType.emit(result.SaleType);
      }
      if (this.orderService.order.OrderDay != result.OrderDay) {
        AppEvents.ChangeOrderDay.emit({
          OrderDay: result.OrderDay,
          Reason: OrderTimeChangedReason.USER,
        });
      }
      if (this.orderService.order.OrderTime != result.OrderTime) {
        AppEvents.ChangeOrderTime.emit({
          OrderTime: result.OrderTime,
          Reason: OrderTimeChangedReason.USER,
        });
      }
    }

    this.storeTimePickerShown = false;
  }

  ionViewDidLeave(): void {
    this.subscriptions.forEach((s) => {
      s.unsubscribe();
    });
    this.showStoreList = false;
  }
}
