/**
 * The reason for the order time or day changing.
 */
export enum OrderTimeChangedReason {
  /**
   * The system changed the order expected time because the size of the order is
   * too big to fit in the previous time due to kitchen limits.
   */
  ORDER_SIZE_KITCHEN_LIMITS = 'ORDER_SIZE_KITCHEN_LIMITS',

  /**
   * The system changed the order expected time because the size of the order is
   * too big to fit in the previous time due to catering thresholds.
   */
  ORDER_SIZE_CATERING_THRESHOLD = 'ORDER_SIZE_CATERING_THRESHOLD',

  /**
   * The system changed the time because the expected order time is too close
   * to the current time and there is not enough time to prepare it.
   */
  ORDER_TOO_CLOSE_TO_NOW = 'ORDER_TOO_CLOSE_TO_NOW',

  /**
   * The system changed the day because the user selected a store
   * which was not open on the day they had currently selected
   * due to holidays or store hours
   */
  ORDER_DAY_NOT_AVAILABLE = 'ORDER_DAY_NOT_AVAILABLE',

  /**
   * The system changed the day, but only the reference, not the actual day
   *  because the new store had the same order day available
   */
  STORE_CHANGED = 'STORE_CHANGED',

  /** The user changed the time. */
  USER = 'USER',

  /** The system changed the time because it hadn't been set yet. */
  INITIALISATION = 'INITIALISATION',
}
