import { Order } from '../order/order';
import { Store } from '../store';

export class StoreChangeEvent {
  constructor(
    public Store: Store,
    public Order?: Order,
    public PreventSalesTypeChange?: boolean
  ) {}
}
