import { Injectable, NgZone, OnDestroy } from '@angular/core';
import { DatePipe } from '@angular/common';
import {
  BehaviorSubject,
  combineLatest,
  EMPTY,
  interval,
  merge,
  Observable,
  of,
  Subscription,
} from 'rxjs';
import { catchError, debounceTime, filter, first, map } from 'rxjs/operators';
import { SaleType, Store, WeekOpenTime } from '../models/domain/store';
import { Order } from '../models/domain/order/order';
import { AppEvents } from '../models/domain/events/app-events';
import { Category } from '../models/domain/category';
import { Menu } from '../models/domain/menu';
import { OrderProduct } from '../models/domain/order/order-product';
import { OrderDay } from '../models/domain/order/order-day';
import { OrderTime } from '../models/domain/order/order-time';
import { StoreChangeEvent } from '../models/domain/events/store-change-event';
import { IOrderClient } from '../clients/order-client';
import { NavigationEvents } from '../models/domain/events/navigation-events';
import { OrderPage } from '../models/domain/navigation-page';
import { MemberEvents } from '../models/domain/events/member-events';
import { EnvironmentVariables } from '../models/environment';
import { OrderCombo } from '../models/domain/order/order-combo';
import { PaymentResultDTO } from '../models/dto/payment-result.dto';
import { OrderTimeDisabledReason } from '../models/domain/order/order-time-disabled-reason';
import { OrderDayChangeEvent } from '../models/domain/events/order-day-changed-event';
import { OrderTimeChangedReason } from '../models/domain/events/order-time-changed-reason';
import { OrderTimeChangeEvent } from '../models/domain/events/order-time-changed-event';
import { HttpErrorResponse } from '@angular/common/http';
import { getDateTime } from '../helpers/orderDateTimeHelpers';
import {
  addTime,
  differenceInCalendarDays,
  differenceInMinutes,
  isSameDay,
  startOfDay,
} from '../helpers/dateHelpers';
import { OrderErrorReason } from '../models/domain/enums/order-error-reason';
import { OrderUpdateTimeResult } from '../models/domain/response/order-update-time-result';

@Injectable({
  providedIn: 'root',
})
export class OrderService implements OnDestroy {
  //Order is intialised as null so components dont start triggering
  //before saved order is loaded
  public order: Order;
  //Populated by app component, kept here for reference by other components on load
  public initialStoreParam: string;

  public initialised = new BehaviorSubject<boolean>(false);

  private subscriptions: Array<Subscription> = new Array<Subscription>();

  public currentCategory = new BehaviorSubject<Category | null>(null);

  constructor(
    private orderClient: IOrderClient,
    private variables: EnvironmentVariables,
    public ngZone: NgZone
  ) {
    this.subscriptions.push(
      AppEvents.AppLoaded.pipe(filter((loaded) => loaded)).subscribe(() => {
        this.checkForStoredOrder();
      }),
      AppEvents.ChangeStore.subscribe((s) => {
        this.changeStore(s);
      }),
      AppEvents.ChangeSaleType.subscribe((s) => {
        this.changeSaleType(s);
      }),
      AppEvents.ChangeOrderDay.subscribe((d) => {
        this.changeOrderDay(d);
      }),
      AppEvents.ChangeOrderTime.subscribe((t) => {
        this.changeOrderTime(t);
      }),

      AppEvents.MenuSet.subscribe((m) => {
        this.menuSet(m);
      }),
      AppEvents.ChangeCategory.subscribe((c) => {
        this.changeCategory(c);
      }),
      AppEvents.AddProduct.subscribe((p) => {
        this.addProducts(p);
      }),
      AppEvents.AddProducts.subscribe((p) => {
        this.addProducts(...p.products);
      }),
      AppEvents.ChangeQuantity.subscribe((p) => {
        this.changeQuantity(p.OrderProduct, p.Change);
      }),
      AppEvents.AddCombo.subscribe((c) => {
        this.addCombo(c);
      }),
      AppEvents.ChangeComboQuantity.subscribe((c) => {
        this.changeComboQuantity(c.OrderCombo, c.Change);
      }),

      AppEvents.StoreChanged.subscribe((s) => {
        this.checkForNewSalesType(s);
      }),

      merge(
        AppEvents.SetStoreAvailableOrderTimes,
        AppEvents.StoreChanged
      ).subscribe((s) => {
        this.initAvailableTimes(s.Store);
      }),

      AppEvents.SaleTypeChanged.subscribe(() => {
        this.initAvailableTimes(this.order.Store);
      }),

      AppEvents.StoreOrderPeriodsChanged.subscribe((s) => {
        this.checkKitchenLimitTimes(s.Store);
      }),

      AppEvents.CheckAvailableOrderTimes.subscribe((e) => {
        this.checkAvailableTimes(e.Store, e.OrderDay, this.order);
      }),

      AppEvents.RewardApplied.subscribe((e) => {
        if (!e.Order.IdSignature) {
          this.saveOrder();
        }
      }),

      merge(
        AppEvents.StoreChanged,
        AppEvents.SaleTypeChanged,
        AppEvents.OrderDayChanged
        //Using this causes e2e tests to hang
        //Set this interval after some other event triggers
        //, interval(60 * 1000)
      )
        .pipe(debounceTime(10))
        .subscribe(() => {
          this.checkAvailableTimes(
            this.order.Store,
            this.order.OrderDay,
            this.order
          );
          this.checkCurrentOrderTime(this.order, false);
        }),

      AppEvents.CartUpdated.subscribe((event) => {
        this.checkCurrentOrderTime(event.Order, event.SubtotalIncreased);
      }),

      merge(AppEvents.MenuChanged, AppEvents.StockUpdated).subscribe((menu) => {
        if (!menu) {
          return;
        }
        this.checkUnavailableProducts(menu);
      }),
      AppEvents.OrderTimeChanged.subscribe(() =>
        this.checkUnavailableTimeProducts()
      ),
      AppEvents.UpdateCart.subscribe(() => {
        this.updateCart();
      }),

      AppEvents.CalculatePrice.subscribe((p) => {
        this.calculatePrice(p);
      }),
      AppEvents.CalculateComboPrice.subscribe((p) => {
        this.calculateComboPrice(p);
      }),

      AppEvents.SubmitOrder.subscribe(() => {
        this.submitOrder();
      }),
      AppEvents.ResendReceipt.subscribe((n) =>
        this.resendReceipt(n, MemberEvents.CurrentMember.value.EmailAddress)
      ),
      AppEvents.Reorder.subscribe((o) => {
        this.reorder(o);
      }),
      AppEvents.ValidateOrder.subscribe((o) => {
        this.validateOrder(o.Order);
      }),
      AppEvents.GetAllDuplicateOrders.subscribe((o) => {
        this.GetAllDuplicateOrders(o.Order);
      }),
      NavigationEvents.NavigateToConfirm.subscribe(() => {
        this.setToNewOrder();
      })
    );

    this.ngZone.runOutsideAngular(() => {
      this.subscriptions.push(
        interval(60 * 1000)
          .pipe(debounceTime(10))
          .subscribe(() => {
            this.checkCurrentOrderTime(this.order, false);
          })
      );
    });
  }

  /*
   * On load, we should wait until any saved order and menu has been completely
   * loaded, then start listening to events that can come from user interation
   * Otherwise we get strange bugs where it treats a loading event as a user
   * interaction
   */
  private setInitialised() {
    if (!this.initialised.value) {
      this.subscriptions.push(
        //Now we are initialised we can subscribe to any member changes
        MemberEvents.CurrentMember.pipe(filter((m) => m != null)).subscribe(
          () => {
            this.setCurrentMemberDetails();
          }
        ),
        merge(
          AppEvents.StoreChanged,
          AppEvents.SaleTypeChanged,
          AppEvents.CartUpdated,
          AppEvents.RedeemableResult,
          //Wait for delay after member set so details can be applied
          MemberEvents.CurrentMember.pipe(filter((m) => m != null))
        )
          .pipe(debounceTime(20))
          .subscribe(() => this.saveOrder()),
        merge(AppEvents.OrderDayChanged, AppEvents.OrderTimeChanged)
          .pipe(debounceTime(20))
          .subscribe(() => this.saveOrderTime())
      );
      this.initialised.next(true);
    }
  }

  private changeStore(storeChangeEvent: StoreChangeEvent) {
    const oldStore = this.order.Store;
    if (storeChangeEvent.Store != this.order.Store) {
      this.order.Store = storeChangeEvent.Store;
    }
    //Update store closing time - removed from ui
    //Load stock for store
    AppEvents.StoreChanged.emit(storeChangeEvent);
    const saleType = this.order.SaleType?.Code;

    if (!saleType) {
      return;
    }

    let newMenuId: string;
    let oldMenuId: string;
    switch (saleType) {
      case 'CATERING':
        newMenuId = storeChangeEvent.Store.Catering?.MenuId;
        oldMenuId = oldStore?.Catering?.MenuId;
        break;
      case 'CURBSD':
        newMenuId = storeChangeEvent.Store.Curbside?.MenuId;
        oldMenuId = oldStore?.Curbside?.MenuId;
        break;
      case 'TABLE':
        newMenuId = storeChangeEvent.Store.TableOrder?.MenuId;
        oldMenuId = oldStore?.TableOrder?.MenuId;
        break;
      case 'ONLINEORDERS':
        // TODO: Use online order sale type menu id?
        newMenuId = storeChangeEvent.Store.MenuId;
        oldMenuId = oldStore?.MenuId;
        break;
    }
    if (newMenuId !== oldMenuId) {
      AppEvents.MenuChanged.emit();
    }
  }

  private checkForNewSalesType(storeChangedEvent: StoreChangeEvent) {
    if (
      !storeChangedEvent.PreventSalesTypeChange &&
      this.order.SaleType?.Code != SaleType.CateringCode
    ) {
      //Check that current sales type is enabled for the new store
      let newSaleType = this.order.Store.SaleTypes.find(
        (s) => s.Code == this.order.SaleType?.Code && s.IsActive
      );
      if (!this.order.SaleType || newSaleType != this.order.SaleType) {
        newSaleType =
          newSaleType ||
          this.order.Store.SaleTypes.find(
            (s) => s.Code == SaleType.InStoreCode && s.IsActive
          ) ||
          this.order.Store.SaleTypes[0];

        if (newSaleType?.Code == this.order.SaleType?.Code) {
          //If they are the same sale type but not the same reference
          //Change the reference but don't notify
          this.order.SaleType = newSaleType;
        } else {
          AppEvents.ChangeSaleType.emit(newSaleType);
        }
      }
    }
  }

  private changeSaleType(saleType: SaleType) {
    saleType ??= SaleType.DEFAULT;
    if (this.order.SaleType != saleType) {
      this.order.SaleType = saleType;
    }
    // Show curbside warning if selected
    AppEvents.SaleTypeChanged.emit({ Order: this.order, SaleType: saleType });
  }

  private menuSet(m: Menu) {
    //Find the category in the new menu that matches the same id
    //Otherwise the first category in the new menu
    const newCategory =
      m.Categories.find(
        (c) => c.Name == this.currentCategory.getValue()?.Name
      ) || m.Categories[0];
    this.changeCategory(newCategory);
  }

  private changeCategory(category: Category) {
    this.currentCategory.next(category);
  }

  private addProducts(...products: OrderProduct[]) {
    for (const product of products) {
      let existingProduct = this.order.Products.find(
        (p) =>
          p.ProductId == product.ProductId &&
          JSON.stringify(p.ModifierGroups) ==
            JSON.stringify(product.ModifierGroups) &&
          JSON.stringify(p.DietaryRequirements) ==
            JSON.stringify(product.DietaryRequirements)
      );
      if (existingProduct) {
        existingProduct.Quantity += product.Quantity;
      } else {
        existingProduct = product;
        this.order.Products.push(product);
      }
    }
    AppEvents.UpdateCart.emit();
  }

  private changeQuantity(product: OrderProduct, change: number) {
    product.Quantity += change;
    if (product.Quantity == 0) {
      this.order.Products.splice(this.order.Products.indexOf(product), 1);
    }

    AppEvents.UpdateCart.emit();
  }

  private addCombo(combo: OrderCombo) {
    const existingCombo = this.order.Combos.find(
      (c) =>
        c.ComboId == combo.ComboId &&
        //Must be exactly the same selection
        OrderCombo.SerialiseComboSelections(c) ==
          OrderCombo.SerialiseComboSelections(combo)
    );
    if (existingCombo) {
      existingCombo.Quantity += combo.Quantity;
    } else {
      this.order.Combos.push(combo);
    }
    AppEvents.UpdateCart.emit();
  }

  private changeComboQuantity(combo: OrderCombo, change: number) {
    combo.Quantity += change;
    if (combo.Quantity == 0) {
      this.order.Combos.splice(this.order.Combos.indexOf(combo), 1);
    }
    AppEvents.UpdateCart.emit();
  }

  /**
   * Recreates the `store.AvailableDays` array and populates it with all the
   * days and times available for the selected saletype. Any references to the
   * old `store.AvailableDays` will need to be updated.
   */
  private setAvailableDays(store: Store) {
    const weekOpenTimes = this.getStoreOpenTimesForOrder(store, this.order);
    let currentDay = new Date();
    currentDay.setHours(0, 0, 0, 0);
    const datePipe: DatePipe = new DatePipe('en-US');
    const availableDays = new Array<OrderDay>();
    let startDay = 0;
    let maxDays = this.variables.futureOrderDays;
    if (this.order.SaleType?.Code == SaleType.CateringCode) {
      const cateringMinOrderDays = Math.floor(
        store.CateringMinimumOrderWaitingHours / 24
      );
      // Ensure cateringMaxOrderDays is at least 1 day after the min
      const cateringMaxOrderDays =
        Math.max(
          Math.ceil(store.CateringMaximumOrderWaitingHours / 24),
          cateringMinOrderDays
        ) + 1;
      currentDay.setDate(currentDay.getDate() + cateringMinOrderDays);
      startDay = cateringMinOrderDays;
      // Override environment variable
      maxDays = cateringMaxOrderDays;
    }
    for (let i = startDay; i < maxDays; i++) {
      let addDay = true;
      //Get day of week (Monday based)
      const dayOfWeek = (currentDay.getDay() + 6) % 7;
      //If the current day is closed, don't add this day
      const dayOpenTimes = weekOpenTimes[dayOfWeek];
      if (dayOpenTimes.Closed) {
        addDay = false;
      }

      //Search the holidays list to see if the store is open on this holiday
      const dayOfMonth = currentDay.getDate();
      // JS month is index 0
      const month = currentDay.getMonth() + 1;
      if (store.Holidays) {
        const isHoliday = store.Holidays.some(
          (h) => h.Day === dayOfMonth && h.Month === month && !h.Open
        );
        if (isHoliday) {
          addDay = false;
        }
      }

      if (this.order?.SaleType?.Code === SaleType.CateringCode) {
        // Check to see if the store would be closed at start of day already based
        // on catering hours max notice time
        const maxTimeThreshold = addTime(new Date(), {
          hours: store.CateringMaximumOrderWaitingHours,
        });
        const storeOpenDateTime = addTime(currentDay, {
          minutes: dayOpenTimes.OpenTime,
        });
        if (maxTimeThreshold < storeOpenDateTime) {
          addDay = false;
        }
      }

      if (addDay) {
        const availableDay: OrderDay = {
          Label:
            i == 0
              ? 'Today'
              : i == 1
              ? 'Tomorrow'
              : datePipe.transform(currentDay, 'EEE, dd MMM'),
          Date: currentDay,
          DayOfWeek: dayOfWeek,
          AvailableOrderTimes: [],
        };
        availableDays.push(availableDay);
      }

      currentDay = new Date(currentDay.getTime());
      currentDay.setDate(currentDay.getDate() + 1);
    }
    store.AvailableOrderDays = availableDays;
    AppEvents.AvailableOrderDaysChanged.emit(store.AvailableOrderDays);
  }

  private changeOrderDay(ev: OrderDayChangeEvent) {
    if (this.order.OrderDay != ev.OrderDay) {
      this.order.OrderDay = ev.OrderDay;
      AppEvents.OrderDayChanged.emit(ev);
    }
  }

  /**
   * Returns the open times for the store for the sale type of `order` (if
   * provided).
   *
   * @param store The store to get open times for.
   * @param order An order to access the sale type of, to get more specific open
   * times. If not provided, then defaults to in-store pickup code.
   */
  getStoreOpenTimesForOrder(
    store: Store,
    order?: Order
  ): ReadonlyArray<WeekOpenTime> {
    const orderType = order?.SaleType?.Code ?? SaleType.DEFAULT.Code;
    let weekOpenTimes: WeekOpenTime[];
    switch (orderType) {
      case SaleType.CurbsideCode:
        weekOpenTimes = store.Curbside?.WeekOpenTimes;
        break;
      case SaleType.CateringCode:
        weekOpenTimes = store.Catering?.WeekOpenTimes;
        break;
      case SaleType.InStoreCode:
        weekOpenTimes = store.InStore?.WeekOpenTimes;
        break;
      case SaleType.TableOrderCode:
        weekOpenTimes = store.TableOrder?.WeekOpenTimes;
        break;
    }
    return weekOpenTimes?.length ? weekOpenTimes : store.WeekOpenTimes;
  }

  /**
   * Initialises the available times for each day, regardless of cart info.
   * Disabling order times based on cart is done in checkAvailableTimes as it is
   * called more frequently. This should not change anything in the order, as it
   * could be called from StoreTimePicker which needs to display available days
   * for a store without actually changing the current order.Store if the user clicks
   * cancel from the modal
   *
   * Triggered when:
   * * Store changed
   * * Available order days changed
   * * Sales type changed
   */
  private initAvailableTimes(store: Store) {
    if (!store) {
      return;
    }

    const weekOpenTimes = this.getStoreOpenTimesForOrder(store, this.order);

    //We need to calculate this every time,
    //To account for situation where sale type is changed
    this.setAvailableDays(store);
    const currentTime = new Date();
    const oneHour = 1000 * 60 * 60; //milliseconds

    // Date (including time) of the first available catering day
    const cateringMinDay = new Date(
      currentTime.getTime() + store.CateringMinimumOrderWaitingHours * oneHour
    );
    // Date (including time) of the last available catering day
    const cateringMaxDay = new Date(
      currentTime.getTime() + store.CateringMaximumOrderWaitingHours * oneHour
    );
    // The offset (minutes from 12am) of the last available time on the last
    // catering day
    const maxDayMaxNoticeOffset = differenceInMinutes(
      cateringMaxDay,
      startOfDay(cateringMaxDay)
    );

    store.AvailableOrderDays.forEach((orderDay) => {
      const isCateringMinDay =
        this.order.SaleType?.Code == SaleType.CateringCode &&
        isSameDay(cateringMinDay, orderDay.Date);
      const isCateringMaxDay =
        this.order.SaleType?.Code == SaleType.CateringCode &&
        isSameDay(cateringMaxDay, orderDay.Date);
      const isCurrentDay = isSameDay(orderDay.Date, currentTime);
      const datePipe: DatePipe = new DatePipe('en-US');
      const availableTimes = new Array<OrderTime>();

      // Calculate the starting offset of available times for the day
      let currentOffset = weekOpenTimes[orderDay.DayOfWeek].OpenTime;
      if (isCurrentDay || isCateringMinDay) {
        currentOffset = Math.max(
          currentOffset,
          currentTime.getHours() * 60 + currentTime.getMinutes()
        );
        currentOffset = Math.ceil(currentOffset / 5) * 5;
      }

      // Calculate the end offset of available times for the day
      let closeOffset = weekOpenTimes[orderDay.DayOfWeek].CloseTime;
      if (isCateringMaxDay) {
        closeOffset = Math.min(closeOffset, maxDayMaxNoticeOffset);
        closeOffset = Math.floor(closeOffset / 5) * 5;
      }

      while (currentOffset < closeOffset) {
        const aTime = new Date(
          0,
          0,
          1,
          Math.floor(currentOffset / 60),
          currentOffset % 60,
          0,
          0
        );

        availableTimes.push(
          new OrderTime({
            Label: datePipe.transform(aTime, 'h:mm a'),
            Time: aTime,
            Offset: currentOffset,
            DisabledReasons: [],
            CurrentOrderTotal: 0,
          })
        );
        currentOffset += 5;
      }

      // Update the store's available times for the day, and the reference to
      // the day's times on the order also
      orderDay.AvailableOrderTimes = availableTimes;
    });

    AppEvents.StoreAvailableOrderTimesInitialised.emit({ Store: store });
    this.checkKitchenLimitTimes(store);
  }

  /**
   * Disables times that are at the kitchen limit.
   *
   * Triggered when:
   * * Kitchen dollar limits is changed
   */
  private checkKitchenLimitTimes(store: Store): void {
    if (
      !store ||
      (this.order?.SaleType && !this.doesKitchenLimitsApply(this.order))
    ) {
      return;
    }

    // We need to calculate this every time,
    // To account for situation where sale type is changed
    store.AvailableOrderDays.forEach((orderDay) => {
      const orderDayDate = `${orderDay.Date.getFullYear()}/${
        orderDay.Date.getMonth() + 1
      }/${orderDay.Date.getDate()}`;
      const dayOrderPeriods = store.OrderPeriods?.get(orderDayDate);

      for (const orderTime of orderDay.AvailableOrderTimes) {
        const storeOrderPeriod = dayOrderPeriods?.find(
          // Match the current offset with the order period from 5 mins before
          // because if the user is expecting their order at 10am, then the
          // kitchen limits of 9:55-10am is what should be checked.
          (period) => period.Offset === orderTime.Offset - 5
        );

        const kitchenDollarAmountCurrent = storeOrderPeriod?.Total ?? 0;
        const isAtKitchenLimit =
          store.KitchenDollarPeriodLimit &&
          kitchenDollarAmountCurrent >= store.KitchenDollarPeriodLimit;

        if (isAtKitchenLimit) {
          orderTime.DisabledReasons.push(
            OrderTimeDisabledReason.DISABLED_KITCHEN_LIMITS
          );
        }

        orderTime.CurrentOrderTotal = storeOrderPeriod?.Total ?? 0;
      }
    });

    AppEvents.OrderTimesStatusChanged.emit();

    if (this.order?.Store === store) {
      // When kitchen limits are updated for the current order's store, then
      // ensure it can still fit
      this.checkCurrentOrderTime(this.order, false);
    } else {
      // Otherwise, just enable/disable times
      this.checkAvailableTimes(store, this.order.OrderDay, this.order);
    }
  }

  /**
   * Enables or disables the available order times for a given day, based on the
   * cart details.
   */
  private checkAvailableTimes(store: Store, day: OrderDay, order: Order): void {
    if (!day) {
      return;
    }

    const currentTime = new Date();
    const currentDateWithoutTime = startOfDay(currentTime);
    const oneHour = 1000 * 60 * 60; //milliseconds

    // Calculate the minimum date and time that can be selected when the order subtotal is zero
    const cateringMinDay = new Date(
      currentTime.getTime() + store.CateringMinimumOrderWaitingHours * oneHour
    );

    // Calculate the minimum day that can be selected due to this order's
    // subtotal based on catering settings
    const cateringThreshold =
      store.CateringThresholds?.filter(
        (threshold) => threshold.MinimumSpend < order.SubTotal
      )?.pop() ?? store.CateringThresholds[0];
    const cateringOrderMinDay = new Date(
      currentTime.getTime() + (cateringThreshold?.AdvanceHours ?? 0) * oneHour
    );

    const isCatering = order.SaleType?.Code == SaleType.CateringCode;

    const isCateringMinDay =
      isCatering && cateringMinDay.getDate() == day.Date.getDate();
    const isCurrentDay =
      day.Date.getMonth() == currentTime.getMonth() &&
      day.Date.getDate() == currentTime.getDate();

    const weekOpenTimes = this.getStoreOpenTimesForOrder(store, order);

    if (!weekOpenTimes?.length) {
      return;
    }

    let currentOffset = weekOpenTimes[day.DayOfWeek].OpenTime;
    if (isCurrentDay || isCateringMinDay) {
      currentOffset = Math.max(
        currentOffset,
        currentTime.getHours() * 60 + currentTime.getMinutes()
      );
    }

    // How long the store must be open for at least when the catering order has
    // a minimum time
    const cateringMaxStoreOpenMinutes = 6 * 60; // 6 hours
    const cateringStoreOpenMinutes = Math.min(
      cateringMaxStoreOpenMinutes,
      cateringThreshold.AdvanceHours * 60
    );

    // Aggregate the total time. Initialise as satisfied, and then work out
    // later if it's not.
    let cumulativeStoreOpenTime = Number.MAX_VALUE;
    let metMinRequiredTime = true;

    if (isCatering) {
      const orderDayIndex = store.AvailableOrderDays.findIndex(
        (availableDay) =>
          differenceInCalendarDays(order.OrderDay.Date, availableDay.Date) === 0
      );
      const previousDay = store.AvailableOrderDays[orderDayIndex - 1];
      if (
        orderDayIndex === 1 &&
        !differenceInCalendarDays(previousDay.Date, currentDateWithoutTime)
      ) {
        // If the chosen order time is one business day after today then set
        // cumulativeStoreOpenTime to the remaining minutes of today
        cumulativeStoreOpenTime =
          weekOpenTimes[previousDay.DayOfWeek].CloseTime -
          (currentTime.getHours() * 60 + currentTime.getMinutes());
      } else if (orderDayIndex === 0) {
        cumulativeStoreOpenTime = 0;
      }
    }

    const limit = store.KitchenDollarPeriodLimit || Number.MAX_VALUE;

    // Start at the end of the day because we iterate backwards over the times
    cumulativeStoreOpenTime += day.AvailableOrderTimes.length * 5;

    // Start at the end of the day and work backwards so that indexes of not yet
    // processed times are not affected when items are removed with splice()
    for (let i = day.AvailableOrderTimes.length - 1; i >= 0; i--) {
      const time = day.AvailableOrderTimes[i];
      time.DisabledReasons = [];

      // Check time is not too close to current time + wait time
      if (time.Offset < currentOffset + store.WaitTime) {
        day.AvailableOrderTimes.splice(i, 1);
        continue;
      }

      // Remove times based on advanced catering settings
      if (order.SaleType?.Code === SaleType.CateringCode && cateringThreshold) {
        metMinRequiredTime = cumulativeStoreOpenTime > cateringStoreOpenMinutes;

        // Remove 5 more minutes from the time the store has been open for the
        // purposes of catering min notice time because we are iterating backwards
        // over the list
        cumulativeStoreOpenTime -= 5;

        const dateTime = getDateTime(day, time);

        if (dateTime < cateringOrderMinDay || !metMinRequiredTime) {
          time.DisabledReasons.push(
            OrderTimeDisabledReason.DISABLED_CATERING_THRESHOLD
          );
        }
      }

      // Remove times based on kitchen limits for relevant sale types
      if (order.SaleType && this.doesKitchenLimitsApply(order)) {
        // Check kitchen limits

        // Calculate overflows
        if (store.IsKitchenOverflowActive) {
          let orderSizeRemaining = order.SubTotal;
          for (let searchOffset = 0; i - searchOffset >= 0; searchOffset++) {
            const searchOrderTime = day.AvailableOrderTimes[i - searchOffset];

            const remainingKitchenCapacity = Math.max(
              limit - searchOrderTime.CurrentOrderTotal,
              0
            );
            orderSizeRemaining -= remainingKitchenCapacity;
          }

          if (orderSizeRemaining > 0) {
            // The order couldn't fit in the order time, and couldn't overflow to
            // the preceding times
            time.DisabledReasons.push(
              OrderTimeDisabledReason.DISABLED_ORDER_SIZE
            );
          }
        }
      }
    }
  }

  /**
   * Checks that the current selected time is valid for the current order.
   * Selects the next available day and time if the order time is not valid.
   */
  private checkCurrentOrderTime(order: Order, isSubtotalIncreased: boolean) {
    if (!order?.Store || !order.Store.AvailableOrderDays) {
      return;
    }

    const originalOrderDay = order.OrderDay;
    let dayChangedReason: OrderTimeChangedReason | null = null;

    //If the order day needs to be changed because of changing store,
    // we need to know if the same date is available in the new store
    let newDayAvailable = true;

    //call change order day
    if (
      order.OrderDay &&
      !order.Store.AvailableOrderDays.includes(this.order.OrderDay)
    ) {
      this.order.OrderDay = order.Store.AvailableOrderDays.find(
        (d) => d.Date.getTime() == this.order.OrderDay.Date.getTime()
      );
      if (order.OrderDay == null) {
        //If we can't find the same day in the new store
        //Because of closed days or holidays, then also reset the time
        newDayAvailable = false;
        this.order.OrderTime = null;
      } else {
        AppEvents.OrderDayChanged.emit({
          OrderDay: order.OrderDay,
          Reason: OrderTimeChangedReason.STORE_CHANGED,
        });
      }
    }
    if (order.OrderDay == null) {
      const newOrderDay = order.Store.AvailableOrderDays.find(
        (d) => d.AvailableOrderTimes.length > 0
      );
      if (newOrderDay) {
        if (!originalOrderDay) {
          dayChangedReason = OrderTimeChangedReason.INITIALISATION;
        } else if (
          isSubtotalIncreased &&
          order.OrderTime?.DisabledReasons.includes(
            OrderTimeDisabledReason.DISABLED_CATERING_THRESHOLD
          )
        ) {
          dayChangedReason =
            OrderTimeChangedReason.ORDER_SIZE_CATERING_THRESHOLD;
        } else if (
          isSubtotalIncreased &&
          order.OrderTime?.DisabledReasons.includes(
            OrderTimeDisabledReason.DISABLED_KITCHEN_LIMITS
          )
        ) {
          /*
           * TODO: Get the reason by checking both the previous order and the
           * current order to see if both can fit. If the previous one couldn't
           * but the new one could, then the reason is order size, otherwise too
           * close to now.
           */
          dayChangedReason = OrderTimeChangedReason.ORDER_SIZE_KITCHEN_LIMITS;
        } else if (!newDayAvailable) {
          dayChangedReason = OrderTimeChangedReason.ORDER_DAY_NOT_AVAILABLE;
        } else {
          dayChangedReason = OrderTimeChangedReason.ORDER_TOO_CLOSE_TO_NOW;
        }

        AppEvents.ChangeOrderDay.emit({
          OrderDay: newOrderDay,
          Reason: dayChangedReason,
        });
      }
      //Emit will retrigger checkCurrentOrderTime, or orderday is still null so end here
      return;
    }
    this.checkAvailableTimes(order.Store, order.OrderDay, order);

    //If the currently selected order time is not in the list of available times
    //The order day has probably changed, try to find the current order time in the new list
    //If the time has been disabled, find the next available time in the current day
    if (
      !order.OrderDay.AvailableOrderTimes.find(
        (t) => t.Enabled == true && t == order.OrderTime
      )
    ) {
      this.setNextValidOrderTime(order, isSubtotalIncreased);
    }
  }

  /**
   * Pushes the selected order time to the next available order time.
   */
  private setNextValidOrderTime(
    order: Order,
    isSubtotalIncreased: boolean
  ): void {
    if (!order) {
      return;
    }

    // order.OrderTime doesn't have offset, and
    // order.Order.AvailableOrderTimes.Time uses 1970 as their time. Calculate
    // offset to `order.OrderTime` so that times can be compared.

    const orderTime =
      order.OrderDay && order.OrderTime
        ? new Date(
            order.OrderDay.Date.getFullYear(),
            order.OrderDay.Date.getMonth(),
            order.OrderDay.Date.getDate(),
            order.OrderTime.Time.getHours(),
            order.OrderTime.Time.getMinutes()
          )
        : null;

    // Calculate the offset to use for comparison later if it doesn't exist yet
    if (order.OrderTime && orderTime) {
      order.OrderTime.Offset ??=
        orderTime && orderTime.getHours() * 60 + orderTime.getMinutes();
    }

    // Use an offset of 0 if there's no order time, to find the first available
    // order time of the day
    const orderTimeOffset = order.OrderTime?.Offset ?? 0;

    const newOrderTime = order.OrderDay.AvailableOrderTimes.find(
      (t) => t.Offset >= orderTimeOffset && t.Enabled
    );

    if (newOrderTime?.Offset !== order?.OrderTime?.Offset) {
      let timeChangedReason: OrderTimeChangedReason;

      if (!orderTime) {
        timeChangedReason = OrderTimeChangedReason.INITIALISATION;
      } else if (
        isSubtotalIncreased &&
        order.OrderTime.DisabledReasons.includes(
          OrderTimeDisabledReason.DISABLED_CATERING_THRESHOLD
        )
      ) {
        timeChangedReason =
          OrderTimeChangedReason.ORDER_SIZE_CATERING_THRESHOLD;
      } else if (
        isSubtotalIncreased &&
        order.OrderTime.DisabledReasons.includes(
          OrderTimeDisabledReason.DISABLED_KITCHEN_LIMITS
        )
      ) {
        timeChangedReason = OrderTimeChangedReason.ORDER_SIZE_KITCHEN_LIMITS;
      } else {
        timeChangedReason = OrderTimeChangedReason.ORDER_TOO_CLOSE_TO_NOW;
      }

      AppEvents.ChangeOrderTime.emit({
        OrderTime: newOrderTime,
        /*
         * TODO: Get the reason by checking both the previous order and the
         * current order to see if both can fit. If the previous one couldn't
         * but the new one could, then the reason is order size, otherwise too
         * close to now.
         */
        Reason: timeChangedReason,
      });
    }
  }

  /**
   * Sets the order time on the order, then emits to `OrderTimeChanged` with the
   * new time.
   */
  private changeOrderTime(ev: OrderTimeChangeEvent) {
    if (this.order.OrderTime != ev.OrderTime) {
      this.order.OrderTime = ev.OrderTime;
      AppEvents.OrderTime.next(ev.OrderTime);
      AppEvents.OrderTimeChanged.emit(ev);
    }
  }

  private calculateComboPrice(orderCombo: OrderCombo): OrderCombo {
    orderCombo.ItemPrice = orderCombo.BasePrice;

    orderCombo.Products.forEach((comboItem) => {
      if (comboItem.IsModifiers) {
        comboItem.Modifiers.forEach((modifier) => {
          if (!modifier.Included && modifier.Selected) {
            orderCombo.ItemPrice += modifier.ItemPrice;
          }
        });
      } else {
        comboItem.Products.forEach((product) => {
          product.ItemPrice = product.BasePriceInCombo;

          product.ModifierGroups.forEach((mg) => {
            mg.Modifiers.forEach((m) => {
              if (!m.Included && m.Selected) {
                product.ItemPrice += m.ItemPrice;
              }
            });
          });
          product.ItemPrice = parseFloat(product.ItemPrice.toFixed(2));
          //Quantity could be 0 here if the product is not selected in the combo
          product.TotalPrice = product.ItemPrice * product.Quantity;
          orderCombo.ItemPrice += product.TotalPrice;
        });
      }
    });
    orderCombo.ItemPrice = parseFloat(orderCombo.ItemPrice.toFixed(2));
    orderCombo.SubTotal = orderCombo.ItemPrice * orderCombo.Quantity;
    orderCombo.TotalPrice = orderCombo.SubTotal;
    return orderCombo;
  }

  private calculatePrice(orderProduct: OrderProduct): OrderProduct {
    orderProduct.ItemPrice = orderProduct.BasePrice;
    orderProduct.ModifierGroups.forEach((mg) => {
      mg.Modifiers.forEach((m) => {
        if (!m.Included && m.Selected) {
          orderProduct.ItemPrice += m.ItemPrice;
        }
      });
    });
    orderProduct.ItemPrice = parseFloat(orderProduct.ItemPrice.toFixed(2));
    orderProduct.SubTotal = orderProduct.ItemPrice * orderProduct.Quantity;
    orderProduct.TotalPrice = orderProduct.SubTotal;
    return orderProduct;
  }

  private updateCart() {
    const previousSubtotal = this.order.SubTotal ?? 0;

    //Apply discounts
    this.order.SubTotal = 0;
    this.order.ProductCount = 0;

    this.order.Combos.forEach((c) => {
      this.calculateComboPrice(c);
      this.order.SubTotal += c.ItemPrice * c.Quantity;
      this.order.ProductCount += c.Quantity;
    });

    this.order.Products.forEach((p) => {
      this.calculatePrice(p);
      this.order.SubTotal += p.ItemPrice * p.Quantity;
      this.order.ProductCount += p.Quantity;
    });

    this.order.SubTotal = parseFloat(this.order.SubTotal.toFixed(2));
    //Applied by coupon service by event
    this.order.Discount = 0;
    this.order.Total = parseFloat(this.order.SubTotal.toFixed(2));
    //Todo: reduce duplication between here and order.service
    this.order.PointsEarned = parseFloat(
      (this.order.Total * this.variables.loyaltyConfig.pointRatio).toFixed(2)
    );
    AppEvents.CartUpdated.emit({
      Order: this.order,
      SubtotalIncreased: previousSubtotal < this.order.SubTotal,
    });
  }

  //Find any products in cart that are now out of stock, or not on the menu
  private checkUnavailableProducts(menu: Menu) {
    //Don't remove products from cart before store has been chosen
    if (this.order.Store != null) {
      const removedProducts = Array<OrderProduct>();
      for (let i = this.order.Products.length - 1; i >= 0; i--) {
        const orderProduct = this.order.Products[i];
        const menuProduct = menu.ProductDictionary[orderProduct.ProductId];
        if (!menuProduct || menuProduct.OutOfStock) {
          removedProducts.push(orderProduct);
          this.order.Products.splice(i, 1);
        }
      }
      const removedCombos = Array<OrderCombo>();
      for (let i = this.order.Combos.length - 1; i >= 0; i--) {
        const orderCombo = this.order.Combos[i];
        const menuCombo = menu.ComboDictionary[orderCombo.ComboId];
        if (!menuCombo || menuCombo.OutOfStock) {
          removedCombos.push(orderCombo);
          this.order.Combos.splice(i, 1);
        }
      }
      if (removedProducts.length > 0) {
        AppEvents.ProductsUnavailable.emit({
          Products: removedProducts,
          Combos: removedCombos,
          Reason: 'Store',
        });
        AppEvents.UpdateCart.emit();
      }
    }
  }

  /**
   * Finds any products in the cart that have a time frame associated with them
   * and remove them from the cart
   */
  private checkUnavailableTimeProducts() {
    if (this.order?.OrderTime) {
      const removedProducts = Array<OrderProduct>();
      const removedCombos = Array<OrderCombo>();
      for (let i = this.order.Products.length - 1; i >= 0; i--) {
        const p = this.order.Products[i];
        if (p.SaleTimeFrame) {
          if (
            this.order.OrderTime.Offset < p.SaleTimeFrame.OpenTime ||
            this.order.OrderTime.Offset > p.SaleTimeFrame.CloseTime
          ) {
            removedProducts.push(p);
            this.order.Products.splice(i, 1);
          }
        }
      }
      for (let i = this.order.Combos.length - 1; i >= 0; i--) {
        const c = this.order.Combos[i];
        let removeCombo = false;
        if (c.SaleTimeFrame) {
          if (
            this.order.OrderTime.Offset < c.SaleTimeFrame.OpenTime ||
            this.order.OrderTime.Offset > c.SaleTimeFrame.CloseTime
          ) {
            removeCombo = true;
          }
        }
        //Also search any products inside the combo
        c.Products.forEach((ci) => {
          ci.Products.forEach((p) => {
            if (p.SaleTimeFrame && p.Quantity > 0) {
              if (
                this.order.OrderTime.Offset < p.SaleTimeFrame.OpenTime ||
                this.order.OrderTime.Offset > p.SaleTimeFrame.CloseTime
              ) {
                removeCombo = true;
              }
            }
          });
        });
        if (removeCombo) {
          removedCombos.push(c);
          this.order.Combos.splice(i, 1);
        }
      }
      if (removedProducts.length > 0 || removedCombos.length > 0) {
        AppEvents.ProductsUnavailable.emit({
          Products: removedProducts,
          Combos: removedCombos,
          Reason: 'Time',
        });
        AppEvents.UpdateCart.emit();
      }
    }
  }

  checkForStoredOrder(): void {
    const storedOrder = this.orderClient.getStored();
    if (storedOrder == null) {
      this.setToNewOrder();
      return;
    }

    if (storedOrder.NavigatedToPayment) {
      //If we are already at payment result, that component will do these checks
      //If we are not already at payment result, we should go there now
      //  This handles the case where customer navigates to /order from somewhere else
      if (!window.location.pathname.includes('/' + OrderPage.PaymentResult)) {
        //Check if payment actually completed
        NavigationEvents.NavigateToPaymentResult.emit({
          idSignature: storedOrder.IdSignature,
        });
      }
    } else {
      this.loadFromOrder(storedOrder);
    }
  }

  getStoredOrder(): Order {
    return this.orderClient.getStored();
  }

  getOrder(signature: string): Observable<Order> {
    return this.orderClient.get(signature);
  }

  private loadFromOrder(loadOrder: Order) {
    const loadOrderStoreName = loadOrder.Store?.Name;
    this.order = new Order(loadOrder);
    this.order.Combos = [];
    this.order.Products = [];
    this.order.Coupons = [];

    AppEvents.OrderPreLoaded.next(this.order);

    this.subscriptions.push(
      AppEvents.MenuSet.pipe(first()).subscribe((menu) => {
        loadOrder.Combos.forEach((c) => {
          //Check the combo is still in the selected menu
          const combo = menu.ComboDictionary[c.ComboId];
          if (combo) {
            //Apply the quantity, and the selected products
            //Also apply the modifiers to the selected products
            //All other fields should be taken from data in current menu
            this.order.Combos.push(
              OrderCombo.ApplyToOrderCombo(c, OrderCombo.FromCombo(combo))
            );
          }
        });
        loadOrder.Products.forEach((p) => {
          //Check the product is still in the selected menu
          const product = menu.ProductDictionary[p.ProductId];
          if (product) {
            //Apply only the quantity and the modifiers, all else should be taken from current menu
            this.order.Products.push(
              OrderProduct.ApplyToOrderProduct(
                p,
                OrderProduct.FromProduct(product)
              )
            );
          }
        });
        if (loadOrder.Coupons.length > 0) {
          AppEvents.CouponCodeEntered.emit({
            Code: loadOrder.Coupons[0].Code,
            Order: this.order,
          });
        }
        AppEvents.UpdateCart.emit();

        this.setInitialised();
        AppEvents.OrderLoaded.next(this.order);
      }),

      //Wait for menus to be loaded before asking for store to be set
      combineLatest([
        AppEvents.MenusInitialised.pipe(filter((e) => e)),
        AppEvents.Stores.pipe(filter((s) => s != null)),
      ])
        .pipe(first())
        .subscribe(([_, stores]) => {
          this.order.Store = stores.find((s) => s.Name === loadOrderStoreName);
          const store =
            stores.find((s) => s.Id == this.order.Store?.Id) ??
            stores.find((s) => s.Name == this.order.Store?.Name);
          if (store) {
            let saleType = this.order.SaleType;

            //If the sale type can be found, then set sale type to that
            //If it is table ordering or catering, then it won't be in the store sales types list
            // so we leave as is so the same sale type is emitted
            if (this.order.SaleType?.Code != SaleType.TableOrderCode) {
              saleType = store.SaleTypes.find(
                (st) => st.Code == this.order.SaleType?.Code
              );
            }

            // If the sale type can't be found, then let the store determine it
            if (saleType) {
              AppEvents.ChangeStore.emit(
                new StoreChangeEvent(store, this.order, true)
              );
              AppEvents.ChangeSaleType.emit(saleType);
            } else {
              AppEvents.ChangeStore.emit(
                new StoreChangeEvent(store, this.order)
              );
            }
          } else {
            this.order.Store = null;
            this.setInitialised();
            AppEvents.OrderLoaded.next(this.order);
          }
        })
    );
    if (this.order.ComoIdSignature) {
      this.subscriptions.push(
        combineLatest([
          AppEvents.OrderLoaded.pipe(filter((o) => o != null)),
          MemberEvents.CurrentMember.pipe(filter((m) => m != null)),
        ]).subscribe(() => {
          this.setCurrentMemberDetails();
        })
      );
    }
  }

  private saveOrderLocal() {
    this.orderClient.saveLocal(this.order);
  }

  /**
   * Saves the order locally and sends it to the server for further processing.
   */
  private saveOrder() {
    this.saveOrderLocal();

    //Only allow saving without logging in if it is a table order
    if (!this.order?.ComoIdSignature && !this.order.IsTableOrder) {
      return;
    }

    //Only allow saving an order if:
    // - There's something in the cart, or
    // - a coupon is applied, or
    // - the order had been saved with items in the cart earlier, but the cart was cleared
    if (
      !this.order.IdSignature &&
      this.order.Products.length == 0 &&
      this.order.Combos.length == 0 &&
      !this.order.ComoRewards.length
    ) {
      return;
    }

    const orderIdSignature = this.order.IdSignature;
    this.orderClient
      .save(this.order)
      .pipe(
        catchError((error) => {
          if (error == 'Order already submitted') {
            NavigationEvents.NavigateToPaymentResult.emit({
              idSignature: orderIdSignature,
            });
          } else if (
            error.Message == '' ||
            error === OrderErrorReason.IdSignatureEncryptionFailed
          ) {
            //Create a new order Id when encryption failed
            this.order.IdSignature = '';
            // Make sure order is saved locally so the user can't continue
            // modifying a previous order if they refresh
            this.saveOrderLocal();
          }

          return EMPTY;
        })
      )
      .subscribe((o) => {
        if (
          o?.Success !== true ||
          orderIdSignature !== this.order.IdSignature ||
          !o.Updates
        ) {
          return;
        }

        if (o.Updates.IdSignature != orderIdSignature) {
          this.order.IdSignature = o.Updates.IdSignature;
          AppEvents.OrderIdSet.emit(this.order);
        }

        if (o.Updates.TransactionOpenTime) {
          this.order.TransactionOpenTime = o.Updates.TransactionOpenTime;
        }

        this.orderClient.saveLocal(this.order);
      });
  }

  /**
   * Saves the order time.
   *
   * @param previousDay - The previous day of the order.
   * @param previousTime - The previous time of the order.
   * @private
   */
  private saveOrderTime(
    previousDay?: OrderDay,
    previousTime?: OrderTime
  ): void {
    this.saveOrderLocal();

    //Only allow saving without logging in if it is a table order
    if (!this.order?.ComoIdSignature && !this.order.IsTableOrder) {
      return;
    }

    //Only allow saving an order if:
    // - There's something in the cart, or
    // - a coupon is applied, or
    // - the order had been saved with items in the cart earlier, but the cart was cleared
    if (
      !this.order.IdSignature &&
      this.order.Products.length == 0 &&
      this.order.Combos.length == 0 &&
      !this.order.ComoRewards.length
    ) {
      return;
    }

    // If the order has not yet been saved, save the entire order instead of
    // just updating the time.
    if (!this.order.IdSignature) {
      this.saveOrder();
      return;
    }

    this.orderClient
      .updateTime(this.order)
      .pipe(
        catchError((error) => {
          console.error(error);
          return of({
            SuggestedTime: undefined,
            Success: false,
            FailureMessages: [],
          } satisfies OrderUpdateTimeResult);
        })
      )
      .subscribe((o) => {
        if (o?.Success !== true) {
          AppEvents.OrderUpdateTimeFailed.emit({
            suggestedTime: o?.SuggestedTime,
          });
          return;
        }
      });
  }

  checkTransactionStatus(
    orderIdSignature: string
  ): Observable<PaymentResultDTO> {
    const order = this.orderClient.getStored();
    order.IdSignature = orderIdSignature;
    return this.orderClient.getTransactionStatus(order).pipe(
      catchError((error) => {
        order.NavigatedToPayment = false;
        this.orderClient.saveLocal(order);
        if (typeof error == 'string') {
          return of({
            Message: error,
          } as PaymentResultDTO);
        } else {
          return of({
            PaymentFailedReason: error.PaymentFailedReason,
            Message:
              'Payment was not successful, please try again or with a different card',
          } as PaymentResultDTO);
        }
      }),
      map((result) => {
        if (result.Message == 'Success') {
          AppEvents.OrderSubmitted.emit(order);
          this.setToNewOrder();
        } else {
          AppEvents.OrderSubmitFailed.emit(result.Message);
        }
        return result;
      })
    );
  }

  private submitOrder() {
    this.orderClient
      .submitOrder(this.order, null)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          console.error(error);
          AppEvents.OrderSubmitFailed.emit('Oops, something went wrong.');

          return EMPTY;
        })
      )
      .subscribe((result) => {
        if (result.Success) {
          AppEvents.OrderSubmitted.emit(this.order);
          this.setToNewOrder();
        } else {
          AppEvents.OrderSubmitFailed.emit(result.FailureMessages[0]);
        }
      });
  }

  clearCartItems(): void {
    this.order.Products = [];
    this.order.Combos = [];
    this.order.ComoDeals = [];
    this.order.ComoRewards = [];
    this.order.Coupons = [];
    AppEvents.UpdateCart.emit();
    AppEvents.LogEvent.emit('Cart cleared by user');
  }

  setToNewOrder(
    preserveFields?: Partial<{ store: boolean; saleType: boolean }>
  ): void {
    AppEvents.Stores.pipe(
      filter((stores) => !!stores),
      first()
    ).subscribe((stores) => {
      const storeName = preserveFields?.store ? this.order.Store.Name : null;
      const saleType = preserveFields?.saleType ? this.order.SaleType : null;
      const store = stores.find((s) => s.Name === storeName);

      AppEvents.OrderPreLoaded.next(null);
      AppEvents.OrderLoaded.next(null);

      this.order = new Order({
        Products: [],
        Combos: [],
        Store: store,
        SaleType: saleType,
      });

      AppEvents.CartCleared.emit(this.order);
      this.orderClient.saveLocal(this.order);

      //Load from order will call:
      //set Initialised, OrderPreloaded, SetCurrentMember, and OrderLoaded

      this.loadFromOrder(this.order);
    });
  }

  private setCurrentMemberDetails() {
    const member = MemberEvents.CurrentMember.value;
    if (this.order && member) {
      if (this.order.ComoIdSignature != member.ComoIdSignature) {
        this.order.IdSignature = null;
      }
      this.order.ComoIdSignature = member.ComoIdSignature;
      this.order.FirstName = member.FirstName;
      this.order.LastName = member.LastName;
      this.order.PhoneNumber = member.PhoneNumber;
      this.order.EmailAddress = member.EmailAddress;
      this.order.NumberPlate = member.CurbsideDetails?.NumberPlate;
      this.order.CarColour = member.CurbsideDetails?.CarColour;
      this.order.CarModel = member.CurbsideDetails?.CarModel;
      this.order.IsGuestOrder = false;
    }
  }

  private resendReceipt(orderIdSignature: string, emailAddress: string) {
    this.orderClient
      .resendReceipt(orderIdSignature, emailAddress)
      .subscribe(() => {
        AppEvents.ReceiptResent.emit();
      });
  }

  private reorder(order: Order) {
    this.loadFromOrder(
      new Order({
        Store: order.Store,
        SaleType: order.SaleType,
        Products: order.Products,
        Combos: order.Combos,
      })
    );
    NavigationEvents.NavigateToOrderPage.emit({
      Page: OrderPage.MainMenu,
      Order: this.order,
    });
  }

  /**
   * Runs validation on the order and emits the result to
   * `AppEvents.OrderValidated`.
   */
  private validateOrder(order: Order): void {
    this.orderClient
      .validateOrder(order)
      .subscribe((result) => AppEvents.OrderValidated.emit(result));
  }

  /**
   * Runs a get of possible duplicated orders and emits the result to
   * `AppEvents.DuplicateOrders`.
   */
  private GetAllDuplicateOrders(order: Order): void {
    if (order.IdSignature != null) {
      this.orderClient
        .getAllDuplicateOrders(order.IdSignature)
        .subscribe((result) => AppEvents.DuplicateOrders.emit(result));
    }
  }

  /**
   * Returns `true` if the order has a salestype that is affected by kitchen
   * limits.
   */
  private doesKitchenLimitsApply(order: Order): boolean {
    return (
      !order?.SaleType?.Code ||
      [SaleType.InStoreCode, SaleType.CurbsideCode].includes(
        order.SaleType.Code
      )
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }
}
