import { OrderDay } from '../domain/order/order-day';
import { OrderTime } from '../domain/order/order-time';
import { SaleType, Store } from '../domain/store';

export class StoreTimePickerModel {
  constructor(o: Partial<StoreTimePickerModel>) {
    Object.assign(this, o);
  }
  public Store: Store;
  public SaleType: SaleType;
  public OrderDay: OrderDay;
  public OrderTime: OrderTime;
}
