import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { first } from 'rxjs/operators';
import {
  DietaryRequirement,
  DietaryRequirementType,
} from 'src/app/models/domain/dietary-requirement';
import { OrderModifier } from 'src/app/models/domain/order/order-modifier';
import { OrderModifierGroup } from 'src/app/models/domain/order/order-modifier-group';
import { OrderProduct } from 'src/app/models/domain/order/order-product';
import { ModalComponent } from 'src/app/models/view-models/modal-component';
import { ConfigService } from 'src/app/services/config.service';

type DietaryRequirementOption = {
  Selected: boolean;
  DietaryRequirement: DietaryRequirement;
};

@Component({
  selector: 'app-customise-modifiers',
  templateUrl: './customise-modifiers.component.html',
  styleUrls: ['./customise-modifiers.component.scss'],
})
export class CustomiseModifiersComponent
  extends ModalComponent
  implements OnInit
{
  @Input() orderProduct: OrderProduct;

  dietaryRequirements: DietaryRequirementOption[] = [];
  allergenModifierIds: string[] = [];

  valid: boolean;

  constructor(
    modalController: ModalController,
    public configService: ConfigService
  ) {
    super(modalController);
  }

  ngOnInit(): void {
    // Get all the modifier allergens so we can display only those allergy
    // options in the modal.
    const modifierAllergens: DietaryRequirementType[] = [];
    this.orderProduct.ModifierGroups.forEach((modifierGroup) => {
      modifierGroup.Modifiers.forEach((modifier) => {
        if (modifier.isDairyAllergy) {
          modifierAllergens.push(DietaryRequirementType.DairyAllergy);
        }
        if (modifier.isEggAllergy) {
          modifierAllergens.push(DietaryRequirementType.EggAllergy);
        }
        if (modifier.isGlutenAllergy) {
          modifierAllergens.push(DietaryRequirementType.GlutenAllergy);
        }
        if (modifier.isPeanutAllergy) {
          modifierAllergens.push(DietaryRequirementType.PeanutAllergy);
        }
        if (modifier.isTomatoAllergy) {
          modifierAllergens.push(DietaryRequirementType.TomatoAllergy);
        }
      });
    });
    if (modifierAllergens.length > 0) {
      this.configService.dietaryRequirements$
        .pipe(first())
        .subscribe((requirements) => {
          this.dietaryRequirements = requirements
            .filter(
              (requirement) =>
                // Only show allergens relevant to the product. Always show halal.
                modifierAllergens.includes(requirement.Type) ||
                requirement.Type === DietaryRequirementType.Halal
            )
            .map((requirement) => {
              const selected = this.orderProduct.DietaryRequirements.some(
                (selectedRequirement) =>
                  requirement.Type === selectedRequirement.Type
              );
              return {
                Selected: selected,
                DietaryRequirement: requirement,
              };
            });
          this.calculateDietaryRequirements();
        });
    }
    this.calculateValid();
  }

  /**
   * Called when a modifier is changed on the UI.
   */
  modifierChanged(
    modifier: OrderModifier,
    modifierGroup: OrderModifierGroup
  ): void {
    // Deselect all the other modifiers if only one is allowed to be selected in
    // the modifier group
    if (modifierGroup.SelectionLimit == 1 && modifier.Selected == true) {
      modifierGroup.Modifiers.forEach((m) => {
        if (m != modifier) {
          m.Selected = false;
        }
      });
    }

    // Update the number of selected modifiers in the group
    modifierGroup.Selection = modifierGroup.Modifiers.filter(
      (m) => m.Selected
    ).length;

    // Update the display text of the group
    modifierGroup.SelectionText =
      modifierGroup.Modifiers.filter((m) => m.Included).length > 0
        ? modifierGroup.Modifiers.filter((m) => m.Included).join(', ')
        : modifierGroup.Name;
    this.calculateValid();
  }

  /**
   * Called when a dietary requirement is changed on the UI.
   *
   * @param selection The option that was selected, with the update selection
   * value.
   */
  dietaryRequirementChanged(selection: DietaryRequirementOption): void {
    selection.Selected = !selection.Selected;
    if (selection.Selected) {
      // Only add the requirement if it isn't already selected on the product
      if (
        this.orderProduct.DietaryRequirements.every(
          (requirement) =>
            requirement.Type !== selection.DietaryRequirement.Type
        )
      ) {
        this.orderProduct.DietaryRequirements.push(
          selection.DietaryRequirement
        );
      }

      // Deselect the modifiers that conflict with the requirement
      this.orderProduct.ModifierGroups.forEach((group) => {
        group.Modifiers.forEach((modifier) => {
          const modifierConflicts =
            (modifier.isDairyAllergy &&
              selection.DietaryRequirement.Type ===
                DietaryRequirementType.DairyAllergy) ||
            (modifier.isEggAllergy &&
              selection.DietaryRequirement.Type ===
                DietaryRequirementType.EggAllergy) ||
            (modifier.isGlutenAllergy &&
              selection.DietaryRequirement.Type ===
                DietaryRequirementType.GlutenAllergy) ||
            (modifier.isPeanutAllergy &&
              selection.DietaryRequirement.Type ===
                DietaryRequirementType.PeanutAllergy) ||
            (modifier.isTomatoAllergy &&
              selection.DietaryRequirement.Type ===
                DietaryRequirementType.TomatoAllergy);

          if (modifierConflicts) {
            modifier.Selected = false;
          }
        });
      });
    } else {
      this.orderProduct.DietaryRequirements =
        this.orderProduct.DietaryRequirements.filter(
          (requirement) =>
            requirement.Type !== selection.DietaryRequirement.Type
        );
    }
    this.calculateDietaryRequirements();
    this.calculateValid();
  }

  /**
   * Reconstructs the `allergenModifierIds` array.
   */
  private calculateDietaryRequirements(): void {
    const allergenModifierIdsNext: string[] = [];

    const selectedAllergens = this.dietaryRequirements
      .filter((requirement) => requirement.Selected)
      .map((requirement) => requirement.DietaryRequirement.Type);
    this.orderProduct.ModifierGroups.forEach((group) => {
      group.Modifiers.forEach((modifier) => {
        const modifierAllergens = this.getModifierDietaryRequirements(modifier);
        if (
          modifierAllergens.some((allergen) =>
            selectedAllergens.includes(allergen)
          )
        ) {
          allergenModifierIdsNext.push(modifier.ModifierId);
        }
      });
    });

    this.allergenModifierIds = allergenModifierIdsNext;
  }

  /**
   * Returns an array of `DietaryRequirementType`s that the modifier has.
   */
  private getModifierDietaryRequirements(
    modifier: OrderModifier
  ): DietaryRequirementType[] {
    const requirements: DietaryRequirementType[] = [];

    if (modifier.isDairyAllergy) {
      requirements.push(DietaryRequirementType.DairyAllergy);
    }
    if (modifier.isEggAllergy) {
      requirements.push(DietaryRequirementType.EggAllergy);
    }
    if (modifier.isGlutenAllergy) {
      requirements.push(DietaryRequirementType.GlutenAllergy);
    }
    if (modifier.isPeanutAllergy) {
      requirements.push(DietaryRequirementType.PeanutAllergy);
    }
    if (modifier.isTomatoAllergy) {
      requirements.push(DietaryRequirementType.TomatoAllergy);
    }

    return requirements;
  }

  private calculateValid(): void {
    this.valid = true;
    if (this.orderProduct) {
      this.valid = OrderProduct.Validate(this.orderProduct);
    }
  }

  done(): void {
    this.dismiss(undefined);
  }
}
