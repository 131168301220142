import { Combo } from './combo';
import { Product } from './product';

export class Category {
  public Id: string;
  public Name: string;
  public DisplayName: string;
  public Description: string;
  public DisplayOrder: number;
  public PLU: number;
  public ImageUrl: string;
  public HoverImageUrl: string;
  public SelectedImageUrl: string;
  public HoverSelectedImageUrl: string;
  public SocialImageUrl: string;

  public Products: Array<Product>;
  public Combos: Array<Combo>;

  public ProductCount: number;

  constructor(o: Partial<Category>) {
    Object.assign(this, o);
  }
}
