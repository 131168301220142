import { Category } from '../domain/category';
import { Combo } from '../domain/combo';
import { Menu } from '../domain/menu';
import { Product } from '../domain/product';
import { ComboDTO } from './combo.dto';
import { ProductDTO } from './product.dto';

export class MenuDTO {
  public Id: string;
  public Name: string;
  public Products: Array<string>;
  public ProductsDetails: Array<ProductDTO>;
  public Combos: Array<string>;
  public CombosDetails: Array<ComboDTO>;

  public static ToDomain(dto: MenuDTO): Menu {
    if (!dto) {
      return null;
    }
    const menu: Menu = {
      Id: dto.Id,
      //Extract all categories listed in products
      Categories: dto.ProductsDetails.map((p) =>
        (p.Subcategories || []).map((s) => s.Name).concat(p.Category)
      )
        .concat(
          dto.CombosDetails.map((p) =>
            (p.Subcategories || []).map((s) => s.Name).concat(p.Category)
          )
        )
        //Change from string[][] to string[]
        .reduce((a, b) => a.concat(...b), [])
        //Remove duplicates
        .filter((v, i, a) => a.indexOf(v) === i)
        .map(
          (c) =>
            new Category({
              Name: c,
              DisplayName: c,
              Combos: new Array<Combo>(),
              Products: new Array<Product>(),
              ProductCount: 0,
            })
        )
        .sort((a, b) => (a.DisplayName < b.DisplayName ? -1 : 1)),
      ProductDictionary: {},
      ComboDictionary: {},
    };
    const combos = new Array<Combo>();
    if (dto.CombosDetails && dto.CombosDetails.length > 0) {
      dto.CombosDetails.forEach((c) => {
        const combo = ComboDTO.ToDomain(c);
        combos.push(combo);
        const comboCategory = menu.Categories.find(
          (ca) => ca.Name == combo.Category
        );
        if (comboCategory) {
          comboCategory.Combos.push(combo);
        }
        if (c.Subcategories) {
          c.Subcategories.forEach((s) => {
            const comboSubCategory = menu.Categories.find(
              (c) => c.Name == s.Name
            );
            if (comboSubCategory) {
              comboSubCategory.Combos.push(combo);
            }
          });
        }
        menu.ComboDictionary[combo.Id] = combo;
      });
    }
    dto.ProductsDetails.forEach((p) => {
      const product = ProductDTO.ToDomain(p);

      const productCategory = menu.Categories.find((c) => c.Name == p.Category);
      if (productCategory) {
        productCategory.Products.push(product);
      }
      if (p.Subcategories) {
        p.Subcategories.forEach((s) => {
          const productSubCategory = menu.Categories.find(
            (c) => c.Name == s.Name
          );
          if (productSubCategory) {
            productSubCategory.Products.push(product);
          }
        });
      }
      menu.ProductDictionary[product.Id] = product;
      product.AvailableCombos = combos.filter(
        (c) =>
          !c.DisplayOnMenu &&
          c.Items[0].Products.some((ip) => ip.Id == product.Id)
      );
      product.AllCombos = product.AvailableCombos;
    });
    return menu;
  }

  static PlaceProductInCategory(menu: Menu, product: Product | Combo): void {}
}
