import { Modifier } from '../domain/modifier';

/**
 * DTO for `Modifier`.
 *
 * @see Modifier
 */
export class ModifierDTO {
  public Id: string;
  public Name: string;
  public InternalName: string;
  public Price: number;
  public DisplayOrder: number;
  public CanAddAsExtra: boolean;
  public Category: string;
  public PLU: number;
  public RemovedPLU: number;

  public isGlutenAllergy: boolean;
  public isDairyAllergy: boolean;
  public isPeanutAllergy: boolean;
  public isEggAllergy: boolean;
  public isTomatoAllergy: boolean;

  public static ToDomain(dto: ModifierDTO): Modifier {
    if (!dto) {
      return null;
    }
    const modifier = new Modifier({});
    modifier.Id = dto.Id;
    modifier.Name = dto.Name;
    modifier.InternalName = dto.Name;
    modifier.Price = dto.Price;
    modifier.DisplayOrder = dto.DisplayOrder;
    modifier.PLU = dto.PLU;
    modifier.RemovedPLU = dto.RemovedPLU;
    modifier.Category = dto.Category;
    modifier.isGlutenAllergy = dto.isGlutenAllergy;
    modifier.isDairyAllergy = dto.isDairyAllergy;
    modifier.isPeanutAllergy = dto.isPeanutAllergy;
    modifier.isEggAllergy = dto.isEggAllergy;
    modifier.isTomatoAllergy = dto.isTomatoAllergy;
    return modifier;
  }
}
