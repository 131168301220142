import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';
import { SwiperContainer } from 'swiper/element';

/**
 * Initializes and configures the Swiper container as soon as the element exists.
 *
 * https://medium.com/@fbanyai/swiper-10-angular-16-uncovered-1721bf32dfd6
 *
 * To use this directive, add the `appSwiper` attribute to a `swiper-container` element (should only be done on the
 * `component-lib` carousel implementation):
 *
 * ```html
 * <swiper-container appSwiper [config]="swiperConfig"></div>
 * ```
 *
 * @param config - The configuration options for the Swiper instance.
 */
@Directive({
  selector: '[appSwiper]',
})
export class SwiperDirective implements AfterViewInit {
  // Type is `SwiperOptions` but the type import is broken
  @Input() config?: any;
  // Type is `SwiperModule[]` but the type import is broken
  @Input() modules?: any[];

  constructor(private el: ElementRef<SwiperContainer>) {}

  ngAfterViewInit(): void {
    Object.assign(this.el.nativeElement, this.config);

    // After the config is loaded into the element, initialises the carousel.
    this.el.nativeElement.initialize();
  }
}
