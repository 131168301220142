import { Injectable, OnDestroy } from '@angular/core';
import { AppEvents } from '../models/domain/events/app-events';
import { Observable, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

/**
 * Precision for a significant change.
 *
 * Latitude follows this estimate, longitude changes as we get further from the
 * equator.
 *
 * decimal places | distance
 * -------        | --------
 * 0              | 111  km
 * 1              | 11.1 km
 * 2              | 1.11 km
 * 3              | 111  m
 * 4              | 11.1 m
 * 5              | 1.11 m
 * 6              | 11.1 cm
 * 7              | 1.11 cm
 * 8              | 1.11 mm
 */
const precision = 0.01; // About 1km

@Injectable({
  providedIn: 'root',
})
export class LocationService implements OnDestroy {
  /**
   * Observable with the user's current location as latitude and longitude
   * coordinates.
   */
  private currentLocation$ = new Observable<google.maps.LatLngLiteral | null>(
    (subscriber) => {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          subscriber.next({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
        },
        (error) => {
          subscriber.error(error.message);
        }
      );
      const watch = navigator.geolocation.watchPosition(
        (position) =>
          subscriber.next({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          }),
        (error) => {
          subscriber.error(error.message);
        }
      );

      // Clear watch on subscription end
      return () => navigator.geolocation.clearWatch(watch);
    }
  ).pipe(
    filter((location) => {
      return (
        !this.lastEmittedLocation ||
        (Math.abs(this.lastEmittedLocation.lat - location.lat) > precision &&
          Math.abs(this.lastEmittedLocation.lng - location.lng) > precision)
      );
    })
  );

  private lastEmittedLocation: google.maps.LatLngLiteral | null = null;

  subscriptions: Subscription[] = [
    AppEvents.AppLoaded.pipe(filter((loaded) => loaded)).subscribe(() => {
      //Update the store distances before broadcasting the location
      this.updateStores(null);
    }),
    AppEvents.Stores.subscribe(() => {
      this.updateStores(AppEvents.CurrentLocation.value);
    }),
    this.currentLocation$.subscribe((location) => {
      this.lastEmittedLocation = location;
      //Update the store distances before broadcasting the location
      this.updateStores(location);
      AppEvents.CurrentLocation.next(location);
    }),
  ];

  private updateStores(location: google.maps.LatLngLiteral | null): void {
    if (!AppEvents.Stores.value) {
      return;
    }
    AppEvents.Stores.value.forEach((s) => {
      if (!location) {
        s.Distance = undefined;
        s.DistanceLabel = null;
      } else {
        s.Distance = this.GetLatLongLength(
          location.lat,
          location.lng,
          s.Latitude,
          s.Longitude
        );
        s.DistanceLabel = this.FormatDistance(s.Distance);
      }
    });
  }

  private FormatDistance(distance: number): string {
    let suffix = ' m';
    if (distance >= 1000) {
      suffix = ' km';
      distance = distance / 1000;
    }
    distance = Math.round(distance);
    return distance + suffix;
  }

  private GetLatLongLength(
    lat1: number,
    lon1: number,
    lat2: number,
    lon2: number
  ): number {
    // generally used geo measurement function
    const R = 6378.137; // Radius of earth in KM
    const dLat = (lat2 * Math.PI) / 180 - (lat1 * Math.PI) / 180;
    const dLon = (lon2 * Math.PI) / 180 - (lon1 * Math.PI) / 180;
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos((lat1 * Math.PI) / 180) *
        Math.cos((lat2 * Math.PI) / 180) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = R * c;
    return d * 1000; // meters
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
