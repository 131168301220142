import { Component, OnDestroy, OnInit } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { MemberEvents } from 'src/app/models/domain/events/member-events';
import { EnvironmentVariables } from 'src/app/models/environment';
import { IMemberService } from 'src/app/services/member.service';

@Component({
  selector: 'app-notification-settings',
  templateUrl: './notification-settings.component.html',
  styleUrls: ['./notification-settings.component.scss'],
})
export class NotificationSettingsComponent implements OnInit, OnDestroy {
  subscriptions = [
    MemberEvents.MemberUpdated.subscribe(async () => {
      (
        await this.toastController.create({
          message: 'Profile updated',
          duration: 800,
        })
      ).present();
      this.setUpdating(false); //stops loader icon
    }),
    MemberEvents.UpdateMemberFailed.subscribe(async (e) => {
      (
        await this.toastController.create({ message: e, duration: 1500 })
      ).present();
      this.setUpdating(false); //stops loader icon
    }),
  ];

  updating = false; //for loading icon

  constructor(
    public memberService: IMemberService,
    public variables: EnvironmentVariables,
    public toastController: ToastController
  ) {}

  ngOnInit(): void {}

  updateMember(): void {
    MemberEvents.UpdateMember.emit(this.memberService.currentMember);
    this.setUpdating(true);
  }
  updateLoyaltyProgram(): void {
    if (this.memberService.currentMember.LoyaltyProgram) {
      this.memberService.currentMember.AllowEmail =
        this.memberService.currentMember.LoyaltyProgram;
    }
    this.updateMember();
  }

  //Set updating: for loading icon when details are getting updated
  setUpdating(value: boolean): void {
    this.updating = value;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }
}
