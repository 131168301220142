import { Component, OnDestroy } from '@angular/core';
import { ModalController, ViewWillEnter } from '@ionic/angular';
import { merge, Subscription } from 'rxjs';
import { CurbsideWarningComponent } from 'src/app/components/curbside-warning/curbside-warning.component';
import { LoginModalComponent } from 'src/app/components/login/login-modal/login-modal.component';
import { AppEvents } from 'src/app/models/domain/events/app-events';
import { NavigationEvents } from 'src/app/models/domain/events/navigation-events';
import { OrderPage } from 'src/app/models/domain/navigation-page';
import { SaleType, Store } from 'src/app/models/domain/store';
import { EnvironmentVariables } from 'src/app/models/environment';
import { CouponService } from 'src/app/services/coupon.service';
import { IMemberService } from 'src/app/services/member.service';
import { MenuService } from 'src/app/services/menu.service';
import { OrderService } from 'src/app/services/order.service';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss'],
})
export class CheckoutComponent implements OnDestroy, ViewWillEnter {
  public stores: Array<Store>;
  public orderValid = false;
  public loginModal: HTMLIonModalElement;

  private subscriptions: Subscription[] = [];

  constructor(
    public orderService: OrderService,
    public menuService: MenuService,
    public couponService: CouponService,
    public memberService: IMemberService,
    public modalController: ModalController,
    public variables: EnvironmentVariables
  ) {
    this.subscriptions.push(
      merge(
        AppEvents.CartUpdated,
        AppEvents.OrderTimeChanged,
        AppEvents.RedeemableResult
      ).subscribe(() => {
        this.checkOrderValid();
      })
    );

    this.subscriptions.push(
      AppEvents.Stores.subscribe((stores) => {
        this.stores = stores;
      })
    );
  }

  ionViewWillEnter(): void {
    // Use ionViewWillEnter to check validity so it is re-checked every time the
    // page is viewed
    this.checkOrderValid();
  }

  checkOrderValid(): void {
    if (this.orderService.order == null) {
      return;
    }
    if (
      this.orderService.order.Products.length == 0 &&
      this.orderService.order.Combos.length == 0
    ) {
      NavigationEvents.NavigateToOrderPage.emit({
        Page: OrderPage.MainMenu,
        Order: this.orderService.order,
      });
      return;
    }
    this.orderValid =
      this.orderService.order.Store != null &&
      this.orderService.order.SaleType != null &&
      this.orderService.order.OrderDay != null &&
      this.orderService.order.OrderTime != null &&
      this.couponService.couponModel.Requirements == null;
  }

  goToMainMenu(): void {
    NavigationEvents.NavigateToOrderPage.emit({
      Page: OrderPage.MainMenu,
      Order: this.orderService.order,
    });
  }

  goToSummary(): void {
    if (
      !this.memberService.currentMember &&
      this.orderService.order?.SaleType?.Code != SaleType.TableOrderCode
    ) {
      this.showLoginModal();
      return;
    } else if (
      this.orderService.order?.SaleType?.Code == SaleType.CurbsideCode &&
      !this.memberService.currentMember.CurbsideDetails?.NumberPlate
    ) {
      this.showCurbsideWarning();
      return;
    }
    NavigationEvents.NavigateToOrderPage.emit({
      Page: OrderPage.Summary,
      Order: this.orderService.order,
    });
  }

  async showCurbsideWarning(): Promise<void> {
    const curbsideWarningModal = await this.modalController.create({
      component: CurbsideWarningComponent,
      componentProps: {
        member: this.memberService.currentMember,
      },
      cssClass: 'curbside-warning-modal',
    });
    //TODO: We may navigate to summary if this modal is only displayed in goToSummary()
    await curbsideWarningModal.present();
  }

  async showLoginModal(): Promise<void> {
    this.loginModal = await this.modalController.create({
      component: LoginModalComponent,
      componentProps: {
        loginModel: this.memberService.loginModel,
      },
      cssClass: 'show-header-modal login-modal',
    });
    this.loginModal.onDidDismiss().then(() => {
      //Navigate to summary
      //Check current member again to avoid login loop
      if (this.memberService.currentMember) {
        this.goToSummary();
      }
    });
    await this.loginModal.present();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }
}
