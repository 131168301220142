export enum NavigationPage {
  Account = 'account',
  AccountDetails = 'account/details',
  Catering = 'catering',
  Home = 'home',
  Table = 'table',
  MyOrders = 'my-orders',
  Locations = 'locations',

  Order = 'order',

  // Add order routes because they are the 'default' options for navigation
  // when we don't have an order available to use to determine sale type
  OrderMainMenu = 'order/main-menu',
}

export enum OrderPage {
  MainMenu = 'main-menu',
  Checkout = 'checkout',
  Summary = 'summary',
  Payment = 'payment',
  PaymentResult = 'payment-result',
  Confirmation = 'confirmation',
}

export enum AccountPage {
  Details = 'details',
  NotificationSettings = 'notification-settings',
  MyCards = 'my-cards',
}
