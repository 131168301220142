/**
 * Coupon for discounting items in an order.
 */
export class Coupon {
  /** The reference to this coupon. */
  public Code: string;

  /** Display name. */
  public Name: string;

  /** Names of stores this coupon is valid at. */
  public ValidStores: Array<string>;

  /** Prerequisite items for this coupon to be able to apply. */
  public RequiredProducts: Array<ProductList>;

  /** Items that can be discounted after the required products are added. */
  public DiscountedProducts: Array<ProductList>;

  /** Amount of discount to apply. */
  public Value: number;

  /** Type of discount (flat vs percent). */
  public ValueType: ValueType;

  /** When the coupon is valid from. */
  public StartDate: string;

  /** When the coupon expires. */
  public ExpiryDate: string;

  /** Whether modifiers of a discounted product should also get discounted. */
  public IncludeModifiersInDiscount: boolean;

  /**
   * When `true` the cheapest item of `DiscountedProducts` will have the
   * discount applied. When `false`, it will apply to the most expensive.
   */
  public DiscountCheapest: boolean;

  /** If this is a code blue, the category that should be discounted */
  public CodeBlueType: string;
}

/**
 * The items allowed for this coupon requirement.
 */
export class ProductList {
  /** PLUs of products to satisfy this requirement. */
  public AllowedProducts: Array<number>;
  /** PLUs of categories to satisfy this requirement. */
  public AllowedCategories: Array<number>;
}

export enum ValueType {
  Percent = '%',
  Other = '',
}
