import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { CarouselComponent } from './carousel/carousel.component';
import { SwiperDirective } from './carousel/swiper.directive';

@NgModule({
  imports: [BrowserModule, RouterModule, IonicModule],
  declarations: [CarouselComponent, SwiperDirective],
  exports: [CarouselComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ComponentLibModule {}
