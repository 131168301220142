/**
 * This class represents the online configuration of an application, including
 * aspects related to allergens, warnings, orders, catering, table settings,
 * store-related aspects, menu items, footer categories, and app URLs.
 *
 * This configuration provides elements like banners, carousels, keep-in-touch
 * text along with app URLs.
 */
export type OnlineConfiguration = {
  /**
   * Information to display to the user about generic allergen info.
   */
  AllergenConfig?: AllergenConfig;

  WarningBanner: Banner;

  OrderBanner: Banner;
  OrderCarousel: Array<LinkImage>;

  CateringBanner: Banner;
  CateringCarousel: Array<LinkImage>;

  TableBanner: Banner;
  TableCarousel: Array<LinkImage>;

  //Fields same as above but include any extra images from the store
  //set in the CMS
  StoreOrderCarousel: Array<LinkImage>;
  StoreTableCarousel: Array<LinkImage>;
  StoreCateringCarousel: Array<LinkImage>;
  StoreWarningBanner: Banner;

  MenuItems: Array<MenuItem>;
  FooterCategories: Array<FooterCategory>;
  KeepInTouchHeader: string;
  KeepInTouchText: string;

  AppUrl: string;
  AndroidAppLink: string;
  AppleAppLink: string;
};

/**
 * The Banner class represents a banner object, which includes the text to
 * display, the link associated with the banner, and an internal name used for
 * checking whether the banner has been dismissed.
 */
export type Banner = {
  Text: string;
  Link: string;

  //The internal name of the banner used for checking
  //if a banner has been dismissed already
  Name: string;
};

/**
 * The Banner class represents a banner object, which includes the text to
 * display, the link associated with the banner, and an internal name used for
 * checking whether the banner has been dismissed.
 */
export type LinkImage = {
  Type: LinkType;
  Image: string;
  Link: string;
};

/**
 * This is an enumeration that specifies the type of link available (like
 * Category, Combo, Product, Url). It is used in LinkImage.
 */
export enum LinkType {
  Category = 'Category',
  Combo = 'Combo',
  Product = 'Product',
  Url = 'Url',
}

/**
 * This class constructs a menu item, which includes the text that represents
 * the item, an associated link, and possibly other sub-menu items which are
 * themselves MenuItem instances, allowing for nested menus.
 */
export type MenuItem = {
  Text: string;
  //Either a link, or a mini menu inside
  Link: string;
  MenuItems: Array<MenuItem>;
};

/**
 * The FooterCategory class represents a category to be displayed in the footer
 * of the application. Each FooterCategory includes a text to display and a list
 * of MenuItem objects associated with it.
 */
export type FooterCategory = {
  Text: string;
  Items: Array<MenuItem>;
};

export type AllergenConfig = {
  allergenText?: string;
  allergenLink?: string;
};
