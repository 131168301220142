import { CouponRequirements } from '../view-models/coupon-model';

export class DiscountResult {
  constructor(o: Partial<DiscountResult>) {
    Object.assign(this, o);
  }

  public Success: boolean;
  public Messages: Array<string>;
  public Amount: number;
  public Requirements: CouponRequirements;
}
