import {
  Component,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Subscription, timer } from 'rxjs';
import { ComoIFrameEvent } from 'src/app/models/domain/events/como-IFrame-Event';
import { MemberEvents } from 'src/app/models/domain/events/member-events';
import { EnvironmentVariables } from 'src/app/models/environment';
import {
  LoginModel,
  LoginViewMode,
} from 'src/app/models/view-models/login-model';
import { delay, filter } from 'rxjs/operators';

@Component({
  selector: 'app-login-index',
  templateUrl: './login-index.component.html',
  styleUrls: ['./login-index.component.scss'],
})
export class LoginIndexComponent implements OnInit, OnDestroy {
  @Input()
  loginModel: LoginModel;

  LoginViewMode = LoginViewMode;

  inApp = false;

  submitted: boolean;

  codeJustSent: boolean;

  registerUrl: SafeResourceUrl;

  /** Whether the entered phone number is valid */
  phoneNumberValid = false;

  /** Company who has referred this user,
   * will display extra information and force sign in
   * to happen first when this is not null */
  referrer = null;

  /** The user is currently being authenticated on the remote server. */
  loggingIn = false;

  subscriptions: Subscription[] = [];

  constructor(
    public variables: EnvironmentVariables,
    public domSanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    //When reopening the modal, we start again
    this.loginModel.ViewMode = LoginViewMode.PhoneNumber;
    this.loginModel.AccessToken = '';
    this.loginModel.Loading = false;
    this.loginModel.Error = null;
    this.validatePhoneNumber();
    this.subscriptions = [
      MemberEvents.InApp.subscribe((i) => {
        this.inApp = i;
      }),
      MemberEvents.Referrer.subscribe((referrer) => (this.referrer = referrer)),
      // Instantly set logging in to immediately show loading dots if required
      MemberEvents.LoggingIn.pipe(
        filter((isLoggingIn) => isLoggingIn)
      ).subscribe(() => {
        this.loggingIn = true;
      }),
      // Delay before setting not logging in so the modal has time to disappear
      // before the loading dots disappear, otherwise you get a flash of the
      // sign-up screen fields as the modal fades
      MemberEvents.LoggingIn.pipe(
        filter((isLoggingIn) => !isLoggingIn),
        delay(500)
      ).subscribe(() => {
        this.loggingIn = false;
      }),
    ];
  }

  @HostListener('window:message', ['$event'])
  KeyUpCtrl(event: ComoIFrameEvent): void {
    if (event.result == 'success') {
      MemberEvents.GetMemberFromCommonExtId.emit();
      //Retrieve Como user from event id
    }
  }

  setViewMode(viewMode: LoginViewMode): void {
    this.loginModel.ViewMode = viewMode;
  }

  allowedKeys = [
    '0',
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    ' ',
    '/',
    'Backspace',
    'Delete',
    'Enter',
    'ArrowLeft',
    'ArrowRight',
    'Tab',
  ];

  phoneNumberKeyDown(event: KeyboardEvent): void {
    if (!this.allowedKeys.includes(event.key) && !event.ctrlKey) {
      event.preventDefault();
    }
  }

  /**
   * Changes the view mode to "PhoneNumber" in the login model, resets related
   * properties and validates the phone number.
   */
  phoneNumberChanged(): void {
    this.loginModel.ViewMode = LoginViewMode.PhoneNumber;
    this.loginModel.AccessToken = '';
    this.loginModel.Error = null;
    this.codeJustSent = null;
    this.validatePhoneNumber();
  }

  /**
   * Validates phone number and sets `this.phoneNumberValid` to the result.
   */
  validatePhoneNumber(): void {
    const phoneNumber = (this.loginModel.PhoneNumber || '').replace(/ /g, '');
    const first2numbers = phoneNumber.slice(0, 2);
    if (phoneNumber.length > 1 && first2numbers !== '02') {
      this.loginModel.Error = 'Phone number must start with 02';
      this.phoneNumberValid = false;
    } else {
      this.loginModel.Error = null;
      this.phoneNumberValid = true;
    }
  }

  confirmPhoneNumberKeyDown(event: KeyboardEvent): void {
    if (!this.allowedKeys.includes(event.key) && !event.ctrlKey) {
      event.preventDefault();
    }
  }

  //Returns true if confirm phone number match phone number
  validateConfirmPhoneNumber(): boolean {
    const phoneNumber = (this.loginModel.PhoneNumber || '').replace(/ /g, '');
    const confirmPhoneNumber = (
      this.loginModel.ConfirmPhoneNumber || ''
    ).replace(/ /g, '');

    if (confirmPhoneNumber && phoneNumber !== confirmPhoneNumber) {
      return false;
    } else {
      return true;
    }
  }

  usePhoneNumber(): void {
    const phoneNumber = (this.loginModel.PhoneNumber || '').replace(/ /g, '');

    //We can now generate the registration URL with the phone number that has been used
    this.registerUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(
      `https://janua.como.com/ssr-modules/reg/${this.variables.comoLocationId}
        ?hideHeader=true
        &PhoneNumber=${phoneNumber}`
    );

    MemberEvents.SendIdentificationCode.emit({ phoneNumber, method: 'SMS' });
    if (this.codeJustSent == false) {
      this.codeJustSent = true;
    }
    timer(5 * 1000).subscribe(() => {
      this.codeJustSent = false;
    });
  }

  useEmail(): void {
    const phoneNumber = (this.loginModel.PhoneNumber || '').replace(/ /g, '');

    //We can now generate the registration URL with the phone number that has been used
    this.registerUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(
      `https://janua.como.com/ssr-modules/reg/${this.variables.comoLocationId}
        ?hideHeader=true
        &PhoneNumber=${phoneNumber}`
    );

    MemberEvents.SendIdentificationCode.emit({ phoneNumber, method: 'EMAIL' });
    if (this.codeJustSent == false) {
      this.codeJustSent = true;
    }
    timer(5 * 1000).subscribe(() => {
      this.codeJustSent = false;
    });
  }

  enterAccessToken(): void {
    const phoneNumber = (this.loginModel.PhoneNumber || '').replace(/ /g, '');
    MemberEvents.GetMemberFromAccessToken.emit({
      phoneNumber: phoneNumber,
      accessToken: this.loginModel.AccessToken,
    });
  }

  birthdayKeyDown(event: KeyboardEvent): void {
    if (!this.allowedKeys.includes(event.key) && !event.ctrlKey) {
      event.preventDefault();
    }
  }

  register(): void {
    const phoneNumber = (this.loginModel.PhoneNumber || '').replace(/ /g, '');

    MemberEvents.Register.emit({
      EmailAddress: this.loginModel.EmailAddress,
      FirstName: this.loginModel.FirstName,
      LastName: this.loginModel.LastName,
      Birthday: this.loginModel.Birthday,
      PhoneNumber: phoneNumber,
      LoyaltyProgram: this.loginModel.LoyaltyProgram,
      TermsAndConditions: this.loginModel.TermsAndConditions,
      AllowEmail: this.loginModel.AllowEmail,
      Referrer: null,
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }
}
