import { Category } from '../domain/category';
import { Combo, ComboItem } from '../domain/combo';
import { SaleTimeFrame } from '../domain/product';
import { ModifierDTO } from './modifier.dto';
import { ProductDTO } from './product.dto';

export class ComboDTO {
  public Id: string;
  public Name: string;
  public DisplayName: string;
  public ShortName: string;
  public Description: string;

  public Price: number;
  public DisplayOrder: number;

  public Category: string;
  public Subcategories: Array<Category>;
  public ImageUrl: string;
  public PLU: string;

  public DisplayOnMenu: boolean;
  public HideProductsAndModifiers: boolean;

  public Items: Array<ComboItemDTO>;

  public OutOfStock: boolean;
  public IsHiddenOutOfStock: boolean;
  public SaleTimeFrame: SaleTimeFrame;

  public static ToDomain(dto: ComboDTO): Combo {
    if (!dto) {
      return null;
    }
    const combo: Combo = {
      Id: dto.Id,
      Description: dto.Description,
      DisplayName: dto.Name,
      DisplayOrder: dto.DisplayOrder,
      Category: dto.Category,
      ImageUrl: dto.ImageUrl,
      Name: dto.Name,
      ShortName: dto.Name,
      Price: dto.Price,
      PLU: dto.PLU,
      DisplayOnMenu: dto.DisplayOnMenu,
      HideProductsAndModifiers: dto.HideProductsAndModifiers,
      Items: dto.Items.map((i) => ComboItemDTO.ToDomain(i)),
      OutOfStock: false,
      IsHiddenOutOfStock: dto.IsHiddenOutOfStock,
      ProductCount: 0,
      SaleTimeFrame: dto.SaleTimeFrame
        ? {
            OpenTime: dto.SaleTimeFrame.OpenTime,
            CloseTime: dto.SaleTimeFrame.CloseTime,
          }
        : null,
      //Activated by selecting a timeframe later
      TimeAvailable: !dto.SaleTimeFrame,
    };

    return combo;
  }
}
export class ComboItemDTO {
  public Name: string;
  public IsModifiers: boolean;
  public Products: Array<ProductDTO>;
  public DefaultProductId: string;
  public Modifiers: Array<ModifierDTO>;
  public MaxItems: number;
  public MinItems: number;

  public static ToDomain(dto: ComboItemDTO): ComboItem {
    if (!dto) {
      return null;
    }
    const combo: ComboItem = {
      Name: dto.Name,
      IsModifiers: dto.IsModifiers,
      MaxItems: dto.MaxItems,
      MinItems: dto.MinItems,
      Products: dto.Products
        ? dto.Products.map((p) => ProductDTO.ToDomain(p))
        : [],
      Modifiers: dto.Modifiers
        ? dto.Modifiers.map((m) => ModifierDTO.ToDomain(m))
        : [],
      DefaultProductId: dto.DefaultProductId,
    };

    return combo;
  }
}
