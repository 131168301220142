import { Banner, LinkImage } from './online-configuration';
import { StoreOrderPeriods } from './order/order';
import { OrderDay } from './order/order-day';
import { PaymentIntegration } from './response/payment-response';

export class Store {
  constructor(o: Partial<Store>) {
    // getter properties cannot be used in Object.assign(..). So instead iterate over all the keys and assign them
    // to the new store object.
    const ignoreKeys: ReadonlyArray<keyof Store> = [
      'CateringMinimumOrderPrice',
      'CateringMinimumOrderWaitingHours',
    ];
    Object.entries(o)
      .filter(([key]) => !ignoreKeys.includes(key as keyof Store))
      .forEach(([key, value]) => {
        this[key] = value;
      });
  }

  public Id: string;
  public Address: string;
  public City: string;
  public DisplayName: string;
  public Latitude: number;
  public Longitude: number;
  public Name: string;
  public PaymentIntegrationSelected: PaymentIntegration;
  public PhoneNumber: string;
  public Suburb: string;
  public Distance: number; //Distance from current location in m
  public DistanceLabel: string;

  public OnlineOrderingActive: boolean;
  public OnlineOrderingStatusMessage: string;
  public WarningBanner: Banner;

  public SaleTypes: Array<SaleType>;
  public WeekOpenTimes: Array<WeekOpenTime>;
  public Holidays: Array<StoreHoliday>;
  public IsOpen: boolean;
  public WaitTime: number;

  public MenuId: string;
  public AvailableOrderDays: Array<OrderDay>;

  public InStore: SaleType;

  public Curbside: SaleType;
  public CurbsideMapImageLocation: string;

  public TableOrder: SaleType;
  public TableOrderMaximumTableNumber: number;

  public Catering: SaleType;

  public get CateringMinimumOrderPrice(): number {
    return this.CateringThresholds?.[0]?.MinimumSpend ?? 0;
  }

  public get CateringMinimumOrderWaitingHours(): number {
    return this.CateringThresholds?.[0]?.AdvanceHours ?? 0;
  }

  public CateringMaximumOrderWaitingHours: number;
  public CateringThresholds: CateringThresholdSetting[];

  /** The dollar amount the kitchen can produce in an hour. */
  public KitchenDollarLimit: number;

  /** The dollar amount the kitchen can produce in a time period. */
  public get KitchenDollarPeriodLimit(): number {
    const periodsInHour = 12;
    return this.KitchenDollarLimit / periodsInHour;
  }

  /**
   * `true` if orders that go over the `KitchenDollarPeriodLimit` overflow to
   * previous or subsequent intervals.
   */
  public IsKitchenOverflowActive: boolean;

  public OrderPeriods?: StoreOrderPeriods = undefined;
}

export class SaleType {
  public Name: string;
  public Code: SaleTypeCode;
  public Message: string;
  public MenuId: string;
  public IsActive: boolean; //Replaces Enabled
  public IsOpen: boolean; //Current time is within the week open times
  public WeekOpenTimes: Array<WeekOpenTime>;
  public Image: LinkImage;

  constructor(o: Partial<SaleType>) {
    Object.assign(this, o);
  }

  static readonly Curbside = 'Curbside';
  static readonly CurbsideCode = 'CURBSD';
  static readonly CurbsideObj = new SaleType({
    Name: SaleType.Curbside,
    Code: SaleType.CurbsideCode,
  });

  static readonly InStore = 'Pick Up';
  static readonly InStoreCode = 'ONLINEORDERS';
  static readonly InStoreObj = new SaleType({
    Name: SaleType.InStore,
    Code: SaleType.InStoreCode,
  });

  static readonly TableOrder = 'Table order';
  static readonly TableOrderCode = 'TABLE';
  static readonly TableOrderObj = new SaleType({
    Name: SaleType.TableOrder,
    Code: SaleType.TableOrderCode,
  });

  static readonly Catering = 'Catering';
  static readonly CateringCode = 'CATERING';
  static readonly CateringObj = new SaleType({
    Name: SaleType.Catering,
    Code: SaleType.CateringCode,
  });

  static readonly DEFAULT = SaleType.InStoreObj;
}

export type SaleTypeCode =
  | typeof SaleType.CurbsideCode
  | typeof SaleType.CateringCode
  | typeof SaleType.InStoreCode
  | typeof SaleType.TableOrderCode;

export class WeekOpenTime {
  public Day: string;
  public OpenTime: number;
  public OpenTimeLabel: string;
  public CloseTime: number;
  public CloseTimeLabel: string;
  public Closed: boolean;
}

export class StoreHoliday {
  public Day: number;
  public Month: number;
  public Open: boolean;
}

export class CateringThresholdSetting {
  AdvanceHours: number;
  MinimumSpend: number;

  constructor(obj: Partial<CateringThresholdSetting>) {
    this.AdvanceHours = obj.AdvanceHours;
    this.MinimumSpend = obj.MinimumSpend;
  }
}
