import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { EnvironmentVariables } from '../models/environment';
import { catchError, map } from 'rxjs/operators';
import { PurchasableRewardDTO } from '../models/dto/purchasable-reward.dto';
import { PurchasableRewardsResult } from '../models/domain/response/purchasable-rewards-result';
import { PurchasableReward } from '../models/domain/purchasable-reward';
import { PurchaseRewardResult } from '../models/domain/response/purchase-reward-result';

export abstract class IRewardClient {
  /** Gets all purchasable rewards. */
  abstract getPurchasableRewards(): Observable<PurchasableRewardsResult>;
  /** Purchases the selected reward. */
  abstract purchaseReward(
    reward: PurchasableReward
  ): Observable<PurchaseRewardResult>;
}

@Injectable({
  providedIn: 'root',
})
export class RewardClient implements IRewardClient {
  constructor(
    private client: HttpClient,
    public variables: EnvironmentVariables
  ) {}

  getPurchasableRewards(): Observable<PurchasableRewardsResult> {
    return this.client
      .get<PurchasableRewardDTO[]>(this.variables.baseApiUrl + '/Rewards/Perks')
      .pipe(
        map(
          (purchasableRewards) =>
            ({
              success: true,
              purchasableRewards: purchasableRewards.map(
                (purchasableReward) => ({
                  Key: purchasableReward.ExternalId,
                  Name: purchasableReward.Name,
                  Description: purchasableReward.Description,
                  Image: purchasableReward.ImageUrl,
                  Redeemable:
                    purchasableReward.ValidFrom <= new Date() &&
                    purchasableReward.ValidUntil >= new Date(),
                  // Prices come through from como *100 of the value we enter
                  Price: purchasableReward.Price / 100,
                })
              ),
            } as PurchasableRewardsResult)
        ),
        catchError((exception: HttpErrorResponse) => {
          let error = exception.error;
          if (typeof error == 'object') {
            error = 'Sorry, something went wrong';
          }
          return of({
            success: false,
            error: error as string,
          } as PurchasableRewardsResult);
        })
      );
  }

  purchaseReward(reward: PurchasableReward): Observable<PurchaseRewardResult> {
    return this.client
      .post<PurchaseRewardResult>(
        `${this.variables.baseApiUrl}/Rewards/Purchase/${reward.Key}`,
        null,
        { withCredentials: true }
      )
      .pipe(
        catchError<PurchaseRewardResult, Observable<PurchaseRewardResult>>(
          (exception: HttpErrorResponse) => {
            let error = exception.error;
            if (typeof error == 'object') {
              error = 'Sorry, something went wrong';
            }
            return of({
              Success: false,
              FailureMessages: [error as string],
            }) as Observable<PurchaseRewardResult>;
          }
        )
      );
  }
}
