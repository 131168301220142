import { Component, Input } from '@angular/core';
import { CateringThresholdSetting } from 'src/app/models/domain/store';
import { EnvironmentVariables } from 'src/app/models/environment';

/**
 * A popup component that displays catering threshold information to the user.
 */
@Component({
  selector: 'app-catering-threshold-message',
  templateUrl: './catering-threshold-message.component.html',
  styleUrls: ['./catering-threshold-message.component.scss'],
})
export class CateringThresholdMessageComponent {
  /**
   * Catering thresholds of the store.
   */
  @Input()
  cateringThresholds: CateringThresholdSetting[];

  constructor(public variables: EnvironmentVariables) {}
}
