import { Injectable, OnDestroy } from '@angular/core';
import { AppEvents } from '../models/domain/events/app-events';
import { NavigationEvents } from '../models/domain/events/navigation-events';
import { Order } from '../models/domain/order/order';
import { OrderCombo } from '../models/domain/order/order-combo';
import { OrderProduct } from '../models/domain/order/order-product';

@Injectable({
  providedIn: 'root',
})
export class GA4Service implements OnDestroy {
  subscriptions = [
    AppEvents.ViewProduct.subscribe((p) => this.viewProduct(p)),
    AppEvents.ViewCombo.subscribe((c) => this.viewCombo(c)),
    AppEvents.AddProduct.subscribe((p) =>
      this.changeQuantityProduct(p, p.Quantity)
    ),
    AppEvents.AddCombo.subscribe((c) =>
      this.changeQuantityCombo(c, c.Quantity)
    ),
    AppEvents.ChangeQuantity.subscribe((p) =>
      this.changeQuantityProduct(p.OrderProduct, p.Change)
    ),
    AppEvents.ChangeComboQuantity.subscribe((c) =>
      this.changeQuantityCombo(c.OrderCombo, c.Change)
    ),
    NavigationEvents.NavigateToPayment.subscribe((o) => {
      this.navigateToPayment(o.Order);
    }),
    AppEvents.OrderSubmitted.subscribe((o) => {
      this.orderSubmitted(o);
    }),
  ];
  constructor() {}

  viewProduct(product: OrderProduct) {
    (window as any).gtag('event', 'view_item', {
      currencyCode: 'NZD',
      items: [
        {
          item_name: product.Name,
          item_id: product.PLU,
          price: product.ItemPrice,
          item_category: product.Category,
          quantity: product.Quantity,
        },
      ],
    });
  }

  viewCombo(combo: OrderCombo) {
    (window as any).gtag('event', 'view_item', {
      currencyCode: 'NZD',
      items: [
        {
          item_name: combo.Name,
          item_id: combo.PLU,
          price: combo.ItemPrice,
          item_category: combo.Category,
          quantity: combo.Quantity,
        },
      ],
    });
  }

  changeQuantityProduct(product: OrderProduct, quantity: number) {
    (window as any).gtag(
      'event',
      quantity > 0 ? 'add_to_cart' : 'remove_from_cart',
      {
        currencyCode: 'NZD',
        items: [
          {
            item_name: product.Name,
            item_id: product.PLU,
            price: product.ItemPrice,
            item_category: product.Category,
            quantity: Math.abs(quantity),
          },
        ],
      }
    );
  }

  changeQuantityCombo(combo: OrderCombo, quantity: number) {
    (window as any).gtag(
      'event',
      quantity > 0 ? 'add_to_cart' : 'remove_from_cart',
      {
        currencyCode: 'NZD',
        items: [
          {
            item_name: combo.Name,
            item_id: combo.PLU,
            price: combo.ItemPrice,
            item_category: combo.Category,
            quantity: Math.abs(quantity),
          },
        ],
      }
    );
  }

  navigateToPayment(order: Order) {
    const dataLayerProducts = [];
    for (const combo of order.Combos) {
      dataLayerProducts.push({
        item_id: combo.PLU,
        item_name: combo.Name,
        price: combo.ItemPrice,
        quantity: combo.Quantity,
        item_category: combo.Category,
      });
    }
    for (const product of order.Products) {
      dataLayerProducts.push({
        item_id: product.PLU,
        item_name: product.Name,
        price: product.ItemPrice,
        quantity: product.Quantity,
        item_category: product.Category,
      });
    }
    (window as any).gtag('event', 'begin_checkout', {
      currencyCode: 'NZD',
      items: dataLayerProducts,
    });
  }

  orderSubmitted(order: Order) {
    const dataLayerProducts = [];

    order.Combos.forEach((combo) => {
      dataLayerProducts.push({
        item_id: combo.PLU,
        item_name: combo.Name,
        price: combo.Price,
        quantity: combo.Quantity,
        item_category: combo.Category ?? 'NULL CATEGORY', //TODO: Include Category in products and delete that product dictionary
      });
    });

    order.Products.forEach((product) => {
      dataLayerProducts.push({
        item_id: product.PLU,
        item_name: product.Name,
        price: product.Price,
        quantity: product.Quantity,
        item_category: product.Category ?? 'NULL CATEGORY', //TODO: Include Category in products and delete that product dictionary
      });
    });

    let couponCode = '';
    if (order.Coupons.length > 0) {
      couponCode = order.Coupons[0].Code;
    } else if (order.ComoRewards.length > 0) {
      couponCode = order.ComoRewards[0].code ?? order.ComoRewards[0].key;
    }

    (window as any).gtag('event', 'purchase', {
      currencyCode: 'NZD',
      transaction_id: order.IdSignature,
      value: order.Total,
      coupon: couponCode,
      items: dataLayerProducts,
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }
}
