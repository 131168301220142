import { EventEmitter, Injectable, OnDestroy } from '@angular/core';
import { EMPTY, Observable, of, Subscription } from 'rxjs';
import {
  catchError,
  filter,
  map,
  shareReplay,
  switchMap,
} from 'rxjs/operators';
import { ICouponClient } from '../clients/coupon-client';
import {
  BenefitAsset,
  BenefitsResponse,
} from '../models/domain/benefits-response';
import { Coupon, ProductList, ValueType } from '../models/domain/coupon';
import { DiscountResult } from '../models/domain/discount-result';
import { AppEvents } from '../models/domain/events/app-events';
import { Reward } from '../models/domain/reward';
import { Menu } from '../models/domain/menu';
import { Reward as OrderReward } from '../models/domain/order/reward';
import { Order } from '../models/domain/order/order';
import { OrderProduct } from '../models/domain/order/order-product';
import { EnvironmentVariables } from '../models/environment';
import {
  CouponModel,
  CouponRequirement,
  CouponRequirements,
} from '../models/view-models/coupon-model';
import { IRewardClient } from '../clients/reward-client';
import { PurchasableReward } from '../models/domain/purchasable-reward';
import { PurchaseRewardResult } from '../models/domain/response/purchase-reward-result';
import { HttpErrorResponse } from '@angular/common/http';
import { Product } from '../models/domain/product';
import { debounceTimeKeepLeading } from '../helpers/rxJsHelpers';
import { OrderCombo } from '../models/domain/order/order-combo';

import { MemberEvents } from '../models/domain/events/member-events';
import { OrderModifier } from '../models/domain/order/order-modifier';

@Injectable({
  providedIn: 'root',
})
export class CouponService implements OnDestroy {
  public couponModel: CouponModel = {
    Code: '',
    Error: null,
    Requirements: null,
    Loading: false,
  };

  private plusProducts: Record<number, { product: Product; name: string }> = {};
  private plusCategoryNames: { [plu: number]: string[] } = {};

  /**
   * `true if the rewards have previously been checked for the current order.
   * This is used to prevent the redeemable adding items automatically to the
   * cart continuously, only on application of the redeemable (or as soon as the
   * orderIdSignature is set).
   */
  private hasCheckedRewards = true;

  public purchasableRewards$: Observable<PurchasableReward[]>;

  /** Event that emits when we need to apply loyalty benefits to the order. */
  private getRewardBenefits$ = new EventEmitter<{
    order: Order;
    newlyApplied: boolean;
  }>();

  constructor(
    public couponClient: ICouponClient,
    public rewardClient: IRewardClient,
    public variables: EnvironmentVariables
  ) {
    for (const categoryName in variables.categoryPLUs) {
      const plu = variables.categoryPLUs[categoryName];
      if (!this.plusCategoryNames[plu]) {
        this.plusCategoryNames[plu] = [];
      }
      this.plusCategoryNames[plu].push(categoryName);
    }

    this.purchasableRewards$ = variables.loyaltyConfig.purchaseRewardsEnabled
      ? rewardClient.getPurchasableRewards().pipe(
          filter((result) => result.success),
          map((result) =>
            result.success === true ? result.purchasableRewards : []
          ),
          shareReplay(1)
        )
      : of([]);
  }

  subscriptions: Array<Subscription> = [
    AppEvents.CouponCodeEntered.subscribe((e) => {
      this.clearDiscounts(e.Order);
      this.getCouponCode(e.Code, e.Order, true);
    }),
    AppEvents.RewardApplied.subscribe((e) => {
      this.hasCheckedRewards = false;
      // If Order.IdSignature is null, OrderService will save the current order
      // and set the order IdSignature, which will emit an event to OrderIdSet
      // which is caught below.
      if (e.Order.IdSignature) {
        // Clear any existing applied coupons
        e.Order.Coupons = [];
        this.clearDiscounts(e.Order);
        this.getRewardBenefits(e.Order, true);
      }
    }),
    AppEvents.OrderIdSet.subscribe((o) => {
      this.clearDiscounts(o);
      this.applyCouponCodeToOrder(o, false);
      this.getRewardBenefits(o, !this.hasCheckedRewards);
    }),
    AppEvents.CartUpdated.subscribe((event) => {
      this.clearDiscounts(event.Order);
      this.applyCouponCodeToOrder(event.Order, false);
      this.getRewardBenefits(event.Order, false);
    }),
    AppEvents.StockUpdated.subscribe((menu) => {
      this.updateProductsFromPLUs(menu);
    }),
    AppEvents.MenuChanged.subscribe((menu) => {
      this.updateProductsFromPLUs(menu);
    }),
    AppEvents.PurchaseReward.subscribe((ev) => {
      this.purchaseReward(ev.purchasableReward);
    }),
    debounceTimeKeepLeading(500, this.getRewardBenefits$)
      .pipe(
        // If there is still a request in-flight when a new order comes in, the
        // in-flight request will be cancelled and only the new order will be
        // calculated
        switchMap(({ order, newlyApplied }) => {
          if (newlyApplied) {
            AppEvents.RewardLoading.next(true);
          }
          return this.couponClient.getBenefits(order).pipe(
            catchError((error: string) => {
              AppEvents.RewardLoading.next(false);
              AppEvents.RedeemableResult.emit({
                Success: false,
                Message: error || 'An unknown error occurred',
              });
              return EMPTY;
            }),
            map((res) => ({ order, newlyApplied, res }))
          );
        })
      )
      .subscribe(({ order, newlyApplied, res: res }) => {
        AppEvents.RewardLoading.next(false);
        if (res.Status == 'ok') {
          this.hasCheckedRewards = true;
          this.applyBenefitResponseToOrder(order, res, newlyApplied);
        } else {
          if (res.Errors && res.Errors.length > 0) {
            AppEvents.RedeemableResult.emit({
              Success: false,
              Message: res.Errors[0].Message,
              FirstApplication: newlyApplied,
            });
          } else {
            AppEvents.RedeemableResult.emit({
              Success: false,
              Message: 'Sorry, something has gone wrong',
              FirstApplication: newlyApplied,
            });
          }
        }
      }),
  ];

  private getCouponCode(
    code: string,
    order: Order,
    newlyApplied: boolean
  ): void {
    code = code.toUpperCase();
    this.couponClient
      .getCoupon(code)
      .pipe(
        catchError((error: string) => {
          AppEvents.RedeemableResult.emit({
            Success: false,
            Message: error || 'An unknown error occurred',
            FirstApplication: newlyApplied,
          });
          return EMPTY;
        })
      )
      .subscribe((c) => {
        const today = new Date();
        today.setHours(0, 0, 0, 0);

        if (new Date(c.ExpiryDate) < today) {
          AppEvents.RedeemableResult.emit({
            Success: false,
            Message: 'Coupon is expired',
            FirstApplication: newlyApplied,
          });
        } else if (new Date(c.StartDate) > today) {
          AppEvents.RedeemableResult.emit({
            Success: false,
            Message: 'Coupon is not yet active',
            FirstApplication: newlyApplied,
          });
        } else {
          AppEvents.RedeemableResult.emit({ Success: true });
          order.Coupons = [c];
          this.applyCouponCodeToOrder(order, newlyApplied);
        }
      });
  }

  /** Sends a request to the server to purchase a reward for the user. */
  private purchaseReward(purchasableReward: PurchasableReward): void {
    this.rewardClient
      .purchaseReward(purchasableReward)
      .pipe(
        catchError<PurchaseRewardResult, Observable<PurchaseRewardResult>>(
          (err: HttpErrorResponse) => {
            return of({
              FailureMessages: [
                `${err.status} ${err.statusText}: ${err.message}`,
              ],
              Success: false,
            });
          }
        )
      )
      .subscribe((res) => {
        if (res.Success === true) {
          AppEvents.RewardPurchased.emit({
            price: purchasableReward.Price,
            reward: res.PurchasedReward,
          });
        } else {
          AppEvents.RewardPurchaseFailed.emit({
            failureMessages: res.FailureMessages,
          });
        }
      });
  }

  private getRewardBenefits(order: Order, newlyApplied: boolean): void {
    //We must wait for the order to have an id before we send cart to como
    if (
      !order.IdSignature ||
      order.Coupons?.length > 0 ||
      order.IsGuestOrder ||
      !order.ComoRewards?.length
    ) {
      return;
    }
    let lineId = 1;
    order.Combos.forEach((combo) => {
      combo.LineId = lineId++;
    });
    order.Products.forEach((product) => {
      product.LineId = lineId++;

      product.ModifierGroups.forEach((group) =>
        group.Modifiers.forEach((modifier) => {
          modifier.LineId = lineId++;
        })
      );
    });
    order.ComoDeals.forEach((d) => {
      d.appliedAmount = 0;
    });
    order.ComoRewards.forEach((d) => {
      d.appliedAmount = 0;
    });

    this.getRewardBenefits$.emit({ order, newlyApplied });
  }

  private applyBenefitResponseToOrder(
    order: Order,
    benefits: BenefitsResponse,
    isNewlyApplied: boolean
  ): void {
    benefits.totalDiscountsSum = -benefits.totalDiscountsSum;
    if (order.ComoRewards)
      order.ComoDeals = (benefits.deals || []).map((d) => {
        return {
          name: d.name,
          code: d.code,
          key: d.key,
          appliedAmount: 0,
        };
      });

    const discountResult = new DiscountResult({
      Success: true,
      Amount: 0,
      Messages: [],
    });
    const assetsResult = this.applyRewardsToOrder(
      benefits.redeemAssets,
      order,
      order.ComoRewards,
      isNewlyApplied
    );

    if (!assetsResult) {
      return;
    }

    discountResult.Amount += assetsResult.Amount;
    if (!assetsResult.Success) {
      discountResult.Success = false;
      discountResult.Messages.push(...assetsResult.Messages);
      discountResult.Requirements = assetsResult.Requirements;
    }
    const dealsResult = this.applyRewardsToOrder(
      benefits.deals,
      order,
      order.ComoDeals,
      isNewlyApplied
    );

    if (!dealsResult) {
      return;
    }

    discountResult.Amount += dealsResult.Amount;
    if (!dealsResult.Success) {
      discountResult.Success = false;
      discountResult.Messages.push(...dealsResult.Messages);
    }

    if (discountResult.Requirements && isNewlyApplied) {
      const productsToAddToOrder = this.getItemsToAddToOrder(
        discountResult.Requirements
      );

      if (
        productsToAddToOrder.length > 0 &&
        productsToAddToOrder.length ===
          discountResult.Requirements.missingDiscounted.length +
            discountResult.Requirements.missingRequired.length
      ) {
        AppEvents.AddProducts.emit({
          products: productsToAddToOrder,
        });
        return;
      }
    }

    order.Discount += parseFloat((discountResult.Amount / 100).toFixed(2));
    order.Total = parseFloat((order.SubTotal - order.Discount).toFixed(2));
    //Todo: reduce duplication between here and order.service
    order.PointsEarned = parseFloat(
      (order.Total * this.variables.loyaltyConfig.pointRatio).toFixed(2)
    );

    if (discountResult.Success) {
      AppEvents.RedeemableResult.emit({ Success: true });
    } else if (discountResult.Messages?.[0]) {
      //Todo: concatenate errors if we allow multiple discounts
      // If there is a message then display it
      AppEvents.RedeemableResult.emit({
        Success: false,
        Message: discountResult.Messages[0],
        Requirements: discountResult.Requirements,
        FirstApplication: isNewlyApplied,
      });
    } else if (
      (discountResult.Requirements?.missingRequired?.length &&
        discountResult.Requirements.missingRequired.some(
          (missing) => !missing.length
        )) ||
      (discountResult.Requirements?.missingDiscounted?.length &&
        discountResult.Requirements.missingDiscounted.every(
          (missing) => !missing.length
        ))
    ) {
      /*
       * If any of the required items or all of the discounted items are not
       * valid (i.e. an empty array of items), then the coupon is not redeemable
       * at the store. It is missing items on its menu that are needed for the
       * coupon
       */
      AppEvents.RedeemableResult.emit({
        Success: false,
        Message: `Sorry, this ${this.variables.loyaltyConfig.rewardsName} cannot be used at this store.`,
        FirstApplication: isNewlyApplied,
      });
    } else if (
      discountResult.Requirements?.missingDiscounted?.length ||
      discountResult.Requirements?.missingRequired?.length
    ) {
      // There are some requirements missing so don't set the message, it will
      // be generated from the missing requirements
      AppEvents.RedeemableResult.emit({
        Success: false,
        Message: '',
        Requirements: discountResult.Requirements,
        FirstApplication: isNewlyApplied,
      });
    } else {
      // There are no requirements missing, and no message, so set a generic message
      AppEvents.RedeemableResult.emit({
        Success: false,
        Message: 'Sorry, something went wrong',
        FirstApplication: isNewlyApplied,
        Requirements: discountResult.Requirements,
      });
    }
  }

  private applyRewardsToOrder(
    benefits: Array<BenefitAsset>,
    order: Order,
    deals: Array<OrderReward>,
    isNewlyApplied: boolean
  ): DiscountResult {
    const discountResult = new DiscountResult({
      Success: true,
      Amount: 0,
      Messages: [],
    });

    const missingDiscounted: CouponRequirement[][] = [];
    const missingRequired: CouponRequirement[][] = [];

    if (!benefits) {
      return discountResult;
    }
    let appliedDiscountSum = 0;
    for (const ra of benefits) {
      if (!ra.benefits) {
        let message = ra.nonRedeemableCause?.message;
        if (ra.nonRedeemableCause?.code === '5510') {
          // "Asset is locked for redemption. Parallel request was received from another source"
          message = `This ${this.variables.loyaltyConfig.rewardsName} is currently applied to another order. Try again in ${this.variables.loyaltyConfig.usedRewardLockTime}.`;
        }
        if (
          ra.nonRedeemableCause?.code === '5523' &&
          MemberEvents.CurrentMember?.value?.Tags?.includes('BITECLUBNOREDEEM')
        ) {
          // "Asset is locked for redemption. Parallel request was received from another source"
          message = `This ${this.variables.loyaltyConfig.rewardsName} cannot be redeemed as you have exceeded your staff redemptions for today. Please try again tomorrow.`;
        }
        AppEvents.RedeemableResult.emit({
          Success: false,
          Message: message,
          FirstApplication: isNewlyApplied,
        });
        return;
      }
      const orderBenefit = deals.find(
        (cr) => cr.code == ra.code || cr.key == ra.key
      );
      if (orderBenefit != null) {
        orderBenefit.appliedAmount = 0;
      }
      const discountedItems: (OrderProduct | OrderCombo | OrderModifier)[] = [];
      let benefitMessage = null;
      for (const rab of ra?.benefits || []) {
        if (rab.type == 'discount') {
          for (const ed of rab.extendedData || []) {
            ed.discount = -ed.discount;
            const appliedCombo = order.Combos.find(
              (p) => p.LineId == ed.item.lineId
            );
            const appliedProduct = order.Products.find(
              (p) => p.LineId == ed.item.lineId
            );
            let modifiers: OrderModifier[] = [];
            order.Products.forEach((product) => {
              product.ModifierGroups.forEach((group) => {
                modifiers = modifiers.concat(group.Modifiers);
              });
            });
            const appliedModifier = modifiers.find(
              (m) => m.LineId == ed.item.lineId
            );

            if (!appliedCombo && !appliedProduct && !appliedModifier) {
              continue;
            }

            if (appliedCombo) {
              let appliedDiscount = ed.discount;
              if (appliedDiscount > appliedCombo.TotalPrice * 100) {
                appliedDiscount = appliedCombo.TotalPrice * 100;
              }
              appliedDiscount = parseInt(appliedDiscount.toFixed(0), 10);
              appliedCombo.Discount += appliedDiscount / 100;
              appliedCombo.TotalPrice = parseFloat(
                (appliedCombo.SubTotal - appliedCombo.Discount).toFixed(2)
              );

              appliedDiscountSum += appliedDiscount;
              if (orderBenefit != null) {
                orderBenefit.appliedAmount += appliedDiscount;
              }

              discountedItems.push(appliedCombo);
            } else if (appliedProduct) {
              let appliedDiscount = ed.discount;
              if (appliedDiscount > appliedProduct.TotalPrice * 100) {
                appliedDiscount = appliedProduct.TotalPrice * 100;
              }
              appliedDiscount = parseInt(appliedDiscount.toFixed(0), 10);
              appliedProduct.Discount += appliedDiscount / 100;
              appliedProduct.TotalPrice = parseFloat(
                (appliedProduct.SubTotal - appliedProduct.Discount).toFixed(2)
              );

              appliedDiscountSum += appliedDiscount;
              if (orderBenefit != null) {
                orderBenefit.appliedAmount += appliedDiscount;
              }

              discountedItems.push(appliedProduct);
            } else {
              // For modifiers, add the discount to the product so it displays
              // nicely in the cart
              const parentProduct = order.Products.filter(
                (p) => p.LineId < appliedModifier.LineId
              )
                .sort((p) => p.LineId)
                .pop();
              let appliedDiscount = ed.discount;
              if (appliedDiscount > parentProduct.TotalPrice * 100) {
                appliedDiscount = parentProduct.TotalPrice * 100;
              }
              appliedDiscount = parseInt(appliedDiscount.toFixed(0), 10);
              parentProduct.Discount += appliedDiscount / 100;
              parentProduct.TotalPrice = parseFloat(
                (parentProduct.ItemPrice - parentProduct.Discount).toFixed(2)
              );

              appliedDiscountSum += appliedDiscount;
              if (orderBenefit != null) {
                orderBenefit.appliedAmount += appliedDiscount;
              }

              discountedItems.push(parentProduct);
            }
          }
        } else if (rab.type == 'itemCode' && !discountedItems.length) {
          // Custom error returned from Como, only use itemcode if it
          // the only rab present, i.e. it is not accompanied by a discount
          benefitMessage = rab.code;
        }

        if (ra) {
          const redeemable: Redeemable = {
            redeemableType: 'reward',
            reward: {
              Description: '',
              Image: null,
              Key: ra.key,
              Name: ra.name,
              Redeemable: ra.redeemable,
            },
            discountedProducts: ra.DiscountedProducts ?? [],
            requiredProducts: ra.RequiredProducts ?? [],
          };

          const applyRedeemableResult = this.applyRedeemable(
            redeemable,
            order,
            []
          );

          for (const item of applyRedeemableResult.missingDiscounted) {
            missingDiscounted.push(item);
          }
          for (const item of applyRedeemableResult.missingRequired) {
            missingRequired.push(item);
          }
        }
      }

      if (!discountedItems.length) {
        discountResult.Success = false;
        if (benefitMessage) {
          discountResult.Messages.push(benefitMessage);
        }
      }
    }

    discountResult.Amount = appliedDiscountSum;
    discountResult.Requirements = {
      missingDiscounted,
      missingRequired,
    };
    return discountResult;
  }

  private applyCouponCodeToOrder(order: Order, newlyApplied: boolean): void {
    if (order.Coupons.length == 0) {
      return;
    }
    if (order.Coupons.length > 1) {
      // Log an error and continue applying the first coupon
      console.error('Applying more than one coupon is not supported');
    }

    const coupon = order.Coupons[0];

    if (!coupon.ValidStores.includes(order.Store.Name)) {
      AppEvents.RedeemableResult.emit({
        Success: false,
        Message: 'This voucher is not valid at this store',
        FirstApplication: newlyApplied,
      });
      return;
    }

    if (
      !Object.values(this.plusProducts).length &&
      !Object.values(this.plusCategoryNames).length
    ) {
      AppEvents.RedeemableResult.emit({
        Success: false,
        Message: 'This voucher is not valid for this menu',
        FirstApplication: newlyApplied,
      });
      return;
    }

    if (!coupon.ValidStores.includes(order.Store.Name)) {
      AppEvents.RedeemableResult.emit({
        Success: false,
        Message: 'This voucher is not valid at this store',
        FirstApplication: newlyApplied,
      });
      return;
    }

    let discountProducts: OrderProduct[] = [];
    let applyRedeemableResult: CouponRequirements;

    const redeemable: Redeemable = {
      redeemableType: coupon.CodeBlueType ? 'codeblue' : 'coupon',
      coupon,
      discountedProducts: coupon.DiscountedProducts,
      requiredProducts: coupon.RequiredProducts,
    };

    if (coupon.CodeBlueType) {
      const { codeBlueDiscountProduct, missingProducts, errorMessage } =
        this.applyCodeBlue(coupon, order);
      if (errorMessage) {
        AppEvents.RedeemableResult.emit({
          Success: false,
          Message: errorMessage,
          FirstApplication: newlyApplied,
        });
        return;
      }
      applyRedeemableResult = missingProducts;
      if (codeBlueDiscountProduct) {
        discountProducts = [codeBlueDiscountProduct];
      }
    } else {
      applyRedeemableResult = this.applyRedeemable(
        redeemable,
        order,
        discountProducts
      );
      if (
        (applyRedeemableResult.missingRequired.length &&
          applyRedeemableResult.missingRequired.some(
            (requirement) => !requirement.length
          )) ||
        (applyRedeemableResult.missingDiscounted.length &&
          applyRedeemableResult.missingDiscounted.every(
            (requirement) => !requirement.length
          ))
      ) {
        // If any of the sets of required items, or all the sets of discounted items
        // are missing from the menu, then we don't have the items necessary to
        // fulfil the redeemable
        AppEvents.RedeemableResult.emit({
          Success: false,
          Message: "Sorry, we can't accept this coupon right now.",
          FirstApplication: newlyApplied,
        });
        return;
      }
    }

    const productsToAddToOrder = this.getItemsToAddToOrder(
      applyRedeemableResult
    );

    if (
      productsToAddToOrder.length > 0 &&
      productsToAddToOrder.length ===
        applyRedeemableResult.missingDiscounted.length +
          applyRedeemableResult.missingRequired.length &&
      newlyApplied
    ) {
      // Add products when all the missing sets have only 1 option
      AppEvents.AddProducts.emit({
        products: productsToAddToOrder,
      });
      return;
    }

    //Customer must add all required products, but only some discountable products
    if (discountProducts.length == 0) {
      AppEvents.RedeemableResult.emit({
        Success: false,
        Message: null,
        Requirements: applyRedeemableResult,
        FirstApplication: newlyApplied,
      });
    } else if (applyRedeemableResult.missingRequired.length > 0) {
      AppEvents.RedeemableResult.emit({
        Success: false,
        Message: null,
        Requirements: applyRedeemableResult,
        FirstApplication: newlyApplied,
      });
    } else {
      // The coupon is valid
      this.applyCouponDiscount(order, discountProducts, redeemable.coupon);
      AppEvents.RedeemableResult.emit({ Success: true });
    }
  }

  private getItemsToAddToOrder(
    redeemableRequirements: CouponRequirements
  ): OrderProduct[] {
    const allMissingProducts = [
      ...redeemableRequirements.missingDiscounted,
      ...redeemableRequirements.missingRequired,
    ];

    const productsToAddToOrder: OrderProduct[] = [];

    for (const products of allMissingProducts) {
      // Add a product automatically when it is a product that has no other
      // option available and it has no modifiers.
      if (
        products.length === 1 &&
        products[0].itemType === 'Product' &&
        products[0].product &&
        !products[0].product.ModifierGroups.length
      ) {
        productsToAddToOrder.push(
          OrderProduct.FromProduct(products[0].product)
        );
      }
    }

    return productsToAddToOrder;
  }

  private applyRedeemable(
    redeemable: Redeemable,
    order: Order,
    discountProducts: OrderProduct[]
  ): CouponRequirements {
    const productUsesInRedeemable = new Map<OrderProduct, number>();
    order.Products.forEach((p) => {
      productUsesInRedeemable.set(p, 0);
    });
    const missingRequirementOptions: CouponRequirement[][] = [];
    const missingDiscountOptions: CouponRequirement[][] = [];

    for (const discountedProduct of redeemable.discountedProducts) {
      let hasRequirementNotOnMenu = false;
      const productDiscountOptions: CouponRequirement[] = [];
      let discountable: OrderProduct = null;
      // Find a product that matches the criteria for this discountable
      for (const orderProduct of order.Products) {
        const categoryPLU = this.variables.categoryPLUs[orderProduct.Category];
        if (
          discountedProduct.AllowedCategories.indexOf(categoryPLU) > -1 ||
          discountedProduct.AllowedProducts.indexOf(orderProduct.PLU) > -1
        ) {
          if (
            productUsesInRedeemable.get(orderProduct) < orderProduct.Quantity
          ) {
            if (
              redeemable.redeemableType === 'coupon' &&
              redeemable.coupon.DiscountCheapest == true
            ) {
              if (
                discountable == null ||
                discountable.BasePrice > orderProduct.BasePrice
              ) {
                discountable = orderProduct;
              }
            } else {
              if (
                discountable == null ||
                discountable.BasePrice < orderProduct.BasePrice
              ) {
                discountable = orderProduct;
              }
            }
          }
        }
      }

      if (discountable != null) {
        discountProducts.push(discountable);

        productUsesInRedeemable.set(
          discountable,
          productUsesInRedeemable.get(discountable) + 1
        );
      } else {
        for (const allowedCategory of discountedProduct.AllowedCategories.filter(
          (x) => x
        )) {
          if (this.plusCategoryNames[allowedCategory]) {
            this.plusCategoryNames[allowedCategory].forEach((categoryName) => {
              productDiscountOptions.push({
                plu: allowedCategory,
                name: categoryName,
                itemType: 'Category',
              });
            });
          } else {
            hasRequirementNotOnMenu = true;
          }
        }
        for (const allowedProduct of discountedProduct.AllowedProducts.filter(
          (x) => x
        )) {
          if (this.plusProducts[allowedProduct]) {
            productDiscountOptions.push({
              plu: allowedProduct,
              name: this.plusProducts[allowedProduct].name,
              itemType: 'Product',
              product: this.plusProducts[allowedProduct].product,
            });
          } else {
            hasRequirementNotOnMenu = true;
          }
        }
      }

      if (productDiscountOptions.length || hasRequirementNotOnMenu) {
        missingDiscountOptions.push(productDiscountOptions);
      }
    }

    for (const requiredProduct of redeemable.requiredProducts) {
      let hasRequirementNotOnMenu = false;
      let requireProductFound: OrderProduct = null;
      const productRequiredOptions: CouponRequirement[] = [];
      for (const orderProduct of order.Products) {
        if (
          requiredProduct.AllowedCategories.indexOf(
            this.variables.categoryPLUs[orderProduct.Category]
          ) > -1 ||
          requiredProduct.AllowedProducts.indexOf(orderProduct.PLU) > -1
        ) {
          if (
            productUsesInRedeemable.get(orderProduct) < orderProduct.Quantity
          ) {
            if (
              redeemable.redeemableType === 'coupon' &&
              redeemable.coupon.DiscountCheapest == true
            ) {
              //Find most expensive to use as requirement
              if (
                requireProductFound == null ||
                requireProductFound.BasePrice < orderProduct.BasePrice
              ) {
                requireProductFound = orderProduct;
              }
            } else {
              if (
                requireProductFound == null ||
                requireProductFound.BasePrice > orderProduct.BasePrice
              ) {
                requireProductFound = orderProduct;
              }
            }
          }
        }
      }

      if (requireProductFound == null) {
        for (const allowedCategory of requiredProduct.AllowedCategories) {
          if (this.plusCategoryNames[allowedCategory]) {
            this.plusCategoryNames[allowedCategory].forEach((categoryName) => {
              productRequiredOptions.push({
                plu: allowedCategory,
                name: categoryName,
                itemType: 'Category',
              });
            });
          } else {
            hasRequirementNotOnMenu = true;
          }
        }
        for (const allowedProduct of requiredProduct.AllowedProducts) {
          if (this.plusProducts[allowedProduct]) {
            productRequiredOptions.push({
              plu: allowedProduct,
              name: this.plusProducts[allowedProduct].name,
              itemType: 'Product',
              product: this.plusProducts[allowedProduct].product,
            });
          } else {
            hasRequirementNotOnMenu = true;
          }
        }
      } else {
        productUsesInRedeemable.set(
          requireProductFound,
          productUsesInRedeemable.get(requireProductFound) + 1
        );
      }

      if (productRequiredOptions.length || hasRequirementNotOnMenu) {
        missingRequirementOptions.push(productRequiredOptions);
      }
    }

    return {
      missingDiscounted: missingDiscountOptions,
      missingRequired: missingRequirementOptions,
    };
  }

  private applyCodeBlue(
    coupon: Coupon,
    order: Order
  ): {
    errorMessage?: string;
    codeBlueDiscountProduct?: OrderProduct;
    missingProducts: CouponRequirements;
  } {
    const discountableProducts: OrderProduct[] = [];
    const menuCategoryPLUs = this.variables.categoryPLUs;
    let allowedCategoryPLUForCodeBlueDiscount =
      menuCategoryPLUs[coupon.CodeBlueType];

    //Hardcoding the categories PLU checks for now to fix burgerfuel.
    //Remove this once we are getting checking categories properly (from the DB)
    if (!allowedCategoryPLUForCodeBlueDiscount) {
      if (coupon.CodeBlueType.toLowerCase() === 'burger') {
        allowedCategoryPLUForCodeBlueDiscount = 2004;
      }
      if (
        coupon.CodeBlueType.toLowerCase() === 'side' ||
        coupon.CodeBlueType.toLowerCase() === 'fries'
      ) {
        allowedCategoryPLUForCodeBlueDiscount = 2038;
      }
    }

    const codeBlueDiscountCategories = [];
    codeBlueDiscountCategories.push(allowedCategoryPLUForCodeBlueDiscount);

    //No matching Code Blue to Menu's Category PLU
    if (!codeBlueDiscountCategories) {
      return {
        errorMessage:
          'Could not find any ' +
          coupon.CodeBlueType.toLowerCase() +
          's on the menu',
        missingProducts: {
          missingDiscounted: [],
          missingRequired: [],
        },
      };
    }

    const categoryNamesForCodeBluePLU: string[] = [];
    for (const [categoryName, PLU] of Object.entries(menuCategoryPLUs)) {
      if (PLU === allowedCategoryPLUForCodeBlueDiscount) {
        categoryNamesForCodeBluePLU.push(categoryName);
      }
    }

    //Get products that matches the criteria for this code blue discountable
    for (const orderProduct of order.Products) {
      const orderedProductCategoryPLU = menuCategoryPLUs[orderProduct.Category];
      if (codeBlueDiscountCategories.includes(orderedProductCategoryPLU)) {
        if (orderProduct.BasePrice > 0) {
          discountableProducts.push(orderProduct);
        }
      }
    }

    //Get 1 product for the Code Blue discount
    const codeBlueDiscountProduct = discountableProducts.sort(
      (a, b) => a.BasePrice - b.BasePrice
    )[0];

    if (codeBlueDiscountProduct) {
      return {
        codeBlueDiscountProduct,
        missingProducts: {
          missingDiscounted: [],
          missingRequired: [],
        },
      };
    } else {
      //no discount matched from ordered product
      return {
        missingProducts: {
          missingDiscounted: [
            categoryNamesForCodeBluePLU.map((name) => {
              return {
                name: name,
                plu: allowedCategoryPLUForCodeBlueDiscount,
                itemType: 'Category',
              };
            }),
          ],
          missingRequired: [],
        },
      };
    }
  }

  private applyCouponDiscount(
    order: Order,
    discountedProducts: Array<OrderProduct>,
    coupon: Coupon
  ): void {
    discountedProducts.forEach((p) => {
      //Applying the discount to this product
      let totalProductDiscountablePrice = p.BasePrice;

      if (coupon.ValueType == ValueType.Percent) {
        if (coupon.IncludeModifiersInDiscount) {
          //Get total cost of product's selected modifiers that is valid for discount
          const totalCostOfModifiersValidForDiscount =
            this.getTotalCostOfModifiersValidForDiscount(coupon, p);
          //Getting the total prodct cost that's valid for discount
          totalProductDiscountablePrice += totalCostOfModifiersValidForDiscount;
        }
        //Value will be a percent of the total
        p.Discount += (totalProductDiscountablePrice * coupon.Value) / 100;
      } else {
        //flat rate discount ($) - (no modifiers limits)
        if (coupon.IncludeModifiersInDiscount) {
          totalProductDiscountablePrice = p.ItemPrice;
        }
        p.Discount += Math.min(totalProductDiscountablePrice, coupon.Value);
      }
    });

    //Products with multiple quantities could be discounted multiple times.
    //Calculate all discounts before calculating total discount
    order.Products.forEach((p) => {
      p.TotalPrice = p.SubTotal - p.Discount;
      order.Discount += p.Discount;
    });
    order.Total = order.SubTotal - order.Discount;
    //Todo: reduce duplication between here and order.service
    order.PointsEarned = parseFloat(
      (order.Total * this.variables.loyaltyConfig.pointRatio).toFixed(2)
    );
  }

  private updateProductsFromPLUs(menu: Menu): void {
    const categoryProducts: Record<string, Product[]> = {};
    const plusProducts = {};
    const plusCategoryNames = {};

    if (!menu) {
      return;
    }

    for (const productId in menu.ProductDictionary) {
      if (
        Object.prototype.hasOwnProperty.call(menu.ProductDictionary, productId)
      ) {
        const product = menu.ProductDictionary[productId];
        if (product.OutOfStock) {
        }
        if (product && !product.OutOfStock) {
          plusProducts[product.PLU] = {
            product,
            name: product.DisplayName || product.Name || product.PLU.toString(),
          };
        }
      }
    }

    // Get all the categories that have in-stock products
    for (const category of menu.Categories) {
      for (const product of category.Products) {
        if (product.OutOfStock) {
          continue;
        }
        // Add the product to the category it's in.
        if (!categoryProducts[category.Name]) {
          categoryProducts[category.Name] = [];
        }
        categoryProducts[category.Name].push(product);
      }
    }

    // For all the categories with in-stock products, add them to the PLU-CategoryName lookup
    for (const [categoryName, products] of Object.entries(categoryProducts)) {
      if (!products.length || !categoryName) {
        continue;
      }
      const plu = this.variables.categoryPLUs[categoryName];
      if (!plusCategoryNames[plu]) {
        plusCategoryNames[plu] = [];
      }
      plusCategoryNames[plu].push(categoryName);
    }

    this.plusProducts = plusProducts;
    this.plusCategoryNames = plusCategoryNames;
  }

  private clearDiscounts(order: Order): void {
    this.couponModel.Error = '';
    order.Combos.forEach((c) => {
      c.Discount = 0;
      c.TotalPrice = c.SubTotal;
    });
    order.Products.forEach((p) => {
      p.Discount = 0;
      p.TotalPrice = p.SubTotal;
    });
    order.Discount = 0;
    order.Total = order.SubTotal;
    //Todo: reduce duplication between here and order.service
    order.PointsEarned = parseFloat(
      (order.Total * this.variables.loyaltyConfig.pointRatio).toFixed(2)
    );
  }

  //Getting the total cost of modifiers that's valid for the discount
  //The modifiers are a product that is valid for discount
  private getTotalCostOfModifiersValidForDiscount(
    coupon: Coupon,
    productValidForDiscount: OrderProduct
  ) {
    let totalCostOfModifiersValidForDiscount = 0;
    if (coupon.IncludeModifiersInDiscount) {
      if (productValidForDiscount.ModifierGroups.length > 0) {
        //Getting the modifiers from the product that is valid for discount
        //(excluding the free modifiers & included modifiers)
        const modifiersOrderedFromDiscountProduct =
          productValidForDiscount.ModifierGroups.map((mg) =>
            mg.Modifiers.filter(
              (mod) => mod.Selected && mod.ItemPrice > 0 && !mod.Included
            )
          ).reduce((m1, m2) => {
            return m1.concat(m2);
          });

        //Ordering the Modifiers by price based on the coupon setting
        if (coupon.DiscountCheapest) {
          //Sort by from low -> high price
          modifiersOrderedFromDiscountProduct.sort(
            (low, high) => low.ItemPrice - high.ItemPrice
          );
        } else {
          //Sort by from high -> low price
          modifiersOrderedFromDiscountProduct.sort(
            (low, high) => high.ItemPrice - low.ItemPrice
          );
        }

        //Current giving discounts to 2 modifiers max
        //Getting the 2 modifiers that's valid for the discount
        const modifiersValidForDiscount =
          modifiersOrderedFromDiscountProduct.slice(0, 2);

        totalCostOfModifiersValidForDiscount = modifiersValidForDiscount
          .map((mod) => mod.ItemPrice)
          .reduce((partialSum, a) => partialSum + a, 0);
      }
    }
    return totalCostOfModifiersValidForDiscount;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }
}

type Redeemable = {
  requiredProducts: Array<ProductList>;
  discountedProducts: Array<ProductList>;
} & (
  | {
      redeemableType: 'coupon' | 'codeblue';
      coupon: Coupon;
    }
  | {
      redeemableType: 'reward';
      reward: Reward;
    }
);
