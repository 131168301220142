import { Component, OnDestroy, ViewChild } from '@angular/core';
import { GoogleMap } from '@angular/google-maps';
import { BehaviorSubject, combineLatest, ReplaySubject } from 'rxjs';
import { delay, filter, map, retryWhen, startWith, take } from 'rxjs/operators';
import { AppEvents } from 'src/app/models/domain/events/app-events';
import { MemberEvents } from 'src/app/models/domain/events/member-events';
import { NavigationEvents } from 'src/app/models/domain/events/navigation-events';
import { NavigationPage } from 'src/app/models/domain/navigation-page';
import { Store } from 'src/app/models/domain/store';
import { EnvironmentVariables } from 'src/app/models/environment';
import { OrderService } from 'src/app/services/order.service';

@Component({
  selector: 'app-locations',
  templateUrl: './locations.component.html',
  styleUrls: ['./locations.component.scss'],
})
export class LocationsComponent implements OnDestroy {
  public stores: Array<Store>;
  regions = new Array<string>();
  IsTableOrder = this.orderService.order?.IsTableOrder;
  inApp = false;

  @ViewChild('storesMap') map: GoogleMap;

  currentRegion: string;

  currentLocation: google.maps.LatLngLiteral;
  center: google.maps.LatLngLiteral;
  options: google.maps.MapOptions;
  mapMarkerConfig: google.maps.MarkerOptions;
  showLabelText = false;
  /**
   * A zoom level that can see enough of the surrounding area for context.
   *
   * 1 is zoomed out - whole world visible.
   * 24 is fully zoomed in.
   * 16 seems to be about a 1km radius.
   */
  private minZoomLevelOnRegionSelect = 16;

  /**
   * `true` when the maps library has loaded and the map should be rendered in
   * the HTML
   */
  public isMapsInitialised$ = new BehaviorSubject(false);
  /** Emits when a regions gets set for the map to display. */
  private regionSet = new ReplaySubject<void>();

  subscriptions = [
    AppEvents.CurrentLocation.pipe(
      // Start with the center of New Zealand as the centre of the map
      startWith({
        lat: -41,
        lng: 174,
      } as google.maps.LatLngLiteral)
    ).subscribe((location) => {
      this.currentLocation = location;
    }),

    // Initialise the map when the stores and the maps library have loaded
    combineLatest([
      AppEvents.Stores.pipe(filter((stores) => !!stores?.length)),
      AppEvents.MapLoaded.pipe(filter((isLoaded) => isLoaded)),
    ]).subscribe(([stores]) => {
      this.stores = stores;
      this.initOptions();
      // Update map to show all stores
      this.isMapsInitialised$.next(true);
      this.checkCurrentRegion();
    }),

    // Set the bounds as soon as `this.map` is initialised
    combineLatest([
      this.regionSet,
      this.isMapsInitialised$.pipe(filter((init) => init)),
    ])
      .pipe(
        map(() => this.displayStores()),
        // Try again every 100ms up to 5 seconds until `this.map` has loaded
        retryWhen((errors) => errors.pipe(delay(100), take(50)))
      )
      .subscribe(),

    MemberEvents.InApp.subscribe((i) => {
      this.inApp = i;
    }),
  ];

  constructor(
    public orderService: OrderService,
    public variables: EnvironmentVariables
  ) {}

  /**
   * Initialises the google maps options objects. This is used rather than
   * properties because the `google.maps` library loads in asynchronously, and
   * accessing some google maps properties before it has loaded causes errors.
   */
  private initOptions(): void {
    const styles =
      this.variables.mapConfig.features.map<google.maps.MapTypeStyle>(
        (feature) => {
          const styleSet: google.maps.MapTypeStyle = {
            featureType: feature.featureType,
            stylers: [],
          };
          for (const [style, value] of Object.entries(feature.stylers)) {
            styleSet.stylers.push({
              [style]: value,
            });
          }
          return styleSet;
        }
      );

    this.options = {
      zoomControlOptions: {
        position: google.maps.ControlPosition.LEFT_TOP,
      },
      mapTypeControl: false,
      streetViewControl: false,
      fullscreenControl: false,
      scaleControl: false,
      clickableIcons: false,
      styles: styles,
    } as google.maps.MapOptions;

    const { iconWidth, iconHeight } = this.variables.mapConfig.mapMarkers;

    this.mapMarkerConfig = {
      icon: {
        url: 'assets/' + this.variables.brandPath + '/location.png',
        scaledSize: { width: iconWidth, height: iconHeight, equals: null },
        labelOrigin: { x: iconWidth / 2, y: iconHeight + 6, equals: null },
      },
    };
  }

  /**
   * Sets the current region to the city of the selected store, and updates the
   * view on the map.
   */
  private checkCurrentRegion(): void {
    if (!this.stores) {
      return;
    }
    this.regions = this.stores
      .map((s) => s.City)
      // Get an array of unique cities
      .filter((city, index, allCities) => allCities.indexOf(city) === index)
      .sort((a, b) => (a < b ? -1 : 1));

    if (this.orderService.order?.Store) {
      this.currentRegion = this.orderService.order.Store.City;
    } else {
      this.currentRegion = this.stores[0].City;
    }
    this.regionSelected();
  }

  /**
   * Called when a new region is selected to display the region when next
   * possible.
   */
  public regionSelected(): void {
    this.regionSet.next();
  }

  /**
   * Updates the view on the map to show only the stores in the selected region,
   * or all stores if there is no region selected.
   */
  private displayStores(): void {
    if (!this.isMapsInitialised$.getValue()) {
      return;
    }
    // if(this.orderService.order?.Store
    //   && this.currentRegion == this.orderService.order.Store.City){
    //     this.center = {
    //       lat: this.orderService.order?.Store.Latitude,
    //       lng: this.orderService.order?.Store.Longitude,
    //     };
    // }else{
    // const store = ;
    // this.center = {
    //   lat: store.Latitude,
    //   lng: store.Longitude,
    // };
    const bounds = new google.maps.LatLngBounds();

    // Extend the bounds to all the cities in the region/country
    this.stores
      .filter((s) => !this.currentRegion || s.City == this.currentRegion)
      .forEach((s) =>
        bounds.extend(new google.maps.LatLng(s.Latitude, s.Longitude))
      );

    if (this.map) {
      this.map.fitBounds(bounds);
      if (this.map.getZoom() > this.minZoomLevelOnRegionSelect) {
        this.map.googleMap?.setZoom(this.minZoomLevelOnRegionSelect);
      }
      this.onZoomChange();
    } else {
      throw new Error('Maps not initialised');
    }
  }

  /**
   * Navigates to the order page and selects the store automatically.
   */
  orderFromStore(store: Store): void {
    AppEvents.ChangeStore.emit({
      Store: store,
      Order: null,
      PreventSalesTypeChange: false,
    });
    NavigationEvents.NavigateToPage.emit(NavigationPage.OrderMainMenu);
  }

  /**
   * Provides directions to the `store` to the user.
   */
  getDirections(store: Store): void {
    let storeDirections =
      'https://www.google.com/maps/place/' +
      encodeURIComponent(store.Address) +
      ', ' +
      store.Suburb;

    //if store address does not start with a number, use the lat and long
    if (!store.Address[0].match(/^\d/)) {
      storeDirections =
        'https://www.google.com/maps?q=' +
        encodeURIComponent(store.Latitude + ',' + store.Longitude);
    }

    window.open(storeDirections, '_blank');
  }

  onZoomChange(): void {
    this.showLabelText = this.map.getZoom() >= 10;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => {
      s.unsubscribe();
    });
  }
}
