import { Component, Input, OnDestroy } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { NavigationEvents } from 'src/app/models/domain/events/navigation-events';
import { Store, WeekOpenTime } from 'src/app/models/domain/store';
import { ModalComponent } from '../../models/view-models/modal-component';

@Component({
  selector: 'app-store-info-modal',
  templateUrl: './store-info-modal.component.html',
  styleUrls: ['./store-info-modal.component.scss'],
})
export class StoreInfoModalComponent
  extends ModalComponent<Store>
  implements OnDestroy
{
  @Input() store: Store;
  @Input() orderSaleType: string | undefined;
  @Input() storeOpenTimes: ReadonlyArray<WeekOpenTime> | undefined;

  subscriptions = [
    NavigationEvents.MenuOpening.subscribe(() => {
      this.menuOpening();
    }),
  ];

  constructor(modalController: ModalController) {
    super(modalController);
  }

  back(): void {
    this.dismiss(undefined);
  }

  selectStore(store: Store, event: MouseEvent): void {
    this.dismiss(store);
    if (event) {
      event.stopPropagation();
    }
  }

  menuOpening(): void {
    this.dismiss(undefined);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }
}
