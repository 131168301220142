import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { GoogleMapsModule } from '@angular/google-maps';

import { IonicModule } from '@ionic/angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ComponentsModule } from './components/components.module';
import { ContainersModule } from './containers/containers.module';
import { IStoreClient } from './clients/store-client';
import { environment } from 'src/environments/environment';
import { IMenuClient } from './clients/menu-client';
import { IMemberClient } from './clients/member-client';
import { IOrderClient } from './clients/order-client';
import { IMemberService, MemberService } from './services/member.service';
import { EnvironmentVariables } from './models/environment';
import { BrMaskerModule } from 'br-mask';
import { PixelModule } from 'ngx-pixel';
import { CouponClient, ICouponClient } from './clients/coupon-client';
import { IRewardClient } from './clients/reward-client';
import { IConfigClient } from './clients/config-client';
import { ConfigService, IConfigService } from './services/config.service';
import { IModalService, ModalService } from './services/modal.service';
import { NetworkErrorInterceptor } from './clients/network-error-interceptor';

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    PixelModule.forRoot({
      enabled: true,
      pixelId: environment.variables.fbPixelCode,
    }),
    HttpClientModule,
    AppRoutingModule,
    ComponentsModule,
    ContainersModule,
    GoogleMapsModule,

    BrMaskerModule,
  ],
  providers: [
    { provide: IStoreClient, useClass: environment.storeClient },
    { provide: IConfigClient, useClass: environment.configClient },
    { provide: IConfigService, useClass: ConfigService },
    { provide: IMemberService, useClass: MemberService },
    { provide: IModalService, useClass: ModalService },
    { provide: IMemberClient, useClass: environment.memberClient },
    { provide: IRewardClient, useClass: environment.rewardClient },
    { provide: IOrderClient, useClass: environment.orderClient },
    { provide: IMenuClient, useClass: environment.menuClient },
    { provide: ICouponClient, useClass: CouponClient },
    { provide: EnvironmentVariables, useValue: environment.variables },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: NetworkErrorInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
