import { DatePipe } from '@angular/common';
import { Injectable, OnDestroy } from '@angular/core';
import {
  asyncScheduler,
  combineLatest,
  EMPTY,
  interval,
  merge,
  Subscription,
} from 'rxjs';
import { catchError, filter, map, throttleTime } from 'rxjs/operators';
import { AppEvents } from '../models/domain/events/app-events';
import { NavigationEvents } from '../models/domain/events/navigation-events';
import { StoreChangeEvent } from '../models/domain/events/store-change-event';
import { Reward } from '../models/domain/reward';
import { Menu } from '../models/domain/menu';
import { NavigationPage } from '../models/domain/navigation-page';
import { Order } from '../models/domain/order/order';
import { OrderCombo } from '../models/domain/order/order-combo';
import { OrderLog } from '../models/domain/order/order-log';
import { OrderProduct } from '../models/domain/order/order-product';
import { SaleType } from '../models/domain/store';
import { OrderDTO } from '../models/dto/order/order.dto';
import { UnavailableProductsModel } from '../models/view-models/unavailable-products-model';
import { OrderDayChangeEvent } from '../models/domain/events/order-day-changed-event';
import { OrderTimeChangeEvent } from '../models/domain/events/order-time-changed-event';
import { CouponApplicationResult } from '../models/domain/response/coupon-application-result';
import { UAParser } from 'ua-parser-js';
import { MemberEvents } from '../models/domain/events/member-events';
import { ProductModifierUpdate } from '../models/domain/product-modifier-update';
import { Member } from '../models/domain/member';
import { IOrderClient } from '../clients/order-client';
import {
  OrderComboDTO,
  OrderComboItemDTO,
} from '../models/dto/order/order-combo.dto';
import { OrderProductDTO } from '../models/dto/order/order-product.dto';
import { OrderModifierDTO } from '../models/dto/order/order-modifier.dto';
import { NetworkErrorEvent } from '../models/domain/events/network-error-event';

@Injectable({
  providedIn: 'root',
})
export class LogviewService implements OnDestroy {
  datepipe: DatePipe = new DatePipe('en-US');

  private OrderLoaded(e: Order): void {
    this.order = e;
  }

  private CurrentLocation(e: google.maps.LatLngLiteral): void {
    this.addOrderLog(
      'Current Location set to lat: ' + e.lat + ' lng: ' + e.lng
    );
  }

  private MenusLoaded(): void {
    this.addOrderLog('Menus loaded');
  }

  private ProductsUnavailable(e: UnavailableProductsModel): void {
    this.addOrderLog(
      'Products unavailable ' +
        e.Reason +
        ' ' +
        (e.Combos || [])
          .map((c) => c?.DisplayName)
          .concat((e.Products || []).map((c) => c?.DisplayName))
          .join(', ')
    );
  }

  private StoreChanged(e: StoreChangeEvent): void {
    this.addOrderLog('Changed store to ' + e.Store?.DisplayName);
  }

  private MenuChanged(menu: Menu): void {
    if (menu?.Id) {
      this.addOrderLog('Changed menu ' + menu.Id);
    }
  }

  private SaleTypeChanged(e: { Order: Order; SaleType: SaleType }): void {
    this.addOrderLog('Changed sale type to ' + e.SaleType?.Name);
  }

  private OrderDayChanged(e: OrderDayChangeEvent): void {
    this.addOrderLog(
      `Changed order day to ${e?.OrderDay?.Label} (${e?.Reason})`
    );
  }

  private OrderTimeChanged(e: OrderTimeChangeEvent): void {
    this.addOrderLog(
      `Changed order time to ${e?.OrderTime?.Label} (${e?.Reason})`
    );
  }

  private AddProduct(e: OrderProduct): void {
    this.addOrderLog(
      'Added to cart ' + e.DisplayName + ' quantity: ' + e.Quantity
    );
  }

  private AddCombo(e: OrderCombo): void {
    this.addOrderLog(
      'Added to cart ' + e.DisplayName + ' quantity: ' + e.Quantity
    );
  }

  private ChangeQuantity(e: {
    OrderProduct: OrderProduct;
    Change: number;
  }): void {
    this.addOrderLog(
      'Changed quantity of ' + e.OrderProduct.DisplayName + ' by ' + e.Change
    );
  }

  private ChangeComboQuantity(e: {
    OrderCombo: OrderCombo;
    Change: number;
  }): void {
    this.addOrderLog(
      'Changed quantity of ' + e.OrderCombo.DisplayName + ' by ' + e.Change
    );
  }

  private CartUpdated(e: Order): void {
    const cleanModifier = (m: OrderModifierDTO) => {
      delete m.Category;
      // Delete the allergens that are false
      if (!m.isDairyAllergy) delete m.isDairyAllergy;
      if (!m.isEggAllergy) delete m.isEggAllergy;
      if (!m.isPeanutAllergy) delete m.isPeanutAllergy;
      if (!m.isTomatoAllergy) delete m.isTomatoAllergy;
      if (!m.isGlutenAllergy) delete m.isGlutenAllergy;
    };
    const cleanProduct = (p: OrderProductDTO) => {
      delete p.Description;
      delete p.Category;
      delete p.ImageUrl;
      p.SelectedModifiers.forEach(cleanModifier);
    };
    const cleanComboItem = (ci: OrderComboItemDTO) => {
      delete ci.MaxItems;
      delete ci.MinItems;
      if (ci.IsModifiers) {
        delete ci.Products;
        ci.Modifiers.forEach(cleanModifier);
      } else {
        delete ci.Modifiers;
        ci.Products.forEach(cleanProduct);
      }
    };
    const cleanCombo = (c: OrderComboDTO) => {
      delete c.Description;
      delete c.ImageUrl;
      delete c.HideProductsAndModifiers;
      delete c.Category;
      c.Items.forEach(cleanComboItem);
    };
    const dto = OrderDTO.FromDomain(e);
    delete dto.Store;
    delete dto.OrderSaleType;
    delete dto.LastLoaded;
    if (!dto.NumberPlate) delete dto.NumberPlate;
    if (!dto.CarColour) delete dto.CarColour;
    if (!dto.CarModel) delete dto.CarModel;
    if (!dto.Buzzer) delete dto.Buzzer;
    dto.Products.forEach(cleanProduct);
    dto.Combos.forEach(cleanCombo);
    this.addOrderLog('Cart was updated to ' + JSON.stringify(dto));
  }

  private CouponCodeEntered(e: { Code: string; Order: Order }): void {
    this.addOrderLog('Entered coupon code ' + e.Code);
  }

  private CouponCodeResult(e: CouponApplicationResult): void {
    this.addOrderLog(
      e.Success === true
        ? 'Got result for coupon: Applied'
        : `Got result for coupon: Not Applied ${e.Message}`
    );
  }

  private RewardApplied(e: { Asset: Reward; Order: Order }): void {
    this.addOrderLog('Applied Reward ' + e.Asset.Name);
  }

  private PurchaseReward(e: { purchasableReward: Reward }): void {
    this.addOrderLog(
      'Attempted to purchase reward: ' + e.purchasableReward.Name
    );
  }

  private RewardPurchased(e: { reward: Reward }): void {
    this.addOrderLog('Purchased reward: ' + e.reward.Name);
  }

  private RewardPurchaseFailed(e: { failureMessages: string[] }): void {
    this.addOrderLog(
      'Failed to purchase reward: ' + e.failureMessages.join(' | ')
    );
  }

  private SubmitOrder(): void {
    this.addOrderLog('Submitted order');
  }

  private NavigateToPage(e: NavigationPage): void {
    this.addOrderLog('Navigated to ' + e);
  }

  private NavigateToPayment(e: { Order: Order; PaymentOption: string }): void {
    this.addOrderLog('Chose payment option ' + e.PaymentOption);
  }

  private logDeviceDetails() {
    const inApp = MemberEvents.InApp.getValue();
    const uaParser = new UAParser();

    const operatingSystem = uaParser.getOS();
    const browser = uaParser.getBrowser();

    const operatingSystemString = operatingSystem.name
      ? `${operatingSystem.name} (${
          operatingSystem.version ?? 'unknown version'
        })`
      : 'unknown';

    let browserString = 'unknown';
    if (browser.name) {
      browserString = `${browser.name} (${
        browser.version ?? 'unknown version'
      })`;
    }

    if (inApp) {
      browserString += ' (Como app)';
    }

    const logMessage = `Device details - OS: "${operatingSystemString}" | Browser: "${browserString}"`;
    this.addOrderLog(logMessage);
  }

  private logModifiersChange(ev: {
    changes: Map<OrderProduct, ProductModifierUpdate>;
  }) {
    const logs: string[] = [];
    for (const [product, change] of ev.changes) {
      let productLog = '';
      if (change.selected.length) {
        productLog += `Added ${change.selected
          .map((modifier) => modifier.Name)
          .join(', ')}`;
      }
      if (change.selected.length && change.removed.length) {
        productLog += ', ';
      }
      if (change.removed.length) {
        productLog += `Removed ${change.removed
          .map((modifier) => modifier.Name)
          .join(', ')}`;
      }
      logs.push(`${product.Name}: ${productLog}`);
    }

    const message = `Modifiers updated | ${logs.join(' | ')}`;
    this.addOrderLog(message);
  }

  private identifySession(user: Member, orderId: string) {
    if (!user || !orderId) {
      return;
    }
    (window as any).hj?.('identify', user.ComoIdSignature, {
      order_id: orderId,
    });
  }

  /**
   * Logs the network error against the order.
   *
   * @param networkError The error details.
   */
  private logNetworkError(networkError: NetworkErrorEvent) {
    const body = networkError.body || 'No request body';
    const err = networkError.err && {
      message: networkError.err.message,
      status: networkError.err.status,
    };
    const errorToLog = JSON.stringify({ ...networkError, body, err });
    this.addOrderLog(`Network request failed: ${errorToLog}`, [
      'Error',
      'Ordering',
    ]);
  }

  /**
   * `true` when the logs are currently being sent to the server, to avoid
   * double sending any logs.
   */
  private sendingLogs = false;

  /**
   * An observable the produces a batch of all the currently unsent logs every
   * 10 seconds or since the last batch when navigating to the confirmation page.
   */
  private logBatches$ = merge(
    // Every 10 seconds
    interval(10000),
    // Or when navigating to confirmation page
    NavigationEvents.NavigateToConfirm
  ).pipe(
    // Skip sending when sending is already in progress
    filter(() => !this.sendingLogs),
    // Skip sending when there's no saved order
    filter(() => !!this.order?.IdSignature),
    // Skip sending when there's no logs
    filter(() => !!this.orderLogs.length),
    // Get the current logs
    map(() => [...this.orderLogs])
  );

  subscriptions: Array<Subscription> = [
    AppEvents.LogEvent.subscribe((e) => this.addLog(e, null)),
    // Clear the existing logs when a new order is created (i.e. preloading one with no signature)
    AppEvents.OrderPreLoaded.pipe(filter((order) => !order)).subscribe(() => {
      this.clearLogs();
    }),
    AppEvents.OrderLoaded.pipe(filter((o) => o != null)).subscribe((e) =>
      this.OrderLoaded(e)
    ),
    AppEvents.CurrentLocation.pipe(
      filter((l) => l != null),
      // Only emit the first change each 20 seconds to avoid spamming the logs.
      throttleTime(20 * 1000, asyncScheduler, {
        leading: true,
        trailing: false,
      })
    ).subscribe((e) => this.CurrentLocation(e)),
    AppEvents.MenusLoaded.subscribe(() => this.MenusLoaded()),
    AppEvents.ProductsUnavailable.subscribe((e) => this.ProductsUnavailable(e)),
    AppEvents.StoreChanged.subscribe((e) => this.StoreChanged(e)),
    AppEvents.MenuChanged.subscribe((menu) => this.MenuChanged(menu)),
    AppEvents.SaleTypeChanged.subscribe((e) => this.SaleTypeChanged(e)),
    AppEvents.OrderDayChanged.subscribe((e) => this.OrderDayChanged(e)),
    AppEvents.OrderTimeChanged.subscribe((e) => this.OrderTimeChanged(e)),
    AppEvents.AddProduct.subscribe((e) => this.AddProduct(e)),
    AppEvents.AddCombo.subscribe((e) => this.AddCombo(e)),
    AppEvents.ChangeQuantity.subscribe((e) => this.ChangeQuantity(e)),
    AppEvents.ChangeComboQuantity.subscribe((e) => this.ChangeComboQuantity(e)),
    AppEvents.ModifiersChanged.subscribe((e) => this.logModifiersChange(e)),
    AppEvents.CartUpdated.subscribe((e) => this.CartUpdated(e.Order)),
    AppEvents.CouponCodeEntered.subscribe((e) => this.CouponCodeEntered(e)),
    AppEvents.RedeemableResult.subscribe((e) => this.CouponCodeResult(e)),
    AppEvents.PurchaseReward.subscribe((e) => this.PurchaseReward(e)),
    AppEvents.RewardPurchased.subscribe((e) => this.RewardPurchased(e)),
    AppEvents.RewardPurchaseFailed.subscribe((e) =>
      this.RewardPurchaseFailed(e)
    ),
    AppEvents.RewardApplied.subscribe((e) => this.RewardApplied(e)),
    AppEvents.SubmitOrder.subscribe(() => this.SubmitOrder()),
    AppEvents.LogDeviceInfo.subscribe(() => this.logDeviceDetails()),
    NavigationEvents.NavigateToPage.subscribe((e) => this.NavigateToPage(e)),
    NavigationEvents.NavigateToPayment.subscribe((e) =>
      this.NavigateToPayment(e)
    ),
    combineLatest([MemberEvents.LoggedIn, AppEvents.OrderIdSet]).subscribe(
      ([user, order]) => {
        this.identifySession(user, order.IdSignature);
      }
    ),
    this.logBatches$.subscribe((logs) => {
      this.sendLogs(logs);
    }),
    AppEvents.ClearLogs.subscribe(() => {
      this.clearLogs();
    }),
    AppEvents.NetworkError.subscribe((networkError) => {
      this.logNetworkError(networkError);
    }),
  ];

  /** Logs shown on the log view component for debugging. */
  logs: string[] = [];

  /** The order the events are for. */
  order: Order;

  /** Log events to be sent to the server. */
  orderLogs: Array<OrderLog> = [];

  constructor(private orderClient: IOrderClient) {
    this.replaceLogger('log');
    this.replaceLogger('warn');
    this.replaceLogger('error');
    this.orderLogs = orderClient.getStoredLogs();
  }

  private replaceLogger = (logType: keyof typeof window.console): void => {
    const logFnOriginal:
      | typeof window.console.log
      | typeof window.console.warn
      | typeof window.console.error = window.console[logType];

    window.console[logType] = (...data: any[]) => {
      this.addLog(data[0], data[1]);
      logFnOriginal(...data);
    };
  };

  private addLog(e: any, e2: Error): void {
    if (e2?.stack) {
      e += '\n\r' + e2.stack;
    }
    const now = new Date();
    //TODO: use DatePipe here instead
    const timestamp = this.datepipe.transform(now, 'HH:mm:ss');

    this.logs.push(timestamp + '\t' + e);
  }

  /**
   * Adds an order log message to the list of order logs.
   *
   * @param message - The message to be added to the order logs.
   */
  private addOrderLog(message: string, types: string[] = []): void {
    this.orderLogs ??= [];

    const newLog: OrderLog = {
      Date: this.datepipe.transform(new Date(), 'yyyy-MM-ddTHH:mm:ssZZZZZ'),
      Message: message,
      Type: types.join(','),
    };

    this.orderLogs.push(newLog);
    this.orderClient.saveLogsLocal(this.orderLogs);
  }

  /**
   * Clear logs.
   */
  private clearLogs() {
    this.logs = [];
    this.orderLogs = [];
    this.orderClient.saveLogsLocal([]);
  }

  /**
   * Sends the specified logs to the server for saving.
   *
   * @param logs - The logs to be sent.
   */
  private sendLogs(logs: OrderLog[]) {
    this.sendingLogs = true;
    this.orderClient
      .sendLogs(this.order.IdSignature, logs)
      .pipe(
        catchError((_) => {
          this.sendingLogs = false;
          return EMPTY;
        })
      )
      .subscribe(() => {
        // On success, slice the sent logs, keeping only logs that have been added
        // after the send was initiated
        const unsentLogs = this.orderLogs.slice(logs.length);
        this.orderLogs = unsentLogs;
        this.orderClient.saveLogsLocal(unsentLogs);
        this.sendingLogs = false;
      });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }
}
