import { EventEmitter } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { RegisterInfo } from '../login/register-info';
import { Member, SavedCard } from '../member';
import { SendVerificationCodeEvent } from './send-verification-code-event';

export class MemberEvents {
  public static CurrentMember = new BehaviorSubject<Member>(null);
  public static ExternalAuthToken = new BehaviorSubject<string>('');
  public static InApp = new BehaviorSubject<boolean>(false);
  public static Referrer = new BehaviorSubject<string>(null);

  public static ChangeLoginEmail = new EventEmitter<string>();

  public static SendIdentificationCode =
    new EventEmitter<SendVerificationCodeEvent>();
  public static GetLoggedInMember = new EventEmitter();
  public static GetMemberFromCommonExtId = new EventEmitter<string>();
  public static GetMemberFromAccessToken = new EventEmitter<{
    phoneNumber: string;
    accessToken: string;
  }>();
  public static GetMemberFromAppToken = new EventEmitter<string>();
  public static LoggingIn = new BehaviorSubject<boolean>(false);
  public static LoggedIn = new EventEmitter<Member>();
  public static RefreshMember = new EventEmitter<Member>();
  public static Register = new EventEmitter<RegisterInfo>();
  public static RegistrationSuccessful = new EventEmitter<{ email: string }>();
  public static SendResetCode = new EventEmitter<string>();

  public static UpdateMember = new EventEmitter<Member>();
  public static MemberUpdated = new EventEmitter<Member>();
  public static UpdateMemberFailed = new EventEmitter<string>();
  public static ChangePassword = new EventEmitter<string>();
  public static PasswordChanged = new EventEmitter<string>();
  public static SaveCard = new EventEmitter<SavedCard>();
  public static RemoveCard = new EventEmitter<SavedCard>();

  public static LogOut = new EventEmitter();
  public static LoggedOut = new EventEmitter<Member>();
}
