import { OrderTimeDisabledReason } from './order-time-disabled-reason';

/**
 * Information about an order's time component.
 */
export class OrderTime {
  public Label: string;

  /**
   * The time of the order. The date component should not be used, the source of
   * truth for order dates is Order.OrderDate.
   */
  public Time: Date;

  /** The number of minutes past midnight. */
  public Offset: number;

  /**
   * Whether and why the `OrderTime` is disabled, or if it is enabled.
   */
  public DisabledReasons: OrderTimeDisabledReason[];

  /**
   * The total dollar amount of existing orders in this OrderTime for the store.
   */
  public CurrentOrderTotal: number;

  constructor(o: Partial<OrderTime>) {
    this.DisabledReasons = [];
    Object.assign(this, o);
  }

  /**
   * Returns `true` if there are no reasons to be disabled.
   */
  public get Enabled(): boolean {
    return !this.DisabledReasons?.length;
  }
}
