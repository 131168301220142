import { Component, Input } from '@angular/core';
import { AppEvents } from 'src/app/models/domain/events/app-events';
import { Menu } from 'src/app/models/domain/menu';
import { OrderProduct } from 'src/app/models/domain/order/order-product';
import { EnvironmentVariables } from 'src/app/models/environment';
import {
  CustomiseProductComponent,
  CustomiseProductDismissEvent,
} from '../customise-product/customise-product.component';
import { IModalService } from '../../services/modal.service';

@Component({
  selector: 'app-cart-product',
  templateUrl: './cart-product.component.html',
  styleUrls: ['./cart-product.component.scss'],
})
export class CartProductComponent {
  @Input()
  product: OrderProduct;

  @Input()
  menu: Menu;

  @Input()
  editable: boolean;

  constructor(
    private modalService: IModalService,
    public variables: EnvironmentVariables
  ) {}

  async editProduct(): Promise<void> {
    if (!this.editable) {
      return;
    }
    const originalProduct = this.menu.ProductDictionary[this.product.ProductId];
    const editingProduct = OrderProduct.ApplyToOrderProduct(
      this.product,
      OrderProduct.FromProduct(originalProduct)
    );
    this.modalService
      .presentModal<CustomiseProductDismissEvent | undefined>({
        component: CustomiseProductComponent,
        componentProps: {
          orderProduct: editingProduct,
          originalProduct: originalProduct,
          inCart: true,
        },
        cssClass: 'show-header-modal',
      })
      .subscribe((data) => {
        if (data) {
          if (data.Combo) {
            AppEvents.ChangeQuantity.emit({
              OrderProduct: this.product,
              Change: -this.product.Quantity,
            });
            AppEvents.AddCombo.emit(data.Combo);
          } else {
            if (data.Product.Quantity == 0) {
              //Product was removed from cart
              AppEvents.ChangeQuantity.emit({
                OrderProduct: this.product,
                Change: -this.product.Quantity,
              });
            } else {
              OrderProduct.ApplyToOrderProduct(data.Product, this.product);
            }
            AppEvents.UpdateCart.emit();
          }
        }
      });
  }

  changeQuantity(
    event: MouseEvent,
    product: OrderProduct,
    change: number
  ): void {
    event.stopPropagation();
    AppEvents.ChangeQuantity.emit({ OrderProduct: product, Change: change });
  }
}
