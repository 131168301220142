import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ModalComponent } from 'src/app/models/view-models/modal-component';
import { UnavailableProductsModel } from 'src/app/models/view-models/unavailable-products-model';

@Component({
  selector: 'app-unavailable-products',
  templateUrl: './unavailable-products.component.html',
  styleUrls: ['./unavailable-products.component.scss'],
})
export class UnavailableProductsComponent extends ModalComponent {
  @Input()
  unavailableProducts: { [reason: string]: UnavailableProductsModel };

  constructor(modalController: ModalController) {
    super(modalController);
  }

  finish(): void {
    this.dismiss(undefined);
  }
}
