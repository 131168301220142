import { Reward } from './reward';
import { PaymentIntegration } from './response/payment-response';

export class Member {
  constructor(m: Partial<Member>) {
    Object.assign(this, m);
    this.CurbsideDetails = Object.assign(
      new CurbsideDetails(),
      this.CurbsideDetails
    );
    this.NotificationSettings = Object.assign({}, this.NotificationSettings);
    this.PaymentMethods = Object.assign([], this.PaymentMethods);
  }

  public FirstName: string;
  public LastName: string;

  public EmailAddress: string;
  public PhoneNumber: string;
  public Birthday: string;

  public ComoIdSignature: string;

  public CurbsideDetails: CurbsideDetails = new CurbsideDetails();
  public LoyaltyProgram: boolean;
  public AllowEmail: boolean;
  public NotificationSettings: { [name: string]: boolean } = {};

  public PaymentMethods: Array<SavedCard> = [];

  public Assets: Array<Reward> = [];
  public PointsBalance: PointsBalance;
  public Tags: Array<string> = [];
}

export class CurbsideDetails {
  public NumberPlate: string;
  public CarColour: string;
  public CarModel: string;
}

export class SavedCard {
  public Id: string;
  public CardNumber: string;
  public IntegrationMethod: PaymentIntegration;
}

export class PointsBalance {
  public Balance: Balance;
  public UsedByPayment: boolean;
}

export class Balance {
  public Monetary: number;
  public NonMonetary: number;
}
