import { Modifier } from '../modifier';

/**
 * A modifier that has been added or removed from an order, rather than just
 * the config for a modifier. This is different because it includes the
 * `Selected` property to know if it was added or removed from the
 * combo/product.
 *
 * @see Modifier
 */
export class OrderModifier {
  public ModifierId: string;
  public InternalName: string;
  public Name: string;
  public Discount: number;
  public ItemPrice: number;
  public TotalPrice: number;
  public Included: boolean;
  /**
   * `true` when the modifier is in the combo/product, `false` if it was
   * removed.
   */
  public Selected: boolean;
  public PLU: number;
  public RemovedPLU: number;
  public Category: string;
  public LineId: number;

  // Dietary information:
  public isGlutenAllergy: boolean;
  public isDairyAllergy: boolean;
  public isPeanutAllergy: boolean;
  public isEggAllergy: boolean;
  public isTomatoAllergy: boolean;

  // Front end only
  /**
   * Whether the item is out of stock.
   */
  public OutOfStock: boolean;

  constructor(o: Partial<OrderModifier>) {
    Object.assign(this, o);
  }

  public static fromModifier(modifier: Modifier): OrderModifier {
    return new OrderModifier({
      InternalName: modifier.InternalName,
      Included: modifier.Included,
      Discount: 0,
      ItemPrice: modifier.Price,
      TotalPrice: modifier.Price,
      ModifierId: modifier.Id,
      Name: modifier.Name,
      Selected: modifier.Included,
      Category: modifier.Category,
      PLU: modifier.PLU,
      RemovedPLU: modifier.RemovedPLU,
      OutOfStock: modifier.OutOfStock,
      isDairyAllergy: modifier.isDairyAllergy,
      isEggAllergy: modifier.isEggAllergy,
      isGlutenAllergy: modifier.isGlutenAllergy,
      isPeanutAllergy: modifier.isPeanutAllergy,
      isTomatoAllergy: modifier.isTomatoAllergy,
    });
  }
}
