import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { CouponRequirements } from 'src/app/models/view-models/coupon-model';
import { ModalComponent } from 'src/app/models/view-models/modal-component';

@Component({
  selector: 'app-item-picker-modal',
  templateUrl: './item-picker-modal.component.html',
  styleUrls: ['./item-picker-modal.component.scss'],
})
export class ItemPickerModalComponent extends ModalComponent {
  @Input()
  couponRequirements: CouponRequirements;

  @Input()
  redeemableType: 'coupon' | 'reward';

  constructor(modalController: ModalController) {
    super(modalController);
  }

  dismiss(): void {
    super.dismiss(undefined);
  }
}
