import { Category } from '../domain/category';
import { Product, SaleTimeFrame } from '../domain/product';
import { ModifierGroupDTO } from './modifier-group.dto';
import { ModifierDTO } from './modifier.dto';

export class ProductDTO {
  public Id: string;
  public Name: string;
  public DisplayName: string;
  public ShortName: string;
  public Description: string;

  public Price: number;
  public PriceInCombo: number;
  public DisplayOrder: number;

  public Active: boolean;
  public Category: string; //"Side";
  public Subcategories: Array<Category>;
  public ImageUrl: string; //"http://test.admin.shakeout.co/Content/Not%20Found.png";
  public ModifierGroups: Array<ModifierGroupDTO>;
  public Modifiers: Array<ModifierDTO>;
  public DefaultModifiersIds: Array<string>;
  public OutOfStock: 0;
  public IsHiddenOutOfStock: boolean;

  public PLU: number;
  public SaleTimeFrame: SaleTimeFrame;

  public static ToDomain(dto: ProductDTO): Product {
    if (!dto) {
      return null;
    }
    const product: Product = {
      Id: dto.Id,
      Category: dto.Category,
      Description: dto.Description,
      DisplayName: dto.Name,
      DisplayOrder: dto.DisplayOrder,
      ImageUrl: dto.ImageUrl,
      Name: dto.Name,
      ShortName: dto.ShortName,
      Price: dto.Price,
      ModifierGroups: (dto.ModifierGroups || []).map((mg) => {
        return {
          Name: mg.Name,
          Modifiers: mg.Modifiers,
          SelectionLimit: mg.SelectionLimit,
          SelectionRequirement: mg.SelectionRequirement,
        };
      }),
      AllCombos: [],
      AvailableCombos: [],
      OutOfStock: false,
      IsHiddenOutOfStock: dto.IsHiddenOutOfStock,
      ProductCount: 0,
      PLU: dto.PLU,
      PriceInCombo: dto.PriceInCombo,
      SaleTimeFrame: dto.SaleTimeFrame
        ? {
            OpenTime: dto.SaleTimeFrame.OpenTime,
            CloseTime: dto.SaleTimeFrame.CloseTime,
          }
        : null,
      //Activated by selecting a timeframe later
      TimeAvailable: !dto.SaleTimeFrame,
    };
    if (dto.DefaultModifiersIds != null) {
      product.ModifierGroups.forEach((mg) => {
        mg.Modifiers.forEach((m) => {
          m.Included = dto.DefaultModifiersIds.includes(m.Id);
        });
      });
    }

    return product;
  }
}
