import {
  AfterViewChecked,
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Combo } from 'src/app/models/domain/combo';
import { AppEvents } from 'src/app/models/domain/events/app-events';
import { NavigationEvents } from 'src/app/models/domain/events/navigation-events';
import {
  OrderCombo,
  OrderComboItem,
} from 'src/app/models/domain/order/order-combo';
import { OrderModifier } from 'src/app/models/domain/order/order-modifier';
import { OrderProduct } from 'src/app/models/domain/order/order-product';
import { Product } from 'src/app/models/domain/product';
import { EnvironmentVariables } from 'src/app/models/environment';
import { CustomiseModifiersComponent } from '../customise-modifiers/customise-modifiers.component';
import { ModalService } from 'src/app/services/modal.service';
import { ModalComponent } from 'src/app/models/view-models/modal-component';
import { ProductModifierUpdate } from '../../models/domain/product-modifier-update';
import { map } from 'rxjs/operators';
import { AllergenConfig } from '../../models/domain/online-configuration';

export type CustomiseProductDismissEvent = {
  Combo: OrderCombo;
  Product: OrderProduct;
};

/** This component is used to view a product or combo before adding it into the cart
 *
 * Which mode it operates in depends on exactly one of orderProduct or orderCombo being populated
 * This is represented by the selection property, which determines the header and image of the modal
 *
 * If orderProduct is populated, then originalProduct will also be selected, which is the actual
 * item in the Menu object that the orderProduct was created from. Similiarly for orderCombo and originalCombo
 *
 * The product/combo may not already be in the cart. This component must not add products
 *  or combos to the cart, but return a result via this.modalController.dismiss, and the code that
 * opened the modal containing this component must handle the changes to the cart
 *
 * If orderProduct is selected, and it has available combos, it can be upgraded to a combo
 * It will display a radio selection of the base product, and then each available combo.
 * Selecting one of these will change the "selection" field, and selecting a combo will then display all
 * combo items for that combo, except the first one. The first combo item contains the orderProduct,
 * so its selection is implied.
 * If it has no available combos, it will only display quantity buttons, and a link to customise if available
 *
 * If orderCombo is selected, and the originalCombo.DisplayOnMenu is true, then it is a true combo.
 * originalCombo will be populated, but originalProduct will not, as it was not upgraded from a product
 *  All combo items will be displayed, as well as quantity buttons, and customise links to any
 * products in combo items that are selected.
 *
 * If orderCombo is selected, but the originalCombo.DisplayOnMenu is false, then it is a combo that
 * was upgraded from a product. originalProduct should also be populated, which represents the product
 * that was originally clicked to open the product modal, before the product was converted to a combo.
 */
@Component({
  selector: 'app-customise-product',
  templateUrl: './customise-product.component.html',
  styleUrls: ['./customise-product.component.scss'],
})
export class CustomiseProductComponent
  extends ModalComponent<CustomiseProductDismissEvent | undefined>
  implements OnInit, OnDestroy, AfterViewInit, AfterViewChecked
{
  constructor(
    modalController: ModalController,
    private modalService: ModalService,
    public variables: EnvironmentVariables
  ) {
    super(modalController);
  }

  //Represents what we started with
  @Input()
  orderProduct: OrderProduct;

  //Represents our current choice in the modal
  _selectedOrderProduct: OrderProduct;

  //Represents what we started with
  @Input()
  orderCombo: OrderCombo;

  //Represents our current choice in the modal
  _selectedOrderCombo: OrderCombo;

  @Input()
  inCart: boolean;

  @Input()
  originalProduct: Product;

  @Input()
  originalCombo: Combo;

  /**
   * The product or products in combo that were already selected when this modal was opened, and each modifier that was
   * customised already for each product.
   */
  private originalModifiers: Map<OrderProduct, OrderModifier[]>;

  valid: boolean;

  @ViewChild('inner') inner: ElementRef<HTMLElement>;
  @ViewChild('scrollshadow') scrollshadow: ElementRef<HTMLElement>;
  private checkedScroll = false;

  selection: { product: Product | Combo } = {
    product: null,
  };

  allergenConfig: AllergenConfig;

  subscriptions = [
    NavigationEvents.MenuOpening.subscribe(() => {
      this.menuOpening();
    }),
    AppEvents.OnlineConfiguration.pipe(
      map((config) => config.AllergenConfig)
    ).subscribe((allergenConfig) => {
      this.allergenConfig = allergenConfig;
    }),
  ];

  ngOnInit(): void {
    this._selectedOrderProduct = this.orderProduct;
    this._selectedOrderCombo = this.orderCombo;
    this.originalModifiers = new Map<OrderProduct, OrderModifier[]>();

    if (this.originalProduct) {
      if (this.orderProduct) {
        this.selection.product = this.originalProduct;
        this.originalModifiers.set(
          this.orderProduct,
          this.getSelectedModifiers(this.orderProduct)
        );
      } else {
        this.selection.product = this.originalProduct.AvailableCombos.find(
          (c) => c.Id == this.orderCombo.ComboId
        );
      }
    } else {
      this.selection.product = this.originalCombo;
      this.orderCombo.Products.forEach((item) => {
        item.Products.forEach((product) => {
          this.originalModifiers.set(
            product,
            this.getSelectedModifiers(product)
          );
        });
      });
    }
    this.calculateValid();
    if (this._selectedOrderProduct && !this.valid) {
      this.customiseModifiers(this._selectedOrderProduct, null);
    }
  }

  /**
   * Performs initialization tasks after the view has been initialized.
   * This method attaches a scroll event listener to the native element.
   * When the user scrolls completely to the bottom, the scrollshadow element's display is set to "none", hiding it.
   * Otherwise, the scrollshadow element's display is set to "inline-block", making it visible.
   */
  ngAfterViewInit(): void {
    this.inner.nativeElement.addEventListener('scroll', (e) => {
      const el = e.target as HTMLElement;
      // Round el.scrollTop up because sometimes it has a decimal value causing it to be
      // < 1px lower than `el.scrollHeight - el.clientHeight` when the user scrolls
      // completely to the bottom due to some systems' display scaling.
      // see warning on https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollTop
      if (Math.ceil(el.scrollTop) >= el.scrollHeight - el.clientHeight) {
        this.scrollshadow.nativeElement.style.display = 'none';
      } else {
        this.scrollshadow.nativeElement.style.display = 'inline-block';
      }
    });
  }

  /**
   * Checks if the scroll has been already checked and if not,
   * it determines whether to show the scroll fade based on the content's scroll height and client height.
   * If the scroll height is less than or equal to the client height, the scroll fade is hidden.
   * Otherwise, it scrolls the content to a position of 100 pixels from the top.
   */
  ngAfterViewChecked(): void {
    if (this.checkedScroll) {
      return;
    }
    // Check if we need to show the scroll fade
    if (this.inner.nativeElement.scrollHeight > 0) {
      if (
        this.inner.nativeElement.scrollHeight <=
        this.inner.nativeElement.clientHeight
      ) {
        this.scrollshadow.nativeElement.style.display = 'none';
      } else {
        this.inner.nativeElement.scrollTo(0, 100);
      }
      this.checkedScroll = true;
    }
  }

  chooseBaseProduct(): void {
    // If we started from a base product, go back to it
    if (this.selection.product == this.originalProduct) {
      return;
    } //Already selected
    const currentQuantity = (
      this._selectedOrderProduct || this._selectedOrderCombo
    ).Quantity;
    if (this.orderProduct) {
      this._selectedOrderProduct = this.orderProduct;
    } else {
      //If we started with a combo, create the product it was originally
      //Modifiers will be lost
      //When exiting the modal, if the product was in the cart
      //we must remove the original combo from the cart
      //And add the product instead
      const originalCombo = this.originalProduct.AvailableCombos.find(
        (c) => c.Id == this.orderCombo.ComboId
      );
      const defaultProd = OrderProduct.FromProduct(
        originalCombo.Items[0].Products[0]
      );
      this._selectedOrderProduct = defaultProd;
    }
    this._selectedOrderProduct.Quantity =
      currentQuantity * this._selectedOrderProduct.Quantity;
    AppEvents.CalculatePrice.emit(this._selectedOrderProduct);
    this.selection.product = this.originalProduct;
    this._selectedOrderCombo = null;
    this.calculateValid();
  }

  chooseCombo(combo: Combo): void {
    if (this.selection.product == combo) {
      return;
    } //Already selected
    const currentQuantity = (
      this._selectedOrderProduct || this._selectedOrderCombo
    ).Quantity;
    if (this.orderCombo && this.orderCombo.ComboId == combo.Id) {
      this._selectedOrderCombo = this.orderCombo;
      this._selectedOrderCombo.Quantity = currentQuantity;
      AppEvents.CalculateComboPrice.emit(this._selectedOrderCombo);
    } else {
      this._selectedOrderCombo = OrderCombo.FromCombo(combo);
      this._selectedOrderCombo.Quantity = currentQuantity;

      //If entering chooseCombo, then this must be a product upgraded to a combo
      //We need to make sure that product is selected in the combo
      //It should always be in the first ComboItem in the Combo, and the only one with a quantity
      const productFromOriginalSelection =
        this._selectedOrderCombo.Products[0].Products.find(
          (p) => p.ProductId == this.originalProduct.Id
        );
      if (productFromOriginalSelection) {
        //Make sure it is always selected
        productFromOriginalSelection.Selected = true;
        this.selectedProductChanged(
          productFromOriginalSelection,
          this._selectedOrderCombo.Products[0]
        );
      }
    }
    this._selectedOrderProduct = null;
    this.selection.product = combo;
    this.calculateValid();
  }

  //ion-item was clicked instead of checkbox so we manually toggle the checkbox
  //This is required because when the checkbox element is clicked, it toggles the check mark
  //This throws a "Expression changed after checked" error if we change it here
  //So we split the different types of click into separate functions
  //This is why the checkbox ng model binds to "Selected" and why it stops propagation on (click)
  //  to make sure only one of these events triggers
  changeSelectedProduct(
    product: OrderProduct,
    comboItem: OrderComboItem
  ): void {
    if (product.OutOfStock) {
      return;
    }
    if (
      comboItem.SelectionCount >= comboItem.MaxItems &&
      comboItem.MaxItems > 1 &&
      !product.Selected
    ) {
      //Deny if this would mean selecting more than the max, except when max is 1
      return;
    }
    product.Selected = !product.Selected;
    this.selectedProductChanged(product, comboItem);
  }

  selectedProductChanged(
    product: OrderProduct,
    comboItem: OrderComboItem
  ): void {
    if (product.Selected) {
      product.Quantity = 1;
      if (comboItem.MaxItems == 1) {
        //Deselect all other products by setting quantity to 0
        comboItem.Products.forEach((p) => {
          if (p != product) {
            p.Quantity = 0;
            p.Selected = false;
          }
        });
      }
      if (
        comboItem.MaxItems > 1 &&
        comboItem.MaxItems == comboItem.MinItems &&
        comboItem.MaxItems > comboItem.Products.length
      ) {
        // Increase the Quantity of the selected product to the max
        product.Quantity = comboItem.MaxItems;
        comboItem.SelectionCount = comboItem.MaxItems;
      }
    } else {
      product.Quantity = 0;
    }
    AppEvents.CalculateComboPrice.emit(this._selectedOrderCombo);
    this.calculateValid();
  }

  changeSelectedModifier(
    modifier: OrderModifier,
    comboItem: OrderComboItem
  ): void {
    if (
      comboItem.SelectionCount >= comboItem.MaxItems &&
      comboItem.MaxItems > 1 &&
      !modifier.Selected
    ) {
      //Deny if this would mean selecting more than the max, except when max is 1
      return;
    }
    modifier.Selected = !modifier.Selected;
    this.selectedModifierChanged(modifier, comboItem);
  }

  selectedModifierChanged(
    modifier: OrderModifier,
    comboItem: OrderComboItem
  ): void {
    if (modifier.Selected) {
      if (comboItem.MaxItems == 1) {
        //Deselect all other modifiers
        comboItem.Modifiers.forEach((p) => {
          if (p != modifier) {
            p.Selected = false;
          }
        });
      }
    }
    AppEvents.CalculateComboPrice.emit(this._selectedOrderCombo);
    this.calculateValid();
  }

  customiseModifiers(
    orderProduct: OrderProduct,
    event: MouseEvent | null
  ): void {
    event?.stopPropagation();
    this.modalService
      .presentModal({
        component: CustomiseModifiersComponent,
        componentProps: {
          orderProduct: orderProduct,
        },
        cssClass: 'show-header-modal',
      })
      .subscribe(() => {
        if (this._selectedOrderProduct) {
          AppEvents.CalculatePrice.emit(this._selectedOrderProduct);
        } else if (this._selectedOrderCombo) {
          AppEvents.CalculateComboPrice.emit(this._selectedOrderCombo);
        }
        this.calculateValid();
      });
  }

  /**
   * Retrieves the selected modifiers of an order product.
   *
   * @param orderProduct - The order product containing the modifier groups.
   * @return - The array of selected modifiers.
   */
  private getSelectedModifiers(orderProduct: OrderProduct): OrderModifier[] {
    let modifiers: OrderModifier[] = [];
    orderProduct.ModifierGroups.forEach(
      (group) =>
        (modifiers = [
          ...modifiers,
          ...group.Modifiers.filter(
            (modifier) => modifier.Selected !== modifier.Included
          ),
        ])
    );
    return modifiers;
  }

  /**
   * Calculates the difference between two arrays of OrderModifier, returning the added and removed modifiers.
   *
   * @param original - The original array of order modifiers.
   * @param updated - The updated array of order modifiers.
   * @returns - The added and removed modifiers.
   */
  private getModifiersDifference(
    original: OrderModifier[],
    updated: OrderModifier[]
  ): ProductModifierUpdate {
    const originalIds = original.map((modifier) => modifier.ModifierId);
    const updatedIds = updated.map((modifier) => modifier.ModifierId);

    const newModifiers = updated.filter(
      (modifier) => !originalIds.includes(modifier.ModifierId)
    );
    const oldModifiers = original.filter(
      (modifier) => !updatedIds.includes(modifier.ModifierId)
    );

    return {
      selected: [
        ...newModifiers.filter((m) => m.Selected),
        ...oldModifiers.filter((m) => m.Selected),
      ],
      removed: [
        ...newModifiers.filter((m) => !m.Selected),
        ...oldModifiers.filter((m) => !m.Selected),
      ],
    };
  }

  changeQuantity(change: number): void {
    if (this._selectedOrderProduct) {
      this._selectedOrderProduct.Quantity += change;
      AppEvents.CalculatePrice.emit(this._selectedOrderProduct);
    } else if (this._selectedOrderCombo) {
      this._selectedOrderCombo.Quantity += change;
      AppEvents.CalculateComboPrice.emit(this._selectedOrderCombo);
    }
  }

  calculateValid(): void {
    this.valid = true;
    if (this._selectedOrderProduct) {
      this.valid = OrderProduct.Validate(this._selectedOrderProduct);
    } else if (this._selectedOrderCombo) {
      this._selectedOrderCombo.Products.forEach((op) => {
        this.valid = OrderComboItem.Validate(op) && this.valid;
      });
    }
  }

  removeFromCart(): void {
    if (this._selectedOrderProduct) {
      this._selectedOrderProduct.Quantity = 0;
    } else if (this._selectedOrderCombo) {
      this._selectedOrderCombo.Quantity = 0;
    }
    this.done();
  }

  cancel(): void {
    super.dismiss(undefined);
  }

  done(): void {
    this.logModifierChanges();
    super.dismiss({
      Product: this._selectedOrderProduct,
      Combo: this._selectedOrderCombo,
    });
  }

  /**
   * Logs the modifier changes for selected order product or order combo products.
   */
  private logModifierChanges(): void {
    const changes = new Map<
      OrderProduct,
      { selected: OrderModifier[]; removed: OrderModifier[] }
    >();

    if (this._selectedOrderCombo) {
      // Get each of the changes for each product in the combo
      this._selectedOrderCombo.Products.forEach((item) => {
        item.Products?.forEach((product) => {
          if (product.Quantity) {
            const modifierChanges = this.getModifierChanges(product);
            if (
              modifierChanges.removed.length ||
              modifierChanges.selected.length
            ) {
              changes.set(product, modifierChanges);
            }
          }
        });
      });
    } else {
      // Get changes for the product
      const modifierChanges = this.getModifierChanges(
        this._selectedOrderProduct
      );
      if (modifierChanges.removed.length || modifierChanges.selected.length) {
        changes.set(this._selectedOrderProduct, modifierChanges);
      }
    }

    if (changes.size) {
      AppEvents.ModifiersChanged.emit({ changes });
    }
  }

  /**
   * Calculates the differences between the original modifiers and the selected modifiers of a given modified product.
   *
   * @param modifiedProduct - The modified product for which to calculate the differences.
   * @returns - An array containing the differences between the original modifiers and the selected modifiers.
   */
  private getModifierChanges(
    modifiedProduct: OrderProduct
  ): ProductModifierUpdate {
    const originalProductModifiers =
      this.originalModifiers.get(modifiedProduct) ?? [];

    const differences = this.getModifiersDifference(
      originalProductModifiers,
      this.getSelectedModifiers(modifiedProduct)
    );

    return differences;
  }

  menuOpening(): void {
    super.dismiss(undefined);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }
}
