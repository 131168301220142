import {
  DietaryRequirement,
  DietaryRequirementType,
} from '../domain/dietary-requirement';

/**
 * DTO for `DietaryRequirement`.
 *
 * @see DietaryRequirement
 */
export class DietaryRequirementDto {
  Name: string;
  Plu: number;
  Type: string;

  constructor(partial: Partial<DietaryRequirementDto>) {
    this.Name = partial.Name;
    this.Plu = partial.Plu;
    this.Type = partial.Type;
  }

  public static toDomain(dto: DietaryRequirementDto): DietaryRequirement {
    return new DietaryRequirement({
      ...dto,
      Type: DietaryRequirementType[dto.Type],
    });
  }

  public static fromDomain(obj: DietaryRequirement): DietaryRequirementDto {
    return new DietaryRequirementDto({
      ...obj,
    });
  }
}
