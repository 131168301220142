/**
 * The reason why an order failed validation.
 */
export enum OrderValidationFailedReason {
  /** The expected time of the order is invalid. */
  INVALID_TIME = 'INVALID_TIME',

  /** The order failed for an unhandled/unknown reason. */
  UNKNOWN = 'UNKNOWN',
}
