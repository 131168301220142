import { Combo } from '../combo';
import { SaleTimeFrame } from '../product';
import { OrderModifier } from './order-modifier';
import { OrderProduct } from './order-product';

export class OrderCombo {
  public ComboId: string;
  public Name: string;
  public DisplayName: string;
  public ShortName: string;
  public Description: string;
  public ImageUrl: string;

  public Quantity: number;
  public Products: Array<OrderComboItem>;
  // Base price without any modifiers
  public BasePrice: number;
  // Price with modifiers applied
  public ItemPrice: number;

  // What is returned from the API when retrieving a submitted order
  public Price: number;

  public SubTotal: number;
  public Discount: number;
  public TotalPrice: number;
  public Category: string;

  public HideProductsAndModifiers: boolean;

  public SaleTimeFrame: SaleTimeFrame;

  public PLU: string;

  // Calculated by front end, not needed on server side
  public TimeAvailable: boolean;
  public OutOfStock: boolean;
  public IsHiddenOutOfStock: boolean;

  /**
   * The 'line' of the receipt the combo is on. Used to differentiate it from
   * other products/combos that may be the same product/combo but with some
   * differences, e.g. modifiers.
   */
  // Required by Como, so we can identify which cart item the Como api applied the discount to
  public LineId: number;

  /**
   * The PLU of the category of the product.
   */
  // Required by Como
  public CategoryId: string;

  constructor(o: Partial<OrderCombo>) {
    Object.assign(this, o);
  }

  public static FromCombo(combo: Combo): OrderCombo {
    return new OrderCombo({
      ComboId: combo.Id,
      DisplayName: combo.DisplayName,
      Name: combo.Name,
      ShortName: combo.ShortName,
      Description: combo.Description,
      ImageUrl: combo.ImageUrl,
      Quantity: 1,
      BasePrice: combo.Price,
      ItemPrice: combo.Price,
      Discount: 0,
      TotalPrice: combo.Price,
      Products: combo.Items.map((i) => {
        const item: OrderComboItem = {
          Name: i.Name,
          IsModifiers: i.IsModifiers,
          Products: i.Products
            ? i.Products.map((p) => OrderProduct.FromProduct(p)).map((p) => {
                // Quantity 0 means not selected in combo
                p.Quantity = 0;
                return p;
              })
            : [],
          Modifiers: i.Modifiers
            ? i.Modifiers.map(OrderModifier.fromModifier)
            : [],
          MaxItems: i.MaxItems,
          MinItems: i.MinItems,
          SelectionCount: 0,
        };
        if (i.DefaultProductId) {
          const defaultProduct = item.Products.find(
            (p) => p.ProductId == i.DefaultProductId
          );
          if (defaultProduct && !defaultProduct.OutOfStock) {
            defaultProduct.Selected = true;
            // Increase the quantity of the selected product to the max
            defaultProduct.Quantity = item.MaxItems;
            item.SelectionCount = item.MaxItems;
          }
        }
        return item;
      }),
      PLU: combo.PLU,
      Category: combo.Category,
      HideProductsAndModifiers: combo.HideProductsAndModifiers,
      SaleTimeFrame: combo.SaleTimeFrame
        ? {
            OpenTime: combo.SaleTimeFrame.OpenTime,
            CloseTime: combo.SaleTimeFrame.CloseTime,
          }
        : null,
      IsHiddenOutOfStock: combo.IsHiddenOutOfStock,
      OutOfStock: combo.OutOfStock,
      TimeAvailable: combo.TimeAvailable,
    });
  }

  public static ApplyToOrderCombo(
    from: OrderCombo,
    to: OrderCombo
  ): OrderCombo {
    to.Quantity = from.Quantity;
    to.Products.forEach((toComboItem) => {
      // Try to find the matching combo item in the from
      const fromComboItem = from.Products.find(
        (ci) => ci.Name == toComboItem.Name
      );
      if (fromComboItem) {
        if (toComboItem.IsModifiers) {
          toComboItem.Modifiers.forEach((toModifier) => {
            //Find matching product in fromComboItem
            const fromModifier = fromComboItem.Modifiers.find(
              (m) => m.ModifierId == toModifier.ModifierId
            );
            if (fromModifier) {
              toModifier.Selected = fromModifier.Selected;
            } else {
              // ComboItem options setup was modified on server after item already in cart
              // Assume unselected
              // TODO: check that defaults were correctly applied
            }
          });
        } else {
          toComboItem.Products.forEach((toProduct) => {
            //Find matching product in fromComboItem
            const fromProduct = fromComboItem.Products.find(
              (p) => p.ProductId == toProduct.ProductId
            );
            if (fromProduct) {
              OrderProduct.ApplyToOrderProduct(fromProduct, toProduct);
            } else {
              // ComboItem options setup was modified on server after item already in cart
              // Assume unselected
              // TODO: check that defaults were correctly applied
            }
          });
        }
      } else {
        // ComboItem setup was modified on server after Combo was already in cart
        // Assume unselected
        // TODO: check that defaults were correctly applied
      }
    });
    return to;
  }

  // Serialise all selections in a combo so it can be compared,
  // ignoring any fields that aren't required for comparison
  public static SerialiseComboSelections(combo: OrderCombo): string {
    let serialisation = '{';
    combo.Products.forEach((p) => {
      serialisation += '"' + p.Name + '":{';
      p.Products?.forEach((pp) => {
        if (pp.Quantity > 0) {
          serialisation += '"' + pp.Name + '":{';
          serialisation += '"Q":' + pp.Quantity + ',';
          serialisation += '"MG":{';
          pp.ModifierGroups.forEach((mg) => {
            serialisation += '"' + mg.Name + '":{';
            mg.Modifiers.forEach((m) => {
              if (m.Selected != m.Included) {
                serialisation +=
                  '"' + m.Name + '":' + (m.Selected ? 1 : 0) + ',';
              }
            });
            serialisation += '},';
          });
          serialisation += '}';
          serialisation += '"DR":{';
          pp.DietaryRequirements.forEach((dietaryReq, index) => {
            serialisation += '"' + dietaryReq.Name + '":[';
            serialisation +=
              '"' +
              dietaryReq.Name +
              '"' +
              (index + 1 === pp.DietaryRequirements.length)
                ? ''
                : ',';
            serialisation += '],';
          });
          serialisation += '}';
          serialisation += '},';
        }
      });
      p.Modifiers?.forEach((m) => {
        if (m.Selected != m.Included) {
          serialisation += '"' + m.Name + '":' + (m.Selected ? 1 : 0) + ',';
        }
      });
      serialisation += '},';
    });
    serialisation += '}';
    return serialisation;
  }
}

export class OrderComboItem {
  public Name: string;
  public IsModifiers: boolean;
  public Products: Array<OrderProduct>;
  public Modifiers: Array<OrderModifier>;
  public SelectionCount: number;
  public MaxItems: number;
  public MinItems: number;

  public static Validate(oci: OrderComboItem): boolean {
    let valid = true;
    if (oci.IsModifiers) {
      oci.SelectionCount = oci.Modifiers.filter((m) => m.Selected).length;
    } else {
      //Always validate all products before returning so all errors are visible
      oci.Products.forEach((p) => {
        if (p.Quantity > 0) {
          valid = OrderProduct.Validate(p) && valid;
        }
      });

      oci.SelectionCount = oci.Products.map((p) => p.Quantity)
        //sum
        .reduce((a, b) => {
          return a + b;
        }, 0);
    }

    //Invert so expression is not short circuited
    valid =
      oci.SelectionCount <= oci.MaxItems &&
      oci.SelectionCount >= oci.MinItems &&
      valid;

    return valid;
  }
}
