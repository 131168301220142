import { Injectable, NgZone, OnDestroy } from '@angular/core';
import { Store, WeekOpenTime } from '../models/domain/store';
import { IStoreClient } from '../clients/store-client';
import { AppEvents } from '../models/domain/events/app-events';
import { DatePipe } from '@angular/common';
import { EnvironmentVariables } from '../models/environment';
import { combineLatest, interval, Subscription } from 'rxjs';
import { OrderDay } from '../models/domain/order/order-day';
import { distinctUntilChanged, filter } from 'rxjs/operators';
import {
  Banner,
  OnlineConfiguration,
} from '../models/domain/online-configuration';

@Injectable({
  providedIn: 'root',
})
export class StoreService implements OnDestroy {
  public subscriptions: Array<Subscription> = [
    AppEvents.AppLoaded.pipe(filter((loaded) => loaded)).subscribe(() => {
      this.getStores();
      this.getOnlineConfiguration();
    }),
    AppEvents.LoadStoreOrderPeriods.pipe(
      filter((s) => !!s.Store && s.Store.KitchenDollarLimit > 0),
      distinctUntilChanged(
        (previous, next) =>
          // Don't load the same store twice unless the event includes is a forced reload
          previous.Store.Id === next.Store.Id && !next.ForceReload
      )
    ).subscribe((s) => {
      this.getStoreOrderPeriods(s.Store);
    }),
    AppEvents.OrderLoaded.pipe(filter((o) => o != null)).subscribe((order) => {
      this.currentOrderDay = order.OrderDay;
      AppEvents.LoadStoreOrderPeriods.emit({ Store: order.Store });
    }),
    AppEvents.StoreChanged
      //Using this causes e2e tests to hang
      //, interval(5 * 60 * 1000)
      .subscribe((s) => {
        this.currentStore = s.Store;
        this.getStoreStock();
        AppEvents.LoadStoreOrderPeriods.emit({ Store: s.Store });
      }),
    combineLatest([
      AppEvents.StoreChanged.pipe(filter((s) => s?.Store != null)),
      AppEvents.OnlineConfiguration.pipe(filter((o) => o != null)),
    ]).subscribe((e) => {
      this.applyStoreConfiguration(e[0]?.Store, e[1]);
    }),
  ];

  //Reference to current order
  public currentStore: Store;

  public currentOrderDay: OrderDay;

  constructor(
    private client: IStoreClient,
    public variables: EnvironmentVariables,
    public ngZone: NgZone
  ) {}

  getOnlineConfiguration(): void {
    this.client.getOnlineConfiguration().subscribe((c) => {
      if (!c.OrderBanner) {
        c.OrderBanner = { Text: null, Link: null, Name: 'OrderBanner' };
      } else {
        c.OrderBanner.Name = 'OrderBanner';
        this.checkBannerDismissal(c.OrderBanner);
      }

      if (!c.TableBanner) {
        c.TableBanner = { Text: null, Link: null, Name: 'TableBanner' };
      } else {
        c.TableBanner.Name = 'TableBanner';
        this.checkBannerDismissal(c.TableBanner);
      }

      if (!c.CateringBanner) {
        c.CateringBanner = { Text: null, Link: null, Name: 'CateringBanner' };
      } else {
        c.CateringBanner.Name = 'CateringBanner';
        this.checkBannerDismissal(c.CateringBanner);
      }

      if (!c.WarningBanner) {
        c.WarningBanner = { Text: null, Link: null, Name: 'WarningBanner' };
      } else {
        c.WarningBanner.Name = 'WarningBanner';
        this.checkBannerDismissal(c.WarningBanner);
      }

      c.StoreOrderCarousel = c.OrderCarousel;
      c.StoreCateringCarousel = c.CateringCarousel;
      c.StoreTableCarousel = c.TableCarousel;

      c.StoreWarningBanner = c.WarningBanner;

      AppEvents.OnlineConfiguration.next(c);
    });
  }

  //Applies carousel images and banners from the store configuration to the online configuration
  applyStoreConfiguration(
    store: Store,
    onlineConfiguration: OnlineConfiguration
  ): void {
    if (onlineConfiguration.OrderCarousel) {
      onlineConfiguration.StoreOrderCarousel = [
        ...onlineConfiguration.OrderCarousel,
      ];
      if (store.InStore?.Image) {
        onlineConfiguration.StoreOrderCarousel.push(store.InStore?.Image);
      }
    }

    if (onlineConfiguration.TableCarousel) {
      onlineConfiguration.StoreTableCarousel = [
        ...onlineConfiguration.TableCarousel,
      ];
      if (store.TableOrder?.Image) {
        onlineConfiguration.StoreTableCarousel.push(store.TableOrder?.Image);
      }
    }

    if (onlineConfiguration.CateringCarousel) {
      onlineConfiguration.StoreCateringCarousel = [
        ...onlineConfiguration.CateringCarousel,
      ];
      if (store.Catering?.Image) {
        onlineConfiguration.StoreCateringCarousel.push(store.Catering?.Image);
      }
    }

    if (!onlineConfiguration.WarningBanner?.Text && store.WarningBanner?.Text) {
      onlineConfiguration.StoreWarningBanner = store.WarningBanner;
      store.WarningBanner.Name = 'StoreWarningBanner' + store.Id;
    } else {
      onlineConfiguration.StoreWarningBanner =
        onlineConfiguration.WarningBanner;
    }

    AppEvents.CarouselSet.next(onlineConfiguration);
  }

  checkBannerDismissal(banner: Banner): void {
    const lastDismissed = window.localStorage.getItem(banner.Name);
    if (banner.Text == lastDismissed) {
      banner.Text = '';
    }
  }

  getStores(): void {
    this.client.get().subscribe((ss) => {
      ss.sort((a, b) => (a.DisplayName < b.DisplayName ? -1 : 1));

      ss.forEach((s) => {
        this.generateOpenTimeLabels(s.WeekOpenTimes);
        for (const saleType of s.SaleTypes)
          if (saleType.WeekOpenTimes) {
            this.generateOpenTimeLabels(saleType.WeekOpenTimes);
          }
      });

      this.checkOpenHours(ss);
      AppEvents.Stores.next(ss);

      this.ngZone.runOutsideAngular(() => {
        this.subscriptions.push(
          interval(60 * 1000 * 5).subscribe(() =>
            this.checkOpenHours(AppEvents.Stores.value)
          )
        );
      });
    });
  }

  private generateOpenTimeLabels(weekOpenTimes: Array<WeekOpenTime>) {
    const days = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
    const datePipe: DatePipe = new DatePipe('en-US');
    weekOpenTimes.forEach((w, i) => {
      w.Day = days[i];
      w.OpenTimeLabel = datePipe.transform(
        new Date(0, 0, 1, Math.floor(w.OpenTime / 60), w.OpenTime % 60, 0, 0),
        'h:mm a'
      );
      w.CloseTimeLabel = datePipe.transform(
        new Date(0, 0, 1, Math.floor(w.CloseTime / 60), w.CloseTime % 60, 0, 0),
        'h:mm a'
      );
    });
  }

  getStoreStock(): void {
    if (!this.currentStore) {
      return;
    }
    this.client.getStoreStock(this.currentStore).subscribe((i) => {
      //Don't emit if current store has changed between
      if (i.Id == this.currentStore.Id) {
        AppEvents.StoreStockLoaded.emit(i);
      }
    });
  }

  checkOpenHours(stores: Array<Store>): void {
    const currentTime = new Date();
    const currentOffset =
      currentTime.getHours() * 60 + currentTime.getMinutes();
    const currentDay = (currentTime.getDay() + 6) % 7;
    stores.forEach((s) => {
      s.IsOpen = this.openHourComparison(
        s.WeekOpenTimes[currentDay],
        currentOffset
      );
      if (s.TableOrder) {
        s.TableOrder.IsOpen = this.openHourComparison(
          (s.TableOrder.WeekOpenTimes ?? s.WeekOpenTimes)[currentDay],
          currentOffset
        );
      }
      if (s.Catering) {
        s.Catering.IsOpen = this.openHourComparison(
          (s.Catering.WeekOpenTimes ?? s.WeekOpenTimes)[currentDay],
          currentOffset
        );
      }
    });
  }

  openHourComparison(weekOpenTime: WeekOpenTime, offset: number): boolean {
    return (
      !weekOpenTime.Closed &&
      offset >= weekOpenTime.OpenTime &&
      offset < weekOpenTime.CloseTime
    );
  }

  private getStoreOrderPeriods(store: Store): void {
    this.client.getStoreOrderPeriods(store).subscribe((orderPeriods) => {
      store.OrderPeriods = orderPeriods;
      AppEvents.StoreOrderPeriodsChanged.emit({ Store: store });
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }
}
