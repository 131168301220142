import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { AppEvents } from 'src/app/models/domain/events/app-events';
import { Category } from 'src/app/models/domain/category';
import { Menu } from 'src/app/models/domain/menu';
import { BehaviorSubject, timer } from 'rxjs';

/**
 * Renders a list of the categories in the menu.
 */
@Component({
  selector: 'app-categories-list',
  templateUrl: './categories-list.component.html',
  styleUrls: ['./categories-list.component.scss'],
})
export class CategoriesListComponent implements AfterViewInit, OnDestroy {
  /** Menu from which to display categories. */
  @Input()
  public menu: Menu;

  /**
   * `true` to highlight the `currentCategory` as selected.
   */
  @Input()
  public showSelectedCategory: boolean;

  /** Category to display as selected. */
  @Input()
  public set currentCategory(newCategory: Category) {
    // Updates the observable when the parent component passes a new category in
    // as selected.
    this.selectedCategory$.next(newCategory);
  }

  /** Observable of the category to display as selected. */
  public selectedCategory$ = new BehaviorSubject<Category>(null);

  /**
   * (Optional) Emits when a category is clicked.
   */
  @Output()
  public categoryClicked = new EventEmitter<Category>();

  /**
   * `true` to highlight all menu categories as selected if they have
   * `Category.ProductCount > 0`.
   */
  // TODO: Fix to work with subcategories. Always set to false until done.
  @Input()
  public showCategoriesInOrder: boolean;

  private subscriptions = [
    this.selectedCategory$.subscribe(() => {
      this.scrollToCurrentCategory();
    }),
  ];

  @ViewChild('scroll', { read: ElementRef })
  scroll: ElementRef<HTMLElement>;
  @ViewChild('scrollLeft', { read: ElementRef })
  scrollLeft: ElementRef<HTMLElement>;
  @ViewChild('scrollRight', { read: ElementRef })
  scrollRight: ElementRef<HTMLElement>;

  constructor() {}

  ngAfterViewInit(): void {
    if (this.menu?.Categories?.length <= 1) {
      return;
    }
    this.updateScrollShadows();
    timer(1000).subscribe(() => this.scrollToCurrentCategory());
    this.scroll.nativeElement.addEventListener('scroll', (ev) =>
      this.updateScrollShadows(ev)
    );
    window.addEventListener('resize', this.updateScrollShadows);
  }

  /**
   * Scrolls the categories list left and right.
   *
   * @param isLeftScroll `true` to scroll left, `false` to scroll right.
   */
  handleScroll(isLeftScroll: boolean): void {
    if (this.menu?.Categories?.length <= 1) {
      return;
    }
    const pixelsToScroll = 250;
    this.scroll.nativeElement.scrollBy({
      behavior: 'smooth',
      left: isLeftScroll ? -pixelsToScroll : pixelsToScroll,
    });
    this.updateScrollShadows();
  }

  /**
   * Hides the scroll shadows when touching that side of the scrollable content.
   */
  private updateScrollShadows(event?: Event) {
    const el = (event?.target as HTMLElement) ?? this.scroll?.nativeElement;

    if (
      !el ||
      !this.scrollLeft?.nativeElement ||
      !this.scrollRight?.nativeElement
    ) {
      return;
    }

    // Round el.scrollLeft up because sometimes it has a decimal value causing it to be
    // < 1px lower than `el.scrollHeight - el.clientHeight` when the user scrolls
    // completely to the bottom due to some systems' display scaling.
    // see warning on https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollTop
    const atRightEdge =
      Math.ceil(el.scrollLeft) >= el.scrollWidth - el.clientWidth;
    const atLeftEdge = Math.floor(el.scrollLeft) <= 0;

    if (atLeftEdge && atRightEdge) {
      // Both edges visible
      this.scrollLeft.nativeElement.style.display = 'none';
      this.scrollRight.nativeElement.style.display = 'none';
    } else if (atRightEdge) {
      this.scrollLeft.nativeElement.style.display = 'flex';
      this.scrollRight.nativeElement.style.display = 'none';
    } else if (atLeftEdge) {
      this.scrollLeft.nativeElement.style.display = 'none';
      this.scrollRight.nativeElement.style.display = 'flex';
    } else {
      // At neither edge
      this.scrollLeft.nativeElement.style.display = 'flex';
      this.scrollRight.nativeElement.style.display = 'flex';
    }
  }

  /**
   * Fires a `ChangeCategory` event with the category that was clicked.
   */
  selectCategory(category: Category): void {
    this.categoryClicked.next(category);
    if (category != this.selectedCategory$.getValue()) {
      AppEvents.ChangeCategory.emit(category);
      //Component gets passed the new category when it gets selected, no need to
      // call scroll explicitly
    }
  }

  /**
   * Scrolls the picker so the selected category is visible. This will centre it
   * as much as possible.
   */
  scrollToCurrentCategory(): void {
    if (this.menu?.Categories?.length <= 1) {
      return;
    }
    const selectedCategory = this.selectedCategory$.getValue();
    if (!this.showSelectedCategory || !selectedCategory || !this.menu) {
      return;
    }
    const categoryIndex = this.menu.Categories.indexOf(selectedCategory);
    if (categoryIndex == -1) {
      return;
    }

    const categoryPickerWidth = this.scroll.nativeElement.clientWidth;
    const categoryEl = this.scroll.nativeElement.children[
      categoryIndex
    ] as HTMLElement;
    const elementLeft =
      categoryEl.offsetLeft -
      (categoryPickerWidth - categoryEl.clientWidth) / 2;

    this.scroll.nativeElement.scrollTo({
      left: elementLeft,
      top: 0,
      behavior: 'smooth',
    });

    this.updateScrollShadows();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }
}
