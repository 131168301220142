import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Member } from 'src/app/models/domain/member';
import { EnvironmentVariables } from 'src/app/models/environment';
import { IMemberService } from 'src/app/services/member.service';
import { OrderService } from 'src/app/services/order.service';
import { LoginModalComponent } from '../login/login-modal/login-modal.component';
import { AppEvents } from 'src/app/models/domain/events/app-events';
import { NavigationEvents } from 'src/app/models/domain/events/navigation-events';
import { NavigationPage } from 'src/app/models/domain/navigation-page';
import { MemberEvents } from 'src/app/models/domain/events/member-events';
import { getLoyaltyNumberFormat } from '../../helpers/angularHelper';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  @Input()
  member: Member = null;

  inApp: boolean = MemberEvents.InApp.getValue();

  inAppLocations: boolean =
    window.location.toString().includes('locations') && this.inApp;

  public loyaltyPointFormat = getLoyaltyNumberFormat(this.variables);
  public isLoggingIn$ = MemberEvents.LoggingIn;

  subscriptions: Subscription[] = [];

  constructor(
    public memberService: IMemberService,
    public orderService: OrderService,
    public modalController: ModalController,
    public variables: EnvironmentVariables
  ) {}

  ngOnInit(): void {
    this.subscriptions = [
      MemberEvents.InApp.subscribe((i) => {
        this.inApp = i;
        this.inAppLocations =
          window.location.toString().includes('locations') && this.inApp;
      }),
    ];
  }

  showRewardsModal(): void {
    if (this.member.Tags.includes('LOYALTYOPTIN')) {
      AppEvents.OpenRewards.emit();
    } else {
      NavigationEvents.NavigateToPage.emit(NavigationPage.AccountDetails);
    }
  }

  async showLoginModal(): Promise<void> {
    const loginModal = await this.modalController.create({
      component: LoginModalComponent,
      componentProps: {
        loginModel: this.memberService.loginModel,
      },
      cssClass: 'show-header-modal login-modal',
    });
    await loginModal.present();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }
}
