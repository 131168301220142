import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AppEvents } from 'src/app/models/domain/events/app-events';
import { Member } from 'src/app/models/domain/member';
import { Order } from 'src/app/models/domain/order/order';
import { SaleType, Store } from 'src/app/models/domain/store';
import { StoreChangeEvent } from 'src/app/models/domain/events/store-change-event';
import { StoreTimePickerModel } from 'src/app/models/view-models/store-time-picker-model';
import { EnvironmentVariables } from 'src/app/models/environment';
import { timer } from 'rxjs';
import { OrderTimeChangedReason } from 'src/app/models/domain/events/order-time-changed-reason';
import { OrderTimeChangeEvent } from 'src/app/models/domain/events/order-time-changed-event';
import { OrderDayChangeEvent } from 'src/app/models/domain/events/order-day-changed-event';

// Component for displaying the currently selected store, method and time for mobile
@Component({
  selector: 'app-store-time-display',
  templateUrl: './store-time-display.component.html',
  styleUrls: ['./store-time-display.component.scss'],
})
export class StoreTimeDisplayComponent implements AfterViewInit, OnDestroy {
  @Input()
  stores: Array<Store>;

  @Input()
  order: Order;

  @Input()
  member: Member;

  @Input()
  mode: string; //dropdown(default)|link

  @ViewChild('container', { read: ElementRef })
  container: ElementRef<HTMLElement>;

  public storeTimePickerShown: boolean;
  public storeTimePickerModal: HTMLIonModalElement;
  public storeTimePickerModel: StoreTimePickerModel;

  public systemChangedDay = false;
  public systemChangedTime = false;

  //Binding in HTML
  public SaleType = SaleType;

  public subscriptions = [
    AppEvents.StoreChanged.subscribe(() => {
      this.onStoreChanged();
    }),
    AppEvents.OrderDayChanged.subscribe((ev) => {
      this.onOrderDayChangedSuccessfully(ev);
    }),
    AppEvents.OrderTimeLastChanged.subscribe((ev) => {
      this.onOrderTimeSuccessfullyChanged(ev);
    }),
  ];

  constructor(
    public modalController: ModalController,
    public variables: EnvironmentVariables
  ) {}

  ngAfterViewInit(): void {
    timer(100).subscribe(() => {
      const scrollEl = this.searchForScrollPreventionElement(
        this.container.nativeElement
      );

      if (scrollEl != null) {
        scrollEl.addEventListener(
          'DOMMouseScroll',
          this.preventDefaultFunction(this),
          false
        );
        scrollEl.addEventListener('wheel', this.preventDefaultFunction(this));
        scrollEl.addEventListener(
          'mousewheel',
          this.preventDefaultFunction(this),
          {
            passive: false,
          }
        );
        scrollEl.addEventListener(
          'touchmove',
          this.preventDefaultFunction(this),
          {
            passive: false,
          }
        );
        scrollEl.addEventListener('scroll', () => {
          if (this.storeTimePickerShown) {
            scrollEl.scrollTo({ top: 0 });
          }
        });
      }
    });
  }

  /**
   * Sets `systemChangedTime` depending on `OrderTimeChangeEvent.Reason`.
   */
  private onOrderTimeSuccessfullyChanged(ev: OrderTimeChangeEvent) {
    this.systemChangedTime = this.didSystemChangeTime(ev.Reason);
  }

  /**
   * Sets `systemChangedTime` depending on `OrderTimeChangeEvent.Reason`.
   */
  private onOrderDayChangedSuccessfully(ev: OrderDayChangeEvent) {
    this.systemChangedDay = this.didSystemChangeTime(ev.Reason);
  }

  private didSystemChangeTime(reason: OrderTimeChangedReason) {
    return (
      reason === OrderTimeChangedReason.ORDER_DAY_NOT_AVAILABLE ||
      reason === OrderTimeChangedReason.ORDER_SIZE_KITCHEN_LIMITS ||
      reason === OrderTimeChangedReason.ORDER_SIZE_CATERING_THRESHOLD
    );
  }

  preventDefaultFunction(localReference: StoreTimeDisplayComponent) {
    return (e: Event): void => {
      if (localReference.storeTimePickerShown) {
        e.preventDefault();
        e.stopPropagation();
      }
    };
  }

  searchForScrollPreventionElement(element: HTMLElement): HTMLElement {
    if (element.parentElement == null) {
      return null;
    } else if (
      element.parentElement.classList.contains('main-menu-content') ||
      element.parentElement.classList.contains('summary-content')
    ) {
      return element.parentElement;
    } else {
      return this.searchForScrollPreventionElement(element.parentElement);
    }
  }

  showStoreTimePicker(): void {
    if (this.order.IsTableOrder) {
      return;
    }
    if (!this.storeTimePickerShown) {
      this.storeTimePickerModel = new StoreTimePickerModel({
        Store: this.order.Store,
        SaleType: this.order.SaleType,
        OrderDay: this.order.OrderDay,
        OrderTime: this.order.OrderTime,
      });

      this.storeTimePickerShown = true;
      //disable scroll in most recent parent element while it is open
    }
  }

  storeTimePickerDismissed(result: StoreTimePickerModel): void {
    if (result) {
      if (this.order.Store != result.Store) {
        AppEvents.ChangeStore.emit(
          new StoreChangeEvent(result.Store, this.order, true)
        );
      }

      if (this.order.SaleType != result.SaleType) {
        AppEvents.ChangeSaleType.emit(result.SaleType);
      }
      if (this.order.OrderDay != result.OrderDay) {
        AppEvents.ChangeOrderDay.emit({
          OrderDay: result.OrderDay,
          Reason: OrderTimeChangedReason.USER,
        });
      }
      if (this.order.OrderTime != result.OrderTime) {
        AppEvents.ChangeOrderTime.emit({
          OrderTime: result.OrderTime,
          Reason: OrderTimeChangedReason.USER,
        });
      }
    }
    this.storeTimePickerShown = false;
  }

  //Store changed by store service
  onStoreChanged(): void {
    if (this.storeTimePickerModal) {
      this.storeTimePickerModal.dismiss();
    }
  }

  /**
   * Returns `true` when the order day has some times, and none of them are
   * enabled.
   */
  hasNoAvailableTimes(): boolean {
    return (
      this.order?.OrderDay?.AvailableOrderTimes?.length &&
      this.order.OrderDay.AvailableOrderTimes.every((time) => !time.Enabled)
    );
  }

  /**
   * Returns `true` when the order day has no times, whether enabled or not.
   */
  hasNoStoreTimes(): boolean {
    return this.order?.OrderDay?.AvailableOrderTimes?.length === 0;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }
}
