import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: environment.variables.appInsightsInstrumentationKey,
    enableAutoRouteTracking: true, // enable automatic route change tracking for your single page application
  },
});

appInsights.loadAppInsights();
appInsights.trackPageView(); // Manually call trackPageView to establish the current user/session/pageview

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
