import { EventEmitter } from '@angular/core';
import { BehaviorSubject, merge } from 'rxjs';
import { UnavailableProductsModel } from '../../view-models/unavailable-products-model';
import { Category } from '../category';
import { Reward } from '../reward';
import { Menu } from '../menu';
import { OnlineConfiguration } from '../online-configuration';
import { OrderPeriod } from '../order-period';
import { Order } from '../order/order';
import { OrderCombo } from '../order/order-combo';
import { OrderDay } from '../order/order-day';
import { OrderProduct } from '../order/order-product';
import { OrderTime } from '../order/order-time';
import { SaleType, Store } from '../store';
import { StoreStock } from '../store-stock';
import { StoreChangeEvent } from './store-change-event';
import { CheckAvailableOrderTimesEvent } from './update-available-order-days-event';
import { OrderTimeChangeEvent } from './order-time-changed-event';
import { OrderDayChangeEvent } from './order-day-changed-event';
import { shareReplay } from 'rxjs/operators';
import { OrderValidationResult } from '../order/order-validation-result';
import { CouponApplicationResult } from '../response/coupon-application-result';
import { PurchasableReward } from '../purchasable-reward';
import { MemberEvents } from './member-events';
import { NavigationEvents } from './navigation-events';
import { ProductModifierUpdate } from '../product-modifier-update';
import { ErrorModalEvent } from './error-modal-event';
import { OrderTimeChangeFailedEvent } from './order-time-change-failed-event';
import { NetworkErrorEvent } from './network-error-event';

export class AppEvents {
  /*          App Initialise events           */

  /**
   * Indicates that the services are all constructed and have created their
   * subscriptions lists. Anything that would be run in the constructor of a
   * service (other than event subscriptions) should be instead run when this
   * emits `true`.
   */
  public static AppLoaded = new BehaviorSubject<boolean>(false);
  public static Stores = new BehaviorSubject<Array<Store>>(null);
  //In future, can have a BehaviorSubject for the whole order
  public static OrderTime = new BehaviorSubject<OrderTime>(null);
  public static OnlineConfiguration = new BehaviorSubject<OnlineConfiguration>(
    null
  );
  public static CurrentLocation =
    new BehaviorSubject<google.maps.LatLngLiteral>(null);

  /** `true` when the maps API has loaded. */
  public static MapLoaded = new BehaviorSubject<boolean>(false);

  public static MenusInitialised = new BehaviorSubject<boolean>(false);
  public static MenusLoaded = new EventEmitter<Array<Menu>>();
  /**
   * Allows anything to preconfigure properties on the order
   * Before any checks happen like does the store/sales type exist
   * and products already existing in the cart are verified
   * Cart will be empty as products are still loading
   * This is a BehaviourSubject instead of an EventEmitter
   * so that components don't miss out on initialisation if they
   * are instantiated after order service calls OrderPreLoaded
   */
  public static OrderPreLoaded = new BehaviorSubject<Order>(null);

  /** `true` if a reward is being loaded form the server. */
  public static RewardLoading = new BehaviorSubject<boolean>(false);

  /** Happens once the store, salestype, and products have been verified */
  public static OrderLoaded = new BehaviorSubject<Order>(null);

  /*          Order Initialise events         */
  public static StockUpdated = new EventEmitter<Menu>();
  public static ProductsUnavailable =
    new EventEmitter<UnavailableProductsModel>();

  /*          Order Details events            */
  public static ChangeStore = new EventEmitter<StoreChangeEvent>();
  public static StoreChanged = new EventEmitter<StoreChangeEvent>();
  public static RefreshStoreInfo = new EventEmitter<Store>();
  public static StoreStockLoaded = new EventEmitter<StoreStock>();

  public static ChangeMenuId = new EventEmitter<string>();
  public static MenuChanged = new EventEmitter<Menu>();
  /**
   * Always called after ChangeMenuId, even if the menu didn't actually change.
   */
  public static MenuSet = new EventEmitter<Menu>();

  public static ChangeSaleType = new EventEmitter<SaleType>();
  public static SaleTypeChanged = new EventEmitter<{
    Order: Order;
    SaleType: SaleType;
  }>();

  public static ChangeGuestOrderType = new EventEmitter<boolean>();
  public static GuestOrderChanged = new EventEmitter<{
    Order: Order;
    IsGuestOrder: boolean;
  }>();

  /** Emits when the app should load the order periods for a store */
  public static LoadStoreOrderPeriods = new EventEmitter<{
    Store: Store;
    ForceReload?: boolean;
  }>();
  /** Set order times for a store and salestype regardless of order. */
  public static SetStoreAvailableOrderTimes = new EventEmitter<{
    Store: Store;
  }>();
  /** Emits when the order times for a store have been set. */
  public static StoreAvailableOrderTimesInitialised = new EventEmitter<{
    Store: Store;
  }>();
  /** Emits when times are enabled/disabled due to kitchen limits or wait times. */
  public static OrderTimesStatusChanged = new EventEmitter<void>();
  /** Enable order times based on cart. */
  public static CheckAvailableOrderTimes =
    new EventEmitter<CheckAvailableOrderTimesEvent>();
  public static AvailableOrderDaysChanged = new EventEmitter<Array<OrderDay>>();
  public static ChangeOrderDay = new EventEmitter<OrderDayChangeEvent>();
  public static OrderDayChanged = new EventEmitter<OrderDayChangeEvent>();

  /**
   * Information about the current orders in the system for a day.
   */
  public static StoreOrderPeriods = new BehaviorSubject<Array<OrderPeriod>>(
    null
  );
  /**
   * Emits when `StoreOrderPeriods` is updated.
   */
  public static StoreOrderPeriodsChanged = new EventEmitter<{
    Store: Store;
  }>();

  public static AvailableOrderTimesChanged = new EventEmitter<
    Array<OrderTime>
  >();

  public static ChangeOrderTime = new EventEmitter<OrderTimeChangeEvent>();
  public static OrderTimeChanged = new EventEmitter<OrderTimeChangeEvent>();
  public static OrderUpdateTimeFailed =
    new EventEmitter<OrderTimeChangeFailedEvent>();
  /**
   * Observable of `OrderTimeChanged` that replays the last result to new
   * subscribers. For subscribers that only want to be notified when the event
   * is triggered use `OrderTimeChanged` instead.
   */
  public static OrderTimeLastChanged = AppEvents.OrderTimeChanged.pipe(
    shareReplay(1)
  );

  /*          UI events                       */
  public static ChangeCategory = new EventEmitter<Category>();
  public static CarouselSet = new BehaviorSubject<OnlineConfiguration>(null);

  /*          Cart events                     */
  public static ViewProduct = new EventEmitter<OrderProduct>();
  public static ViewCombo = new EventEmitter<OrderCombo>();
  public static AddProduct = new EventEmitter<OrderProduct>();
  public static AddProducts = new EventEmitter<{
    products: OrderProduct[];
  }>();
  public static AddCombo = new EventEmitter<OrderCombo>();
  public static ChangeQuantity = new EventEmitter<{
    OrderProduct: OrderProduct;
    Change: number;
  }>();
  public static ChangeComboQuantity = new EventEmitter<{
    OrderCombo: OrderCombo;
    Change: number;
  }>();
  public static ModifiersChanged = new EventEmitter<{
    changes: Map<OrderProduct, ProductModifierUpdate>;
  }>();
  public static UpdateCart = new EventEmitter<void>();
  public static CartUpdated = new EventEmitter<{
    Order: Order;
    SubtotalIncreased: boolean;
  }>();
  public static CartCleared = new EventEmitter<Order>();
  public static CalculatePrice = new EventEmitter<OrderProduct>();
  public static CalculateComboPrice = new EventEmitter<OrderCombo>();

  /*          Order API events                */
  public static OrderIdSet = new EventEmitter<Order>();
  /** Emits a link for the user to pay for their order. */
  public static PaymentLink = new BehaviorSubject<{
    link: string;
  }>(null);
  /**
   * Emits when an order payment (price > $0) fails.
   *
   * Use `OrderFailed` to catch both submission and payment failures.
   */
  public static OrderPaymentFailed = new EventEmitter<void>();
  public static SubmitOrder = new EventEmitter<void>();
  public static OrderSubmitted = new EventEmitter<Order>();
  /**
   * Emits when an order submission (price = $0) fails.
   *
   * Use `OrderFailed` to catch both submission and payment failures.
   */
  public static OrderSubmitFailed = new EventEmitter<string>();
  /** Emits on both `OrderPaymentFailed` and `OrderSubmitFailed` events. */
  public static OrderFailed = merge(
    AppEvents.OrderPaymentFailed,
    AppEvents.OrderSubmitFailed
  );
  public static ValidateOrder = new EventEmitter<{ Order: Order }>();
  public static OrderValidated = new EventEmitter<OrderValidationResult>();

  public static GetAllDuplicateOrders = new EventEmitter<{ Order: Order }>();
  public static DuplicateOrders = new EventEmitter<Array<Order>>();

  public static ResendReceipt = new EventEmitter<string>();
  public static ReceiptResent = new EventEmitter<void>();

  public static Reorder = new EventEmitter<Order>();

  /*          Coupon events                   */
  public static CouponCodeEntered = new EventEmitter<{
    Code: string;
    Order: Order;
  }>();
  public static RedeemableResult = new EventEmitter<CouponApplicationResult>();

  public static PurchaseReward = new EventEmitter<{
    purchasableReward: PurchasableReward;
  }>();
  public static RewardPurchased = new EventEmitter<{
    price: number;
    reward: Reward;
  }>();
  public static RewardPurchaseFailed = new EventEmitter<{
    failureMessages: string[];
  }>();

  public static RewardApplied = new EventEmitter<{
    Asset: Reward;
    Order: Order;
  }>();

  /** The reward modal should be opened. */
  public static OpenRewards = new EventEmitter<void>();

  /** Logs the device information. */
  public static LogDeviceInfo = new EventEmitter<void>();

  /** Displays an error modal from the app component. */
  public static ShowErrorModal = new EventEmitter<ErrorModalEvent>();

  /*          For debugging                   */
  public static LogEvent = new EventEmitter<string>();

  /** Emits when a network request fails. */
  public static NetworkError = new EventEmitter<NetworkErrorEvent>();

  /** Logs should get deleted. */
  public static ClearLogs = new EventEmitter<void>();
}

// Change to true for debugging when any app event emits
const debugEvents = false;
if (debugEvents) {
  const subscribe = (name: string, eventClass: any) =>
    Object.keys(eventClass).forEach((evName) => {
      eventClass[evName].subscribe?.((v: any) =>
        // eslint-disable-next-line no-console
        console.debug(`${name}.${evName} emitted. Value: `, v)
      );
    });

  subscribe('AppEvents', AppEvents);
  subscribe('MemberEvents', MemberEvents);
  subscribe('NavigationEvents', NavigationEvents);
}
