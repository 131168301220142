export class LoginModel {
  public ViewMode: LoginViewMode;
  public EmailAddress: string;
  public FirstName: string;
  public LastName: string;
  public PhoneNumber: string;
  public Birthday: string;
  public TermsAndConditions: boolean;
  public LoyaltyProgram: boolean;
  public AllowEmail: boolean;
  public AccessToken: string;
  public Error: string;
  public Loading: boolean;
  public ConfirmPhoneNumber: string;
  constructor(o: Partial<LoginModel>) {
    Object.assign(this, o);
  }
}

export enum LoginViewMode {
  PhoneNumber = 'PhoneNumber',
  AccessToken = 'AccessToken',
  Register = 'Register',
}
