import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalController, ViewDidEnter } from '@ionic/angular';
import { ErrorModalComponent } from 'src/app/components/error-modal/error-modal.component';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { AppEvents } from 'src/app/models/domain/events/app-events';
import { NavigationEvents } from 'src/app/models/domain/events/navigation-events';
import { OrderPage } from 'src/app/models/domain/navigation-page';
import { Order } from 'src/app/models/domain/order/order';
import { SaleType, Store } from 'src/app/models/domain/store';
import { EnvironmentVariables } from 'src/app/models/environment';
import { IMemberService } from 'src/app/services/member.service';
import { OrdersService } from 'src/app/services/orders.service';

@Component({
  selector: 'app-order-confirmation',
  templateUrl: './order-confirmation.component.html',
  styleUrls: ['./order-confirmation.component.scss'],
})
export class OrderConfirmationComponent implements ViewDidEnter, OnDestroy {
  order: Order;
  amountCheckResult: string;
  amountCheckAmount: string;
  amountCheckPaid: number;
  stores: Array<Store>;

  SaleType = SaleType;
  subscriptions = [
    AppEvents.Stores.subscribe((stores) => {
      this.stores = stores;
      this.applyInformationToOrder();
    }),
  ];

  constructor(
    public route: ActivatedRoute,
    public ordersService: OrdersService,
    public memberService: IMemberService,
    public modalController: ModalController,
    public variables: EnvironmentVariables
  ) {}

  ionViewDidEnter(): void {
    this.route.queryParams
      .pipe(
        map((params) => params.orderSignature as string | undefined),
        distinctUntilChanged()
      )
      .subscribe((orderIdSignature) => {
        if (!orderIdSignature || orderIdSignature.length < 10) {
          this.loadFailed(
            'Order is currently processing, please check your email for confirmation.'
          );
          return;
        }
        this.ordersService.getOrder(orderIdSignature).subscribe((o) => {
          this.order = o;
          this.applyInformationToOrder();
          this.ordersService
            .validateAmountPaid(orderIdSignature)
            .subscribe((r) => {
              this.amountCheckPaid = this.order.Total;
              if (r != 'Amount paid is correct') {
                this.amountCheckAmount = r.split(' ').pop();
                this.amountCheckResult = r.replace(this.amountCheckAmount, '');
                if (this.amountCheckResult.startsWith('Pay')) {
                  this.amountCheckPaid =
                    this.order.Total - parseFloat(this.amountCheckAmount);
                }
                if (this.amountCheckResult.startsWith('Refund')) {
                  this.amountCheckPaid =
                    this.order.Total + parseFloat(this.amountCheckAmount);
                }
              }
            });
        });
      });
  }

  private async loadFailed(error: string): Promise<void> {
    //we need to try load the order again
    this.ordersService.orderClient.getStored();

    const errorModal = await this.modalController.create({
      component: ErrorModalComponent,
      componentProps: {
        error: error,
      },
      cssClass: 'curbside-warning-modal',
    });
    await errorModal.present();
  }

  private applyInformationToOrder(): void {
    if (this.order != null && this.stores != null) {
      const store = this.stores.find((s) => s.Name == this.order.Store.Name);
      if (store) {
        this.order.Store = store;
        this.order.IsTableOrder =
          this.order.SaleType.Code == SaleType.TableOrderCode;
        this.order.SaleType.Name = SaleType.TableOrder;
        if (
          this.order.SaleType?.Code != SaleType.CateringCode &&
          !this.order.IsTableOrder
        ) {
          const saleType = store.SaleTypes.find(
            (s) => s.Code == this.order.SaleType.Code
          );
          if (saleType) {
            this.order.SaleType = saleType;
          }
        }
      }
    }
  }

  getDirections(store: Store): void {
    let storeDirections =
      'https://www.google.com/maps/place/' +
      encodeURIComponent(store.Address) +
      ', ' +
      store.Suburb;

    //if store address does not start with a number, use the lat and long
    if (!store.Address[0].match(/^\d/)) {
      storeDirections =
        'https://www.google.com/maps?q=' +
        encodeURIComponent(store.Latitude + ',' + store.Longitude);
    }

    window.open(storeDirections, '_blank');
  }

  goToMainMenu(): void {
    NavigationEvents.NavigateToOrderPage.emit({
      Order: this.order,
      Page: OrderPage.MainMenu,
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }
}
