import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { EnvironmentVariables } from '../models/environment';
import { catchError, map } from 'rxjs/operators';
import { DietaryRequirement } from '../models/domain/dietary-requirement';
import { DietaryRequirementDto } from '../models/dto/dietary-requirement.dto';

/**
 * Gets global configuration options.
 */
export abstract class IConfigClient {
  /**
   * Fetches the dietary requirement options from the server.
   */
  abstract getDietaryRequirements(): Observable<DietaryRequirement[]>;
}

@Injectable({
  providedIn: 'root',
})
export class ConfigClient implements IConfigClient {
  constructor(
    private client: HttpClient,
    public variables: EnvironmentVariables
  ) {}

  getDietaryRequirements(): Observable<DietaryRequirement[]> {
    return this.client
      .get<DietaryRequirementDto[]>(
        this.variables.baseApiUrl + '/Configuration/DietaryRequirements'
      )
      .pipe(
        catchError(this.handleError),
        map((requirements) => requirements.map(DietaryRequirementDto.toDomain))
      );
  }

  private handleError(exception: HttpErrorResponse): Observable<never> {
    return throwError(exception.error);
  }
}
