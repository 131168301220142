// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { ConfigClient } from 'src/app/clients/config-client';
import { MemberClient } from 'src/app/clients/member-client';
import { MenuClient } from 'src/app/clients/menu-client';
import { OrderClient } from 'src/app/clients/order-client';
import { RewardClient } from 'src/app/clients/reward-client';
import { StoreClient } from 'src/app/clients/store-client';
import { Category } from 'src/app/models/domain/category';
import { Environment } from 'src/app/models/environment';

export const environment: Environment = {
  production: false,
  variables: {
    brandTradeName: 'Shake Out Limited',
    brandPath: 'shakeout',
    comoLocationId: '9094',
    appInsightsInstrumentationKey: '899d24b3-8fd3-447e-b597-87d3d4fbc490',
    pageTitle: '[UAT] Shake Out | Order Online',
    favicon: 'assets/shakeout/favicon.png',
    baseApiUrl: 'https://api-uat.shakeout.co',
    // baseApiUrl: 'https://localhost:5001',
    paymentUrl: 'https://localhost:44396/',
    futureOrderDays: 2,
    defaultMenu: 1,
    logoLocation: 'assets/shakeout/logo.svg',
    categoryPLUs: {
      Burger: 2004,
      Side: 2038,
      Drink: 2034,
      Dessert: 2026,
    },
    displayCombosSeparately: false,
    disableTimers: false,
    gtmCode: 'GTM-T27SV2F',
    googleMapsApiKey: 'AIzaSyBA2qrW-ykUfJPL-m2RX6RUcMYiz73yK9M',
    fbPixelCode: '433366607267050',
    facebookLink: 'https://www.facebook.com/ShakeOutNZ/',
    instagramLink: 'https://www.instagram.com/shakeoutnz/',
    tiktokLink: '',
    youtubeLink: '',
    privacyLink: 'https://www.shakeout.co/privacy-policy',
    termsLink: 'https://www.shakeout.co/terms-and-conditions/',
    categories: [
      //Combos for shake out do not have their own category header
      //and are not displayed on the main menu
      //To get a combo you must first choose a burger and convert it to a combo
      new Category({
        Name: 'Burger',
        DisplayName: 'Burgers',
        DisplayOrder: 2,
      }),
      new Category({
        Name: 'Side',
        DisplayName: 'Sides',
        DisplayOrder: 3,
      }),
      new Category({
        Name: 'Drink',
        DisplayName: 'Drinks',
        DisplayOrder: 4,
      }),
      new Category({
        Name: 'Dessert',
        DisplayName: 'Desserts',
        DisplayOrder: 5,
      }),
    ],
    currencyFormat: '1.0-2',
    cateringConfig: {
      cateringEnabled: true,
    },
    loyaltyConfig: {
      loyaltyEnabled: true,
      pointRatio: 100,
      pointDecimals: 0,
      loyaltyLink: 'https://www.shakeout.co/terms-and-conditions#loyalty',
      loyaltyLabel: 'Shake Out Perks',
      rewardsModalTitle: 'My Perks',
      rewardShopSubtitle: 'Perks shop',
      earnVerb: 'earn',
      //Has a space in it as points template does not, accommodating BF
      pointsName: ' points',
      rewardsName: 'perk',
      purchaseRewardsEnabled: true,
      loyaltyToggleLabel: 'Opt in to our loyalty programme',
      loyaltyToggleLabelDescription:
        'Get access to sweet perks and exclusive deals, promos, prizes and more',
      emailToggleLabel: 'Receive email notifications',
      emailToggleLabelDescription:
        'Get notified on the latest Shake Out news, perks offers, promotions and more',
      usedRewardLockTime: '5 minutes',
      loyaltyLogo: 'assets/shakeout/LoyaltyLogo.png',
      loyaltyReferredSignUpText: 'Sign up to Shake Out Perks and get points!',
    },
    gstPercentage: 0.15,
    sliderSpeed: 8000,
    mapConfig: {
      features: [
        {
          featureType: 'all',
          stylers: {
            hue: '#7affbf',
            saturation: -60,
          },
        },
        {
          featureType: 'road.highway',
          stylers: {
            hue: '#79ff61',
          },
        },
      ],
      mapMarkers: {
        labelColour: 'green',
        iconWidth: 19,
        iconHeight: 30,
      },
    },
    notificationsConfig: {
      notificationsEnabled: true,
      showLoyaltySection: true,
    },
  },
  storeClient: StoreClient,
  menuClient: MenuClient,
  orderClient: OrderClient,
  memberClient: MemberClient,
  rewardClient: RewardClient,
  configClient: ConfigClient,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
