import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { Member, SavedCard } from '../models/domain/member';
import { RegisterInfo } from '../models/domain/login/register-info';
import { EnvironmentVariables } from '../models/environment';
import { MemberResult } from '../models/domain/response/member-result';
import { catchError, map } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import { isEmptyOrSpaces } from '../helpers/stringHelpers';

export abstract class IMemberClient {
  /** Sends a verification code to the `phoneNumber` */
  abstract sendIdentificationCodeSms(
    phoneNumber: string
  ): Observable<MemberResult>;
  /** Sends a verification code to the email associated with `phoneNumber` */
  abstract sendIdentificationCodeEmail(
    phoneNumber: string
  ): Observable<MemberResult>;
  abstract getMemberFromAccessToken(
    phoneNumber: string,
    token: string
  ): Observable<MemberResult>;
  //Temporary method only for debugging
  abstract getMemberFromAppToken(token: string): Observable<MemberResult>;
  abstract getLoggedInMember(): Observable<MemberResult>;
  abstract isPreviouslyLoggedIn(): boolean;
  abstract register(registerInfo: RegisterInfo): Observable<MemberResult>;
  abstract updateMember(member: Member): Observable<MemberResult>;
  abstract saveCard(member: Member, card: SavedCard): Observable<MemberResult>;
  abstract removeCard(
    member: Member,
    card: SavedCard
  ): Observable<MemberResult>;
  abstract logOut(): void;
}

@Injectable({
  providedIn: 'root',
})
export class MemberClient implements IMemberClient {
  constructor(
    private client: HttpClient,
    public variables: EnvironmentVariables
  ) {}

  private datepipe: DatePipe = new DatePipe('en-US');
  private readonly nullPhoneNumberError = 'A Valid Phone Number is Required';
  private readonly nullTokenError =
    'There has been an issue with loading your details, please try again.';

  sendIdentificationCodeSms(phoneNumber: string): Observable<MemberResult> {
    if (!phoneNumber) {
      return of({
        Success: false,
        Reason: this.nullPhoneNumberError,
        Member: null,
      });
    }
    return this.client
      .post<MemberResult>(
        this.variables.baseApiUrl +
          '/Members/SendIdentificationCode/' +
          phoneNumber,
        {},
        { withCredentials: true }
      )
      .pipe(catchError(this.handleMemberError));
  }

  sendIdentificationCodeEmail(phoneNumber: string): Observable<MemberResult> {
    if (!phoneNumber) {
      return of({
        Success: false,
        Reason: this.nullPhoneNumberError,
        Member: null,
      });
    }
    return this.client
      .post<MemberResult>(
        this.variables.baseApiUrl +
          '/Members/SendIdentificationCodeByEmail/' +
          phoneNumber,
        {},
        { withCredentials: true }
      )
      .pipe(catchError(this.handleMemberError));
  }

  getMemberFromAccessToken(
    phoneNumber: string,
    accessToken: string
  ): Observable<MemberResult> {
    if (!phoneNumber || !accessToken) {
      return of({
        Success: false,
        Reason: this.nullTokenError,
        Member: null,
      });
    }
    return this.client
      .get<MemberResult>(
        this.variables.baseApiUrl +
          '/Members/GetMemberFromAccessToken/' +
          phoneNumber +
          '/' +
          accessToken,
        { withCredentials: true }
      )
      .pipe(
        map((r) => {
          if (r.Success) {
            this.mapBirthday(r.Member);
            localStorage.setItem('LoggedIn', 'true');
          } else if (r.Reason == 'register') {
            r.Reason = 'Please check that your verification code is correct';
          }
          return r;
        })
      );
  }

  getMemberFromAppToken(token: string): Observable<MemberResult> {
    if (!token) {
      return of({
        Success: false,
        Reason: this.nullTokenError,
        Member: null,
      });
    }
    return this.client
      .get<MemberResult>(
        this.variables.baseApiUrl + '/Members/GetMemberFromAppToken/' + token,
        { withCredentials: true }
      )
      .pipe(
        map((m) => {
          this.mapBirthday(m.Member);
          return m;
        })
      );
  }

  getLoggedInMember(): Observable<MemberResult> {
    return this.client
      .get<MemberResult>(
        this.variables.baseApiUrl + '/Members/GetLoggedInMember',
        { withCredentials: true }
      )
      .pipe(
        map((m) => {
          this.mapBirthday(m.Member);
          return m;
        })
      );
  }

  isPreviouslyLoggedIn(): boolean {
    return localStorage.getItem('LoggedIn') == 'true';
  }

  register(registerInfo: RegisterInfo): Observable<MemberResult> {
    return this.client.post<MemberResult>(
      this.variables.baseApiUrl + '/Members/Register',
      registerInfo
    );
  }

  updateMember(member: Member): Observable<MemberResult> {
    const copy = new Member(member);
    this.reverseMapBirthday(copy);
    return this.client
      .post<MemberResult>(
        this.variables.baseApiUrl + '/Members/UpdateMember',
        copy,
        { withCredentials: true }
      )
      .pipe(catchError(this.handleMemberError));
  }

  saveCard(member: Member, card: SavedCard): Observable<MemberResult> {
    return this.client.post<MemberResult>(
      this.variables.baseApiUrl + '/Members/SaveCard',
      card,
      { withCredentials: true }
    );
    // .pipe(catchError(this.handleMemberError));
  }

  removeCard(member: Member, card: SavedCard): Observable<MemberResult> {
    return this.client.post<MemberResult>(
      this.variables.baseApiUrl + '/Members/RemoveCard/' + card.Id,
      '',
      { withCredentials: true }
    );
    // .pipe(catchError(this.handleMemberError));
  }

  logOut(): void {
    localStorage.removeItem('LoggedIn');
    this.client
      .post(this.variables.baseApiUrl + '/Members/LogOut', {})
      .subscribe();
  }

  //From server, transform birthday to human readable
  private mapBirthday(member: Member) {
    if (member?.Birthday) {
      member.Birthday = this.datepipe.transform(member.Birthday, 'dd/MM/yyyy');
    }
  }

  //To server, transform birthday to ISO8601
  private reverseMapBirthday(member: Member) {
    if (member?.Birthday) {
      member.Birthday = member.Birthday.split('/').reverse().join('-');
    }
  }

  private handleMemberError(
    exception: HttpErrorResponse
  ): Observable<MemberResult> {
    let error = exception.error;

    if (error.Reason && !isEmptyOrSpaces(error.Reason)) {
      error = error.Reason;
    } else if (typeof error == 'object') {
      error = 'Sorry, something went wrong';
    }
    return of({ Success: false, Reason: error, Member: null });
  }
}
