import { Component, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { filter, first } from 'rxjs/operators';
import { NavigationEvents } from 'src/app/models/domain/events/navigation-events';
import { OrderPage } from 'src/app/models/domain/navigation-page';
import { Order } from 'src/app/models/domain/order/order';
import { IMemberService } from 'src/app/services/member.service';
import { OrderService } from 'src/app/services/order.service';
import { OrdersService } from 'src/app/services/orders.service';

@Component({
  selector: 'app-my-orders',
  templateUrl: './my-orders.component.html',
  styleUrls: ['./my-orders.component.scss'],
})
export class MyOrdersComponent implements OnInit, OnDestroy {
  /**
   * `true` when the component is loading orders, `false` when it has finished
   * loading, and `undefined` when the component initialises and has not
   * loaded at all yet.
   */
  public isLoadingOrders = undefined;

  /** The currently loaded list of orders. */
  public orders: Array<Order>;

  /** The number of orders that exist in the system. */
  public numResultsTotal: number;

  /** Subject to emit to when more orders should be loaded. */
  public loadMore$ = new EventEmitter<void>();

  constructor(
    public ordersService: OrdersService,
    public memberService: IMemberService,
    public orderService: OrderService
  ) {}

  public ngOnInit(): void {
    // Observe loadMore$ and fetch the next set of orders when it is emitted to
    this.loadMore$.pipe(filter(() => !this.isLoadingOrders)).subscribe(() => {
      this.getOrders(this.orders?.length ?? 0);
    });

    this.memberService.initialised
      .pipe(
        filter((i) => i),
        // Initialised should only be set to `true` one time, this is a safeguard
        first()
      )
      .subscribe(() => {
        if (!this.memberService.currentMember) {
          NavigationEvents.NavigateToOrderPage.emit({
            Page: OrderPage.MainMenu,
            Order: this.orderService.order,
          });
        } else {
          this.loadMore$.emit();
        }
      });
  }

  /** Gets the next set of orders from the system, after `offset`. */
  private getOrders(offset: number): void {
    this.isLoadingOrders = true;
    this.ordersService
      .getOrders(this.memberService.currentMember.ComoIdSignature, offset)
      .subscribe((o) => {
        this.orders = [...(this.orders ?? []), ...o.Results];
        this.numResultsTotal = o.ResultsTotal;
        this.isLoadingOrders = false;
      });
  }

  public ngOnDestroy(): void {
    this.loadMore$.unsubscribe();
  }
}
