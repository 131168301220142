import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Coupon } from 'src/app/models/domain/coupon';
import { AppEvents } from 'src/app/models/domain/events/app-events';
import { Member } from 'src/app/models/domain/member';
import { Reward as OrderReward } from 'src/app/models/domain/order/reward';
import { Order } from 'src/app/models/domain/order/order';
import {
  CouponModel,
  CouponRequirements,
} from 'src/app/models/view-models/coupon-model';
import { RewardModalComponent } from '../reward-modal/reward-modal.component';
import { EnvironmentVariables } from 'src/app/models/environment';
import { CouponApplicationResult } from 'src/app/models/domain/response/coupon-application-result';
import { MemberEvents } from 'src/app/models/domain/events/member-events';
import { isEmptyOrSpaces } from 'src/app/helpers/stringHelpers';
import { ItemPickerModalComponent } from '../item-picker-modal/item-picker-modal.component';
import { RewardModalDismissEvent } from '../reward-modal/reward-modal-dismiss-event';
import { ItemPickerModalDismissEvent } from '../item-picker-modal/item-picker-modal-dismiss-event';
import { Subscription } from 'rxjs';
import { IModalService } from 'src/app/services/modal.service';
import { SaleType } from '../../models/domain/store';

@Component({
  selector: 'app-voucher',
  templateUrl: './voucher.component.html',
  styleUrls: ['./voucher.component.scss'],
})
export class VoucherComponent implements OnInit, OnDestroy {
  readonly SaleType = SaleType;

  @Input() couponModel: CouponModel;

  @Input() order: Order;

  @Input() member: Member;

  /**
   * Message shown when the user tries to redeem a reward without being signed in.
   */
  private signInErrorMessage = `Sign in to redeem your ${this.variables.loyaltyConfig.rewardsName}`;

  /**
   * Message shown when the user tries to redeem a reward without being opted in.
   */
  private optInErrorMessage = `Join by opting in (on the account page) to redeem your ${this.variables.loyaltyConfig.rewardsName}`;

  private subscriptions: Subscription[];

  constructor(
    public variables: EnvironmentVariables,
    public modalService: IModalService
  ) {}

  ngOnInit(): void {
    if (this.subscriptions?.length) {
      this.subscriptions.forEach((s) => s.unsubscribe());
    }
    this.subscriptions = [
      AppEvents.RedeemableResult.subscribe((r) => this.couponResult(r)),
      MemberEvents.LoggedIn.subscribe(() => {
        if (this.couponModel.Error === this.signInErrorMessage) {
          this.clearCouponError();
        }
      }),
      AppEvents.OpenRewards.subscribe(() => this.openRewardModal()),
    ];
  }

  clearCouponError(): void {
    this.couponModel.Error = null;
  }

  onKeyUp(event: KeyboardEvent): void {
    this.clearCouponError();
    if (event.code === 'Enter' || event.code === 'NumpadEnter') {
      event.stopPropagation();
      this.checkCouponCode();
    }
  }

  checkCouponCode(): void {
    //Don't continue if coupon code is blank
    if (isEmptyOrSpaces(this.couponModel.Code)) {
      return;
    }
    this.couponModel.Requirements = null;
    this.couponModel.Loading = true;
    this.couponModel.Code = this.couponModel.Code.trim();
    AppEvents.CouponCodeEntered.emit({
      Code: this.couponModel.Code,
      Order: this.order,
    });
  }

  couponResult(result: CouponApplicationResult): void {
    if (result.Success === true) {
      this.couponModel.Error = null;
      this.couponModel.Code = '';
      this.couponModel.Requirements = null;
    } else {
      if (
        (result.Requirements?.missingDiscounted.length ||
          result.Requirements?.missingRequired.length) &&
        result.FirstApplication
      ) {
        this.openItemPickerModal(result.Requirements);
      } else if (result.FirstApplication) {
        const redeemableType = this.order.ComoRewards?.length
          ? this.variables.loyaltyConfig.rewardsName
          : 'coupon';
        AppEvents.ShowErrorModal.emit({
          header: `Error applying ${redeemableType}:`,
          errorMessage: result.Message,
        });
      }
      this.couponModel.Requirements = result.Requirements;
      this.couponModel.Error = result.Message;
    }
    this.couponModel.Loading = false;
  }

  private openItemPickerModal(couponRequirements: CouponRequirements) {
    this.modalService
      .presentModal<ItemPickerModalDismissEvent>({
        id: 'ItemPickerModalComponent',
        component: ItemPickerModalComponent,
        componentProps: {
          couponRequirements,
          redeemableType: this.order.Coupons.length ? 'coupon' : 'reward',
        },
        cssClass: 'show-header-modal',
      })
      .subscribe((d) => {
        if (!d?.code || d.code === 'DISMISSED') {
          return;
        }
        AppEvents.AddProducts.emit({ products: d.selectedProducts });
      });
  }

  couponRequirements(r: CouponRequirements): void {
    this.couponModel.Loading = false;
    this.couponModel.Error = null;
    this.couponModel.Requirements = r;
  }

  removeCoupon(coupon: Coupon): void {
    this.order.Coupons.splice(this.order.Coupons.indexOf(coupon), 1);
    this.couponModel.Requirements = null;
    this.couponModel.Code = '';
    AppEvents.UpdateCart.emit();
  }

  removeReward(reward: OrderReward): void {
    this.order.ComoRewards.splice(this.order.ComoRewards.indexOf(reward), 1);
    AppEvents.UpdateCart.emit();
  }

  openRewardModal(): Promise<void> {
    if (!this.member) {
      this.couponModel.Error = this.signInErrorMessage;
      return;
    }

    if (!this.member.Tags.includes('LOYALTYOPTIN')) {
      this.couponModel.Error = this.optInErrorMessage;
      return;
    }

    this.modalService
      .presentModal<RewardModalDismissEvent>({
        id: 'RewardModalComponent',
        component: RewardModalComponent,
        componentProps: {
          member: this.member,
          order: this.order,
        },
        cssClass: 'rewards-modal show-header-modal',
      })
      .subscribe((d) => {
        if (!d?.code || d.code === 'DISMISSED') {
          return;
        }
        const asset = d.selectedReward;
        if (asset) {
          //Add reward to cart
          this.order.ComoRewards = [
            {
              key: asset.Key,
              name: asset.Name,
              code: null,
              appliedAmount: 0,
            },
          ];
          //Send cart to server for evaluation
          //Apply results to order in coupon service
          AppEvents.RewardApplied.emit({ Asset: asset, Order: this.order });
        } else {
          //Dismissed without using a reward, clear rewards and discounts
          this.order.ComoRewards = [];
          AppEvents.UpdateCart.emit();
        }
      });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }
}
