/**
 * Make URL starts with http:// for the URL that does not contain https:// or http://
 *
 * @param url
 * @returns url starting with http:// if required
 */
export const makeURLStartWithHttp = (url: string): string => {
  if (url) {
    url = url.match(/^http[s]?:\/\//) ? url : 'http://' + url;
  }
  return url;
};

/**
 * A query parameter definition.
 */
export type QueryParameterConfig = {
  /** The key for the query parameter. */
  key: string;
  /** The value of the query parameter. */
  value: string;
  /** Whether to include this parameter when the `value` is falsy. */
  includeIfNull?: boolean;
};

/**
 * Generates a query string (in the format "?key1=value1&key2=value2", etc.) for
 * the given parameters.
 *
 * When `includeIfNull` is `true` in a param config and the value is falsy the
 * key/value pair will generate as "key1=". If it is `false` or `undefined` it
 * will not be included in the resulting string.
 *
 * @param params
 */
export const generateQueryString = (
  params: (QueryParameterConfig | string)[]
): string => {
  const paramsString = params
    .map((config) => {
      if (typeof config === 'string') {
        return config;
      }
      if (config.includeIfNull || config.value) {
        return `${config.key}=${config.value}`;
      }
      return '';
    })
    .filter((paramString) => !!paramString)
    .join('&');

  return paramsString ? `?${paramsString}` : '';
};
