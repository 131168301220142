import { Injectable, OnDestroy } from '@angular/core';
import { combineLatest, merge, Subscription } from 'rxjs';
import { catchError, filter, map } from 'rxjs/operators';
import { IMenuClient } from '../clients/menu-client';
import { AppEvents } from '../models/domain/events/app-events';
import { Menu } from '../models/domain/menu';
import { Order } from '../models/domain/order/order';
import { OrderTime } from '../models/domain/order/order-time';
import { Product } from '../models/domain/product';
import { SaleType, Store } from '../models/domain/store';
import { StoreStock } from '../models/domain/store-stock';
import { EnvironmentVariables } from '../models/environment';
import { Combo } from '../models/domain/combo';

@Injectable({
  providedIn: 'root',
})
export class MenuService implements OnDestroy {
  public menuData: Array<Menu>;

  public currentMenu: Menu;

  private loadStockForStore: Store;
  //When menu is changed, but store is not, then we can use the last store stock
  private lastStoreStockLoaded: StoreStock;

  private subscriptions: Array<Subscription>;

  constructor(
    public client: IMenuClient,
    public variables: EnvironmentVariables
  ) {
    this.subscriptions = [
      AppEvents.AppLoaded.pipe(filter((loaded) => loaded)).subscribe(() => {
        this.getMenuData();
      }),
      AppEvents.ChangeMenuId.subscribe((mId) => {
        this.loadMenu(mId);
      }),
      //Ensure menus are loaded before accepting the last store changed event
      combineLatest([AppEvents.StoreChanged, AppEvents.MenusLoaded]).subscribe(
        (s) => {
          this.loadStockForStore = s[0].Store;
          this.checkOrderMenu(s[0].Order.SaleType);
        }
      ),
      AppEvents.SaleTypeChanged.subscribe((s) => {
        this.loadStockForStore = s.Order.Store;
        this.checkOrderMenu(s.SaleType);
      }),
      AppEvents.OrderTimeChanged.subscribe((ev) => {
        this.checkProductTimes(ev.OrderTime);
      }),

      AppEvents.StoreStockLoaded.subscribe((i) => {
        this.storeStockReceived(i);
      }),

      merge(
        AppEvents.CartUpdated.pipe(map((event) => event.Order)),
        AppEvents.CartCleared,
        AppEvents.OrderLoaded.pipe(filter((order) => order != null))
      ).subscribe((order) => {
        this.updateProductCounts(order);
      }),

      AppEvents.OnlineConfiguration.subscribe(() => this.applyCategoryImages()),
    ];
  }

  public checkOrderMenu(saleType: SaleType): void {
    let menuId: string;
    if (
      saleType?.Code == SaleType.CateringCode &&
      this.loadStockForStore.Catering?.MenuId
    ) {
      menuId = this.loadStockForStore.Catering.MenuId;
    } else if (
      saleType?.Code == SaleType.TableOrderCode &&
      this.loadStockForStore.TableOrder?.MenuId
    ) {
      menuId = this.loadStockForStore.TableOrder.MenuId;
    } else {
      menuId = this.loadStockForStore.MenuId;
    }

    this.loadMenu(menuId);
  }

  public checkMenuCatering(): boolean {
    return this.currentMenu.Id == this.loadStockForStore.Catering?.MenuId;
  }

  public loadMenu(menuId: string): void {
    if (menuId != this.currentMenu?.Id) {
      const newMenu = this.menuData.find((m) => m.Id == menuId);
      if (newMenu) {
        this.currentMenu = newMenu;
        this.checkProductTimes(AppEvents.OrderTime.value);
        AppEvents.MenuChanged.emit(this.currentMenu);
        this.updateProductStock();
      } else {
        console.error('The menu is empty or could not be found.');
      }
    }
    AppEvents.MenuSet.emit(this.currentMenu);
  }

  public storeStockReceived(stock: StoreStock): void {
    if (stock.Id == this.loadStockForStore?.Id) {
      this.lastStoreStockLoaded = stock;
      this.updateProductStock();
    }
  }

  public updateProductStock(): void {
    if (!this.currentMenu || !this.lastStoreStockLoaded) {
      return;
    }
    for (const comboId in this.currentMenu.ComboDictionary) {
      const combo = this.currentMenu.ComboDictionary[comboId];
      combo.OutOfStock =
        this.lastStoreStockLoaded.Products[combo.PLU]?.OutOfStock == true;
      //Now loop through each product in the combo to mark out of stock
      //In the combo modal, there will be visual changes for out of stock combo items
      combo.Items?.forEach((comboItem) => {
        if (comboItem?.Products) {
          comboItem.Products.forEach((comboItemProduct) => {
            comboItemProduct.OutOfStock =
              this.lastStoreStockLoaded.Products[comboItemProduct.PLU]
                ?.OutOfStock == true;
          });
        }
      });
    }
    this.currentMenu.Categories.forEach((category) => {
      category.Products.forEach((product) => {
        //Null product stock means not out of stock
        product.OutOfStock =
          this.lastStoreStockLoaded.Products[product.PLU]?.OutOfStock == true;
        if (product.AllCombos && product.AvailableCombos) {
          //checkProductTimes must do the same validation on both fields
          product.AvailableCombos = product.AllCombos.filter(
            (combo) => combo.TimeAvailable && !combo.OutOfStock
          );
        }
        product.ModifierGroups.forEach((modifierGroup) => {
          modifierGroup.Modifiers.forEach((modifier) => {
            modifier.OutOfStock =
              this.lastStoreStockLoaded.Products[modifier.PLU]?.OutOfStock ==
              true;
          });
        });
      });
    });

    AppEvents.StockUpdated.emit(this.currentMenu);
  }

  public checkProductTimes(orderTime: OrderTime): void {
    if (!this.currentMenu) {
      return;
    }
    for (const comboId in this.currentMenu.ComboDictionary) {
      const combo = this.currentMenu.ComboDictionary[comboId];
      this.setTimeAvailable(combo, orderTime);

      combo.Items?.forEach((ci) => {
        ci.Products?.forEach((p) => {
          this.setTimeAvailable(p, orderTime);
        });
      });
    }
    for (const productId in this.currentMenu.ProductDictionary) {
      const product = this.currentMenu.ProductDictionary[productId];
      this.setTimeAvailable(product, orderTime);
      if (product.AllCombos && product.AvailableCombos) {
        //updateProductStock must do the same validation on both fields
        product.AvailableCombos = product.AllCombos.filter(
          (c) => c.TimeAvailable && !c.OutOfStock
        );
      }
    }
  }

  private setTimeAvailable(product: Product | Combo, orderTime: OrderTime) {
    //Product is available if it doesnt have a timeframe
    product.TimeAvailable =
      !product.SaleTimeFrame ||
      //if it does, then an order time must be selected
      (orderTime &&
        //and the time must be between the sale time frames
        orderTime.Offset >= product.SaleTimeFrame.OpenTime &&
        orderTime.Offset <= product.SaleTimeFrame.CloseTime);
  }

  private getMenuData() {
    this.client
      .get()
      .pipe<Menu[]>(
        catchError(async () => {
          return null;
        })
      )
      .subscribe((data) => {
        if (data == null) {
          return;
        }
        this.menuData = data;
        this.menuData.forEach((m) => {
          m.Categories.sort((a, b) => a.DisplayOrder - b.DisplayOrder);
          m.Categories.forEach((c) => {
            c.Combos.sort((a, b) => b.DisplayOrder - a.DisplayOrder);
            c.Products.sort((a, b) => b.DisplayOrder - a.DisplayOrder);
          });
        });
        this.applyCategoryImages();
        AppEvents.MenusLoaded.emit(this.menuData);
        AppEvents.MenusInitialised.next(true);
      });
  }

  private updateProductCounts(order: Order) {
    if (!this.currentMenu) {
      return;
    }
    const categoryCounts: { [category: string]: number } = {};
    const productCounts: { [productId: number]: number } = {};
    order.Products.forEach((p) => {
      if (categoryCounts[p.Category] == null) {
        categoryCounts[p.Category] = 0;
      }
      categoryCounts[p.Category] += p.Quantity;
      if (productCounts[p.ProductId] == null) {
        productCounts[p.ProductId] = 0;
      }
      productCounts[p.ProductId] += p.Quantity;
    });
    this.currentMenu.Categories.forEach((c) => {
      c.ProductCount = categoryCounts[c.Name] || 0;
      c.Products.forEach((p) => {
        p.ProductCount = productCounts[p.Id] || 0;
      });
    });
  }

  applyCategoryImages(): void {
    const config = AppEvents.OnlineConfiguration.value;
    if (!this.menuData || !config) {
      return;
    }

    this.variables.categories.forEach((c) => {
      c.ImageUrl =
        '/assets/' + this.variables.brandPath + '/' + c.Name + '.png';
    });
    this.menuData.forEach((m) => {
      m.Categories.forEach((c) => {
        //Social category images disabled while home screen is disabled
        // const socialConfig = (config.SocialCategoryImages || []).find(
        //   (sc) => sc.Link == c.Name
        // );
        // if (socialConfig) {
        //   c.SocialImageUrl = socialConfig.Image;
        // }

        c.ImageUrl =
          '/assets/' + this.variables.brandPath + '/' + c.Name + '.png';
        c.HoverImageUrl =
          '/assets/' + this.variables.brandPath + '/' + c.Name + ' Hover.png';
        c.SelectedImageUrl =
          '/assets/' +
          this.variables.brandPath +
          '/' +
          c.Name +
          ' Selected.png';
        c.HoverSelectedImageUrl =
          '/assets/' +
          this.variables.brandPath +
          '/' +
          c.Name +
          ' Selected Hover.png';
      });
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => {
      s.unsubscribe();
    });
  }
}
