import { Component, OnDestroy, OnInit } from '@angular/core';
import { AppEvents } from './models/domain/events/app-events';
import { MemberEvents } from './models/domain/events/member-events';
import { NavigationEvents } from './models/domain/events/navigation-events';
import { EnvironmentVariables } from './models/environment';
import { IMemberService } from './services/member.service';
import { MenuService } from './services/menu.service';
import { OrderService } from './services/order.service';
import { OnlineConfiguration } from './models/domain/online-configuration';
import { combineLatest } from 'rxjs';
import { filter, map, startWith } from 'rxjs/operators';
import { ErrorModalComponent } from './components/error-modal/error-modal.component';
import { IModalService } from './services/modal.service';
import { NavigationEnd, Router } from '@angular/router';
import { NavigationPage, OrderPage } from './models/domain/navigation-page';
import { NavigationService } from './services/navigation.service';
import { GA4Service } from './services/ga4.service';
import { GTMService } from './services/gtm.service';
import { StoreService } from './services/store.service';
import { LocationService } from './services/location.service';
import { LogviewService } from './services/logview.service';
import { ErrorModalOptions } from './models/domain/error-modal-options';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  public onlineConfiguration: OnlineConfiguration;

  /**
   * A list of order pages that should not have the header or banners.
   */
  private orderRoutesWithNoUi: ReadonlyArray<string> = [OrderPage.Payment];

  /**
   * When `true` only the router contents, should be displayed. I.e., the header
   * and banners are hidden.
   */
  public displayContentOnly$ = this.router.events.pipe(
    filter((ev) => ev instanceof NavigationEnd),
    map((ev: NavigationEnd) => {
      // Construct a regex that will allow any base ordering type route (e.g.,
      // /order or /catering to match with the last route
      const baseOrderRoutes = `/(${[
        `${NavigationPage.Order}`,
        `${NavigationPage.Table}/[0-9]+`,
        `${NavigationPage.Catering}`,
      ].join('|')})`;
      const orderRoutes = `(${this.orderRoutesWithNoUi.join('|')})`;
      const routeRegex = new RegExp(`${baseOrderRoutes}/${orderRoutes}`);
      return routeRegex.test(ev.url);
    }),
    startWith(false)
  );

  subscriptions = [
    AppEvents.ShowErrorModal.subscribe((ev) => {
      this.showModal(ev.errorMessage, ev.title, ev.header, ev.options);
    }),
  ];

  /**
   * Whether anything in the app is loading. If `true` then triggers a full
   * screen loading cover.
   */
  public loading$ = combineLatest([AppEvents.RewardLoading]).pipe(
    map((loadingEvents) => loadingEvents.some((ev) => ev === true))
  );

  constructor(
    public orderService: OrderService,
    public memberService: IMemberService,
    public menuService: MenuService,
    public variables: EnvironmentVariables,
    private modalService: IModalService,
    private router: Router,

    // These are not used but do not remove them - without them we cannot see
    // source maps and they do not run their constructors which may have init
    // code we are expecting to run, like subscriptions.
    private storeService: StoreService,
    private ga4Service: GA4Service,
    private gtmService: GTMService,
    private locationService: LocationService,
    private navigationService: NavigationService,
    private logService: LogviewService
  ) {
    document.title = variables.pageTitle;
    let link = document.querySelector("link[rel~='icon']") as any;
    if (!link) {
      link = document.createElement('link');
      link.rel = 'icon';
      document.getElementsByTagName('head')[0].appendChild(link);
    }
    link.href = variables.favicon;

    //== Google Tag Manager
    (window as any).dataLayer = (window as any).dataLayer || [];
    (window as any).dataLayer.push({
      'gtm.start': new Date().getTime(),
      event: 'gtm.js',
    });

    const j = document.createElement('script');
    j.async = true;
    j.src = 'https://www.googletagmanager.com/gtm.js?id=' + variables.gtmCode;

    const f = document.getElementsByTagName('script')[0];
    f.parentNode.insertBefore(j, f);
    //== End Google Tag Manager

    //== Google Maps
    const googleMapsScript = document.createElement('script');
    googleMapsScript.async = true;

    // Set a global function on the window for the google maps api to call when
    // it finishes loading
    const initMapFnName = 'googleMapsOnInitFunction';
    (window as any)[initMapFnName] = () => AppEvents.MapLoaded.next(true);
    googleMapsScript.src = `https://maps.googleapis.com/maps/api/js?key=${variables.googleMapsApiKey}&callback=${initMapFnName}`;

    f.parentNode.insertBefore(googleMapsScript, f);
    //== End Google Maps

    AppEvents.OnlineConfiguration.subscribe((o) => {
      this.onlineConfiguration = o;
    });

    AppEvents.AppLoaded.next(true);
  }

  ngOnInit(): void {
    AppEvents.LogEvent.emit('Loaded ' + window.location);
    AppEvents.LogEvent.emit(navigator.userAgent);

    const url = new URL(window.location.toString());
    const params = new URLSearchParams(url.search);
    const storeParam = params.get('store');
    if (storeParam) {
      this.orderService.initialStoreParam = storeParam;
    }
    const tempToken = params.get('TempToken');
    if (tempToken) {
      AppEvents.LogEvent.emit('Retrieved Temp token: ' + tempToken);
      //We are in the app, pass the temp token and get the member
      MemberEvents.GetMemberFromAppToken.emit(tempToken);
    } else {
      //We are on the website, check if there is a currently logged in member
      MemberEvents.GetLoggedInMember.emit();
    }
    const referrer = params.get('referrer');
    if (referrer) {
      MemberEvents.Referrer.next(referrer);
    }

    AppEvents.LogEvent.emit('InApp: ' + MemberEvents.InApp.getValue());
    AppEvents.LogEvent.emit('Referrer: ' + (referrer ?? 'none'));
    AppEvents.LogDeviceInfo.emit();
  }

  /**
   * Displays an error message that could be queued from anywhere in the app.
   *
   * @param title Large title displayed at the top of the modal.
   * @param header Text to be displayed before the error message.
   * @param errorMessage Error message.
   * @param options Error message.
   *
   * @example {title: "Error", header: "There was an error", errorMessage: "An unexpected problem occurred"}
   * displays as
   * |------------------------------------|
   * |                Error               |
   * |   There was an error:              |
   * |   An unexpected problem occurred   |
   * |                                    |
   * |                [OK]                |
   * |------------------------------------|
   */
  private showModal(
    errorMessage: string,
    title?: string,
    header?: string,
    options?: ErrorModalOptions
  ): void {
    this.modalService
      .presentModal({
        id: 'modal-' + errorMessage.replace(/[^a-zA-Z]/g, ''),
        component: ErrorModalComponent,
        componentProps: {
          title,
          header,
          error: errorMessage,
        },
        cssClass: 'curbside-warning-modal',
      })
      .subscribe(() => {
        if (options?.forceReload) {
          location.reload();
        }
      });
  }

  menuOpening(): void {
    AppEvents.LogEvent.emit('Opened menu');
    NavigationEvents.MenuOpening.emit();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
