import { OrderModifier } from '../../domain/order/order-modifier';

export class OrderModifierDTO {
  public Id: string;
  public Name: string;
  public Selected: boolean;
  public Included: boolean;
  public Category: string;
  public PLU: number;
  public RemovedPLU: number;
  public LineId: number;

  public Discount: number;
  public Price: number;
  public TotalPrice: number;

  public isGlutenAllergy: boolean;
  public isDairyAllergy: boolean;
  public isPeanutAllergy: boolean;
  public isEggAllergy: boolean;
  public isTomatoAllergy: boolean;

  public static ToDomain(m: OrderModifierDTO): OrderModifier {
    if (m == null) {
      return null;
    }
    return new OrderModifier({
      ModifierId: m.Id,
      Name: m.Name,
      Selected: m.Selected,
      Included: m.Included,
      Category: m.Category,
      PLU: m.PLU,
      RemovedPLU: m.RemovedPLU,
      Discount: m.Discount,
      ItemPrice: m.Price,
      TotalPrice: m.TotalPrice,
      LineId: m.LineId,
      isGlutenAllergy: m.isGlutenAllergy,
      isDairyAllergy: m.isDairyAllergy,
      isPeanutAllergy: m.isPeanutAllergy,
      isEggAllergy: m.isEggAllergy,
      isTomatoAllergy: m.isTomatoAllergy,
    });
  }

  public static FromDomain(m: OrderModifier): OrderModifierDTO {
    if (m == null) {
      return null;
    }
    return {
      Id: m.ModifierId,
      Name: m.Name,
      Selected: m.Selected,
      Included: m.Included,
      Category: m.Category,
      PLU: m.PLU,
      RemovedPLU: m.RemovedPLU,
      Discount: m.Discount,
      Price: m.ItemPrice,
      TotalPrice: m.TotalPrice,
      LineId: m.LineId,
      isGlutenAllergy: m.isGlutenAllergy,
      isDairyAllergy: m.isDairyAllergy,
      isPeanutAllergy: m.isPeanutAllergy,
      isEggAllergy: m.isEggAllergy,
      isTomatoAllergy: m.isTomatoAllergy,
    };
  }
}
