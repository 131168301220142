import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AccountDetailsComponent } from './components/account-details/account-details.component';
import { MyCardsComponent } from './components/my-cards/my-cards.component';
import { NotificationSettingsComponent } from './components/notification-settings/notification-settings.component';
import { AccountComponent } from './containers/account/account.component';
import { CateringComponent } from './containers/catering/catering.component';
import { CheckoutComponent } from './containers/checkout/checkout.component';
import { HomeComponent } from './containers/home/home.component';
import { LocationsComponent } from './containers/locations/locations.component';
import { MainMenuComponent } from './containers/main-menu/main-menu.component';
import { MyOrdersComponent } from './containers/my-orders/my-orders.component';
import { OrderConfirmationComponent } from './containers/order-confirmation/order-confirmation.component';
import { OrderComponent } from './containers/order/order.component';
import { PaymentResultComponent } from './containers/payment-result/payment-result.component';
import { SummaryComponent } from './containers/summary/summary.component';
import { TableOrderingComponent } from './containers/table-ordering/table-ordering.component';
import { PaymentComponent } from './containers/payment/payment.component';
import {
  AccountPage,
  NavigationPage,
  OrderPage,
} from './models/domain/navigation-page';

/**
 * Gets the list of subpages that are the ordering flow.
 */
const getOrderFlow: () => Routes = () => [
  {
    path: '',
    component: MainMenuComponent,
  },
  {
    path: OrderPage.MainMenu,
    component: MainMenuComponent,
  },
  {
    path: OrderPage.Checkout,
    component: CheckoutComponent,
  },
  {
    path: OrderPage.Summary,
    component: SummaryComponent,
  },
  {
    path: OrderPage.Payment,
    component: PaymentComponent,
  },
  {
    path: OrderPage.PaymentResult,
    component: PaymentResultComponent,
  },
  {
    path: OrderPage.Confirmation,
    component: OrderConfirmationComponent,
  },
];

const routes: Routes = [
  {
    path: NavigationPage.Home,
    component: HomeComponent,
  },
  {
    path: NavigationPage.Order,
    component: OrderComponent,
    children: getOrderFlow(),
  },
  {
    // Separate from main routing as it has table number in url
    path: `${NavigationPage.Table}/:store/:table`,
    component: TableOrderingComponent,
    children: getOrderFlow(),
  },
  {
    // Separate from main routing
    path: NavigationPage.Catering,
    component: CateringComponent,
    children: getOrderFlow(),
  },
  {
    path: NavigationPage.Account,
    component: AccountComponent,
    children: [
      {
        path: AccountPage.Details,
        component: AccountDetailsComponent,
      },
      {
        path: AccountPage.NotificationSettings,
        component: NotificationSettingsComponent,
      },
      {
        path: AccountPage.MyCards,
        component: MyCardsComponent,
      },
      {
        path: '',
        redirectTo: AccountPage.Details,
        pathMatch: 'full',
      },
    ],
  },
  {
    path: NavigationPage.MyOrders,
    component: MyOrdersComponent,
  },
  {
    path: NavigationPage.Locations,
    component: LocationsComponent,
  },
  {
    path: '',
    redirectTo: '/order',
    pathMatch: 'full',
  },
  {
    path: '**',
    redirectTo: '/order',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
