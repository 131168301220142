import { EnvironmentVariables } from '../models/environment';

/**
 * Returns the format to use with Angular's `number` pipe for displaying loyalty
 * points. Format "1.<num>-<num>"
 *
 * @param env
 */
export const getLoyaltyNumberFormat = (env: EnvironmentVariables) =>
  '1.' +
  env.loyaltyConfig.pointDecimals +
  '-' +
  env.loyaltyConfig.pointDecimals;
