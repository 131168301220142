import { Component } from '@angular/core';
import { ViewDidEnter } from '@ionic/angular';
import { Category } from 'src/app/models/domain/category';
import { AppEvents } from 'src/app/models/domain/events/app-events';
import { NavigationEvents } from 'src/app/models/domain/events/navigation-events';
import {
  LinkImage,
  LinkType,
  OnlineConfiguration,
} from 'src/app/models/domain/online-configuration';
import { Store } from 'src/app/models/domain/store';
import { IMemberService } from 'src/app/services/member.service';
import { MenuService } from 'src/app/services/menu.service';
import { OrderService } from 'src/app/services/order.service';
import { StoreService } from 'src/app/services/store.service';
import { CarouselElement } from '../../component-lib/carousel/model/CarouselElement';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements ViewDidEnter {
  public stores: Array<Store>;
  displaySlider = false;
  onlineConfiguration: OnlineConfiguration;
  carouselImages: Array<LinkImage & CarouselElement>;

  constructor(
    public orderService: OrderService,
    public menuService: MenuService,
    public memberService: IMemberService,
    public storeService: StoreService
  ) {
    AppEvents.Stores.subscribe((stores) => {
      this.stores = stores;
    });
    AppEvents.OnlineConfiguration.subscribe((o) => {
      this.onlineConfiguration = o;
      this.carouselImages = o.OrderCarousel.map((item) => ({
        ...item,
        imageSrc: item.Image,
        link: item.Link,
      }));
    });
  }

  sliderImageClicked(sliderImage: LinkImage): void {
    if (sliderImage.Type == LinkType.Category) {
      const category = this.menuService.currentMenu.Categories.find(
        (c) => c.Name == sliderImage.Link
      );
      if (category) {
        NavigationEvents.NavigateToCategory.emit({
          Category: category,
          Order: this.orderService.order,
        });
      }
    } else if (sliderImage.Type == LinkType.Url) {
      if (sliderImage.Link) {
        window.open(sliderImage.Link, '_blank');
      }
    } else if (sliderImage.Type == LinkType.Product) {
      const link = sliderImage.Link.substring(
        sliderImage.Link.indexOf('?') + 1
      );
      const product = this.menuService.currentMenu.ProductDictionary[link];
      if (product) {
        NavigationEvents.NavigateToProduct.emit(product);
      }
    } else if (sliderImage.Type == LinkType.Combo) {
      const link = sliderImage.Link.substring(
        sliderImage.Link.indexOf('?') + 1
      );
      const combo = this.menuService.currentMenu.ComboDictionary[link];
      if (combo) {
        NavigationEvents.NavigateToCombo.emit(combo);
      }
    }
  }

  ionViewDidEnter(): void {
    this.displaySlider = true;
  }

  navigateToCategory(category: Category): void {
    NavigationEvents.NavigateToCategory.emit({
      Category: category,
      Order: this.orderService.order,
    });
  }
}
