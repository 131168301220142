import { Component } from '@angular/core';
import { ViewDidEnter, ViewDidLeave } from '@ionic/angular';
import { merge } from 'rxjs';
import { debounceTime, filter } from 'rxjs/operators';
import { ConfirmModalComponent } from 'src/app/components/confirm-modal/confirm-modal.component';
import { ErrorModalComponent } from 'src/app/components/error-modal/error-modal.component';
import { isMobile } from 'src/app/helpers/rxJsHelpers';
import { AppEvents } from 'src/app/models/domain/events/app-events';
import { MemberEvents } from 'src/app/models/domain/events/member-events';
import { NavigationEvents } from 'src/app/models/domain/events/navigation-events';
import { SavedCard } from 'src/app/models/domain/member';
import {
  NavigationPage,
  OrderPage,
} from 'src/app/models/domain/navigation-page';
import { Order } from 'src/app/models/domain/order/order';
import { OrderValidationFailedReason } from 'src/app/models/domain/order/order-validation-failed-reason';
import { OrderValidationResult } from 'src/app/models/domain/order/order-validation-result';
import { SaleType, Store } from 'src/app/models/domain/store';
import { EnvironmentVariables } from 'src/app/models/environment';
import { CouponService } from 'src/app/services/coupon.service';
import { IMemberService } from 'src/app/services/member.service';
import { MenuService } from 'src/app/services/menu.service';
import { ModalService } from 'src/app/services/modal.service';
import { OrderService } from 'src/app/services/order.service';

@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss'],
})
export class SummaryComponent implements ViewDidEnter, ViewDidLeave {
  public stores: Array<Store>;
  public orderValid = false;
  public goingToPayment = false;

  /**
   * The selected payment method.
   * * `new` for a new card.
   * * Token for a saved card.
   */
  public paymentOption: 'new' | string | null = null;

  public availablePaymentMethods: Array<SavedCard> = [];

  public isMobile$ = isMobile();

  subscriptions = [];

  //Binding in HTML
  public SaleType = SaleType;

  constructor(
    public menuService: MenuService,
    public orderService: OrderService,
    public couponService: CouponService,
    public memberService: IMemberService,
    public variables: EnvironmentVariables,
    private modalService: ModalService
  ) {}

  ionViewDidEnter(): void {
    this.subscriptions = [
      this.memberService.initialised.pipe(filter((e) => e)).subscribe(() => {
        this.checkOrderValid();
      }),
      this.orderService.initialised.pipe(filter((e) => e)).subscribe(() => {
        this.getAllDuplicateOrders();
        this.checkOrderValid();
      }),
      merge(
        AppEvents.CartUpdated,
        AppEvents.RedeemableResult.pipe(filter((res) => res.Success)),
        AppEvents.OrderTimeChanged,
        MemberEvents.LoggedOut
      )
        .pipe(debounceTime(10))
        .subscribe(() => {
          this.checkOrderValid();
        }),
      AppEvents.Stores.subscribe((stores) => {
        this.stores = stores;
      }),
      AppEvents.OrderFailed.subscribe(() => {
        this.goingToPayment = false;
      }),
      AppEvents.OrderValidated.subscribe((result) => {
        this.handleOrderValidation(result);
      }),
      AppEvents.DuplicateOrders.subscribe((result) => {
        this.handleDuplicateOrders(result);
      }),
    ];

    this.goingToPayment = false;
    this.paymentOption = null;
    this.checkOrderValid();
  }

  /**
   * Handles the result of the server's validation of the order.
   *
   * Goes to the payments screen on successful validation, or shows an error
   * modal on failure.
   */
  private async handleOrderValidation(result: OrderValidationResult) {
    if (result.Success === true) {
      this.goToPayment();
    } else {
      this.goingToPayment = false;
      let errorMessage: string;
      switch (result.Reason) {
        case OrderValidationFailedReason.INVALID_TIME:
          /*
           * Refresh the store's order periods. The order may have been invalid
           * due to orders being placed by other customers after the order
           * periods were last loaded by the front end.
           */
          AppEvents.LoadStoreOrderPeriods.emit({
            Store: this.orderService.order.Store,
            ForceReload: true,
          });
          errorMessage =
            'Collection time not available. The next available time has been selected for you.';
          break;
        default:
          errorMessage = 'An unknown error occurred';
      }

      this.modalService
        .presentModal({
          component: ErrorModalComponent,
          componentProps: {
            error: errorMessage,
          },
          cssClass: 'curbside-warning-modal',
        })
        .subscribe();
    }
  }

  /**
   * Handles the result of the server's retrieval of duplicate orders.
   *
   * shows a warning modal if duplicates are found.
   */
  private async handleDuplicateOrders(result: Array<Order>) {
    if (result.length > 0) {
      let linkBuilder = '';

      for (const order of result) {
        let basePage = NavigationPage.Order;
        switch (order.SaleType.Code) {
          case SaleType.CateringCode:
            basePage = NavigationPage.Catering;
          // TODO: Handle other sale types
        }
        let orderPage = OrderPage.Confirmation;
        if (!order.Status) {
          orderPage = OrderPage.PaymentResult;
        }
        linkBuilder += `<a href="/${basePage}/${orderPage}?orderSignature=${order.IdSignature}" target="_self"><mark>${order.Id}</mark></a>,`;
      }

      this.modalService
        .presentModal<boolean>({
          component: ConfirmModalComponent,
          componentProps: {
            title: 'Double Trouble',
            html:
              'There is more than one order expected for a similar time: ' +
              linkBuilder +
              'Please check your emails for an order confirmation before you continue. Do you want to continue with your current order?',
          },
          cssClass: 'confirm-modal',
        })
        .subscribe((confirmed) => {
          if (!confirmed) {
            NavigationEvents.NavigateToOrderPage.emit({
              Page: OrderPage.MainMenu,
              Order: this.orderService.order,
            });
            return;
          }
        });
    }
  }

  /**
   * Called when the user selects a different payment method.
   */
  paymentChanged(): void {
    this.checkOrderValid();
  }

  /**
   * Checks that the order is ready for payment.
   */
  private checkOrderValid(): void {
    if (
      !this.orderService.initialised.value ||
      !this.memberService.initialised.value
    ) {
      return;
    }

    if (
      this.orderService.order.Products.length == 0 &&
      this.orderService.order.Combos.length == 0
    ) {
      //If you refreshed the page, and everything was removed from your cart
      NavigationEvents.NavigateToOrderPage.emit({
        Page: OrderPage.MainMenu,
        Order: this.orderService.order,
      });
      return;
    }

    if (this.memberService.currentMember == null) {
      if (this.orderService.order.SaleType?.Code == SaleType.TableOrderCode) {
        // We can automatically set to new payment type if user is not logged in
        this.paymentOption = 'new';
      } else {
        // Should not have been able to reach this page if not logged in
        // Return to main menu
        NavigationEvents.NavigateToOrderPage.emit({
          Page: OrderPage.MainMenu,
          Order: this.orderService.order,
        });
        return;
      }
    } else {
      if (this.orderService.order.Store) {
        this.availablePaymentMethods =
          this.memberService.currentMember.PaymentMethods.filter(
            (p) =>
              p.IntegrationMethod ==
              this.orderService.order.Store.PaymentIntegrationSelected
          );
      }

      // Find all payments available for the current store
      // If there are none, default the payment selection to "new"
      if (!this.availablePaymentMethods.length) {
        this.paymentOption = 'new';
      }
    }

    if (this.paymentOption && !this.orderService.order.Total) {
      // Remove the selected payment option if a redeemable is added that drop
      // the total to $0, as the user doesn't need to pay.
      this.paymentOption = null;
    }

    const isMemberValid =
      this.memberService.currentMember.FirstName &&
      this.memberService.currentMember.FirstName.trim() &&
      this.memberService.currentMember.LastName &&
      this.memberService.currentMember.LastName.trim() &&
      this.memberService.currentMember.EmailAddress &&
      this.memberService.currentMember.EmailAddress.trim();
    const isStoreValid = this.orderService.order.Store != null;
    const isOnlineOrderingActive =
      this.orderService.order.Store.OnlineOrderingActive;
    const isOrderDayValid = this.orderService.order.OrderDay != null;
    const isOrderTimeValid = this.orderService.order.OrderTime != null;
    const isTotalAmountAndPaymentAmountValid =
      this.orderService.order.Total == 0 || this.paymentOption != null;
    const isCateringSaleTypeValid =
      this.orderService.order.SaleType.Code != SaleType.CateringCode ||
      this.orderService.order.SubTotal >=
        this.orderService.order.Store.CateringMinimumOrderPrice;
    const isCurbsideSaleTypeValid =
      this.orderService.order.SaleType.Code != SaleType.CurbsideCode ||
      !!this.memberService.currentMember?.CurbsideDetails?.NumberPlate;
    const isTableOrderSaleTypeValid =
      this.orderService.order.SaleType.Code != SaleType.TableOrderCode ||
      (this.orderService.order.TableNumber <=
        this.orderService.order.Store.TableOrderMaximumTableNumber &&
        this.orderService.order.TableNumber > 0);

    this.orderValid =
      isMemberValid &&
      isStoreValid &&
      isOnlineOrderingActive &&
      isOrderDayValid &&
      isOrderTimeValid &&
      isTotalAmountAndPaymentAmountValid &&
      isCateringSaleTypeValid &&
      isCurbsideSaleTypeValid &&
      isTableOrderSaleTypeValid;
  }

  choosePayment(card: SavedCard): void {
    this.paymentOption = card.Id;
    if (this.goingToPayment) {
      location.reload();
    }
  }

  async removeCard(card: SavedCard, event: MouseEvent): Promise<void> {
    event.stopPropagation();

    this.modalService
      .presentModal<boolean>({
        component: ConfirmModalComponent,
        componentProps: {
          title: 'Remove card',
          message: 'Do you want to remove this payment method?',
        },
        cssClass: 'confirm-modal',
      })
      .subscribe((confirmed) => {
        if (confirmed) {
          MemberEvents.RemoveCard.emit(card);
          if (this.paymentOption == card.Id) {
            this.paymentOption = null;
          }
          //Will automatically select new payment if no more saved methods remain
          this.checkOrderValid();
        }
      });
  }

  goToMainMenu(): void {
    NavigationEvents.NavigateToOrderPage.emit({
      Order: this.orderService.order,
      Page: OrderPage.MainMenu,
    });
  }

  goToCheckout(): void {
    NavigationEvents.NavigateToOrderPage.emit({
      Page: OrderPage.Checkout,
      Order: this.orderService.order,
    });
  }

  validateOrder(): void {
    this.goingToPayment = true;
    AppEvents.ValidateOrder.emit({ Order: this.orderService.order });
  }

  getAllDuplicateOrders(): void {
    AppEvents.GetAllDuplicateOrders.emit({ Order: this.orderService.order });
  }

  private goToPayment(): void {
    //Navigate to payment url
    //Listen for any errors
    NavigationEvents.NavigateToPayment.emit({
      Order: this.orderService.order,
      PaymentOption: this.paymentOption,
    });
  }

  goToResult(idSignature: string): void {
    NavigationEvents.NavigateToPaymentResult.emit({ idSignature });
  }

  ionViewDidLeave(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }
}
