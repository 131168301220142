import { Component, Input } from '@angular/core';
import { AppEvents } from 'src/app/models/domain/events/app-events';
import { Menu } from 'src/app/models/domain/menu';
import { OrderCombo } from 'src/app/models/domain/order/order-combo';
import { Product } from 'src/app/models/domain/product';
import { EnvironmentVariables } from 'src/app/models/environment';
import {
  CustomiseProductComponent,
  CustomiseProductDismissEvent,
} from '../customise-product/customise-product.component';
import { IModalService } from '../../services/modal.service';

@Component({
  selector: 'app-cart-combo',
  templateUrl: './cart-combo.component.html',
  styleUrls: ['./cart-combo.component.scss'],
})
export class CartComboComponent {
  @Input()
  combo: OrderCombo;

  @Input()
  menu: Menu;

  @Input()
  editable: boolean;

  constructor(
    private modalService: IModalService,
    public variables: EnvironmentVariables
  ) {}

  async editCombo(combo: OrderCombo): Promise<void> {
    if (!this.editable) {
      return;
    }
    const originalCombo = this.menu.ComboDictionary[combo.ComboId];
    //Copy so the modal doesn't edit the actual combo in case of Cancel
    const editingCombo = OrderCombo.ApplyToOrderCombo(
      combo,
      OrderCombo.FromCombo(originalCombo)
    );
    let originalProduct: Product = null;
    if (!originalCombo.DisplayOnMenu) {
      //If we don't display this combo on the main menu, then there should be exactly one
      //product in the first ComboItem that has a quantity of 1, which was the product we upgraded to a combo
      const selectedComboProduct = editingCombo.Products[0].Products.find(
        (p) => p.Quantity > 0
      );
      if (selectedComboProduct) {
        originalProduct =
          this.menu.ProductDictionary[selectedComboProduct.ProductId];
      }
    }

    this.modalService
      .presentModal<CustomiseProductDismissEvent | undefined>({
        component: CustomiseProductComponent,
        componentProps: {
          orderCombo: editingCombo,
          originalCombo: originalCombo,
          originalProduct: originalProduct,
          inCart: true,
        },
        cssClass: 'show-header-modal',
      })
      .subscribe((data) => {
        if (data) {
          if (data.Product) {
            //Combo was converted to product only
            AppEvents.ChangeComboQuantity.emit({
              OrderCombo: this.combo,
              Change: -this.combo.Quantity,
            });
            AppEvents.AddProduct.emit(data.Product);
          } else {
            if (data.Combo.Quantity == 0) {
              //Combo was removed from cart
              AppEvents.ChangeComboQuantity.emit({
                OrderCombo: this.combo,
                Change: -this.combo.Quantity,
              });
            } else {
              OrderCombo.ApplyToOrderCombo(data.Combo, this.combo);
            }
            AppEvents.UpdateCart.emit();
          }
        }
      });
  }

  changeQuantity(event: MouseEvent, combo: OrderCombo, change: number): void {
    event.stopPropagation();
    AppEvents.ChangeComboQuantity.emit({ OrderCombo: combo, Change: change });
  }
}
