type PaymentNotAllowedReason = 'AlreadyPaid' | 'Unknown900ForShortDuration';
type PaymentMethod = 'NewPayment' | 'SavedCard';

export class PaymentResponse {
  /** Whether the payment (or payment link) attempt succeeded. */
  public Success: boolean;

  /** Messages on why getting payment page was unsuccessful. */
  public FailureMessages?: string[];

  /** Reason on why the payment was not allowed. */
  public PaymentNotAllowedReason?: PaymentNotAllowedReason;

  /** Payment Method, or null if no payment was needed. */
  public PaymentMethod?: PaymentMethod;

  /** The link if the page is required to navigate for new payment method. */
  public Link?: string;
}

export enum PaymentIntegration {
  Paymark = 'PAYMARK',
  Windcave = 'WINDCAVE',
}
