import { Component, EventEmitter, Input, Output } from '@angular/core';
import { OrderModifier } from 'src/app/models/domain/order/order-modifier';
import { EnvironmentVariables } from 'src/app/models/environment';

/**
 * Displays a single product in either a horizontal (for lists) or vertical (for
 * grid) layout.
 */
@Component({
  selector: 'app-product-card',
  templateUrl: './product-card.component.html',
  styleUrls: ['./product-card.component.scss'],
})
export class ProductCardComponent {
  /**
   * `list` for the product picture to display on the left, with the name and
   * description left aligned on the side. `card` for the product picture to
   * display on the top, with the name and description centre aligned beneath
   * it.
   */
  @Input()
  layout: 'list' | 'card';

  @Input()
  name: string;

  @Input()
  description: string;

  @Input()
  basePrice: number;

  @Input()
  totalPrice: number | null;

  @Input()
  imageUrl: string;

  @Input()
  outOfStock: boolean;

  @Input()
  isHiddenOutOfStock: boolean;

  @Input()
  itemAddText: string;

  @Input()
  itemAddTextSecondary: string;

  @Input()
  modifierErrorText: string;

  @Input()
  modifiers: OrderModifier[];

  @Input()
  showModifiers: boolean;

  @Output()
  modifySelected = new EventEmitter();

  @Input()
  showActionButtons: boolean;

  @Output()
  primarySelected = new EventEmitter();

  @Output()
  secondarySelected = new EventEmitter();

  @Output()
  imageSelected = new EventEmitter();

  @Output()
  cardSelected = new EventEmitter();

  constructor(public variables: EnvironmentVariables) {}

  /**
   * The main button was pressed.
   */
  async selectPrimary(event?: MouseEvent): Promise<void> {
    if (event) {
      event.stopPropagation();
    }

    this.primarySelected.emit();
  }

  /**
   * The second button was pressed.
   */
  async selectSecondary(event?: MouseEvent): Promise<void> {
    if (event) {
      event.stopPropagation();
    }

    this.secondarySelected.emit();
  }

  /**
   * The image was pressed.
   */
  async selectImage(event?: MouseEvent): Promise<void> {
    if (event) {
      event.stopPropagation();
    }

    this.imageSelected.emit();
  }

  /**
   * The card was pressed, not a button.
   */
  async selectCard(event?: MouseEvent): Promise<void> {
    if (event) {
      event.stopPropagation();
    }

    this.cardSelected.emit();
  }

  /**
   * The customise/modifications text was selected.
   */
  async selectModify(event?: MouseEvent): Promise<void> {
    if (event) {
      event.stopPropagation();
    }

    this.modifySelected.emit();
  }
}
