import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { makeURLStartWithHttp } from 'src/app/helpers/urlHelpers';
import { Banner } from 'src/app/models/domain/online-configuration';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
})
export class BannerComponent implements OnChanges {
  @Input()
  banner: Banner;

  @Input()
  warning = false;

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    const newBanner = changes.banner.currentValue as Banner;
    if (newBanner && newBanner.Link !== changes.banner.previousValue?.Link) {
      newBanner.Link = makeURLStartWithHttp(newBanner.Link); //avoid redirect issues
    }
  }

  closeBanner(): void {
    window.localStorage.setItem(this.banner.Name, this.banner.Text);
    this.banner.Text = '';
  }
}
