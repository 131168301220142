import { Component } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ViewDidEnter, ViewDidLeave } from '@ionic/angular';
import { first, map } from 'rxjs/operators';
import { AppEvents } from 'src/app/models/domain/events/app-events';
import { NavigationEvents } from 'src/app/models/domain/events/navigation-events';
import { BehaviorSubject, Subscription, timer } from 'rxjs';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss'],
})
export class PaymentComponent implements ViewDidEnter, ViewDidLeave {
  /**
   * The link of the payment gateway to embed in the iframe.
   */
  public paymentLink: SafeResourceUrl | null;

  /**
   * `true` when the page has timed out. This is used to show some kind of loading page to the user
   */
  public timedOut$ = new BehaviorSubject<boolean>(false);
  private subscriptions: Subscription[];

  constructor(private domSanitizer: DomSanitizer) {}

  ionViewDidEnter(): void {
    this.timedOut$.next(false);
    (window as any).paymentFinished = this.paymentFinished;
    this.timedOut$.next(false);
    this.subscriptions = [
      timer(2000)
        .pipe(
          first(),
          map(() => true)
        )
        .subscribe(() => this.timedOut$.next(true)),
      AppEvents.PaymentLink.pipe(
        map((ev) =>
          ev?.link
            ? this.domSanitizer.bypassSecurityTrustResourceUrl(ev.link)
            : null
        )
      ).subscribe((link) => (this.paymentLink = link)),
    ];
  }

  private paymentFinished(newUrl: string) {
    const urlParams = new URLSearchParams(
      newUrl.substring(newUrl.indexOf('?'))
    );
    NavigationEvents.NavigateToPaymentResult.emit({
      idSignature: urlParams.get('idsignature'),
      status: urlParams.get('status'),
      sessionId: urlParams.get('sessionId'),
    });
  }

  ionViewDidLeave(): void {
    AppEvents.PaymentLink.next(null);
    delete (window as any).paymentFinished;
    this.subscriptions.forEach((s) => s.unsubscribe());
  }
}
