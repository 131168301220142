import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ModalComponent } from 'src/app/models/view-models/modal-component';

/**
 * Displays an error message that could be queued from anywhere in the app.
 *
 * @param title Large title displayed at the top of the modal.
 * @param header Text to be displayed before the error message.
 * @param error Error message.
 *
 * @example {title: "Error", header: "There was an error", error: "An unexpected problem occurred"}
 * displays as
 * |------------------------------------|
 * |                Error               |
 * |   There was an error:              |
 * |   An unexpected problem occurred   |
 * |                                    |
 * |                [OK]                |
 * |------------------------------------|
 */
@Component({
  selector: 'app-error-modal',
  templateUrl: './error-modal.component.html',
  styleUrls: ['./error-modal.component.scss'],
})
export class ErrorModalComponent extends ModalComponent {
  /** Large title displayed at the top of the modal. */
  @Input()
  title?: string;

  /** Text to be displayed before the error message. */
  @Input()
  header?: string;

  /** Error message. */
  @Input()
  error: string;

  constructor(modalController: ModalController) {
    super(modalController);
  }

  dismiss(): void {
    super.dismiss(undefined);
  }
}
