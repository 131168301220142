import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { ModalController, ViewWillEnter, ViewWillLeave } from '@ionic/angular';
import { combineLatest, Subscription } from 'rxjs';
import { filter, first } from 'rxjs/operators';
import { NavigationEvents } from '../../models/domain/events/navigation-events';
import { OrderPage } from '../../models/domain/navigation-page';
import { AppEvents } from 'src/app/models/domain/events/app-events';
import { SaleType, Store } from 'src/app/models/domain/store';
import { OrderService } from 'src/app/services/order.service';
import { IMemberService } from 'src/app/services/member.service';
import { ConfirmModalComponent } from 'src/app/components/confirm-modal/confirm-modal.component';
import { LoginModalComponent } from 'src/app/components/login/login-modal/login-modal.component';
import { ErrorModalComponent } from 'src/app/components/error-modal/error-modal.component';
import { MemberEvents } from 'src/app/models/domain/events/member-events';
import { MenuService } from 'src/app/services/menu.service';
import { Order } from 'src/app/models/domain/order/order';
import { OnlineConfiguration } from 'src/app/models/domain/online-configuration';

@Component({
  selector: 'app-table-ordering',
  templateUrl: './table-ordering.component.html',
  styleUrls: ['./table-ordering.component.scss'],
})
export class TableOrderingComponent
  implements OnDestroy, ViewWillEnter, ViewWillLeave
{
  public sessionRequested = false;
  public tableNumber: number;
  public stores: Array<Store>;

  public subscriptions: Array<Subscription> = [];

  public onlineConfiguration: OnlineConfiguration;

  constructor(
    public modalController: ModalController,
    public route: ActivatedRoute,
    public orderService: OrderService,
    public menuService: MenuService,
    public memberService: IMemberService
  ) {}

  ionViewWillEnter(): void {
    this.subscriptions = [
      combineLatest([
        AppEvents.OrderPreLoaded.pipe(filter((o) => o != null)),
        this.route.params.pipe(first()),
      ]).subscribe(([order, params]) => {
        this.setTableOrderingSaleType(order, params);
        this.subscriptions.push(
          combineLatest([
            AppEvents.OrderLoaded.pipe(filter((o) => o != null)),
            AppEvents.Stores.pipe(filter((s) => s != null)),
            //Wait for member service to finish
            //If customer is already logged in, no need to request session mode
            this.memberService.initialised.pipe(filter((e) => e)),
          ]).subscribe((e) => {
            this.stores = e[1];
            this.applyToOrder();
          })
        );
      }),
      MemberEvents.LoggedOut.subscribe(() => {
        this.requestSessionMode();
      }),
      AppEvents.OnlineConfiguration.subscribe((o) => {
        this.onlineConfiguration = o;
      }),
    ];
  }

  //We call this on Order Preload so we can  set the sale type to Table Ordering
  //And the store id to the correct param
  //Then Order Service will set the correct menu to load between OrderPreLoaded and OrderLoaded
  setTableOrderingSaleType(order: Order, params: Params): void {
    this.orderService.order.IsTableOrder = true;

    if (this.orderService.order.SaleType?.Code != SaleType.TableOrderCode) {
      //If we changed from non table ordering, then we should set IsGuestOrder to false
      //This is so we RequestSessionMode, but only if we changed from non table ordering
      this.orderService.order.IsGuestOrder = false;
    }

    const storeId: string = params.store;
    order.Store = new Store({
      Id: storeId,
    });

    this.tableNumber = parseInt(params.table, 10);
    if (!isNaN(this.tableNumber)) {
      this.orderService.order.TableNumber = this.tableNumber;
    }
    order.SaleType = new SaleType({
      Code: SaleType.TableOrderCode,
      Name: SaleType.TableOrder,
    });

    //Table ordering is always immediate, so we clear order day and time
    order.OrderDay = null;
    order.OrderTime = null;
  }

  async applyToOrder(): Promise<void> {
    let error: string = null;

    const localFoundStore = AppEvents.Stores.value.find(
      (s) => s.Id == this.orderService.order?.Store?.Id
    );
    const storeIsFromList = AppEvents.Stores.value.includes(
      this.orderService.order?.Store
    );
    if (localFoundStore != null && !storeIsFromList) {
      //The store exists, but the order service was unable to set it at load time
      //Set it here
      AppEvents.ChangeStore.emit({
        Store: localFoundStore,
        Order: this.orderService.order,
        PreventSalesTypeChange: true,
      });
    }

    if (!this.orderService.order?.Store) {
      error = 'We could not find that store.';
    } else if (
      !this.orderService.order?.Store?.OnlineOrderingActive ||
      !this.orderService.order?.Store?.TableOrder?.IsActive ||
      !this.orderService.order.Store.TableOrder?.IsOpen
    ) {
      error = ' Store is not accepting table orders at the moment.';
    } else if (
      isNaN(this.tableNumber) ||
      this.tableNumber >
        this.orderService.order?.Store?.TableOrderMaximumTableNumber ||
      this.tableNumber < 0
    ) {
      error = 'This table is invalid.';
    }

    if (!error) {
      //If enter here means that the question has not been asked yet
      if (
        this.memberService.currentMember == null &&
        this.orderService.order.IsGuestOrder == false
      ) {
        this.requestSessionMode();
      }
    } else {
      error +=
        '\r\nClick ok to continue with an online order or head to the counter.';
      const errorModal = await this.modalController.create({
        component: ErrorModalComponent,
        componentProps: {
          title: 'Oops',
          error: error,
        },
        cssClass: 'curbside-warning-modal',
      });
      errorModal.onDidDismiss().then(() => {
        NavigationEvents.NavigateToOrderPage.emit({
          Page: OrderPage.MainMenu,
          Order: this.orderService.order,
        });
      });
      await errorModal.present();
    }
  }

  async requestSessionMode(): Promise<void> {
    if (!this.sessionRequested) {
      this.sessionRequested = true;
      const confirmModal = await this.modalController.create({
        component: ConfirmModalComponent,
        componentProps: {
          title: 'Welcome to',
          html:
            '<h1>' +
            this.orderService.order.Store.DisplayName +
            '</h1>' +
            '<br><h4>Ordering for</h4>' +
            '<h1>Table ' +
            this.orderService.order.TableNumber +
            '</h1>' +
            '<br><h5>Order and pay here</h5>' +
            "<h5>We'll bring your order to your table</h5>",
          yes: 'Sign in',
          no: 'Continue as guest',
          orientation: 'vertical',
        },
        cssClass: 'confirm-modal auto-size-modal',
      });
      confirmModal.onDidDismiss().then((d) => {
        if (d.data) {
          //IsGuestOrder will be determined by the result of the login modal
          this.showLoginModal();
        } else {
          if (this.orderService.order) {
            this.orderService.order.IsGuestOrder = true;
          }
        }
      });
      await confirmModal.present();
    }
  }

  async showLoginModal(): Promise<void> {
    const loginModal = await this.modalController.create({
      component: LoginModalComponent,
      componentProps: {
        loginModel: this.memberService.loginModel,
      },
      cssClass: 'show-header-modal login-modal',
    });
    loginModal.onDidDismiss().then(() => {
      //If the login modal was dismissed, and no member is logged in
      //We can assume the customer canceled out of logging in and would
      //like to continue as a guest
      this.orderService.order.IsGuestOrder =
        this.memberService.currentMember == null;
    });
    await loginModal.present();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => {
      s.unsubscribe();
    });
  }

  ionViewWillLeave(): void {
    this.ngOnDestroy();
  }
}
