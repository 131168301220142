/**
 * A modification to an order, either on a combo or a product.
 */
export class Modifier {
  /** ID of the modifier. */
  public Id: string;
  /** Name displayed to the customer for the modifier. */
  public Name: string;
  /** Internal name of the modifier. */
  public InternalName: string;
  /**
   * Price of the modifier. This should only be applied when `Included` is
   * `false` and `Selected` is `true`.
   */
  public Price: number;
  /**
   * Whether the modifier is applied to the combo or product by default. There
   * is no cost added to the combo/product if `Included` is `true`, because the
   * price of the combo/product factors in the modifier already.
   */
  public Included: boolean;
  /**
   * How the modifier is displayed in relation to other modifiers on the same
   * combo/product.
   */
  public DisplayOrder: number;
  /** How the modifier should be grouped. */
  public Category: string;
  /**
   * Lookup code of the modifier when it is added (i.e., Included is `false`).
   */
  public PLU: number;
  /**
   * Lookup code of the modifier when it is removed (i.e., Included is `true`).
   */
  public RemovedPLU: number;

  // Dietary information:

  /** The product contains gluten. */
  public isGlutenAllergy: boolean;
  /** The product contains dairy. */
  public isDairyAllergy: boolean;
  /** The product contains peanut. */
  public isPeanutAllergy: boolean;
  /** The product contains egg. */
  public isEggAllergy: boolean;
  /** The product contains tomato. */
  public isTomatoAllergy: boolean;

  // Front end only
  /**
   * Whether the item is out of stock.
   */
  public OutOfStock: boolean;

  constructor(o: Partial<Modifier>) {
    Object.assign(this, o);
  }
}
