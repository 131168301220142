import {
  addDays as dateFnsAddDays,
  addHours as dateFnsAddHours,
  addMilliseconds as dateFnsAddMilliseconds,
  addMinutes as dateFnsAddMinutes,
  addMonths as dateFnsAddMonths,
  addSeconds as dateFnsAddSeconds,
  addYears as dateFnsAddYears,
  differenceInCalendarDays as dateFnsDifferenceInCalendarDays,
  differenceInMinutes as dateFnsDifferenceInMinutes,
  isSameDay as dateFnsIsSameDay,
} from 'date-fns';
import { DatePipe } from '@angular/common';

/**
 * Creates a new date to the start of the day of the provided date.
 *
 * @param date - The date to return the start of.
 * @returns - A new Date object representing the start of the day for the provided date.
 */
export const startOfDay = (date: Date): Date =>
  new Date(date.getFullYear(), date.getMonth(), date.getDate());

/**
 * Checks if two dates represent the same day.
 *
 * @param left - The first date to compare.
 * @param right - The second date to compare.
 * @returns - Returns true if the dates are the same day, otherwise false.
 */
export const isSameDay = (left: Date | number, right: Date | number): boolean =>
  dateFnsIsSameDay(left, right);

/**
 * Returns the difference in minutes between the two dates. If the first date is
 * larger, the result will be positive, otherwise negative.
 *
 * @param left The first date/timestamp.
 * @param right The second date/timestamp.
 */
export const differenceInMinutes = (
  left: Date | number,
  right: Date | number
): number => dateFnsDifferenceInMinutes(left, right);

/**
 * Returns the difference in calendar days (i.e. without time component) between
 * the two dates. If the first date is larger, the result will be positive,
 * otherwise negative.
 *
 * @param left The first date/timestamp.
 * @param right The second date/timestamp.
 */
export const differenceInCalendarDays = (
  left: Date | number,
  right: Date | number
): number => dateFnsDifferenceInCalendarDays(left, right);

type AddTimeParameters = {
  years?: number;
  months?: number;
  days?: number;
  hours?: number;
  minutes?: number;
  seconds?: number;
  milliseconds?: number;
};

/**
 * Adds specified time to the given date.
 *
 * @param date - The date to which the time will be added.
 * @param toAdd - The time to be added, specified as an object with optional properties.
 * @returns The modified date with the added time.
 */
export const addTime = (
  date: Date | number,
  toAdd: AddTimeParameters
): Date => {
  let result: Date;
  if (typeof date === 'number') {
    result = new Date(date);
  } else {
    result = date;
  }

  if (toAdd.years) result = dateFnsAddYears(result, toAdd.years);
  if (toAdd.months) result = dateFnsAddMonths(result, toAdd.months);
  if (toAdd.days) result = dateFnsAddDays(result, toAdd.days);
  if (toAdd.hours) result = dateFnsAddHours(result, toAdd.hours);
  if (toAdd.minutes) result = dateFnsAddMinutes(result, toAdd.minutes);
  if (toAdd.seconds) result = dateFnsAddSeconds(result, toAdd.seconds);
  if (toAdd.milliseconds)
    result = dateFnsAddMilliseconds(result, toAdd.milliseconds);

  return result;
};

/**
 * Represents a utility class for working with date formatting and localization.
 */
const datePipe: DatePipe = new DatePipe('en-US');

/**
 * Formats a given date to UTC with its offset specified.
 */
export const toUtcWithOffset = (date: Date): string => {
  return datePipe.transform(date, 'yyyy-MM-ddTHH:mm:ssZZZZZ');
};
