import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { register, SwiperContainer } from 'swiper/element/bundle';
import { IonicSlides } from '@ionic/angular';
import { environment } from '../../../environments/environment';
import { CarouselElement } from './model/CarouselElement';

// Register swiper custom elements https://swiperjs.com/element#install--register-from-npm
register();

/**
 * Displays a carousel with elements and emits an event when an element is
 * clicked in the carousel.
 */
@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss'],
})
export class CarouselComponent<
  TElement extends CarouselElement = CarouselElement
> implements AfterViewInit
{
  /**
   * The elements to display in the carousel.
   */
  @Input()
  elements: TElement[];

  /**
   * Emits when an element of the carousel is clicked.
   */
  @Output()
  elementSelected = new EventEmitter<TElement>();

  /**
   * A reference to the native swiper dom element.
   */
  @ViewChild('swiper')
  swiperRef: ElementRef<SwiperContainer> | undefined;

  // Type is `SwiperOptions` but the type import is broken
  config: any = {
    speed: environment.variables.sliderSpeed / 4,
    autoplay: {
      delay: environment.variables.sliderSpeed,
    },
    slidesPerView: 1,
    pagination: true,
    freeMode: false,
    allowTouchMove: false,
    preventInteractionOnTransition: true,
  };
  // Type is `SwiperModule[]` but the type import is broken
  modules: any[] = [IonicSlides];

  ngAfterViewInit(): void {
    if (this.swiperRef) {
      this.swiperRef.nativeElement.swiper.autoplay.start();
    }
  }

  /**
   * Called when an element is clicked.
   */
  carouselImageClicked(element: TElement): void {
    this.elementSelected.emit(element);
  }
}
