import { Component, OnInit } from '@angular/core';
import { AppEvents } from 'src/app/models/domain/events/app-events';
import { OnlineConfiguration } from 'src/app/models/domain/online-configuration';
import { EnvironmentVariables } from 'src/app/models/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  onlineConfiguration: OnlineConfiguration;
  fullYear = new Date().getFullYear();

  constructor(public variables: EnvironmentVariables) {
    AppEvents.OnlineConfiguration.subscribe((o) => {
      this.onlineConfiguration = o;
    });
  }

  ngOnInit() {}

  navigateToApp() {
    if (!this.onlineConfiguration) return;
    if (!this.onlineConfiguration.AppUrl) return;
    window.open(this.onlineConfiguration.AppUrl, '_blank');
  }
}
