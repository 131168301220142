import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ModalComponent } from 'src/app/models/view-models/modal-component';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss'],
})
export class ConfirmModalComponent extends ModalComponent<boolean> {
  @Input()
  title: string;

  @Input()
  message: string;

  @Input()
  html: string;

  @Input()
  yes: string;

  @Input()
  no: string;

  @Input()
  orientation = 'horizontal';

  constructor(modalController: ModalController) {
    super(modalController);
  }

  confirm(): void {
    super.dismiss(true);
  }

  dismiss(): void {
    super.dismiss(false);
  }
}
